import React from "react";

function Culturehero() {
  return (
    <div className="culture">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-md-5 our-culture order-md-1 order-2">
            <span>OUR CULTURE</span>
            <div>
              <h1>Walking the walk—together</h1>
            </div>
            <div>
              <p>
                When it comes to fostering a positive, vibrant workplace
                culture, we truly believe that when our employees feel empowered
                to be who they really are, everyone benefits.
              </p>
            </div>
          </div>
          <div className="col-md-6 walking order-md-2 order-1">
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Culturehero;
