import React from "react";

function Discover() {
  return (
    <div className="discover">
      <div className="container py-5">
        <div className="row justify-content-center pt-5">
          <div className="col-md-11 text-center">
            <div>
              <h1>Discover joy. Build relationships. At work.</h1>
            </div>
            <div>
              <p>
                Every day, we empower and support our employees in their mission
                to give back and make a positive impact in their communities
                through ample opportunities to volunteer and collaborate with
                other organizations focused on youth health and education.
              </p>
            </div>
            <div>
              {/* <button className="btn rounded-5">Apply Now</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discover;
