import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShopProduct, getSingleProduct } from "../../Services";
import { useNavigate, useParams } from "react-router-dom";
import "./ShopDetail.css";
import { baseURL } from "../../Services/axios";
import { CartButton } from "../../Components/ShopCard";
import ShopCart from "../Cart/ShopCart";
import { APP } from "../../Redux/ActionTypes";
import Loader from "../../Components/Loader/Loader";
import TabTitle from "../../Services/TabTitle";

const ShopDetail = () => {
  const [openCart, setOpenCart] = useState(false);
  const [loader, setLoader] = useState(true);
  const [product, setProduct] = useState({});
  const [select, setSelect] = useState("");
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.app);
  const param = useParams();
  const navigate = useNavigate()
  let searchParams = new URLSearchParams(window.location.search);
  let campaign_id = searchParams.get('id');
  let link_code = searchParams.get('linkCode');
  TabTitle(`${product.title ? product.title : 'Product'}`)
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  const getData = async (slug) => {
    try {
      const response = await getSingleProduct(slug);
      if (response) {
        let productPrices = response.product_variations.map((obj) => {
          return {
            label: {
              text: obj.name,
              price: obj.price,
            },
            value: obj.id,
          };
        });
        setProduct((prev) => ({
          ...prev,
          ...response,
          productPrices: productPrices,
        }));
        setSelect(productPrices[0]?.value);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (param.slug) {
      getData(param.slug)
    }
  }, [param]);

  return loader ? <>
    <Loader
    />
  </> : (
    <div className="container-xxl">
      <div className="mt-5  py-3">
        <ShopButton onClick={() => { navigate(-1) }} />
      </div>
      <div className="row mt-4 py-1 justify-content-between mb-5">
        <div className="col-md-8 pe-md-5"
        // style={{ paddingRight: "4rem" }}
        >
          <section className="row">
            {
              <>
                <div className="shopCard col-md-6 p-2 card-with-hover">
                  <img
                    style={{ borderRadius: "10px" }}
                    src={`${baseURL}/${product.image}`}
                    alt={product.imageAlt}
                  />
                </div>
              </>
            }
          </section>
        </div>
        <div className="col-md-4">
          <section className="row">
            <div className="h1 shopDetailHeading">{product.title}</div>

            <div className="cardHeadingOne">
              {product?.product_categories?.map((obj, index, array) => (
                <React.Fragment key={obj.category.id}>
                  <span>{obj.category.name}</span>
                  {index < array.length - 1 && <span> , </span>}
                </React.Fragment>
              ))}
            </div>

            <div
              className="cardDescription "
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>

            <div className="mt-5">
              {product?.productPrices?.length > 1 && (
                <div className="selectCartHeading mt-5">
                  How many would you like?
                </div>
              )}

              <div>
                {product?.productPrices?.map((pack, index) => (
                  <div className={index > 0 ? `mt-2` : ""} key={pack.value}>
                    <ShopRadioButton
                      checked={(select) == pack.value}
                      name={pack.value}
                      value={pack.value}
                      label={pack.label}
                      onClick={(e) => {
                        setSelect(e.target.name);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex gap-2 ">
                <svg
                  className="mt-1"
                  width="30px"
                  height="30px"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                >
                  <path d="M617.045 106.379c33.655-13.946 69.73-21.123 106.159-21.123 36.433 0 72.508 7.178 106.163 21.123 33.647 13.943 64.222 34.378 89.975 60.138 25.758 25.751 46.208 56.339 60.151 89.989 13.943 33.656 21.12 69.73 21.12 106.161s-7.177 72.503-21.12 106.159c-13.943 33.655-34.381 64.23-60.147 89.984 0 0.004 0.004-0.004 0 0l-377.173 377.173c-16.661 16.661-43.678 16.661-60.339 0l-377.173-377.173c-52.020-52.019-81.245-122.577-81.245-196.143 0-73.568 29.225-144.123 81.245-196.143s122.575-81.245 196.143-81.245c73.568 0 144.123 29.225 196.142 81.245l15.057 15.057 15.049-15.050c0.004-0.003 0 0.002 0 0 25.754-25.763 56.337-46.208 89.993-60.152zM723.204 170.589c-25.22 0-50.193 4.969-73.493 14.624s-44.471 23.805-62.302 41.644l-45.235 45.234c-16.661 16.662-43.678 16.662-60.339 0l-45.227-45.227c-36.017-36.017-84.867-56.252-135.803-56.252s-99.786 20.234-135.803 56.252c-36.017 36.017-56.252 84.867-56.252 135.803s20.234 99.785 56.252 135.804l347.002 347.004 347.004-347.004c17.839-17.83 31.996-39.006 41.651-62.306 9.655-23.302 14.622-48.276 14.622-73.498s-4.966-50.196-14.622-73.496c-9.655-23.3-23.804-44.47-41.643-62.3-17.83-17.839-39.014-32.003-62.315-41.658s-48.277-14.624-73.498-14.624z"></path>
                </svg>
                <p className="shopParagraph">
                  Each order is handcrafted and ships anywhere in the USA.
                </p>
              </div>
              <CartButton title="Add to Cart" className="w-100"
                onClick={() => {
                  setOpenCart(true)
                  dispatch({
                    type: APP.ADD_CART_ITEM, payload: {
                      link_code: link_code, type: product?.type, id: product.id,
                      price: product.productPrices?.length > 0 ? `${base_currency}` + product?.productPrices?.find(obj => obj.value == select)?.label?.price : product?.price,
                      image: product.image, title: product.title, type: product?.type
                    }
                  });
                }}
                price={product.productPrices?.length > 0 ? product?.productPrices?.find(obj => obj.value == select)?.label?.price : parseFloat(product?.price?.replace("$", ""))} />
            </div>
          </section>
        </div>
      </div>
      <ShopCart openCart={openCart} onHideCart={() => { setOpenCart(false) }} />
    </div>
  );
};

export default ShopDetail;

const ArrowIcon = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline-block', verticalAlign: 'middle' }}
    >
      <path d="M227.555 512c0-19.635 15.919-35.554 35.555-35.554h497.777c19.639 0 35.558 15.919 35.558 35.554s-15.919 35.554-35.558 35.554h-497.777c-19.637 0-35.555-15.919-35.555-35.554z"></path>
      <path d="M537.143 237.969c13.884 13.885 13.884 36.398 0 50.283l-223.75 223.747 223.75 223.748c13.884 13.884 13.884 36.399 0 50.283-13.888 13.884-36.399 13.884-50.287 0l-248.887-248.887c-13.885-13.888-13.885-36.399 0-50.287l248.887-248.887c13.888-13.885 36.399-13.885 50.287 0z"></path>
    </svg>
  );
};

export const ShopButton = ({ onClick, icon, title = 'Continue Shopping', disabled }) => {
  return (
    <div aria-disabled={disabled} className={`shopButton ${disabled ? 'shopButton-disabled' : ''} `} onClick={onClick}>
      <span style={{ marginLeft: "0.6rem" }}>{title}</span>
      {
        icon == 'none' ? <></> :
          icon == 'back' ? <>
            <ArrowIcon />
          </>
            :
            <>
              <svg
                className=""
                width="24px"
                height="24px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "8px",
                }}
              >
                <path d="M750.477 273.524c13.884 13.885 13.884 36.398 0 50.284l-426.669 426.665c-13.885 13.888-36.398 13.888-50.283 0-13.885-13.884-13.885-36.395 0-50.283l426.665-426.665c13.888-13.885 36.399-13.885 50.287 0z"></path>
                <path d="M273.525 273.524c13.885-13.885 36.398-13.885 50.283 0l426.669 426.665c13.884 13.888 13.884 36.399 0 50.283-13.888 13.888-36.399 13.888-50.287 0l-426.665-426.665c-13.885-13.885-13.885-36.398 0-50.284z"></path>
              </svg>
            </>
      }
    </div>
  );
};

export const ShopRadioButton = ({
  disabled,
  value,
  checked,
  onClick,
  label,
  name,
  className,
}) => {

  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  return (
    <div className={``} onClick={onClick}>
      <button
        className="w-100 btn shopRadio"
        name={name}
        role="radio"
        aria-checked={checked}
        aria-label={value}
        style={{
          marginRight: "10px",
          width: "100%",
          backgroundColor: checked ? "rgb(204, 229, 255)" : "white",
          border: checked ? `1px solid rgb(41, 115, 225)` : "",
        }}
        disabled={disabled}
      >
        <span>{label.text}</span>
        <span>{base_currency}{label.price}</span>
      </button>
    </div>
  );
};
