export const StatesList = [
    { value: 'Alaska', label: 'Alaska' },
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'Armed Forces Americas', label: 'Armed Forces Americas' },
    { value: 'Armed Forces Europe', label: 'Armed Forces Europe' },
    { value: 'Armed Forces Pacific', label: 'Armed Forces Pacific' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'District Of Columbia', label: 'District Of Columbia' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
  ];
  
export const errorMessages = {
  required:'This field is required',
  fillRequiredFields:"Please fill all required fields"
}


export const DIAL_CODES = [
  { label: 'US', value: '+1' },
  { label: 'AF', value: '+93' },
  { label: 'PK', value: '+92' },
  { label: 'AL', value: '+355' },
  { label: 'DZ', value: '+213' },
  { label: 'AD', value: '+376' },
  { label: 'AO', value: '+244' },
  { label: 'AR', value: '+54' },
  { label: 'AM', value: '+374' },
  { label: 'AW', value: '+297' },
  { label: 'AU', value: '+61' },
  { label: 'AT', value: '+43' },
  { label: 'AZ', value: '+994' },
  { label: 'BH', value: '+973' },
  { label: 'BD', value: '+880' },
  { label: 'BY', value: '+375' },
  { label: 'BE', value: '+32' },
  { label: 'BZ', value: '+501' },
  { label: 'BJ', value: '+229' },
  { label: 'BT', value: '+975' },
  { label: 'VE', value: '+58' },
  { label: 'BO', value: '+591' },
  { label: 'BQ', value: '+599' },
  { label: 'BA', value: '+387' },
  { label: 'BW', value: '+267' },
  { label: 'BR', value: '+55' },
  { label: 'IO', value: '+246' },
  { label: 'BN', value: '+673' },
  { label: 'BG', value: '+359' },
  { label: 'BF', value: '+226' },
  { label: 'BI', value: '+257' },
  { label: 'CV', value: '+238' },
  { label: 'KH', value: '+855' },
  { label: 'CM', value: '+237' },
  { label: 'CA', value: '+1' },
  { label: 'CF', value: '+236' },
  { label: 'TD', value: '+235' },
  { label: 'CL', value: '+56' },
  { label: 'CN', value: '+86' },
  { label: 'CX', value: '+61' },
  { label: 'CC', value: '+61' },
  { label: 'CO', value: '+57' },
  { label: 'KM', value: '+269' },
  { label: 'CG', value: '+242' },
  { label: 'CD', value: '+243' },
  { label: 'CK', value: '+682' },
  { label: 'CR', value: '+506' },
  { label: 'CI', value: '+225' },
  { label: 'HR', value: '+385' },
  { label: 'CU', value: '+53' },
  { label: 'CW', value: '+599' },
  { label: 'CY', value: '+357' },
  { label: 'CZ', value: '+420' },
  { label: 'TL', value: '+670' },
  { label: 'DK', value: '+45' },
  { label: 'DJ', value: '+253' },
  { label: 'EC', value: '+593' },
  { label: 'EG', value: '+20' },
  { label: 'SV', value: '+503' },
  { label: 'GQ', value: '+240' },
  { label: 'ER', value: '+291' },
  { label: 'EE', value: '+372' },
  { label: 'ET', value: '+251' },
  { label: 'FK', value: '+500' },
  { label: 'FO', value: '+298' },
  { label: 'FJ', value: '+679' },
  { label: 'FI', value: '+358' },
  { label: 'FR', value: '+33' },
  { label: 'GF', value: '+594' },
  { label: 'PF', value: '+689' },
  { label: 'GA', value: '+241' },
  { label: 'GM', value: '+220' },
  { label: 'GE', value: '+995' },
  { label: 'DE', value: '+49' },
  { label: 'GH', value: '+233' },
  { label: 'GI', value: '+350' },
  { label: 'GR', value: '+30' },
  { label: 'GL', value: '+299' },
  { label: 'GP', value: '+590' },
  { label: 'GT', value: '+502' },
  { label: 'GN', value: '+224' },
  { label: 'GW', value: '+245' },
  { label: 'GY', value: '+592' },
  { label: 'HT', value: '+509' },
  { label: 'HM', value: '+ ' },
  { label: 'HN', value: '+504' },
  { label: 'HK', value: '+852' },
  { label: 'HU', value: '+36' },
  { label: 'IS', value: '+354' },
  { label: 'IN', value: '+91' },
  { label: 'ID', value: '+62' },
  { label: 'IR', value: '+98' },
  { label: 'IQ', value: '+964' },
  { label: 'IE', value: '+353' },
  { label: 'IL', value: '+972' },
  { label: 'IT', value: '+39' },
  { label: 'SJ', value: '+47' },
  { label: 'JP', value: '+81' },
  { label: 'JO', value: '+962' },
  { label: 'KZ', value: '+7' },
  { label: 'KE', value: '+254' },
  { label: 'KI', value: '+686' },
  { label: 'KR', value: '+82' },
  { label: 'KW', value: '+965' },
  { label: 'KG', value: '+996' },
  { label: 'LA', value: '+856' },
  { label: 'LV', value: '+371' },
  { label: 'LB', value: '+961' },
  { label: 'LS', value: '+266' },
  { label: 'LR', value: '+231' },
  { label: 'LY', value: '+218' },
  { label: 'LI', value: '+423' },
  { label: 'LT', value: '+370' },
  { label: 'LU', value: '+352' },
  { label: 'MO', value: '+853' },
  { label: 'MK', value: '+389' },
  { label: 'MG', value: '+261' },
  { label: 'MW', value: '+265' },
  { label: 'MY', value: '+60' },
  { label: 'MV', value: '+960' },
  { label: 'ML', value: '+223' },
  { label: 'MT', value: '+356' },
  { label: 'MH', value: '+692' },
  { label: 'MQ', value: '+596' },
  { label: 'MR', value: '+222' },
  { label: 'MU', value: '+230' },
  { label: 'YT', value: '+262' },
  { label: 'MX', value: '+52' },
  { label: 'FM', value: '+691' },
  { label: 'MD', value: '+373' },
  { label: 'MC', value: '+377' },
  { label: 'MN', value: '+976' },
  { label: 'ME', value: '+382' },
  { label: 'MA', value: '+212' },
  { label: 'MZ', value: '+258' },
  { label: 'MM', value: '+95' },
  { label: 'NA', value: '+264' },
  { label: 'NR', value: '+674' },
  { label: 'NP', value: '+977' },
  { label: 'NL', value: '+31' },
  { label: 'NC', value: '+687' },
  { label: 'NZ', value: '+64' },
  { label: 'NI', value: '+505' },
  { label: 'NE', value: '+227' },
  { label: 'NG', value: '+234' },
  { label: 'NU', value: '+683' },
  { label: 'NF', value: '+672' },
  { label: 'KP', value: '+850' },
  { label: 'NO', value: '+47' },
  { label: 'OM', value: '+968' },
  { label: 'PW', value: '+680' },
  { label: 'PS', value: '+970' },
  { label: 'PA', value: '+507' },
  { label: 'PG', value: '+675' },
  { label: 'PY', value: '+595' },
  { label: 'PE', value: '+51' },
  { label: 'PH', value: '+63' },
  { label: 'PN', value: '+870' },
  { label: 'PL', value: '+48' },
  { label: 'PT', value: '+351' },
  { label: 'QA', value: '+974' },
  { label: 'RE', value: '+262' },
  { label: 'RO', value: '+40' },
  { label: 'RU', value: '+7' },
  { label: 'RW', value: '+250' },
  { label: 'BL', value: '+590' },
  { label: 'SH', value: '+290' },
  { label: 'MF', value: '+590' },
  { label: 'PM', value: '+508' },
  { label: 'WS', value: '+685' },
  { label: 'SM', value: '+378' },
  { label: 'ST', value: '+239' },
  { label: 'SA', value: '+966' },
  { label: 'SN', value: '+221' },
  { label: 'RS', value: '+381' },
  { label: 'SC', value: '+248' },
  { label: 'SL', value: '+232' },
  { label: 'SG', value: '+65' },
  { label: 'SX', value: '+599' },
  { label: 'SK', value: '+421' },
  { label: 'SI', value: '+386' },
  { label: 'SB', value: '+677' },
  { label: 'SO', value: '+252' },
  { label: 'ZA', value: '+27' },
  { label: 'SS', value: '+211' },
  { label: 'ES', value: '+34' },
  { label: 'LK', value: '+94' },
  { label: 'SD', value: '+249' },
  { label: 'SR', value: '+597' },
  { label: 'SJ', value: '+47' },
  { label: 'SZ', value: '+268' },
  { label: 'SE', value: '+46' },
  { label: 'CH', value: '+41' },
  { label: 'SY', value: '+963' },
  { label: 'TW', value: '+886' },
  { label: 'TJ', value: '+992' },
  { label: 'TZ', value: '+255' },
  { label: 'TH', value: '+66' },
  { label: 'TG', value: '+228' },
  { label: 'TK', value: '+690' },
  { label: 'TO', value: '+676' },
  { label: 'TN', value: '+216' },
  { label: 'TR', value: '+90' },
  { label: 'TM', value: '+993' },
  { label: 'TV', value: '+688' },
  { label: 'UM', value: '+1' },
  { label: 'UG', value: '+256' },
  { label: 'UA', value: '+380' },
  { label: 'AE', value: '+971' },
  { label: 'GB', value: '+44' },
  { label: 'UY', value: '+598' },
  { label: 'UZ', value: '+998' },
  { label: 'VU', value: '+678' },
  { label: 'VA', value: '+379' },
  { label: 'VN', value: '+84' },
  { label: 'WF', value: '+681' },
  { label: 'YE', value: '+967' },
  { label: 'ZM', value: '+260' },
  { label: 'ZW', value: '+263' }
]