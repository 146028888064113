import React from "react";

function Donatepopcorn() {
  return (
    <div className="donatepopcorn">
      <div className="container">
        <div className="row">
          <div className="col-md-6 py-5 healthcare order-md-0 order-2">
            <div>
              <h1>Donate popcorn. <br /> Make a double impact.</h1>
            </div>
            <div>
              <p>
                Whether you are contributing to a fundraiser or shopping
                directly from our site, Popcorn World’s Donate Joy program lets
                you donate delicious popcorn to healthcare workers and educators
                of America’s youth.
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div>
              <img
                src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/healthcare.png"}
                alt="know"
                className="img-fluid h-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donatepopcorn;
