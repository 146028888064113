import { useState } from "react";
import valid from "card-validator";

export const validateInfo = (values) => {
  let errors = {};
  let creditCard = valid.number(values.cardNumber);

  creditCard.expirationDate = valid.expirationDate(values.cardExpiration);
  creditCard.cvv = valid.cvv(values.cardSecurityCode);
  creditCard.cardholderName = valid.cardholderName(values.cardName);
  creditCard.postalCode = valid.postalCode(values.cardPostalCode);

  errors.show = true;
  errors.variant = "danger";
  errors.message = "An unknown error occured. Please try again later";
  errors.cname = false;
  errors.cnumber = false;
  errors.ctype = false;
  errors.cexp = false;
  errors.ccvv = false;
  // errors.cpostal = false;
  // console.log(values.cardName);

  // if (values.cardPostalCode === null || !values.cardPostalCode.trim()) {
  //   errors.message = "Credit card postal code is not complete";
  // } else if (creditCard.postalCode.isValid) {
  //   errors.cpostal = true;
  // } else {
  //   errors.message = "Credit card postal code is invalid";
  // }
  // console.log(values.cardName)

  //Card CVV expiration
  if (values.cardSecurityCode === null || !values.cardSecurityCode.trim()) {
    errors.message = "CVC  field is required. ";
  }
  // else if (creditCard.cvv.isValid) {
  // errors.ccvv = true;
  // }
  else {
    errors.ccvv = true;
    // errors.message = "Credit card CVC is invalid";
  }

  //Card Expiration Verification
  if (values.cardExpiration === null || !values.cardExpiration.trim()) {
    errors.message = "Expiry date field is required.";
  }
  // else if (creditCard.expirationDate.isValid) {
  //   errors.cexp = true;
  // }
  else {
    errors.cexp = true;
    // errors.message = "Credit card expiration date is invalid";
  }

  //Card Type Verification
  if (
    values.cardType === null ||
    !values.cardType.trim()
    // || creditCard.card === null
  ) {
    errors.message = "Credit card type field is required.";
  }
  // else if (
  //   creditCard.card.type &&
  //   creditCard.card.type.toUpperCase() === values.cardType.toUpperCase()
  // ) {
  //   errors.ctype = true;
  // }
  else {
    errors.ctype = true;
    // errors.message = "Credit card type is invalid";
  }

  //Card Number Verification
  if (values.cardNumber === null || !values.cardNumber.trim()) {
    errors.message = "Credit card number field is required.";
  }
  // else if (creditCard.isValid) {
  //   errors.cnumber = true;
  // }
  else {
    errors.cnumber = true;
    // errors.message = "Credit card number is invalid";
  }

  //Cardholder Name Verification
  if (values.cardName === "" || !values.cardName.trim()) {
    errors.message = "Cardholder name field is required.";
  }
  // else if (creditCard.cardholderName.isValid) {
  //   errors.cname = true;
  // }
  else {
    errors.cname = true;
    // errors.message = "Cardholder name is invalid";
  }

  console.log("Error object : ", errors);

  if (
    errors.ctype &&
    errors.cname &&
    errors.cnumber &&
    errors.cexp
    //   errors.cpostal &&
    // errors.ccvv
  ) {
    errors.variant = "success";
    errors.message = "Card Information is valid";
  }

  return errors;
};

const usePayment = () => {
  const [values, setValues] = useState({
    cardName: "",
    cardNumber: "",
    cardType: "",
    cardExpiration: "",
    cardSecurityCode: "",
    cardPostalCode: "",
    focus: "",
  });
  console.log(values);
  const [errors, setErrors] = useState({});

  const handleFocus = (e) => {
    // console.log("e.target.name : on focus : ", e.target.name, values);
    setValues({
      ...values,
      focus: e.target.name === "cardSecurityCode" ? "cvc" : e.target.name,
    });
  };

  const validateCardNumber = (number) => {
    // const cardNumberPattern = /^(?:\d{4}\s){3}\d{4}$/;
    // const cardNumberPattern = /^(?:\d{4}\s){2}\d{4}\s\d{0,4}$/;
    // const cardNumberPattern = /^(?:\d{4}\s){2}\d{4}(?:\s\d{0,4})?$/;
    // const cardNumberPattern = /^(?:\d{4}\s){2}\d{4}(?:\s\d{0,4})?$/;
    // const cardNumberPattern = /^(?:\d{4}\s){2}\d{4}(?:\s\d{1,4})?$/;
    const cardNumberPattern = /^(?:\d{4}\s){2}\d{4}(?:\s\d{1,4})?$/;
    console.log(
      "number check ",
      cardNumberPattern.test("2552 5252 5252 5"),
      number
    );

    return cardNumberPattern.test(number);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("on change ");
    if (name == "cardNumber") {
      if (value.length < 20) {
        // if (value.length == 5 && value.slice(4, 5) !== " ") {
        //   setValues({
        //     ...values,
        //     [name]: value.slice(0, 4) + " " + value.slice(4),
        //   });
        // } else if (value.length == 10 && value.slice(9, 10) !== " ") {
        //   setValues({
        //     ...values,
        //     [name]: value.slice(0, 9) + " " + value.slice(9),
        //   });
        // } else if (value.length === 15 && value.slice(14, 15) !== " ") {
        //   setValues({
        //     ...values,
        //     [name]: value.slice(0, 14) + " " + value.slice(14),
        //   });
        // } else {
        //   setValues({
        //     ...values,
        //     [name]: value,
        //   });
        // }
        let input = value.replace(/[^\dA-Za-z]/g, '');
        let formattedNumber = '';

        if (input.length > 16) {
          input = input.slice(0, 16);
        }

        // Format the number
        for (let i = 0; i < input.length; i += 4) {
          formattedNumber += input.slice(i, i + 4) + ' ';
        }

        setValues({
          ...values,
          [name]: formattedNumber.trim(),
        });

        if (
          !validateCardNumber(
            formattedNumber.trim().length == 15
              ? formattedNumber.trim().slice(0, 14) + " " + formattedNumber.trim().slice(14)
              : formattedNumber.trim()
          ) &&
          formattedNumber?.trim().length < 20
        ) {
          setErrors((prev) => ({
            ...prev,
            [name]: "Invalid card number format",
          }));
        }
        if (validateCardNumber(formattedNumber.trim()) && formattedNumber?.trim()?.length < 20) {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
    } else if (name == "cardSecurityCode") {
      if (value.length < 4) {
        setValues({
          ...values,
          [name]: value,
        });
      }
    } else if (name == "cardExpiration") {
      if (value.length < 6) {
        if (value.length == 3 && value.slice(2, 3) !== "/") {
          setValues({
            ...values,
            [name]: value.slice(0, 2) + "/" + value.slice(2),
          });
        } else {
          setValues({
            ...values,
            [name]: value,
          });
        }
      }
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault()
    setErrors(validateInfo(values));
    let error = validateInfo(values);
    return error.variant;
  };

  return { handleChange, handleFocus, handleSubmit, values, errors, setValues };
};

export default usePayment;
