import React, { useState } from "react";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
// import Cards from "react-credit-cards";
// import "react-credit-cards/es/styles-compiled.css";
import usePayment from "./usePayment";
import { ShopButton } from "../ShopDetail/ShopDetail";
import Input from "../../Components/GenericComponents/Input/Input";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../Services";
import { toast } from "react-toastify";
import { APP } from "../../Redux/ActionTypes";
import Dropdown from "../../Components/GenericComponents/Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import TabTitle from "../../Services/TabTitle";
import Loader from "../../Components/Loader/Loader";
import { Spinner } from "./Checkout";

const ShopPayment = () => {
  TabTitle('Shop Payment')

  const { handleChange, handleFocus, handleSubmit, values, errors, setValues } =
    usePayment();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const redux = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const submitOrder = async (e) => {
    let variant = handleSubmit(e);
    console.log("varient", variant)
    if (variant == "danger") {
      toast.error("Invalid card details");
    }
    if (variant == "success") {
      let formdata = {
        ...values,
        cardNumber: values.cardNumber
          .split("")
          .filter((a) => a.trim().length)
          .join(""),
      };
      delete formdata.focus;
      delete formdata.cardPostalCode;
      let payload = {
        ...redux.checkoutInformation,
        ...formdata,
      };
      try {
        const response = await createOrder(payload);
        if (response?.status == 200) {
          if (response.data.status == 200) {
            // setShow(true);
            dispatch({ type: APP.SET_CHECKOUT_INFORMATION, payload: {} });
            dispatch({ type: APP.CLEAR_CART_ITEMS });
            setValues({
              cardName: "",
              cardNumber: "",
              cardType: "",
              cardExpiration: "",
              cardSecurityCode: "",
              cardPostalCode: "",
              focus: "",
            });
            navigate('/shop/checkout/order-confirmed', { state: { order: response?.data } })
          }
          else {
            setError(response?.data?.message)
          }
        }
      } catch (error) {
        console.log("Error catch : ", error);
      }
    }
  };

  // console.log("error:redux:values : ", errors, redux, values);
  return (
    <>
      <div className="container-xl mt-1">
        <div>
          <div className="row">
            <div className="col-md-12  justify-content-center align-items-center">
              <div className="formDiv">
                <form className="" onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <Input
                      label="Cardholder Name"
                      type="text"
                      id="cardName"
                      datatestid="cardName"
                      name="cardName"
                      placeholder="Cardholder Name"
                      value={values.cardName}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      error={errors.cardName ? errors.message : ""}
                    />
                  </div>
                  <div className="col-md-12">
                    <Input
                      type="text"
                      id="cardNumber"
                      datatestid="cardNumber"
                      name="cardNumber"
                      placeholder="Card Number"
                      label="Card Number"
                      value={values.cardNumber}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      error={errors?.cardNumber || errors.cnumber}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Dropdown
                        style={{
                          padding: "4px",
                          margin: "2px",
                        }}
                        name={"cardType"}
                        label="Card Type"
                        options={[
                          { label: "JCB", value: "JCB" },
                          { label: "Visa", value: "Visa" },
                          { label: "Discover", value: "Discover" },
                          { label: "Mastercard", value: "Mastercard" },
                          {
                            label: "American Express",
                            value: "American Express",
                          },
                        ]}
                        value={values.cardType}
                        onChange={handleChange}
                        // onBlur={() =>
                        //   onBlurHandler({
                        //     cardType: state.cardType,
                        //   })
                        // }
                        error={errors.cardType}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        type="text"
                        id="cardExpiration"
                        data-testid="cardExpiration"
                        name="cardExpiration"
                        placeholder="Expiration Date"
                        label="Expiration Date"
                        value={values.cardExpiration}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        error={errors.cexp}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        type="number"
                        id="cardSecurityCode"
                        data-testid="cardSecurityCode"
                        name="cardSecurityCode"
                        placeholder="Security Code"
                        label="Security Code"
                        value={values.cardSecurityCode}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        error={errors.ccvv}
                      />
                    </div>
                    {/* <div className="col-md-12">
                    <Input
                        type="text"
                        id="cardPostalCode"
                        data-testid="cardPostalCode"
                        name="cardPostalCode"
                        placeholder="Postal Code"
                        label="Postal Code"
                        value={values.cardPostalCode}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        error={errors.cpostal}
                    />
                </div> */}
                  </div>
                  {error &&
                    <text style={{ color: "red" }}>{error}</text>
                  }
                  <div className="d-flex gap-3 justify-content-end">
                    {/* <Button
                    size={"block p-3 w-25 mt-3 mb-3"}
                    data-testid="validateButton"
                    id="validateButton"
                    type="submit"
                    >
                    Validate
                </Button> */}
                    <Button
                      className="btn"
                      style={{
                        display: "inline-block",
                        fontWeight: 400,
                        lineHeight: 1.5,
                        color: "white",
                        textAlign: "center",
                        textDecoration: "none",
                        verticalAlign: "middle",
                        cursor: "pointer",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        userSelect: "none",
                        backgroundColor: "#323232",
                        border: "1px solid #323232",
                        padding: ".375rem .75rem",
                        fontSize: "1rem",
                        borderRadius: ".25rem",
                        transition:
                          "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out",
                      }}
                      size={"block p-3 w-25 mt-3 mb-3"}
                      onClick={submitOrder}

                    >
                      Pay
                    </Button>
                  </div>
                </form>
              </div>

              {/* {errors.show && (
                <>
                  <Alert
                    id="alertMessage"
                    data-testid="alertMessage"
                    variant={errors.variant}
                    show={errors.show}
                  >
                    {errors.message}
                  </Alert>{" "}
                </>
              )} */}
            </div>
            {/* <div className="col-md-6   d-flex mt-3">
                <div className="w-100">
                  <Cards
                    cvc={values.cardSecurityCode}
                    expiry={values.cardExpiration}
                    focused={values.focus}
                    name={values.cardName}
                    number={values.cardNumber}
                  />
                </div>
              </div> */}
          </div>

        </div>
      </div>

      {/* <div className="container" style={{ marginTop: "7rem" }}>
        <div className="d-flex">
          <div className="footerText text-lightgrey">Need Help?</div>
          <a
            href="https://www.doublegood.com/privacy/"
            target="_blank"
            data-id="ds-privacy"
            className="footerLinks text-lightgrey"
          >
            Contact Us
          </a>
        </div>
      </div> */}

      {/* <div
        className="container-fluid p-4 mt-3"
        style={{ backgroundColor: "rgb(248, 248, 250)" }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-6 justify-content-start">
              <div className="checkFooter-p">
                © 2023-24 Popcorn World Technologies LP. All rights reserved.
              </div>
            </div>
            <div className="col-md-6 justify-content-end d-flex">
              <a
                href="https://www.doublegood.com/privacy/"
                target="_blank"
                data-id="ds-privacy"
                className="footerLinks"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.doublegood.com/privacy/"
                target="_blank"
                data-id="ds-privacy"
                className="footerLinks"
              >
                Terms of service
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ShopPayment;
