import React from 'react'
import Peoplehero from '../../Components/Elements/People/Peoplehero'
import Peoplebackground from '../../Components/Elements/People/Peoplebackground'
import Come from '../../Components/Elements/People/Come'
import Good from '../../Components/Elements/People/Good'
import Meetfolks from '../../Components/Elements/People/Meetfolks'
import Department from '../../Components/Elements/People/Department'
import Peoplefeedback from '../../Components/Elements/People/Peoplefeedback'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import TabTitle from '../../Services/TabTitle'

function People() {
  TabTitle('Our People')
  return (
    <div>
      <Navbar bgColor={"white"}/>
        <Peoplehero/>
        <Peoplefeedback/>
        <Come/>
        <Department/>
        <Good/>
        <Peoplebackground/>
        <Meetfolks/>
        <Footer/>
    </div>
  )
}

export default People