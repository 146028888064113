import React, { useEffect, useState } from "react";
import "./Shop.css";
import ShopCard, {
  CartButton,
  ShopCollection,
} from "../../Components/ShopCard";
import { getShopCollections, getShopProduct } from "../../Services";
import { baseURL } from "../../Services/axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import ShopCart from "../Cart/ShopCart";
import { APP } from "../../Redux/ActionTypes";
import Loader from "../../Components/Loader/Loader";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Goodback1 from "../../Components/Elements/Toolkit/Goodback1";
import TabTitle from "../../Services/TabTitle";

const Shop = () => {
  TabTitle("Shop");
  const navigate = useNavigate();
  const [state, updateState] = useState({
    products: [],
    loadedItemsCount: 5,
    collectionLoadedItemsCount: 5,
    openCart: false,
    loader: false,
  });

  const dispatch = useDispatch();
  const redux = useSelector((state) => state.app);

  // console.log("redux ----------------------------------------------------: ", redux);

  const setState = (object) => {
    updateState((prev) => ({
      ...prev,
      ...object,
    }));
  };
  const totalItems = redux?.shopProducts?.length;

  const totalCollectionsItems = redux?.shopCollections?.length;
  // const shopProductsLoaded = redux?.shopProductsLoaded;
  // Function to load more items
  const loadMore = () => {
    updateState((prev) => ({
      ...prev,
      loadedItemsCount: prev.loadedItemsCount + 5,
    }));
  };

  useEffect(() => {
    let success = () => {
      setState({ loader: false });
    };
    dispatch(getShopProduct(success));
    dispatch(getShopCollections());
  }, []);

  const addCart = (item) => {
    updateState({ openCart: true });
    dispatch({ type: APP.ADD_CART_ITEM, payload: item });
  };
  return state.loader ? (
    <>
      <Loader />
    </>
  ) : (
    <>
      <Navbar />
      <section
        bgColor={"rgb(219, 244, 227)"}
        className="dollar-supports-sec-shop bg-skyblue"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
              <div className="dollar-supports-number-text">
                <p>
                  <span className="dollar-supports-number secondary_color secondary_font">
                    50
                  </span>
                  <span className="secondary_color secondary_font">%</span>
                </p>
              </div>
              <div className="dollar-supports-title secondary_font">
                <h2>
                  <b>
                    of every dollar supports our Popcorn World Kids Foundation
                  </b>
                </h2>
              </div>
              <div className="dollar-supports-sbtittle">
                Popcorn World always gives back, helping kids with special needs
                through experiences, education, and equipment.
              </div>
              <div className="dollar-supports-buttons">
                <a href="/kids-foundation" class="btn btn-lg get-start">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
              <div className="dollar-supports-image">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/dollar-supports-image.webp"
                  }
                  alt="dollar-supports"
                  className="w-100 h-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container shopContainer">
        <ShopCardHeading
          title="All Flavors"
          className="poppin-bold w-100 shopItemsHeading"
        />
        <div className="row shopCards">
          {redux.shopProducts.length > 0 &&
            redux.shopProducts
              .slice(0, state.loadedItemsCount)
              .map((product) => (
                <>
                  <ShopCard
                    productCategories={product?.product_categories}
                    price={product.price}
                    title={product.title}
                    image={`${baseURL}/${product.image}`}
                    htmlDesctiption={product.description}
                    className="col-12 col-md-4"
                    onClick={() => {
                      navigate(`/shop/${product.slug}`);
                    }}
                  />
                </>
              ))}
          {state.loadedItemsCount < totalItems && (
            <div className="row">
              <CartButton
                title="Load More"
                className="btnHover justify-content-center d-flex"
                style={{ width: "13%" }}
                onClick={loadMore}
              />
            </div>
          )}
        </div>
      </div>
      <div className="container shopContainer mb-5">
        <ShopCardHeading
          title="Collections"
          className="poppin-bold row shopItemsHeading"
        />
        <div className="row  shopCards">
          {redux.shopCollections.length > 0 &&
            redux.shopCollections
              .slice(0, state.collectionLoadedItemsCount)
              .map((collection) => (
                <ShopCollection
                  image={`${baseURL}/${collection.image}`}
                  title={collection["title"]}
                  description={collection.description}
                  price={collection.price}
                  className="col-12 col-md-6"
                  onClick={() => {
                    addCart(collection);
                  }}
                />
              ))}
          {state.collectionLoadedItemsCount < totalCollectionsItems && (
            <div className="row">
              <CartButton
                title="Load More"
                className="btnHover justify-content-center d-flex"
                style={{ width: "13%" }}
                onClick={() => {
                  updateState((prev) => ({
                    ...prev,
                    collectionLoadedItemsCount:
                      prev.collectionLoadedItemsCount + 5,
                  }));
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Goodback1 />
      <Footer />
      <ShopCart
        openCart={state.openCart}
        onHideCart={() => {
          setState({ openCart: false });
        }}
      />
    </>
  );
};

export default Shop;

export const ShopCardHeading = ({ title, className }) => {
  return (
    <h1 className={`poppin-bold row shopItemsHeading ${className}`}>{title}</h1>
  );
};
