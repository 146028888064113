import React from "react";
import { useNavigate } from "react-router-dom";

function Join() {
  const navigate = useNavigate();
  return (
    <div className="join">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 our-culture mb-4">
            <div>
              <h1 className="secondary_font">BECOME A PART OF OUR TEAM OF HAPPINESS CREATORS </h1>
            </div>
            <div>
              <p>
                We’re on a mission to create more joy in people’s lives, and
                that includes our employees. With state-of-the-art,
                employee-focused facilities around Chicago, we want you to come
                to work every day excited to help kids do what they love.
              </p>
            </div>
            <div>
              <button
                className="btn rounded-5"
                onClick={() => navigate("/careers")}
              >
                Explore Careers
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/join.jpg"}
              className="img-fluid w-100 h-100"
              alt="walking"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Join;
