import React from "react";

function Double() {
  return (
    <div className="double">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-7">
            <h1 className="why">
              Why Popcorn World popcorn is an easy peasy sell
            </h1>
          </div>
        </div>
        <div className="row justify-content-between comm">
          <div className="col-md-5 mb-3 premimum order-md-1 order-2">
            <div>
              <h1>Premium popcorn flavors that sell themselves</h1>
            </div>
            <div>
              <p>
                Our flavors have been perfected over 25 years. They are popped
                fresh daily and shipped directly to your doorstep.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3 order-md-2 order-1">
            <div className="comunity">
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/double1.png"
                }
                className="img-fluid"
                alt="community"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-between comm ">
          <div className="col-md-6 mb-3 ">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/double2.png"
              }
              className="img-fluid"
              alt="tech"
            />
          </div>
          <div className="col-md-5 premimum">
            <div>
              <h1>50% of all sales go to your team or cause</h1>
            </div>
            <div>
              <p>
                Unlike other fundraising options, you keep half of everything
                you sell. There are no set up fees. No product handling fees. No
                extra fees ever.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Double;
