import React from 'react'
import Donatehero from '../../Components/Elements/Donatejoy/Donatehero'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Spread from '../../Components/Elements/Donatejoy/Spread'
import Contribute from '../../Components/Elements/Donatejoy/Contribute'
import Know from '../../Components/Elements/Donatejoy/Know'
import Donatepopcorn from '../../Components/Elements/Donatejoy/Donatepopcorn'
import Bags from '../../Components/Elements/Donatejoy/Bags'
import Donatejoybg from '../../Components/Elements/Donatejoy/Donatejoybg'
import Donatefeedback from '../../Components/Elements/Donatejoy/Donatefeedback'
import Raisingjoy from '../../Components/Elements/Careers/Raisingjoy'
import Explorecraousel from '../../Components/Elements/Virtualfundraising/Explorecraousel'
import { CustomCarousel } from '../Home/Home'
import { useSelector } from "react-redux";
import TabTitle from '../../Services/TabTitle'

function Donate() {
  TabTitle('Donate Joy')
  const redux = useSelector((state) => state.data);
  return (
    <div>
      <Navbar bgColor={"#CCE5FF"} />
      <Donatehero />
      <Raisingjoy />
      <Spread />
      <Contribute />
      <Bags />
      <Donatejoybg />
      <Donatefeedback />
      <Know />
      <Donatepopcorn />
      {/* <Explorecraousel/> */}
      {redux.campaigns.length > 0 && (
        <section className="bg-green">
          <div
            className="container-fluid justify-content-center"
            style={{ paddingTop: "5rem", paddingBottom: "3rem" }}
          >
            <h1 className="heading poppin-bold text-center">
              Explore live Pop-Up Stores
            </h1>
            <div>
              <p className="paragraph mt-4 text-center">
                Discover the excitement of fundraisers happening right now!
              </p>
            </div>
            <CustomCarousel campaigns={redux.campaigns} />
          </div>
        </section>
      )}

      <Footer />
    </div>
  )
}

export default Donate