import React from "react";
import { useNavigate } from "react-router-dom";

function Ready() {
  const navigate = useNavigate();
  return (
    <div className="ready">      
      {/* <div className="ready2"> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 offset-md-1 fund">
              <div className="pt-5">
                <h1 className="secondary_font">Ready to start fundraising?</h1>
              </div>
              <div>
                <p>
                  Get ready to boost your fundraising game and create a Pop-Up
                  Store that pops and helps you meet your goals.
                </p>
              </div>
              <div>
                <button className="btn"  onClick={() => navigate("/store-demo")}>Get Started</button>
              </div>
            </div>
            <div className="col-lg-6 col-md-7 offset-lg-1 pe-md-0">
              <div className="ready-right-img-div">
                <div className="ready2"></div>
                <div className="ready1">
                </div>
              </div>
            </div>
          </div>
        </div>
        
      {/* </div> */}
    </div>
  );
}

export default Ready;
