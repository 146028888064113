import React, { useState } from "react";
import { CartButton } from "../../ShopCard";
import { baseURL } from "../../../Services/axios";
import ShopCart from "../../../Pages/Cart/ShopCart";
import { APP } from "../../../Redux/ActionTypes";
import { useDispatch } from "react-redux";

function Allcollection({ collections, openCart, onHideCart, id, linkCode }) {
  const [loadedItemsCount, setLoadedItemsCount] = useState(5);
  const dispatch = useDispatch();
  const loadMore = () => {
    setLoadedItemsCount(loadedItemsCount + 5);
  };
  const addCart = (item) => {
    openCart();
    dispatch({ type: APP.ADD_CART_ITEM, payload: { campaign_id: id, link_code: linkCode, ...item } });
  }
  return (
    <div className="allcollection">
      <div className="container-fluid px-0">
        <div className="row justify-content-between campaign">
          {collections.length > 0 &&
            collections.slice(0, loadedItemsCount).map((collection) => (
              <>
                {/* {console.log(collection, 'collection')} */}
                <div className="col-md-12 mb-3 collection">
                  <div className="card ">
                    <div className="row">
                      <div className="col-md-12 specific-flavour">
                        <div className="d-flex justify-content-between">
                          <div className="p-5">
                            <div>
                              <span className="pack">Pack of {collection?.total_products}</span>
                            </div>
                            <div>
                              <h3 className="secondary_font">{collection['title']}</h3>
                            </div>
                            <div>
                              <p
                                dangerouslySetInnerHTML={{ __html: collection.description }}
                              >
                              </p>
                            </div>
                            <div>
                              <h6>{collection.price}</h6>
                            </div>
                            <div>
                              <button className="btn card-btn" onClick={() => addCart(collection)}>Add to Cart</button>
                            </div>
                          </div>
                          <div className="col-md-4 make-img" sty
                            style={{ backgroundImage: `url(${`${baseURL}/${collection.image}`})`, marginRight: 35 }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          {loadedItemsCount < collections.length && (
            <div className="row">
              <CartButton title="Load More" className="btnHover justify-content-center d-flex" style={{ width: "18%" }} onClick={loadMore} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Allcollection;
