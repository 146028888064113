import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../Services/axios";
import { Modal } from "react-bootstrap";
import Input from "../../GenericComponents/Input/Input";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Logo from "../../..//assets/images/fundraiserLogo.jpeg";
import Navbar from "../../Navbar/NavbarFund";
import {
  EmailShareButton,
  FacebookShareButton,
  MailruShareButton,
} from "react-share";
import { useSelector } from "react-redux";

function Coach({ data, demo }) {
  const isLoggedIn = localStorage.getItem("user_token");
  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  const navigate = useNavigate();
  const [now, setNow] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let delay = 20;
    const interval = setInterval(() => {
      setNow((prevNow) => {
        if (prevNow < Number(data?.collected_amount)) {
          return prevNow + 1;
        } else {
          clearInterval(interval);
          return prevNow;
        }
      });
    }, delay);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Navbar />
      <div className="coach">
        {/* <div className="container-fluid  doublegood">
        <div className="row uprow py-3">
          <div className="d-flex justify-content-between">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = process.env.REACT_APP_PUBLIC_URL;
              }}
            >
              <img
                src={Logo}
                className="img-fluid p-2"
                alt="logo"
                width={170}
                height={110}
              />
            </div>
            <div>
              {!isLoggedIn && (
                <button className="btn" onClick={() => navigate("/signin")}>
                  Sign In
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="setup d-none"> */}

        <div className="container north-west">
          <div className="row">
            <div className="col-md-8 north order-md-1 order-2 mb-3 ">
              <span className="secondary_color">{data?.fundraiser?.name}</span>
              <div>
                <h1 className="secondary_font">{data?.campaign_title}</h1>
              </div>
              <div>
                <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
              </div>
              <div className="py-4">
                <ProgressBar
                  now={now}
                  max={data.raise_amount}
                  style={{
                    backgroundColor: "rgb(214, 214, 214)",
                  }}
                />
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <h4>{`${base_currency}${now}`}</h4>
                  <p>
                    sold of {base_currency}
                    {data.raise_amount} goal
                  </p>
                </div>
                <div>
                  <p>{data.end_time}</p>
                </div>
              </div>
              <div>
                <button
                  className="btn1"
                  style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Share Pop-Up Store
                </button>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center order-md-2 order-1">
              <img
                src={
                  demo
                    ? data.campaign_image
                    : `${baseURL}/${data.campaign_image}`
                }
                className="img-fluid py-5"
                alt="coach"
              />
            </div>
          </div>
        </div>
        <>
          <SharePopup
            show={open}
            handleClose={() => setOpen(false)}
            url={window.location.href}
            qrCode={`${baseURL}/${data?.link?.qr_code}`}
          />
        </>
      </div>
    </>
  );
}

export default Coach;

const SharePopup = ({
  show,
  handleClose,
  url = "https://s.dgpopup.com/dl56d1u3",
  qrCode,
}) => {
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    setCopied(false);
  }, [!show]);

  return (
    <Modal
      size="lg"
      className="custom-modal"
      backdrop="static"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "0px",
          paddingTop: "2rem",
          paddingRight: "2rem",
        }}
      >
        {" "}
      </Modal.Header>
      <Modal.Body
        className=""
        style={{
          paddingTop: "0.5rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingBottom: "3.5rem",
        }}
      >
        <Modal.Title>Support by sharing</Modal.Title>
        <p className="text-lightgrey mt-2">
          Help this Pop-Up Store reach its goal by sharing the link
        </p>
        <div className="d-flex row">
          <div className="col-12 col-md-8">
            <Input
              placeholder="Pop-Up Store Link"
              label="Pop-Up Store Link"
              value={url}
            />
            <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
              <div className="px-2 mt-4 gap-2" style={{ cursor: "pointer" }}>
                <svg
                  className=""
                  width="24px"
                  height="24px"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                >
                  <path d="M469.333 426.667c-23.565 0-42.667 19.102-42.667 42.667v384c0 23.565 19.102 42.667 42.667 42.667h384c23.565 0 42.667-19.102 42.667-42.667v-384c0-23.565-19.102-42.667-42.667-42.667h-384zM341.333 469.333c0-70.692 57.308-128 128-128h384c70.694 0 128 57.308 128 128v384c0 70.694-57.306 128-128 128h-384c-70.692 0-128-57.306-128-128v-384z"></path>
                  <path d="M170.667 128c-11.316 0-22.168 4.495-30.17 12.497s-12.497 18.854-12.497 30.17v384c0 11.315 4.495 22.17 12.497 30.17s18.854 12.497 30.17 12.497h42.667c23.564 0 42.667 19.102 42.667 42.667s-19.103 42.667-42.667 42.667h-42.667c-33.948 0-66.505-13.487-90.51-37.491s-37.49-56.563-37.49-90.509v-384c0-33.948 13.486-66.505 37.49-90.51s56.562-37.49 90.51-37.49h384c33.946 0 66.505 13.486 90.509 37.49s37.491 56.562 37.491 90.51v42.667c0 23.564-19.102 42.667-42.667 42.667s-42.667-19.103-42.667-42.667v-42.667c0-11.316-4.497-22.168-12.497-30.17s-18.854-12.497-30.17-12.497h-384z"></path>
                </svg>

                <span className="px-2" style={{ textDecoration: "underline" }}>
                  Copy Link
                </span>
                {copied && <span className="">- copied!</span>}
              </div>
            </CopyToClipboard>

            <div className="col-md-12 gap-2 d-flex justify-content-start mt-3 align-items-center">
              <FacebookShareButton url={url}>
                <div className="socialIcon">
                  <svg
                    className=""
                    width="24px"
                    height="24px"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      borderRadius: "50%",
                    }}
                  >
                    <path d="M458.982 117.647c48.009-48.009 113.122-74.981 181.018-74.981h128c23.565 0 42.667 19.103 42.667 42.667v170.667c0 23.564-19.102 42.667-42.667 42.667h-128v85.333h128c13.137 0 25.545 6.053 33.63 16.409s10.948 23.859 7.761 36.604l-42.667 170.667c-4.745 18.995-21.811 32.32-41.391 32.32h-85.333v298.667c0 23.565-19.102 42.667-42.667 42.667h-170.667c-23.564 0-42.667-19.102-42.667-42.667v-298.667h-85.333c-23.564 0-42.667-19.102-42.667-42.667v-170.667c0-23.564 19.103-42.667 42.667-42.667h85.333v-85.333c0-67.895 26.971-133.010 74.982-181.019zM640 128c-45.265 0-88.674 17.981-120.678 49.987-32.009 32.006-49.988 75.416-49.988 120.68v128c0 23.565-19.102 42.667-42.667 42.667h-85.333v85.333h85.333c23.565 0 42.667 19.102 42.667 42.667v298.667h85.333v-298.667c0-23.565 19.102-42.667 42.667-42.667h94.686l21.333-85.333h-116.019c-23.565 0-42.667-19.102-42.667-42.667v-128c0-22.632 8.99-44.337 24.994-60.34s37.709-24.994 60.339-24.994h85.333v-85.333h-85.333z"></path>
                  </svg>
                </div>
              </FacebookShareButton>

              <EmailShareButton url={url}>
                <div className="socialIcon">
                  <svg
                    className=""
                    width="24px"
                    height="24px"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    <path d="M170.667 213.333c-23.369 0-42.667 19.297-42.667 42.667v512c0 23.369 19.297 42.667 42.667 42.667h682.667c23.369 0 42.667-19.298 42.667-42.667v-512c0-23.369-19.298-42.667-42.667-42.667h-682.667zM42.667 256c0-70.497 57.503-128 128-128h682.667c70.498 0 128 57.503 128 128v512c0 70.498-57.502 128-128 128h-682.667c-70.497 0-128-57.502-128-128v-512z"></path>
                    <path d="M50.383 231.532c13.513-19.305 40.117-23.999 59.422-10.486l402.199 281.537 402.197-281.537c19.307-13.513 45.909-8.818 59.422 10.486s8.819 45.908-10.487 59.421l-426.667 298.665c-14.69 10.287-34.244 10.287-48.934 0l-426.666-298.665c-19.304-13.513-23.999-40.117-10.486-59.421z"></path>
                  </svg>
                </div>
              </EmailShareButton>
            </div>
          </div>
          <div className=" mt-2 col-12 col-md-4 px-2 d-flex justify-content-center align-items-center">
            <img src={qrCode} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
