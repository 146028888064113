import { APP } from "../ActionTypes/index";

const userData = {
  shopProducts: [],
  shopProductsLoaded: false,
  shopCollections: [],
  shopCartItems: [],
  totalShopCartItemPrice: 0,
  checkoutInformation: {},
  globalSettings: [],
  campaigns: [],
  linkCode: null,
}
const calculateTotalCheckoutPrice = (cartItems) => {
  let totalPrice = 1;  // Initialize to 1 for multiplication
  cartItems.forEach(item => {
    totalPrice += item.quantity * parseInt(item.price.slice(1, item.price.length));
  });
  return totalPrice - 1;
}
const AppReducer = (state = userData, action) => {
  const { type, payload } = action;

  switch (type) {

    case APP.SHOP_PRODUCTS:
      return {
        ...state,
        shopProducts: payload,
        shopProductsLoaded: true,
      };
    case APP.SHOP_COLLECTIONS:
      return {
        ...state,
        shopCollections: payload,
      };
    case APP.SHOP_CART_ITEMS:
      return {
        ...state,
        shopCartItems: payload
      }
    case APP.ADD_CART_ITEM:
      let items;
      payload.quantity = 1;
      if (payload.link_code == state.linkCode) {
        if (state.shopCartItems.length > 0) {
          const existingItem = state.shopCartItems.find(obj => obj.id === payload.id);
          if (existingItem) {
            items = state.shopCartItems.map(obj =>
              obj.id === payload.id ? { ...obj, /* Update properties if needed */ } : obj
            );
          } else {
            items = [payload, ...state.shopCartItems];
          }
        } else {
          items = [payload];
        }
      } else {
        // If link codes don't match, empty previous cart items
        items = [payload];
      }
      return {
        ...state,
        shopCartItems: items,
        totalShopCartItemPrice: calculateTotalCheckoutPrice(items),
        linkCode: payload?.link_code ? payload?.link_code : null,
      };
    case APP.REMOVE_CART_ITEM:
      return {
        ...state,
        shopCartItems: state.shopCartItems.filter(obj => obj.id != payload.id),
        totalShopCartItemPrice: calculateTotalCheckoutPrice(state.shopCartItems.filter(obj => obj.id != payload.id))
      }
    case APP.INCREMENT_CART_QUANTITY:
      return {
        ...state,
        shopCartItems: state.shopCartItems?.map((item) => {
          if (item.id === payload.id) {
            return payload;
          } else {
            return item;
          }
        }),
        totalShopCartItemPrice: calculateTotalCheckoutPrice(state.shopCartItems?.map((item) => {
          if (item.id === payload.id) {
            return payload;
          } else {
            return item;
          }
        }))
      }
    case APP.DECREMENT_CART_QUANTITY:
      return {
        ...state,
        shopCartItems: state.shopCartItems?.map((item) => {
          if (item.id === payload.id) {
            return payload;
          } else {
            return item;
          }
        }),
        totalShopCartItemPrice: calculateTotalCheckoutPrice(state.shopCartItems?.map((item) => {
          if (item.id === payload.id) {
            return payload;
          } else {
            return item;
          }
        }))
      }
    case APP.GET_SHOP_CARTITEMS_PRICE:
      return {
        ...state,
        totalShopCartItemPrice: calculateTotalCheckoutPrice(state.shopCartItems)
      };
    case APP.SET_CHECKOUT_INFORMATION:
      return {
        ...state,
        checkoutInformation: payload
      };
    case APP.CLEAR_CART_ITEMS:
      return {
        ...state,
        shopCartItems: [],
        checkoutInformation: {}
      };
    case APP.GLOBAL_SETTINGS:
      return {
        ...state,
        globalSettings: payload
      };
    case APP.GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: payload
      };
    default:
      return state;
  }

};

export default AppReducer;