import React from "react";

function Virtual() {
  return (
    <div className="virtual">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-md-6 our-culture order-md-1 order-2">
            <span>WHAT IS VIRTUAL FUNDRAISING</span>
            <div>
              <h1 className="secondary_font">
                Virtually the easiest way to fundraise—ever
              </h1>
            </div>
            <div>
              <p>
                The Popcorn World platform helps kids’ teams, clubs, and other
                groups run hands-off digital fundraisers to make mind-blowing
                returns in just four days.
              </p>
            </div>
          </div>
          <div className="col-md-6 virtual-img order-md-2 order-1"></div>
        </div>
      </div>
    </div>
  );
}

export default Virtual;
