import React, { useEffect, useState } from "react";

function Popit() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="popit">
      <div className="container-fluid">
        <div className="row">
          <div className="allimg" id="parallax-container">
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 700}px)`,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/pop2.png"
                }
                className="img1"
                alt="joy"
              />
            </div>
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 700}px)`,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/pop1.svg"
                }
                className="img1 "
                alt="joy"
              />
            </div>
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 700}px)`,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/pop2.png"
                }
                className="img1 "
                alt="joy"
              />
            </div>
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 700}px)`,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/pop3.png"
                }
                className="img1 "
                alt="joy"
              />
            </div>
            <div
              className="  parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 700}px)`,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/pop4.png"
                }
                className="img1"
                alt="joy"
              />
            </div>
            <div
              className="  parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 700}px)`,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/pop1.svg"
                }
                className="img1"
                alt="joy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popit;
