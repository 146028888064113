import React, { useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./TrackOrder.css";
import Footer from "../../Components/Footer/Footer";
import { trackOrder } from "../../Services";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { Spinner } from "../Checkout/Checkout";
import TabTitle from "../../Services/TabTitle";
const moment = require("moment");

const TrackOrder = () => {
  TabTitle('Track Order')

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const redux = useSelector((state) => state?.app);
  const [email, setEmail] = useState(null);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setError(null);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(inputValue);
    setIsValid(isValidEmail);
  };

  const validate = () => {
    if (email?.trim()) {
      if (isValid) {
        getOrders();
      }
    } else {
      setIsValid(true);
      setError("Email field is required.");
    }
  };

  const getOrders = async () => {
    setLoading(true);
    const payload = {
      params: {
        email_address: email,
      },
    };

    const response = await trackOrder(payload);
    setLoading(false);
    if (response?.data?.data?.length > 0) {
      setError(null);
      setOrders(response?.data?.data);
    } else {
      setOrders([]);
      setError(response?.data?.message);
    }
  };

  return (
    <>
      <Navbar bgColor={"white"} />
      <div className="track-order-page">
        <div className="container">
          <div className="header">
            <h1>
              {" "}
              <span className="secondary_color">Track</span> An Order
            </h1>
            <p>
              Stay joyfully informed. We've got you covered every step of the
              way with our 'Track an Order' feature. Easily monitor the status
              and whereabouts of your delivery.
            </p>
          </div>
          <div className="row track_order_container">
            <div className="col-lg-8 col-md-10 offset-lg-2 offset-md-1">
              <div className="email-div">
                <h3 className="email-heading">Enter your email address*</h3>
                <input
                  type="email"
                  className="custom-input"
                  placeholder="ex. Janedoe@gmail.com"
                  onChange={handleChange}
                />
                <p>Please enter the email used when you placed the order.</p>
                {!isValid && (
                  <p style={{ color: "red" }}>Please enter a valid email</p>
                )}

                {error && <p style={{ color: "red" }}>{error}</p>}
                <div className="text-end">
                  {loading ? (
                    <Spinner className="px-3 mt-2 d-flex justify-content-end" />
                  ) : (
                    <button className="black-btn mt-2" onClick={validate}>
                      Continue
                    </button>
                  )}
                </div>

                {orders.length > 0 && (
                  <div className="orders-table-container">
                    <table className="orders-table">
                      <thead>
                        <tr>
                          <th>Order No.</th>
                          <th>Price</th>
                          <th>Status</th>
                          <th>Last activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order) => (
                          <tr key={order.order_id}>
                            {/* <td>{order.order_id}</td> */}
                            <td>
                              {order.order_id.length > 16
                                ? order.order_id.substring(0, 8) +
                                  "..." +
                                  order.order_id.substring(
                                    order.order_id.length - 8
                                  )
                                : order.order_id}
                            </td>
                            <td>
                              {base_currency}
                              {parseFloat(order.total_amount).toFixed(2)}
                            </td>
                            <td>{order.order_status.toUpperCase()}</td>
                            <td className="">{formatDate(order.updated_at)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TrackOrder;
