import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { ShopButton } from "../ShopDetail/ShopDetail";
import "./ShopCart.css";
import cartItemImage from "../../assets/images/cart-item.webp";
import { CartButton } from "../../Components/ShopCard";
import DonationCartImg from "../../assets/images/donation_checkout.webp";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../Services/axios";
import { APP } from "../../Redux/ActionTypes";
import { useSidebar } from "../../Components/Sidebar/helper";
import { Navigate, useNavigate } from "react-router-dom";

export const ShopCart = ({ openCart, onHideCart }) => {
  const navigate = useNavigate();
  const commonStyle = {
    paddingLeft: "32px",
    paddingRight: "32px",
  };
  const redux = useSelector((state) => state.app);

  let onHide = () => onHideCart();
  const { handleOverlayClick } = useSidebar({ onHide });
  const dispatch = useDispatch();
  let minimumOrderValue = redux?.globalSettings?.find(
    (obj) => obj.config_key == "minimum_order_amount"
  )?.config_value;

  let donation_amount = redux?.globalSettings?.find(
    (obj) => obj.config_key == "donation_amount"
  )?.config_value;

  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  useEffect(() => {
    dispatch({ type: APP.GET_SHOP_CARTITEMS_PRICE });
  }, []);

  let donationInCartItems =
    redux?.shopCartItems?.find((obj) => obj?.type == "donation")?.type ==
    "donation"
      ? true
      : false;

  return (
    <>
      <Sidebar open={openCart} onHide={onHideCart}>
        <div
          style={{
            ...commonStyle,
            ...{ marginBottom: donationInCartItems ? "10rem" : "3rem" },
          }}
        >
          <ShopButton icon="back" onClick={handleOverlayClick} />
          <div
            className="cardDescription mt-3 col-md-9"
            style={{ color: "rgb(50, 50, 50)" }}
          >
            50% of each purchase benefits the Popcorn World Kids Foundation
          </div>
          {redux?.shopCartItems?.length > 0 ? (
            redux?.shopCartItems?.map((cartItem) => (
              <>
                <SingleCartItem
                  size={cartItem.total_products}
                  quantity={cartItem?.quantity}
                  data={cartItem}
                  image={
                    cartItem.type == "donation"
                      ? cartItem.image
                      : `${baseURL}/${cartItem.image}`
                  }
                  title={cartItem.title}
                  price={parseInt(
                    cartItem.price.slice(1, cartItem.price.length)
                  )}
                />
              </>
            ))
          ) : (
            <>
              <div className="h5 mt-5 text-muted text-left">
                <h2>Your cart is empty.</h2>
              </div>
            </>
          )}
          <div></div>
        </div>

        <div
          style={{
            ...commonStyle,
            ...{
              marginBottom: "1rem",
              display: donationInCartItems ? "none" : "",
            },
          }}
          className="mt-5"
        >
          <h5 className=" text-lightgrey">Increase your impact</h5>
          <DonationCart
            price="80"
            onClick={() => {
              dispatch({
                type: APP.ADD_CART_ITEM,
                payload: {
                  link_code: redux.linkCode,
                  id: 0,
                  type: "donation",
                  price: base_currency + donation_amount,
                  image:
                    "https://res.cloudinary.com/dg-prod/image/upload/s--5LI3h8xj--/w_500,h_392,f_auto,q_auto/v1/products/F-VIRTUAL/donation_checkout.png",
                  quantity: 1,
                  title: "Make a Donation",
                },
              });
            }}
          />
        </div>

        <div className="cartFooter">
          <div style={{ flex: 1 }}>
            <div className="p text-lightgrey text-center mt-2">
              {redux.totalShopCartItemPrice < minimumOrderValue
                ? `You haven’t reached the  ${base_currency}${minimumOrderValue} order minimum.`
                : ""}
            </div>
            <div>
              <CartButton
                disabled={redux.totalShopCartItemPrice < minimumOrderValue}
                price={redux.totalShopCartItemPrice}
                title="Checkout"
                className="w-100"
                onClick={() => {
                  window.location.href = `/shop/checkout`;
                }}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ShopCart;

export const SingleCartItem = ({
  data,
  image,
  title,
  size = 3,
  price = 100,
  quantity = 1,
  actions = true,
  secondSectionMd = 9,
  screen = "sidebar",
}) => {
  const [counter, setCounter] = useState(quantity);

  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  const dispatch = useDispatch();

  const incrementQuantity = () => {
    dispatch({
      type: APP.INCREMENT_CART_QUANTITY,
      payload: { ...data, quantity: counter + 1 },
    });
    setCounter((prev) => prev + 1);
  };
  const decrementQuantity = () => {
    dispatch({
      type: APP.DECREMENT_CART_QUANTITY,
      payload: { ...data, quantity: counter - 1 },
    });
    setCounter((prev) => prev - 1);
  };
  const removeCartItem = () => {
    dispatch({ type: APP.REMOVE_CART_ITEM, payload: data });
  };
  return (
    <div className="mt-3 d-flex ">
      <div className="cartImage col-md-3">
        <img src={image ? image : cartItemImage} width="100%" />
      </div>
      <div
        className={`col-md-${secondSectionMd} d-flex flex-column justify-content-between `}
        style={{ paddingLeft: "0.7rem" }}
      >
        <div className="col-md-12">
          <div className="d-flex justify-content-between">
            <span className="h6">{title}</span>{" "}
            <span className="h6">
              {base_currency}
              {price * counter}
            </span>
          </div>
          {screen == "checkout" ? (
            <>
              <p className="cartItemSize">Qty {quantity}</p>{" "}
            </>
          ) : (
            screen == "sidebar" && (
              <p
                style={{ display: data.type == "collection" ? "" : "none" }}
                className="cartItemSize"
              >
                {size} Flavor Set
              </p>
            )
          )}
        </div>
        {actions && (
          <div className="col-md-12 justify-content-between d-flex align-items-end">
            <button className="removeBtn" onClick={removeCartItem}>
              Remove
            </button>

            <div className="d-flex align-items-center gap-3">
              <button
                className={`${
                  counter <= 1 ? "disabled-button" : ""
                } cartCounter`}
                disabled={counter <= 1}
                onClick={decrementQuantity}
              >
                <div>
                  <svg
                    className=""
                    width="24px"
                    height="24px"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    <path d="M227.556 512c0-19.635 15.919-35.554 35.555-35.554h497.776c19.639 0 35.558 15.919 35.558 35.554 0 19.639-15.919 35.558-35.558 35.558h-497.776c-19.637 0-35.555-15.919-35.555-35.558z"></path>
                  </svg>
                </div>
              </button>
              <div>{counter}</div>
              <button className="cartCounter" onClick={incrementQuantity}>
                <div>
                  <svg
                    className=""
                    width="24px"
                    height="24px"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    <path d="M512 227.555c19.635 0 35.554 15.919 35.554 35.556v497.777c0 19.639-15.919 35.554-35.554 35.554s-35.554-15.915-35.554-35.554v-497.777c0-19.637 15.919-35.556 35.554-35.556z"></path>
                    <path d="M227.556 512c0-19.635 15.919-35.554 35.555-35.554h497.776c19.639 0 35.558 15.919 35.558 35.554 0 19.639-15.919 35.558-35.558 35.558h-497.776c-19.637 0-35.555-15.919-35.555-35.558z"></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DonationCart = ({ onClick, price }) => {
  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  return (
    <div className="donationCart">
      <div className="donationBox d-flex">
        <div className="col-md-3">
          <div className="donationCartImage">
            <img
              data-id="automation-DGImage"
              loading="lazy"
              src={DonationCartImg}
              draggable="false"
              width="100%"
              height="100%"
              alt="Popcorn World popcorn donation program"
              style={{ objectFit: "contain" }}
            ></img>
          </div>
        </div>
        <div className="col-md-9">
          <h6 className="secondary_font">Make a Popcorn Donation</h6>
          <div className="py-2">
            <div className="text-lightGrey">• Sent to essential workers</div>
            <div className="text-lightGrey">• No extra shipping cost</div>
          </div>
          <div className="h6 mt-3">
            {base_currency}
            {price}
          </div>
        </div>
      </div>
      <button
        className="donationBtn d-flex text-center justify-content-center"
        onClick={onClick}
      >
        Add to Cart
      </button>
    </div>
  );
};
