export const useSidebar = ({onHide,}) => {
    const changeOverlayBackground = (color) => {
        document.body.style.backgroundColor = color;
        document.body.style.overflowY = 'hidden';
      }
    const handleOverlayClick = () => {
        const sidebarElement = document.getElementById('mySidenav');
        if (sidebarElement) {
          sidebarElement.classList.add('closing');
        }
        sidebarElement.addEventListener('animationend', () => {
          const sideNavElements = document.getElementsByClassName('sidenav');
          for (let i = 0; i < sideNavElements.length; i++) {
            sideNavElements[i].style.width = '0';
          }
          onHide();
          changeOverlayBackground("transparent");
        });
      }

    return{
        handleOverlayClick
    }
}