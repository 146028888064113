import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";

function Popup() {
  return (
    <div className="popup">
      <div className="container ">
        <div className="row justify-content-center text-center">
          <div className="col-md-8">
            <h1 className="secondary_font">
              Pop-Up Stores: Hands-down the easiest way to fundraise
            </h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5 mb-3 pop-img order-1"></div>
          <div className="col-md-6 order-2">
            <div className="d-flex gap-3 mb-2">
              <div>
                <IoCheckmarkSharp className="icon" size={50} color="green" />
              </div>
              <div>
                <div>
                  <h3>Simple</h3>
                </div>
                <div>
                  <p>
                    Pop-Up Stores make it easy for teams to fundraise beyond
                    their neighborhood without ever handling money or paperwork.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 mb-2">
              <div>
                <IoCheckmarkSharp className="icon" size={50} color="green" />
              </div>
              <div>
                <div>
                  <h3>Social</h3>
                </div>
                <div>
                  <p>
                    Each team member sells artisanal popcorn in their online
                    Pop-Up Store by sharing their personal link with friends and
                    family
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 mb-2">
              <div>
                <IoCheckmarkSharp className="icon" size={50} color="green" />
              </div>
              <div>
                <div>
                  <h3>Contactless</h3>
                </div>
                <div>
                  <p>
                    Supporters simply follow the Pop-Up Store link and buy all
                    the popcorn their hearts desire—delivered straight to their
                    doorstep.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 mb-2">
              <div>
                <IoCheckmarkSharp className="icon" size={50} color="green" />
              </div>
              <div>
                <div>
                  <h3>Fun</h3>
                </div>
                <div>
                  <p>
                    Real-time leaderboards heat up some friendly competition by
                    showing everyone just how much each seller has brought in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
