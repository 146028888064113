import React from 'react'

function Print() {
  return (
    <div className='print'>
        <div className="container py-5">
            <div className="row justify-content-center text-center">
                <div className="col-md-11">
                    <div>
                        <h1>Print your brochures and start today</h1>
                    </div>
                    <div>
                        <p>Let’s goooooo! To start in-person fundraising, download and print your own brochures now.</p>
                    </div>
                    <div>
                        {/* <button className='btn'>Download Brochures</button> */}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Print