import React from "react";

function Discover() {
  return (
    <div className="discver-come">
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-md-11 text-center">
            <div>
              <h1>Come raise joy with us</h1>
            </div>
            <div>
              <p>
                At Popcorn World, you’ll make a real difference, pursuing your
                passions and joining a close-knit team that values
                collaboration, inclusivity, and diverse and supportive
                individuals.
              </p>
            </div>
            {/* <div>
              <button className="btn rounded-5">Apply Today</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discover;
