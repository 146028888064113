import { useState } from "react"
import { toast } from "react-toastify";
import { errorMessages } from "../Constant";
export function useObjectKeys() {
    const objectKeyConvertToArray = (object) => {
        return Object.keys(object);
    }

    return {
        objectKeyConvertToArray,
    }
}

export default function useValidations() {

    const [errors, setErrors] = useState({});
    const {objectKeyConvertToArray} = useObjectKeys()
    const onBlurHandler = (formField) => {
        setErrors(prev => ({ ...prev, ...validation(formField) }))
    }

    const validation = (fields, message = "") => {
        let errorArr = [];
        let errorMessages;

        let fieldName = objectKeyConvertToArray(fields);
        const capitalizeFirstLatters = (fieldName) => {

            let fieldNameArr = fieldName.split("_");
            let firstValue = fieldNameArr[0];
            let captilizeFirstLatter = firstValue[0].toUpperCase() + firstValue.slice(1);
    
            fieldNameArr.splice(0, 1, captilizeFirstLatter);
            let strValue = fieldNameArr.join(" ");
    
            return strValue;
        }
        const fieldArr = Object.entries(fields).map(([key, value]) => {
            return { ["name"]: key }
        });

        fieldName.forEach(item => {
            fieldArr.forEach(field => {
                if (field.name === item && field.name) {

                    if (!fields[field.name]) {
                        let fieldName = capitalizeFirstLatters(field.name)

                        if (message) {

                            errorArr.push({ [field.name]: message })

                        } else {

                            errorArr.push({ [field.name]: `This field is required` })
                        }

                    } 


                }
            }
            )
        });

        errorMessages = Object.assign({}, ...errorArr);
        return errorMessages;
    }
    const emailValidation = (name,value,returnError) => {
        if(value){
            let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(value)) {
              setErrors((prev) => ({
                ...prev,
                [name]: 'Invalid email address'
              }));
              if(returnError){
                return true
              }
            } else {
              setErrors((prev) => ({
                ...prev,
                [name]: "",
              }));
              if(returnError){
                return false
              }
            }
        }
    }

    let validateRequiredFields = (keys,state) => {
        let error = false;
        let fields = [];
        for(let i=0;i<keys.length;i++){
            fields.push({key:keys[i],value:state[keys[i]]})
        }
        for(let i=0; i<fields.length; i++){
            if(!fields[i].value){           
                error = true;
                setErrors((prev)=>({
                  ...prev,
                  [fields[i].key]:errorMessages.required
                }));
            }
        } 
        if(error){
            // toast.error(errorMessages.fillRequiredFields)
            return error;
        }
    }

    return {
        validation,
        onBlurHandler,
        errors,
        setErrors,
        emailValidation,
        validateRequiredFields,
    }
}