import React from "react";

function Know() {
  return (
    <div className="know">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6  healthcare order-md-1 order-2">
            <div>
              <h1>
                Know any healthcare workers or educators supporting our youth
              </h1>
            </div>
            <div>
              <p>
                If you or someone you know qualifies for popcorn donations,
                please sign up or refer them. We’ve made supporting those who
                care for our children very simple. Whether someone is buying
                from a fundraiser or directly from our site, they always get the
                option to donate our delicious, small-batch popcorn and fill
                someone’s day with joy.
              </p>
            </div>
            <div>
                {/* <button className="btn">Refer a Recipient</button> */}
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center order-md-2 order-1">
            <div>
              <img
                src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/know.png"}
                alt="know"
                className="img-fluid h-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Know;
