import React, { useEffect, useState } from "react";
import { ShopButton } from "../ShopDetail/ShopDetail";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import Input from "../../Components/GenericComponents/Input/Input";
import Dropdown from "../../Components/GenericComponents/Dropdown/Dropdown";
import { DIAL_CODES, StatesList } from "../../Constant";
import { useDispatch, useSelector } from "react-redux";
import { SingleCartItem } from "../Cart/ShopCart";
import { baseURL } from "../../Services/axios";
import { useNavigate } from "react-router-dom";
import useValidations from "../../hooks/useValidation";
import { CartButton } from "../../Components/ShopCard";
import { verify_otp } from "../../Services/serviceVariables";
import {
  verifyDetail,
  verifyOtpCode,
  calculateShippingCharges,
} from "../../Services";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import Modal from "react-bootstrap/Modal";
import { APP } from "../../Redux/ActionTypes";
import ShopPayment from "./ShopPayment";
import { number } from "card-validator";
import axios from "axios";
import Logo from "../../assets/images/fundraiserLogo.jpeg";
import TabTitle from "../../Services/TabTitle";

const Checkout = () => {
  TabTitle("Checkout");
  const {
    onBlurHandler,
    errors,
    setErrors,
    validateRequiredFields,
    emailValidation,
  } = useValidations();
  const redux = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showGift, setGiftMessage] = useState("");
  const [shippingError, setShippingError] = useState(null);
  const navigate = useNavigate();

  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  let shippingCharges = redux?.globalSettings?.find(
    (obj) => obj.config_key == "shipping_charges"
  )?.config_value;

  const [state, setState] = useState({
    otp_code: "",
    verifyLoader: false,
    verifyOtpLoader: false,
    shippingBtnLoader: false,
    otp: "",
    showModal: false,
    showShippingOptModel: false,
    showPaymentModel: false,
    otpVerified: false,
    name: "",
    email: "",
    country_code: "+1",
    phone_number: "",
    // Shipping Detail
    shipping_first_name: "",
    shipping_last_name: "",
    shipping_address: "",
    shipping_apartment: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zipcode: "",
    is_gift: false,
    gift_note: "",
    // Billing Detail
    billing_is_checked: false,
    billing_first_name: "",
    billing_last_name: "",
    billing_address: "",
    billing_apartment: "",
    billing_city: "",
    billing_state: "",
    billing_zipcode: "",
    //shipping
    shipping_options: [],
    shipping_charges: shippingCharges ? shippingCharges : '15',
    errors: null,
  });

  const validatePhoneNumber = (number) => {
    // const phoneNumberPattern =/^(\+\d{1,3})?(\s|-)?\d{10}$/; // Adjusted pattern for "+12124567890"
    // const phoneNumberPattern = /^(\+1-)?\d{3}-\d{3}-\d{4}$/;
    // const phoneNumberPattern = /^(\+1-)?\d{3}-\d{3}-\d{4}$/;
    const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/;

    return phoneNumberPattern.test(number);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "phone_number") {

      let input = value.replace(/\D/g, '');
      let formattedNumber = '';

      if (input.length > 10) {
        input = input.slice(0, 10);
      }

      if (input.length > 3) {
        formattedNumber += input.slice(0, 3) + '-';
        input = input.slice(3);
      }
      if (input.length > 3) {
        formattedNumber += input.slice(0, 3) + '-';
        input = input.slice(3);
      }
      formattedNumber += input;
      setState((prev) => ({
        ...prev,
        [name]: formattedNumber,
        ["errors"]: {
          ['phone']: false
        },
      }));

      if (!validatePhoneNumber(formattedNumber) && formattedNumber?.length <= 12) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Invalid phone number format",
        }));
      }
      if (validatePhoneNumber(formattedNumber) && formattedNumber?.length <= 12) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else if (name == "email") {
      emailValidation(name, value);
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };
  const verify = async () => {
    if (validatePhoneNumber(state.phone_number)) {
      let { email, phone_number, country_code } = state;
      let keys = ["email", "phone_number"];
      let error = validateRequiredFields(keys, state);
      if (!error) {
        setState((prev) => ({
          ...prev,
          ["verifyLoader"]: true,
        }));
        let payload = {
          // email,s
          otp_type: "order",
          phone_number: (country_code + phone_number)
            .split("-")
            .filter((a) => a.trim().length)
            .join(""),
        };
        let success = () => {
          setState((prev) => ({
            ...prev,
            ["verifyLoader"]: false,
          }));
        };
        let fail = () => { };

        const response = await verifyDetail(payload, success, fail);
        if (response?.status == 200) {
          // toast.success(response.data.message);
          setState((prev) => ({
            ...prev,
            ["showModal"]: true,
          }));
        }
      }
    }
  };

  const calculateSC = async () => {
    try {
      let { shipping_city, shipping_state, shipping_zipcode } = state;

      const payload = new FormData();
      payload.append("city", shipping_city);
      payload.append("state", shipping_state);
      payload.append("zip_code", shipping_zipcode);

      setState((prev) => ({
        ...prev,
        shippingBtnLoader: true,
      }));
      const success = () => {
        setState((prev) => ({
          ...prev,
          shippingBtnLoader: false,
        }));
      };
      const fail = () => {
        setState((prev) => ({
          ...prev,
          shippingBtnLoader: false,
        }));
      };

      const response = await calculateShippingCharges(payload, success, fail);
      if (response.status == 200) {
        setShippingError(null);
        console.log("calculate shipping charges : ", response);
        setState((prev) => ({
          ...prev,
          shipping_options: response?.data?.data,
          shipping_charges: "",
          showShippingOptModel: true,
        }));
      } else {
        if (response?.request?.status == 500) {
          setShippingError("Shipping method not available to provided address");
        }
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const verifyOtp = async () => {
    try {
      let { otp_code, phone_number, country_code } = state;
      let payload = {
        otp_code,
        otp_type: "order",
        phone_number: (country_code + phone_number)
          .split("-")
          .filter((a) => a.trim().length)
          .join(""),
      };
      setState((prev) => ({
        ...prev,
        verifyOtpLoader: true,
      }));

      let success = () => {
        setState((prev) => ({
          ...prev,
          verifyOtpLoader: false,
          ["showModal"]: false,
          otp_code: "",
          otpVerified: true,
        }));
      };
      let fail = () => {
        setState((prev) => ({
          ...prev,
          // ['showModal']:false,
          otp_code: "",
          verifyOtpLoader: false,
        }));
      };

      const response = await verifyOtpCode(payload, success, fail);
      if (response?.data?.status != 200) {
        // console.log(first)
        setState((prev) => ({
          ...prev,
          ["errors"]: {
            ["otp"]: response?.data?.message,
          },
        }));
      }
    } catch (error) { }
  };
  // useEffect(() => {
  //   console.log("phoe_number : ", state?.phone_number);
  //   if (
  //     state?.phone_number?.length === 4 &&
  //     state?.phone_number.slice(3, 4) !== "-"
  //   ) {
  //     setState((prev) => ({
  //       ...prev,
  //       phone_number:
  //         state?.phone_number.slice(0, 3) + "-" + state?.phone_number.slice(3),
  //     }));
  //   } else if (
  //     state?.phone_number?.length === 8 &&
  //     state?.phone_number.slice(7, 8) !== "-"
  //   ) {
  //     setState((prev) => ({
  //       ...prev,
  //       phone_number:
  //         state?.phone_number.slice(0, 7) + "-" + state?.phone_number.slice(7),
  //     }));
  //   }
  // }, [state?.phone_number]);
  // useEffect(() => {
  //   console.log("phoe_number : ", state?.phone_number);
  //   if(state?.phone_number?.length === 3 && state?.phone_number.slice(2, 3) !== "-"){
  //     setState((prev) => ({
  //       ...prev,
  //       phone_number:  state?.phone_number.slice(0, 2)+"-"+state?.phone_number.slice(2),
  //     }))
  //   } else if(state?.phone_number?.length === 7 && state?.phone_number.slice(6, 7) !== "-"){
  //     setState((prev) => ({
  //       ...prev,
  //       phone_number:  state?.phone_number.slice(0, 6)+"-"+state?.phone_number.slice(6),
  //     }))
  //   } else if(state?.phone_number?.length === 11 && state?.phone_number.slice(10, 11) !== "-"){
  //     setState((prev) => ({
  //       ...prev,
  //       phone_number:  state?.phone_number.slice(0, 10)+"-"+state?.phone_number.slice(10),
  //     }))
  //   }
  // }, [state?.phone_number])
  // console.log("state : ", state);
  // console.log("redux : ", redux);

  // let totalPriceWithoutDonation =  parseInt(redux.totalShopCartItemPrice) - redux.shopCartItems.map(obj=>{
  //   console.log("obj : ", obj);
  //   if(obj.type == "donation"){
  //     return parseInt(obj.price.slice(1,obj.price.length))*obj.quantity
  //   }
  // })?.filter(obj=>obj!=undefined)[0];

  let totalDonationAmount = redux.shopCartItems
    .map((obj) => {
      if (obj.type == "donation") {
        return parseInt(obj.price.slice(1, obj.price.length)) * obj.quantity;
      }
    })
    ?.filter((obj) => obj != undefined)[0];

  let totalPriceWithoutDonation =
    totalDonationAmount === undefined || totalDonationAmount === null
      ? parseInt(redux.totalShopCartItemPrice) - 0
      : parseInt(redux.totalShopCartItemPrice) - totalDonationAmount;
  let taxPercentage = parseInt(
    redux.globalSettings.find((obj) => obj.config_key == "product_tax")
      .config_value
  );
  let tax = (taxPercentage / 100) * totalPriceWithoutDonation;

  let phone_code_list = DIAL_CODES.map((obj) => {
    return {
      label: obj.value + `  (${obj.label})`,
      value: obj.value,
    };
  });

  return (
    <>
      <div className="" style={{ paddingTop: 12, paddingLeft: 22 }}>
        <img
          src={Logo}
          style={{ width: 150, height: 120 }}
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className="container-xl">
        <div style={{ marginTop: "3rem" }}>
          <ShopButton
            disabled={state.verifyLoader}
            icon="back"
            title="Back to Shopping"
            onClick={() => (window.location.href = "/shop")}
          />
        </div>
        <div className="row mt-3">
          <div className="col-lg-6 col-md-7">
            <div className="h1 shopDetailHeading mt-4">Checkout</div>
            <div className="horizontalLine mt-5 mb-5"></div>
            <BreadCrumb
              list={[
                { label: "INFORMATION" },
                { label: "PAYMENT METHOD", active: true },
              ]}
            />
            <div className="headingTwo  mt-5 mb-3">Information</div>
            <div className="row">
              <div className="h4 mb-3 poppin-semibold">Contact Information</div>
              <div className="col-md-6 ">
                <Input
                  label="Name*"
                  placeholder="Name*"
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                  onBlur={() => onBlurHandler({ name: state.name })}
                  error={errors.name}
                />
              </div>
              <div className="col-md-6 ">
                <Input
                  label="Email Address*"
                  placeholder="Email Address*"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  onBlur={() => onBlurHandler({ email: state.email })}
                  error={errors.email}
                />
              </div>
              <div className="mt-2 d-flex justify-content-between">
                {/* <div className="col-md-4" style={{ paddingRight: "10px" }}>
                  <Dropdown
                    name={"country_code"}
                    label="Country Code*"
                    options={phone_code_list}
                    value={state.country_code}
                    onChange={handleChange}
                    onBlur={() =>
                      onBlurHandler({
                        country_code: state.country_code,
                      })
                    }
                    disabled={state.otpVerified || true}
                  />
                </div> */}
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Phone Number*"
                    placeholder="Phone Number*"
                    name="phone_number"
                    value={state.phone_number}
                    disabled={state.otpVerified}
                    onChange={handleChange}
                    onBlur={() =>
                      onBlurHandler({ phone_number: state.phone_number })
                    }
                    error={errors.phone_number}
                  />
                </div>
              </div>
              <div
                className={`col-md-12 justify-content-end ${state.otpVerified ? "d-none" : "d-flex"
                  }`}
              >
                <div className="col-md-3">
                  {state.verifyLoader ? (
                    <Spinner className="px-3 mt-2 d-flex justify-content-end" />
                  ) : (
                    <CartButton
                      title="Verify"
                      className="w-100"
                      onClick={verify}
                      disabled={
                        state.verifyLoader ||
                        !state.phone_number ||
                        errors.email ||
                        !state.country_code ||
                        !validatePhoneNumber(state.phone_number)
                      }
                    />
                  )}
                </div>
              </div>
              {state.otpVerified && (
                <>
                  <>
                    <div className="h4 mt-4 mb-3 poppin-semibold">
                      Shipping Address
                    </div>
                    <div className="col-md-6 mt-2">
                      <Input
                        label="First Name*"
                        placeholder="First Name*"
                        name="shipping_first_name"
                        value={state.shipping_first_name}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({
                            shipping_first_name: state.shipping_first_name,
                          })
                        }
                        error={errors.shipping_first_name}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <Input
                        label="Last Name*"
                        placeholder="Last Name*"
                        name="shipping_last_name"
                        value={state.shipping_last_name}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({
                            shipping_last_name: state.shipping_last_name,
                          })
                        }
                        error={errors.shipping_last_name}
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <Input
                        label="Street Address*"
                        placeholder="Street Address*"
                        name="shipping_address"
                        value={state.shipping_address}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({
                            shipping_address: state.shipping_address,
                          })
                        }
                        // disabled={state?.shipping_charges}
                        error={errors.shipping_address}
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <Input
                        label="Apartment / Suite / Unit"
                        placeholder="Apartment / Suite / Unit"
                        name="shipping_apartment"
                        value={state.shipping_apartment}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({
                            shipping_apartment: state.shipping_apartment,
                          })
                        }
                      // disabled={state?.shipping_charges}
                      // error={errors.shipping_apartment}
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <Input
                        label="City*"
                        placeholder="City"
                        name="shipping_city"
                        value={state.shipping_city}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({ shipping_city: state.shipping_city })
                        }
                        // disabled={state?.shipping_charges}
                        error={errors.shipping_city}
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <Dropdown
                        name={"shipping_state"}
                        label="State*"
                        options={StatesList}
                        value={state.shipping_state}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({
                            shipping_state: state.shipping_state,
                          })
                        }
                        // disabled={state?.shipping_charges}
                        error={errors.shipping_state}
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <Input
                        type="number"
                        label="Zip Code*"
                        placeholder="Zip Code*"
                        name="shipping_zipcode"
                        value={state.shipping_zipcode}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({
                            shipping_zipcode: state.shipping_zipcode,
                          })
                        }
                        // disabled={state?.shipping_charges}
                        error={errors.shipping_zipcode}
                        minLength={4}
                        maxLength={9}
                      />
                    </div>
                  </>

                  <>
                    <div className="h4 mt-4 mb-3 poppin-semibold">
                      Billing Address
                    </div>
                    <div className="col-md-12  d-flex align-items-center">
                      <TickCheck
                        check={state.billing_is_checked}
                        setCheck={(e) => {
                          setState((prev) => ({
                            ...prev,
                            billing_is_checked: e,
                          }));
                        }}
                      />{" "}
                      <div className="p px-3 poppin-regular">
                        Same as Shipping Address
                      </div>
                    </div>
                    {!state.billing_is_checked && (
                      <>
                        <div className="col-md-6 mt-2">
                          <Input
                            label="First Name*"
                            placeholder="First Name*"
                            name="billing_first_name"
                            value={state.billing_first_name}
                            onChange={handleChange}
                            onBlur={() =>
                              onBlurHandler({
                                billing_first_name: state.billing_first_name,
                              })
                            }
                            error={errors.billing_first_name}
                          />
                        </div>
                        <div className="col-md-6 mt-2">
                          <Input
                            label="Last Name*"
                            placeholder="Last Name*"
                            name="billing_last_name"
                            value={state.billing_last_name}
                            onChange={handleChange}
                            onBlur={() =>
                              onBlurHandler({
                                billing_last_name: state.billing_last_name,
                              })
                            }
                            error={errors.billing_last_name}
                          />
                        </div>
                        <div className="col-md-12 mt-2">
                          <Input
                            label="Street Address*"
                            placeholder="Street Address*"
                            name="billing_address"
                            value={state.billing_address}
                            onChange={handleChange}
                            onBlur={() =>
                              onBlurHandler({
                                billing_address: state.billing_address,
                              })
                            }
                            error={errors.billing_address}
                          />
                        </div>
                        <div className="col-md-12 mt-2">
                          <Input
                            label="Apartment / Suite / Unit"
                            placeholder="Apartment / Suite / Unit"
                            name="billing_apartment"
                            value={state.billing_apartment}
                            onChange={handleChange}
                            onBlur={() =>
                              onBlurHandler({
                                billing_apartment: state.billing_apartment,
                              })
                            }
                          // error={errors.billing_apartment}
                          />
                        </div>
                        <div className="col-md-4 mt-2">
                          <Input
                            label="City*"
                            placeholder="City"
                            name="billing_city"
                            value={state.billing_city}
                            onChange={handleChange}
                            onBlur={() =>
                              onBlurHandler({
                                billing_city: state.billing_city,
                              })
                            }
                            error={errors.billing_city}
                          />
                        </div>
                        <div className="col-md-4 mt-2">
                          <Dropdown
                            name={"billing_state"}
                            label="State*"
                            options={StatesList}
                            value={state.billing_state}
                            onChange={handleChange}
                            // onBlur={() => onBlurHandler({ shipping_city: state.shipping_city })}
                            error={errors.billing_state}
                          />
                        </div>
                        <div className="col-md-4 mt-2">
                          <Input
                            label="Zip Code*"
                            placeholder="Zip Code*"
                            name="billing_zipcode"
                            value={state.billing_zipcode}
                            onChange={handleChange}
                            onBlur={() =>
                              onBlurHandler({
                                billing_zipcode: state.billing_zipcode,
                              })
                            }
                            error={errors.billing_zipcode}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-md-12 mt-4 d-flex align-items-center">
                      <TickCheck
                        check={state.is_gift}
                        setCheck={(e) => {
                          setState((prev) => ({ ...prev, is_gift: e }));
                        }}
                      />{" "}
                      <div className="p px-3 poppin-regular">
                        This is a gift
                      </div>
                    </div>
                    <div
                      className="col-md-12 mt-3"
                      style={{ display: !state.is_gift ? "none" : "" }}
                    >
                      <Input
                        name="gift_note"
                        fieldType="textArea"
                        style={{ height: "100px" }}
                        label="Gift Message"
                        placeholder="Gift Message"
                        className=""
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({
                            gift_note: state.gift_note,
                          })
                        }
                        error={errors.gift_note}
                      />
                    </div>
                    <div className="col-md-12 justify-content-end d-flex mt-5 mb-5">
                      <ShopButton
                        // disabled={true}
                        icon="none"
                        title="Continue to Payment Method"
                        onClick={() => {
                          let {
                            name,
                            email,
                            country_code,
                            phone_number,
                            // Shipping Detail
                            shipping_first_name,
                            shipping_last_name,
                            shipping_address,
                            shipping_apartment,
                            shipping_city,
                            shipping_state,
                            shipping_zipcode,
                            is_gift,
                            gift_note,
                            // Billing Detail
                            billing_is_checked,
                            billing_first_name,
                            billing_last_name,
                            billing_address,
                            billing_apartment,
                            billing_city,
                            billing_state,
                            billing_zipcode,
                            shipping_charges,
                          } = state;
                          let totalkeys = [
                            "name",
                            "email",
                            "phone_number",
                            "shipping_first_name",
                            "shipping_last_name",
                            "shipping_address",
                            // "shipping_apartment",
                            "shipping_city",
                            "shipping_state",
                            "shipping_zipcode",
                            // "is_gift",
                            // "gift_note",
                            // "billing_is_checked",
                            "billing_first_name",
                            "billing_last_name",
                            "billing_address",
                            // "billing_apartment",
                            "billing_city",
                            "billing_state",
                            "billing_zipcode",
                            "shipping_charges",
                          ];
                          let ifBillingIsChecked = [
                            "name",
                            "email",
                            "phone_number",
                            "shipping_first_name",
                            "shipping_last_name",
                            "shipping_address",
                            // "shipping_apartment",
                            "shipping_city",
                            "shipping_state",
                            "shipping_zipcode",
                            // 'is_gift',
                            // 'gift_note',
                            "billing_is_checked",
                            "shipping_charges",
                          ];
                          if (state.is_gift) {
                            ifBillingIsChecked.push("gift_note");
                            totalkeys.push("gift_note");
                          }
                          let keys = state.billing_is_checked
                            ? ifBillingIsChecked
                            : totalkeys;
                          let error = validateRequiredFields(keys, state);
                          console.log("error", error);
                          if (!error) {
                            setState((prev) => ({
                              ...prev,
                              showPaymentModel: true,
                            }));
                            let products = redux.shopCartItems.map((obj) => {
                              return {
                                id: obj.id,
                                quantity: obj.quantity,
                                type: obj.type,
                                price: obj.price,
                              };
                            });

                            let payload = {
                              name,
                              email,
                              phone_number: (country_code + phone_number)
                                .split("-")
                                .filter((a) => a.trim().length)
                                .join(""),
                              // Shipping Detail
                              shipping_first_name,
                              shipping_last_name,
                              shipping_address,
                              shipping_apartment,
                              shipping_city,
                              shipping_state,
                              shipping_zipcode,
                              is_gift,
                              gift_note,
                              // Billing Detail
                              billing_is_checked,
                              billing_first_name,
                              billing_last_name,
                              billing_address,
                              billing_apartment,
                              billing_city,
                              billing_state,
                              billing_zipcode,
                              products,
                              ...(redux.linkCode && {
                                link_code: redux.linkCode,
                              }),
                              shipping_charges: Number(shipping_charges),
                              total_amount:
                                Number(Number(tax).toFixed(2)) +
                                Number(shipping_charges) +
                                Number(redux.totalShopCartItemPrice),
                              tax: tax,
                            };
                            dispatch({
                              type: APP.SET_CHECKOUT_INFORMATION,
                              payload: payload,
                            });
                            // window.location.origin = "/shop/checkout/payment";
                          }
                        }}
                      />
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-5 orderPadding">
            <div className="order-heading mt-4">Order Summary</div>
            <div className="order-paragraph">
              50% of each purchase benefits the Popcorn World Kids Foundation
            </div>
            <div className="">
              {redux?.shopCartItems?.length > 0 ? (
                redux?.shopCartItems?.map((cartItem) => (
                  <>
                    <SingleCartItem
                      screen="checkout"
                      secondSectionMd={9}
                      actions={false}
                      size={cartItem.total_products}
                      quantity={cartItem?.quantity}
                      data={cartItem}
                      image={
                        cartItem.type == "donation"
                          ? cartItem.image
                          : `${baseURL}/${cartItem.image}`
                      }
                      title={cartItem.title}
                      price={parseInt(
                        cartItem.price.slice(1, cartItem.price.length)
                      )}
                    />
                  </>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="horizontalLine mt-5 mb-5" />
            <div className="row">
              <div className="col-md-6 p poppin-regular ">Subtotal</div>
              <div className="col-md-6 h6 d-flex justify-content-end">
                {base_currency}
                {redux.totalShopCartItemPrice.toFixed(2)}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 p poppin-regular ">Tax</div>
              <div className="col-md-6 h6 d-flex justify-content-end poppin-regular text-lightgreyx">
                {base_currency}
                {tax.toFixed(2)}
                {/* Calculated at next step */}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 p poppin-regular ">Shipping</div>
              <div className="col-md-6 h6 d-flex justify-content-end poppin-regular">
                {state?.shipping_charges ? (
                  `${base_currency}` + state?.shipping_charges
                ) : state.shippingBtnLoader ? (
                  <Spinner className="px-3 mt-2 d-flex justify-content-end" />
                ) : (
                  <>
                    <CartButton
                      title="Calculate"
                      className="w-md-25  mt-0"
                      onClick={calculateSC}
                      disabled={
                        state.shippingBtnLoader ||
                        !state.shipping_city ||
                        !state.shipping_state ||
                        !state.shipping_zipcode
                      }
                    />
                  </>
                )}
              </div>
              {shippingError && (
                <div style={{ color: "red" }}>{shippingError}</div>
              )}
            </div>
            {/* <div className="row mt-2">
              <div className="col-md-12 p poppin-regular ">
                Estimated delivery: March 26 - April 5
              </div>
            </div> */}
            <div className="horizontalLine mt-5 mb-5" />
            <div className="row mt-2">
              <div className="col-md-6 p poppin-regular ">Total</div>
              <div className="col-md-6 h6 d-flex justify-content-end poppin-regular">
                {base_currency}
                {(
                  tax +
                  redux.totalShopCartItemPrice +
                  Number(state?.shipping_charges ? state?.shipping_charges : 0)
                ).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ marginTop: "7rem" }}>
        <div className="d-flex">
          <div className="footerText text-lightgrey">Need Help?</div>
          <a
            href="https://www.doublegood.com/privacy/"
            target="_blank"
            data-id="ds-privacy"
            className="footerLinks text-lightgrey"
          >
            Contact Us
          </a>
        </div>
      </div>

      <div
        className="container-fluid p-4 mt-3"
        style={{ backgroundColor: "rgb(248, 248, 250)" }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-6 justify-content-start">
              <div className="checkFooter-p">
                © 2023-24 Popcorn World Technologies LP. All rights reserved.
              </div>
            </div>
            <div className="col-md-6 justify-content-end d-flex">
              <a
                href="https://www.doublegood.com/privacy/"
                target="_blank"
                data-id="ds-privacy"
                className="footerLinks"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.doublegood.com/privacy/"
                target="_blank"
                data-id="ds-privacy"
                className="footerLinks"
              >
                Terms of service
              </a>
            </div>
          </div>
        </div>
        <VerifyOtpModal
          // errors=errors
          show={state.showModal}
          handleClose={() => {
            setState((prev) => ({
              ...prev,
              ["showModal"]: false,
              otp_code: "",
            }));
          }}
          state={state}
          setState={setState}
          verify={verifyOtp}
        />
        <ShippingOptionModel
          show={state.showShippingOptModel}
          handleClose={() => {
            setState((prev) => ({
              ...prev,
              ["showShippingOptModel"]: false,
              shipping_charges: "",
            }));
          }}
          state={state}
          setState={setState}
        />
        <PaymentModel
          show={state.showPaymentModel}
          handleClose={() => {
            setState((prev) => ({
              ...prev,
              ["showPaymentModel"]: false,
            }));
          }}
          state={state}
          setState={setState}
        />
      </div>
    </>
  );
};

export default Checkout;

export const TickCheck = ({ check, setCheck }) => {
  return (
    <>
      <div
        className="unCheck align-items-center"
        onClick={() => {
          setCheck(!check);
        }}
      >
        <div className="check-tick" style={{ display: !check ? "none" : "" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13.3339 4.00021L6.00025 11.3339L2.66675 8.00041"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
};

export const Spinner = ({ className }) => {
  return (
    <div className={`d-flex ${className}`}>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  );
};

export const OTP = ({ otp, setOtp }) => {
  return (
    <OTPInput
      containerStyle={{ justifyContent: "space-between", display: "flex" }}
      inputStyle={{
        width: "4rem",
        height: "4rem",
        borderRadius: "10px",
        border: "1px solid rgb(117, 117, 117)",
      }}
      value={otp}
      onChange={setOtp}
      numInputs={5}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
  );
};

export const PaymentModel = ({ show, handleClose, state, setState }) => {
  return (
    <Modal
      size="lg"
      backdrop="static"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Payment details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <ShopPayment />
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <div className="col-md-12 justify-content-end d-flex gap-2">
          <div className="col-md-3">
            {
              <CartButton
                border="0px"
                bgColor="grey"
                title="Cancel"
                className="w-100"
                onClick={handleClose}
                disabled={state.verifyLoader}
              />
            }
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            {state.verifyOtpLoader ? (
              <Spinner className="px-3 mt-2 d-flex justify-content-end" />
            ) : (
              <CartButton
                title="Okay"
                className="w-100"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    showShippingOptModel: false,
                  }));
                }}
                disabled={state.verifyOtpLoader}
              />
            )}
          </div>
        </div>
      </Modal.Footer> */}
    </Modal>
  );
};

export const VerifyOtpModal = ({
  show,
  handleClose,
  state,
  setState,
  verify,
}) => {
  return (
    <Modal backdrop="static" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Verify OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mt-5 mb-2">
          <OTP
            otp={state.otp_code}
            setOtp={(e) => {
              setState((prev) => ({
                ...prev,
                ["otp_code"]: e,
                ["errors"]: {
                  ["otp"]: false,
                },
              }));
            }}
          />
        </div>
        {state?.errors?.otp && (
          <text style={{ color: "red", marginLeft: "12%" }}>
            {state?.errors?.otp}
          </text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 justify-content-end d-flex gap-2">
          <div className="col-md-3">
            {
              <CartButton
                border="0px"
                bgColor="grey"
                title="Cancel"
                className="w-100"
                onClick={handleClose}
                disabled={state.verifyLoader}
              />
            }
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            {state.verifyOtpLoader ? (
              <Spinner className="px-3 mt-2 d-flex justify-content-end" />
            ) : (
              <CartButton
                title="Verify Otp"
                className="w-100"
                onClick={verify}
                disabled={state.otp_code.length < 5 || state.verifyOtpLoader}
              />
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const ShippingOptionModel = ({ show, handleClose, state, setState }) => {
  const [tog, setTog] = useState(null);
  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  const handleChange = (e) => {
    // setCharges(e.target.value);
    setState((prev) => ({
      ...prev,
      shipping_charges: Number(e.target.value).toFixed(2),
      showShippingOptModel: false,
    }));
  };
  return (
    <Modal backdrop="static" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Shipping Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flexx justify-content-center mt-5x mb-2">
          {state?.shipping_options?.map((item, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input d-none"
                onChange={handleChange}
                type="radio"
                name="exampleRadio"
                id={`option1${index}`}
                value={item?.service_cost}
              />
              <label
                className="form-check-label w-100 pointer"
                for={`option1${index}`}
                onClick={() => setTog(index)}
              >
                <div
                  className={`shipping-option-div ${tog === index && "active-shipping-option"
                    }`}
                >
                  <div className="d-flex justify-content-between">
                    <div>Name:</div>
                    <div>{item?.service_name}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>Charges:</div>
                    <div>
                      {base_currency}
                      {(item?.service_cost).toFixed(2)}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <div className="col-md-12 justify-content-end d-flex gap-2">
          <div className="col-md-3">
            {
              <CartButton
                border="0px"
                bgColor="grey"
                title="Cancel"
                className="w-100"
                onClick={handleClose}
                disabled={state.verifyLoader}
              />
            }
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            {state.verifyOtpLoader ? (
              <Spinner className="px-3 mt-2 d-flex justify-content-end" />
            ) : (
              <CartButton
                title="Okay"
                className="w-100"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    shipping_charges: Number(charges).toFixed(2),
                    showShippingOptModel: false,
                    showPaymentModel: true,
                  }));
                }}
                disabled={!charges || state.verifyOtpLoader}
              />
            )}
          </div>
        </div>
      </Modal.Footer> */}
    </Modal>
  );
};
