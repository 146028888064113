import React from 'react'

function Kidsbg() {
  return (
    <div className='kidsbg'>
        
    </div>
  )
}

export default Kidsbg