import React, { useEffect } from 'react';
import './Sidebar.css';
import { useSidebar } from './helper';

const Sidebar = ({open,onHide,children}) => {
  const {handleOverlayClick} = useSidebar({onHide});
  const changeOverlayBackground = (color) => {
    document.body.style.backgroundColor = color;
    document.body.style.overflowY = 'hidden';
  }
  // const closeNav = () => {
  //   onHide();
  //   changeOverlayBackground("transparent");
  // };

  useEffect(()=>{
    if(open){
    document.body.style.overflowY = 'hidden';
    changeOverlayBackground("#FAFAFA");
    }
    if(!open){
      document.body.style.overflowY = 'scroll';
    }
  },[open])

  return (
    <>
      {open && 
        (<>
          <div className="overlay" onClick={handleOverlayClick}>
          </div>
          <div id="mySidenav" className="sidenav">
                      {children}
          </div>
        </>
        )
      }
    </>
  );
};

export default Sidebar;