import React, { useEffect, useState } from "react";
import "./orders.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { getCustomerProfile } from "../../Services";
import { toast } from "react-toastify";
import avatar from '../../assets/images/user_ava.png'
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TabTitle from "../../Services/TabTitle";

const Orders = () => {
    TabTitle('Orders')
    const [orders, setOrders] = useState(null);
    const [selectedOrder, setSelectedOrders] = useState(null);
    const [loading, setLoading] = useState(true);
    const redux = useSelector((state) => state?.app);
    const navigate = useNavigate();

    let base_currency = redux?.globalSettings?.find(
        (obj) => obj.config_key == "campaign_currency"
    )?.config_value;


    let donation_amount = redux?.globalSettings?.find(
        (obj) => obj.config_key == "donation_amount"
    )?.config_value;

    console.log("first***********", selectedOrder?.orderitems)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
        };
        return date.toLocaleString("en-US", options);
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };
        return date.toLocaleString("en-US", options);
    };
    const getProfile = async () => {
        const token = localStorage.getItem("user_token")
        const phone_number = localStorage.getItem("phone_number")
        const payload = {
            params: {
                phone_number: `${phone_number}`,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await getCustomerProfile(payload);
        setLoading(false);
        if (response.data.data) {
            setOrders(response.data.data?.customer_orders);
            localStorage.setItem("userData", response?.data?.data)
            if (response.data.data?.customer_orders?.length > 0) {
                setSelectedOrders(response.data.data?.customer_orders?.[0]);
            }
        } else {
            // toast.error('User not exist!');
        }

    }
    const handleOrderClick = (order) => {
        setSelectedOrders(order);
    };

    useEffect(() => {
        const token = localStorage.getItem("user_token")
        if (token) {
            getProfile();
        } else {
            navigate('/signin')
        }
    }, [])

    return (
        <>
            {loading ? <Loader /> :
                <>
                    <Navbar />
                    <div className="content">
                        {/* <div className="orders"> */}
                        <div className="sidebar">
                            <h2>Orders</h2>
                            {/* {orders ?
                                    <ul>
                                        {orders?.map(order => (
                                            <li
                                                key={order.order_id}
                                                className={selectedOrder === order ? "selected" : ""}
                                                onClick={() => handleOrderClick(order)}
                                            >
                                                Order #{order.order_id}
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    <ul>
                                        <li>No Order Exist</li>
                                    </ul>
                                } */}
                            {/* <ul>
                                {orders.map(order => (
                                    <li key={order.order_id}>
                                        <a href={`#${order.order_id}`}>#{order.order_id}</a>
                                    </li>
                                ))}
                            </ul> */}
                            {orders ?
                                <ul>
                                    {orders?.map(order => (
                                        <li
                                            key={order.order_id}
                                            className={selectedOrder === order ? "selected" : ""}
                                            onClick={() => handleOrderClick(order)}
                                        >
                                            Order #{order.order_id.length > 16
                                                ? order.order_id.substring(0, 7) +
                                                "..." +
                                                order.order_id.substring(order.order_id.length - 7)
                                                : order.order_id}
                                        </li>
                                    ))}
                                </ul>
                                :
                                <ul>
                                    <li>No Order Exist</li>
                                </ul>
                            }
                        </div>
                        {selectedOrder &&
                            <div className="main-content">
                                <div className="container">
                                    <div class="row">
                                        <div >
                                            <h2 style={{ marginLeft: '4%', marginBottom: 30, marginTop: 20, textAlign: 'left', display: 'inline-block', fontWeight: 'bold' }}>Order No: <span>#{selectedOrder?.order_id}</span></h2>
                                        </div>

                                        <div class="col">
                                            <div className="col-lg-12 col-md-10  offset-lg-1 offset-md-1 mb-4 ">

                                                <div className="item-summary">
                                                    <h3>Items Summary</h3>
                                                    {orders?.length > 0 && (
                                                        <div className="item-table-container">
                                                            <table className="item-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Item</th>
                                                                        <th>QTY</th>
                                                                        <th>Price</th>
                                                                        <th>Total Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {selectedOrder?.orderitems?.map((order) => (
                                                                        <tr key={order.id}>
                                                                            <td>{order?.product?.title ? order?.product?.title : order?.relation_from == "donation" && 'Donation Box'}
                                                                            </td>
                                                                            <td>{order.total_quantity}</td>
                                                                            <td>{order?.product?.price ?
                                                                                `${base_currency}${parseFloat(order?.product?.price).toFixed(2)}`
                                                                                : `${base_currency}${parseFloat(donation_amount).toFixed(2)}`}
                                                                            </td>
                                                                            <td className="">
                                                                                {order?.product?.price ?
                                                                                    `${base_currency}${parseFloat(order?.total_quantity * order?.product?.price).toFixed(2)}`
                                                                                    : `${base_currency}${parseFloat(order?.total_quantity * donation_amount).toFixed(2)}`
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-10  offset-lg-1 offset-md-1 mb-4" >

                                                <div className="item-summary">
                                                    <h3>Shipping Address</h3>
                                                    <p style={{ marginTop: 14 }}>Address line: <span style={{}}>{selectedOrder?.shipping_address?.shipping_address}</span></p>
                                                    <p>Flat / Building Name: <span style={{}}>{selectedOrder?.shipping_address?.shipping_apartment}</span></p>
                                                    <p>State: <span style={{}}>{selectedOrder?.shipping_address?.shipping_state}</span></p>
                                                    <p>City : <span style={{}}>{selectedOrder?.shipping_address?.shipping_city}</span></p>
                                                    <p>Zipcode: <span style={{}}>{selectedOrder?.shipping_address?.shipping_zipcode}</span></p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col">
                                            <div className="col-lg-8 col-md-10 offset-md-1 mb-4" >

                                                <div className="order-summary">
                                                    <h3>Order Summary</h3>
                                                    <div className="row" style={{ marginTop: 14 }}>
                                                        <div className="col">
                                                            <p className="boldw">Status</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{selectedOrder?.order_status?.toUpperCase()}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="boldw">Order Date</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{formatDate(selectedOrder?.created_at)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="boldw">Order Time</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{formatTime(selectedOrder?.created_at)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="boldw">Subtotal</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{base_currency}{(selectedOrder?.total_amount - selectedOrder?.shipping_charges - selectedOrder?.tax).toFixed(2)} </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="boldw">Tax</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{base_currency}{selectedOrder?.tax}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="boldw">Delivery Fee</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{base_currency}{selectedOrder?.shipping_charges}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-10 offset-md-1 mb-4" >
                                                <div className="order-summary">

                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="boldw">Total Amount</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{base_currency}{selectedOrder?.total_amount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-8 col-md-10 offset-md-1 mb-4" >
                                                <div className="order-summary">
                                                    <h3>Billing Address</h3>
                                                    {selectedOrder?.is_billing_address_same == "1" ?
                                                        <p>Billing address is same as shipping address</p>
                                                        : <>
                                                            <p style={{ marginTop: 14 }}>Address line: <span style={{}}>{selectedOrder?.shipping_address?.shipping_address}</span></p>
                                                            <p>Flat / Building Name: <span style={{}}>{selectedOrder?.shipping_address?.shipping_apartment}</span></p>
                                                            <p>State: <span style={{}}>{selectedOrder?.shipping_address?.shipping_state}</span></p>
                                                            <p>City : <span style={{}}>{selectedOrder?.shipping_address?.shipping_city}</span></p>
                                                            <p>Zipcode: <span style={{}}>{selectedOrder?.shipping_address?.shipping_zipcode}</span></p>
                                                        </>

                                                    }
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        }
                        {/* </div> */}
                    </div>

                    <Footer />
                </>
            }
        </>
    )
}

export default Orders;