import React from "react";

function Friends() {
  return (
    <div className="friends">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-5 our-culture mb-3">
            <div>
              <h1>Friends of Popcorn World</h1>
            </div>
            <div>
              <p>
                When we're not collaborating within our own teams, we're
                collaborating with other like-minded organizations to create
                something truly special as we support kids with special needs.
                Meet the Friends of Popcorn World
              </p>
            </div>
            {/* <button className="btn ">
                Meet the Friends of Popcorn World
              </button> */}
          </div>
          <div className="col-md-6 d-flex justify-content-end ">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/friends.png"
              }
              className="img-fluid"
              alt="walking"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Friends;
