import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiSolidQuoteAltRight } from "react-icons/bi";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Donatefeedback() {
  return (
    <div className="donatefeedback">
      <div className="container pt-5">
        <div className="row d-flex justify-content-center text-center carousal-row pb-5">
          <div className="carousal-container">
            <Carousel
              responsive={responsive}
              autoPlay="false"
              interval={2000}
              infinite={false}
              arrows={false}
            >
              <div className="client-img ">
                <div className="feedbackicon" >
                  <BiSolidQuoteAltRight  color="white" className="p-3 icon" />
                </div>
                <div className=" d-flex justify-content-center align-items-center">
                  <h4 className=" client-feedback">
                    “I can’t say how much our employees LOVE your popcorn! The
                    excitement and energy around a Popcorn World popcorn
                    distribution is palpable. It’s a small gift that goes a
                    long, long way in making everyone’s day just a bit brighter.
                    I mean, there’s popcorn, and then there’s Popcorn World
                    Popcorn, which makes the smiles and joy twice as nice! Thank
                    you Popcorn World!”
                  </h4>
                </div>
                <div>
                  <span className="title">Jeff N. </span>
                  <p className="sub-title">Vice President of Hospitality Services</p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Donatefeedback;
