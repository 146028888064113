import React from 'react'
import Brochure from '../../Components/Elements/Brochurefundraising/Brochure'
import Double from '../../Components/Elements/Brochurefundraising/Double'
import Finished from '../../Components/Elements/Brochurefundraising/Finished'
import Print from '../../Components/Elements/Brochurefundraising/Print'
import Raise from '../../Components/Elements/Brochurefundraising/Raise'
import Find from '../../Components/Elements/Brochurefundraising/Find'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import TabTitle from '../../Services/TabTitle'

function Brochurefundraising() {
  TabTitle('Brochure Fundraising')
  return (
    <div>
      <Navbar bgColor={"#CCE5FF"} />
      <Brochure />
      <Double />
      <Finished />
      <Find />
      <Print />
      <Raise />
      <Footer />
    </div>
  )
}

export default Brochurefundraising