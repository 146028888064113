import React from "react";

function Comeplace() {
  return (
    <div className="come">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-5 our-culture pb-5">
            <div>
              <h1>Come raise joy with us</h1>
            </div>
            <div>
              <p>
                Imagine waking up every morning with the knowledge that you're
                making the world a better place. We want you to join our team
                and be a part of something special. Let's bring some extra
                smiles to people's lives—together!
              </p>
            </div>
            {/* <div>
              <button className="btn rounded-5">
                Apply Today
              </button>
            </div> */}
          </div>
          <div className="col-md-6">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/come.png"}
              className="img-fluid "
              alt="walking"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comeplace;
