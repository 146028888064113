import React, { useEffect } from "react";

function Pursue() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pursue">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-10 text-center">
            <span>CAREERS</span>
            <div>
              <h1>Pursue a career with purpose</h1>
            </div>
            <div>
              <p>
                Choose a career that aligns with your values. Work with
                like-minded individuals. And make a positive impact on the
                world. At Popcorn World, you'll discover how helping others brings
                immeasurable joy to your work.
              </p>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center ">
                <button className="btn">Explore Role</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Pursue;
