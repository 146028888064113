import React from "react";

function Looking() {
  return (
    <div className="looking">
      <div className="container">
        <div className="row ">
          <div className="col-md-8">
            <div className="card ">
              <div className="row">
                <div className="col-md-12 specific-flavour">
                  <div className="d-flex justify-content-between">
                    <div className="p-5">
                      <div>
                        <h3 className="secondary_font">
                          Looking for a specific flavor?
                        </h3>
                      </div>
                      <div>
                        <p>
                          Explore all flavors of Popcorn World popcorn and
                          select your favorites.
                        </p>
                      </div>
                      <div>
                        <a href="/shop">Shop All Flavours</a>
                      </div>
                    </div>
                    <div className="col-md-4 make-img"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Looking;
