import React from 'react'

function Dancebg() {
  return (
    <div className='dancebg'>
        
    </div>
  )
}

export default Dancebg