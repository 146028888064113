import React from "react";
import Pursue from "../../Components/Elements/Careers/Pursue";
import Raising from "../../Components/Elements/Careers/Raising";
import Joyful from "../../Components/Elements/Careers/Joyful";
import Feedback from "../../Components/Elements/Careers/Feedback";
import Greate from "../../Components/Elements/Careers/Greate";
import Discover from "../../Components/Elements/Careers/Discover";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Raisingjoy from "../../Components/Elements/Careers/Raisingjoy";
import Blogspage from "../../Components/Elements/Careers/Blogspage";
import TabTitle from "../../Services/TabTitle";

function Careers() {
  TabTitle('Careers')
  return (
    <div>
      <Navbar bgColor={"#FFEEB2"} />
      <Pursue />
      <Raising />
      <Raisingjoy />
      <Joyful />
      <Feedback />
      <Greate />
      <Discover />
      <Blogspage />
      <Footer />
    </div>
  );
}

export default Careers;
