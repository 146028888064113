import React from "react";
import { useNavigate } from "react-router-dom";

function Do() {
  const navigate = useNavigate();
  return (
    <div className="do">
      <div className="container py-5 text-center">
        <div className="row ">
          <div className="col-md-8">
            <div className="card ">
              <div className="row row2 justify-content-between m-0 ">
                <div className="col-md-2 do1-img"></div>
                <div className="col-md-6 specific-flavour">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div>
                        <h2 className="secondary_font">#DoADoubleGood</h2>
                      </div>
                      <div>
                        <p>
                          Fundraise with your team, sell ultra‑premium popcorn,
                          and earn 50% profit. No fundraising minimums or fees.
                        </p>
                      </div>
                      <div>
                        <button
                          className="btn"
                          onClick={() => navigate("/store-demo")}
                        >
                          Start a Fundraiser
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 do2-img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Do;
