import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Raisingjoy from "../../Components/Elements/Careers/Raisingjoy";
import Joy from "../../Components/Elements/Places/Joy";
import Ready from "../../Components/Elements/Virtualfundraising/Ready";
import Donatefeedback from "../../Components/Elements/Donatejoy/Donatefeedback";
import Donatejoybg from "../../Components/Elements/Donatejoy/Donatejoybg";
import "./Home.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../../Services";
import { baseURL } from "../../Services/axios";
import { Link, useNavigate } from "react-router-dom";
import { Svgs } from "../../Components/Navbar/Navbar";
import { BiFontColor } from "react-icons/bi";
import AppstoreBtn from "../../assets/img/app-store-btn.svg";
import TabTitle from "../../Services/TabTitle";
import PopcornProfit from "./PopcornProfit";
import Football from "./Football";
import Mission from "./Mission";
import Whoa from "./Whoa";
import PopcornFlavors from "./PopcornFlavors";
import Box from "./Box";
import Jail from "./Jail";
import VirtualFundraising from "./VirtualFundraising";
import DollarSupport from "./DollarSupport";
import Possible4Days from "./Possible4Days";
import GetPoppin from "./GetPoppin";
import RaisingPopcorn from "../../Components/Elements/Careers/RaisingPopcorn";
import Joy1 from "../../Components/Elements/Places/Joyold";
import RaisingYum from "../../Components/Elements/Careers/RaisingYum";

const Home = () => {
  TabTitle("Home");
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.data);

  useEffect(() => {
    dispatch(getCampaigns());
  }, []);

  return (
    <>
      <Navbar />
      <PopcornProfit />
      {/* <Raisingjoy /> */}
      <RaisingPopcorn />
      {/* <Football /> */}
      <Mission />
      {/* <Joy /> */}
      {/* <Joy1 /> */}
      <RaisingYum />
      <VirtualFundraising />
      <DollarSupport />
      {/* <GetPoppin /> */}

      {redux.campaigns.length > 0 && (
        <section className="bg-green">
          <div
            className="container-fluid justify-content-center"
            style={{ paddingTop: "5rem", paddingBottom: "3rem" }}
          >
            <h1 className="heading poppin-bold text-center">
              Explore live Pop-Up Stores
            </h1>
            <div>
              <p className="paragraph mt-4 text-center">
                Discover the excitement of fundraisers happening right now!
              </p>
            </div>
            <CustomCarousel campaigns={redux.campaigns} />
          </div>
        </section>
      )}
      <Possible4Days />
      <PopcornFlavors />
      {/* <Box /> */}
      <Whoa />
      {/* <Jail /> */}
      <Donatefeedback />
      <Ready />
      <Footer />
    </>
  );
};

export default Home;

const SliderCard = ({ onClick, data }) => {
  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  return (
    <div className="d-flex flex-column slidecard" onClick={onClick}>
      <div
        className="slideImage"
        style={{
          backgroundImage: `url(${`${baseURL}/${data.campaign_image}`})`,
        }}
      ></div>
      <div className="slideline"></div>
      <div className="slideContent">
        <div className="justify-content-between d-flex">
          <div className="slideheading h3 col-9 col-md-9">
            {data?.campaign_title}
          </div>
          <div className="slidePrice h4">
            {base_currency}
            {data["raise_amount"]}
          </div>
        </div>
        <div className="p slidePara">
          Team total: {base_currency}
          {data.collected_amount || 0}
        </div>
      </div>
    </div>
  );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div
      className="carousel-button-group d-flex gap-2 align-items-center w-100 d-flex justify-content-center"
      style={{ position: "absolute", bottom: "0" }}
    >
      <div
        className={`btnArrow `}
        // ${currentSlide == 0 ?`btnArrow-disabled`:''}
        onClick={
          () => previous()
          // currentSlide === 0 ? goToSlide(images.length-3) :
        }
        aria-disabled={currentSlide === 0}
      >
        <svg
          class=""
          width="1.5rem"
          height="1.5rem"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: "inline-block", verticalAlign: "middle" }}
        >
          <path
            d="M496.64 173.568c-6.656 2.261-28.16 23.083-162.603 157.312-112.171 111.957-155.947 156.757-158.549 162.176-2.828 5.59-4.484 12.187-4.484 19.171 0 4.895 0.814 9.6 2.313 13.987l-0.090-0.304c2.816 8.661 10.539 16.725 157.653 164.053 111.957 112.171 156.757 155.947 162.176 158.549 5.522 2.85 12.051 4.521 18.971 4.521 11.663 0 22.217-4.746 29.837-12.412l0.002-0.002c11.904-11.904 15.531-27.947 10.027-44.587-2.517-7.637-13.312-18.987-118.997-124.885l-116.267-116.48h506.624l7.339-3.584c24.875-11.989 30.421-50.261 10.24-70.443-3.906-3.948-8.628-7.080-13.894-9.123l-0.271-0.093c-5.419-1.621-64.469-2.091-258.517-2.091h-251.52l116.267-116.48c105.685-105.899 116.48-117.248 118.997-124.885 5.504-16.64 1.877-32.683-9.984-44.544-7.765-7.75-18.484-12.543-30.322-12.543-5.368 0-10.506 0.985-15.242 2.785l0.295-0.098z"
            style={{ fill: "white" }}
          ></path>
        </svg>
      </div>
      <div
        className={`btnArrow`}
        // ${currentSlide+3 == images.length ?`btnArrow-disabled`:''}
        onClick={() => {
          next();
          // currentSlide  === images.length ? goToSlide(0) :
        }}
      // aria-disabled={lastImageIndex}
      >
        <svg
          width="1.5rem"
          height="1.5rem"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: "inline-block", verticalAlign: "middle" }}
        >
          <path
            d="M496.768 173.611c-15.019 5.291-27.435 23.253-27.435 39.723 0 3.413 1.237 9.984 2.773 14.635 2.517 7.637 13.312 18.987 118.997 124.885l116.267 116.48h-251.52c-194.048 0-253.099 0.469-258.517 2.091-14.72 4.395-26.667 22.613-26.667 40.576 0 16.469 9.429 32.64 22.741 39.083l7.339 3.584h506.624l-116.267 116.48c-105.685 105.899-116.48 117.248-118.997 124.885-5.504 16.64-1.877 32.683 10.027 44.587 7.622 7.668 18.176 12.414 29.839 12.414 6.92 0 13.45-1.671 19.208-4.631l-0.236 0.11c5.419-2.603 50.219-46.379 162.176-158.549 147.115-147.328 154.837-155.392 157.653-164.053 1.409-4.083 2.223-8.788 2.223-13.683 0-6.984-1.656-13.58-4.597-19.419l0.113 0.248c-2.603-5.419-46.379-50.176-158.549-162.176-141.696-141.483-155.648-154.923-163.328-157.44-4.377-1.59-9.429-2.509-14.695-2.509-5.445 0-10.66 0.982-15.478 2.78l0.306-0.1z"
            style={{ fill: "white" }}
          />
        </svg>
      </div>
    </div>
  );
};

export const CustomCarousel = ({ campaigns }) => {
  const navigate = useNavigate();
  var carousel = useRef(null);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 500 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3, // tablet
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // mobile
    },
  };
  return (
    <div className="row justify-content-between gap-2">
      <Carousel
        ssr
        ref={(el) => (carousel = el)}
        customButtonGroup={<ButtonGroup />}
        responsive={responsive}
        autoPlay={false}
        infinite={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
        itemClass="col-md-4 px-3 mb-5 mt-4 py-b-3"
        arrows={false}
      >
        {campaigns?.length > 0 ? (
          campaigns?.map((obj) => (
            <SliderCard
              data={obj}
              onClick={() => {
                window.open(`${window.location.href}s/${obj.slug}`, "_blank");
              }}
            />
          ))
        ) : (
          <></>
        )}
      </Carousel>
    </div>
  );
};
