import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import {thunk} from 'redux-thunk';
import Reducers from './rootReducer';
import { persistStore } from 'redux-persist';
import persistReducer from '../redux-persist-config'; // Import the persist config
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

export default function configureStore() {
  const store = createStore(persistReducer(Reducers) , applyMiddleware(thunk));
  const persistor = persistStore(store);

  return {persistor,store};
}