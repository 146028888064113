import React from 'react'
import About from '../../Components/Elements/About/About'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Aboutbg from '../../Components/Elements/About/Aboutbg'
import Ground from '../../Components/Elements/About/Ground'
import Raisejoy from '../../Components/Elements/About/Raisejoy'
import Aboutfaq from '../../Components/Elements/About/Aboutfaq'
import Join from '../../Components/Elements/About/Join'
// import Good from '../../Components/Elements/Toolkit/Good'
import Raisingjoy from '../../Components/Elements/Careers/Raisingjoy'
import Goodback1 from '../../Components/Elements/Toolkit/Goodback1'
import TabTitle from '../../Services/TabTitle'

function Aboutus() {
  TabTitle('About us')
  return (
    <div>
        <Navbar bgColor={"#CCE5FF"}/>
        <About/>
        <Aboutbg/>
        <Ground/>
        <Raisingjoy/>
        <Raisejoy/>
        <Goodback1/>
        <Aboutfaq/>
        <Join/>
        <Footer/>
    
        
    </div>
  )
}

export default Aboutus