import React from "react";
import { IoBookmarkOutline } from "react-icons/io5";

function Popular() {
  return (
    <div className="popular">
      <div className="container ">
        <div className="row justify-content-center text-center">
          <div className="col-md-8">
            <h1>Popular Topics</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-3">
            <div className="card p-3">
              <div className="pt-3 pb-4">
                <span className="rounded-circle bgcircle p-3">
                  <IoBookmarkOutline size={25} />
                </span>
              </div>
              <div>
                <h2>Virtual Pop-Up Stores</h2>
              </div>
              <div>
                <p>
                  Get tips on how to create an engaging and attractive store
                  display, and how to promote your Pop-Up Store.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="card p-3">
              <div className="pt-3 pb-4">
                <span className="rounded-circle bgcircle p-3">
                  <IoBookmarkOutline size={25} />
                </span>
              </div>
              <div>
                <h2>Fundraiser Payout</h2>
              </div>
              <div>
                <p>
                  Learn how to set up your payout account, track your earnings,
                  and transfer funds to your bank account.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="card p-3">
              <div className="pt-3 pb-4">
                <span className="rounded-circle bgcircle p-3">
                  <IoBookmarkOutline size={25} />
                </span>
              </div>
              <div>
                <h2>Virtual Fundraising</h2>
              </div>
              <div>
                <p>
                  Learn how to conduct a successful fundraising event, find tips
                  and tools to maximize your reach, and engage with supporters
                  virtually.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="card p-3">
              <div className="pt-3 pb-4">
                <span className="rounded-circle bgcircle p-3">
                  <IoBookmarkOutline size={25} />
                </span>
              </div>
              <div>
                <h2>Thank Your Supporters</h2>
              </div>
              <div>
                <p>
                  Learn how you can thank everyone who buys from your Pop-Up
                  Store.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popular;
