import React from "react";
import { GoDeviceCameraVideo } from "react-icons/go";

function Finger() {
  return (
    <div className="finger">
      <div className="container py-5">
        <div className="row text-center">
          <div className="tips">
            <h1>Finger-Tip Videos</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card">
              <div>
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "/assets/images/f1.jpg"
                  }
                  className="img-fluid finger-img"
                  alt="finger1"
                />
              </div>
              <div className="bg-dark camera py-2">
                <div className="d-flex gap-2">
                  <GoDeviceCameraVideo color="white" size={20} />
                  <span>FINGER-TIP</span>
                </div>
              </div>
              <div className="px-3 pt-3 learn">
                <h1>Learn how supporters can contribute</h1>
              </div>
              <div className="px-3 ">
                <p>
                  Popcorn World makes it easier than ever for family and friends
                  to support a fundraiser and receive fresh and delicious
                  small-batch popcorn. In this video, we show you how to reach
                  supporters for your fundraiser.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <div>
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "/assets/images/f2.jpg"
                  }
                  className="img-fluid finger-img"
                  alt="finger1"
                />
              </div>
              <div className="bg-dark camera py-2">
                <div className="d-flex gap-2">
                  <GoDeviceCameraVideo color="white" size={20} />
                  <span>FINGER-TIP</span>
                </div>
              </div>
              <div className="px-3 pt-3 learn">
                <h1>Learn how to organize a Popcorn World fundraiser</h1>
              </div>
              <div className="px-3 pb-4">
                <p>
                  You can organize and manage your team’s fundraiser from
                  anywhere. In this video, we show you how to schedule a
                  fundraising event and invite your team to participate
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <div>
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "/assets/images/f3.jpg"
                  }
                  className="img-fluid finger-img"
                  alt="finger1"
                />
              </div>
              <div className="bg-dark camera py-2">
                <div className="d-flex gap-2">
                  <GoDeviceCameraVideo color="white" size={20} />
                  <span>FINGER-TIP</span>
                </div>
              </div>
              <div className="px-3 pt-3 learn">
                <h1>Learn how to set up a virtual Pop-Up Store</h1>
              </div>
              <div className="px-3 pb-4">
                <p>
                  Popcorn World makes it easy for you to join your team’s
                  fundraiser and meet or surpass your goals. In this video, we
                  show you how to create and personalize your virtual Pop-Up
                  Store.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finger;
