import React from "react";

function Inspired() {
  return (
    <div className="inspired">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <div>
              <h1 className="secondary_font"> Now inspired. Let’s get fundraising.</h1>
            </div>
            <div>
              <p>
                Get ready to boost your fundraising game and create a Pop-Up
                Store that pops and helps you meet your goals.
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center ">
            <button
              onClick={(event) => {
                event.preventDefault();
                window.open(
                  `${process.env.REACT_APP_PUBLIC_URL}` + "store-demo",
                  "_blank"
                );
              }}
              className="btn"
            >
              Start a Fundraiser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inspired;
