import React from "react";

function Raise() {
  return (
    <div className="raise">
      <div className="container py-5">
        <div className="row justify-content-center text-center">
          <div className="col-md-11">
            <div>
              <h1>Raise more with virtual fundraising</h1>
            </div>
            <div>
              <p>
                On average, organizers raise 3x more through a virtual
                fundraiser.
              </p>
            </div>
            <div>{/* <button className="btn">Learn More</button> */}</div>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <img
            src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/raisebg.png"}
            className="img-fluid"
            alt="raise"
          />
        </div>
      </div>
    </div>
  );
}

export default Raise;
