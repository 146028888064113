import React , {useRef} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Feedback() {
  const carouselRef = useRef(null);

  const goToPrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const goToNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
    <div className="feedback">
      <div className="container">
        <div className="row d-flex justify-content-center text-center carousal-row pb-5">
          <div className="carousal-container">
            <Carousel
              responsive={responsive}
              ref={carouselRef}
              autoPlay="false"
              interval={2000}
              // infinite="true"
              arrows={false}
            >
              <div className="client-img ">
                <div>
                  <BiSolidQuoteAltRight className="icon" size={100} color="#0941e5" />
                </div>
                <div className=" d-flex justify-content-center align-items-center">
                  <h4 className=" client-feedback">
                    “Joy is not just a buzzword here. It drives everything we
                    do, from company culture to user experience to the impact we
                    want to have on the communities we serve.”
                  </h4>
                </div>
                <div>
                  <span className="title">David B, </span>
                  <p className="sub-title">Associate Product Manager</p>
                </div>
              </div>
            </Carousel>
          </div>
          {/* <div className="arrow-navigation gap-2">
            <div>
              <div className="arrow-left pb-1" onClick={goToPrevSlide}>
                <BsArrowLeft size={"30px"} className="pt-1 arrow" />
              </div>
            </div>
            <div>
              <div className="arrow-right pb-1" onClick={goToNextSlide}>
                <BsArrowRight size={"30px"} className="pt-1 arrow" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Feedback;
