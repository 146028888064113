import React from "react";
import Culturehero from "../../Components/Elements/Culture/Culturehero";
import Friends from "../../Components/Elements/Culture/Friends";
import Feedback from "../../Components/Elements/Culture/Feedback";
import Discover from "../../Components/Elements/Culture/Discover";
import Live from "../../Components/Elements/Culture/Live";
import Meet from "../../Components/Elements/Culture/Meet";
import Askingquestion from "../../Components/Elements/Culture/Askingquestion";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import TabTitle from "../../Services/TabTitle";

function Culture() {
  TabTitle('Our Culture')
  return (
    <div>
      <Navbar bgColor={"white"}/>
      <Culturehero />
      <Live />
      <Askingquestion />
      <Feedback />
      <Friends />
      <Discover />
      <Meet />
      <Footer/>
    </div>
  );
}

export default Culture;
