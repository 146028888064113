import React, { useRef } from "react";
import Virtual from "../../Components/Elements/Virtualfundraising/Virtual";
import Popup from "../../Components/Elements/Virtualfundraising/Popup";
import Dancebg from "../../Components/Elements/Virtualfundraising/Dancebg";
import Explorecraousel from "../../Components/Elements/Virtualfundraising/Explorecraousel";
import Ready from "../../Components/Elements/Virtualfundraising/Ready";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { useSelector } from "react-redux";
import { CustomCarousel } from "../Home/Home";
import TabTitle from "../../Services/TabTitle";

function Virtualfundraising() {
  TabTitle("Virtual Fundraising");
  const redux = useSelector((state) => state.data);
  return (
    <div>
      <Navbar bgColor={"white"} />
      <Virtual />
      <Popup />
      <Dancebg />
      {redux.campaigns.length > 0 && (
        <section className="bg-green">
          <div
            className="container-fluid justify-content-center"
            style={{ paddingTop: "5rem", paddingBottom: "3rem" }}
          >
            <h1 className="heading poppin-bold text-center">
              Explore live Pop-Up Stores
            </h1>
            <div>
              <p className="paragraph mt-4 text-center">
                Discover the excitement of fundraisers happening right now!
              </p>
            </div>
            <CustomCarousel campaigns={redux.campaigns} />
          </div>
        </section>
      )}
      <Ready />
      <Footer />
    </div>
  );
}

export default Virtualfundraising;
