import React, { useEffect, useState } from "react";

function RaisingYum() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      // console.log(window.scrollY)
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="raisingjoy">
      <div className="container-fluid">
        <div className="row">
          <div className="allimg" id="parallax-container">
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 330}px)`,
              }}
            >
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img1"
                alt="joy"
              />

              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img2"
                alt="joy"
              />
            </div>
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 330}px)`,
              }}
            >
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img1"
                alt="joy"
              />
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img2"
                alt="joy"
              />
            </div>
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 330}px)`,
              }}
            >
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img1"
                alt="joy"
              />
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img2"
                alt="joy"
              />
            </div>
            <div
              className="  parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 330}px)`,
              }}
            >
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img1"
                alt="joy"
              />
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img2"
                alt="joy"
              />
            </div>
            <div
              className="  parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 330}px)`,
              }}
            >
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img1"
                alt="joy"
              />
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img2 "
                alt="joy"
              />
            </div>
            <div
              className=" parallax-item"
              style={{
                transform: `translateX(${scrollPosition * 0.1 - 330}px)`,
              }}
            >
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img1"
                alt="joy"
              />
              <img
                src={
                   process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"
                }
                className="img2"
                alt="joy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RaisingYum;
