import React, {useRef} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { MdOutlineArrowForward } from "react-icons/md";
import { MdOutlineArrowBack } from "react-icons/md";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Feedback() {
  const carouselRef = useRef(null);

  const goToPrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const goToNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
    <div className="feedback">
        <div className="container pt-5">
          <div className="row d-flex justify-content-center text-center carousal-row pb-5">
            <div className="carousal-container">
              <Carousel
                responsive={responsive}
                ref={carouselRef}
                // autoPlay={false}
                interval={2000}
                infinite="true"
                arrows={false}
              >
                <div className="client-img ">
                  <div>
                    <BiSolidQuoteAltRight className="quote" size={100} color="#0941e5" />
                  </div>
                  <div className=" d-flex justify-content-center align-items-center">
                    <p className=" client-feedback">
                      “I feel very lucky to work for a company I truly believe
                      in. I’ve recommended Popcorn World fundraisers to a lot of
                      my friends now, and the popcorn is some of the best I’ve
                      ever had.”
                    </p>
                  </div>
                  <div>
                    <span className="title">Alexa D. </span>
                    <p className="sub-title">Event Coordinator</p>
                  </div>

                </div>
                <div className="client-img  ">
                  <div>
                    <BiSolidQuoteAltRight size={100} color="#0941e5" />
                  </div>
                  <div className=" d-flex justify-content-center align-items-center">
                    <p className=" client-feedback">
                      “I feel very lucky to work for a company I truly believe
                      in. I’ve recommended Popcorn World fundraisers to a lot of
                      my friends now, and the popcorn is some of the best I’ve
                      ever had.”
                    </p>
                  </div>
                  <div>
                    <span className="title">Alexa D. </span>
                    <p className="sub-title">Event Coordinator</p>
                  </div>

                </div>
                <div className="client-img ">
                  <div>
                    <BiSolidQuoteAltRight size={100} color="#0941e5" />
                  </div>
                  <div className=" d-flex justify-content-center align-items-center">
                    <p className=" client-feedback">
                      “I feel very lucky to work for a company I truly believe
                      in. I’ve recommended Popcorn World fundraisers to a lot of
                      my friends now, and the popcorn is some of the best I’ve
                      ever had.”
                    </p>
                  </div>
                  <div>
                    <span className="title">Alexa D. </span>
                    <p className="sub-title">Event Coordinator</p>
                  </div>

                </div>
                
              </Carousel>
            </div>
            <div className="arrow-navigation gap-2">
            <div>
              <div className="arrow-left pb-1" onClick={goToPrevSlide}>
                <MdOutlineArrowBack size={"30px"} className="pt-1 arrow" />
              </div>
            </div>
            <div>
              <div className="arrow-right pb-1" onClick={goToNextSlide}>
                <MdOutlineArrowForward size={"30px"} className="pt-1 arrow" />
              </div>
            </div>
          </div>
          </div>
        </div>
    </div>
  );
}

export default Feedback;
