import React from "react";
import { useNavigate } from "react-router-dom";

function Buy() {
  const navigate = useNavigate();
  return (
    <div className="buy">
      <div className="container ">
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <div>
              <h1>Buy popcorn. Raise joy. Every time.</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/buy1.png"}
                  className="img-fluid"
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>
                  Buy a bag of your favorite flavor directly from our site
                </h3>
              </div>
              <div>
                <p>
                  Whenever you get a hankering for your favorite Popcorn World
                  flavor, add it to your cart, and know that you’re helping
                  dreams come true.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/buy2.png"}
                  className="img-fluid"
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>50% of what you pay goes to our Kids Foundation</h3>
              </div>
              <div>
                <p>
                  We’ll ship our popcorn directly to you, and half of what you
                  pay goes directly into our non-profit fund.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/buy3.png"}
                  className="img-fluid"
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>Our Kids Foundation supports kids with special needs</h3>
              </div>
              <div>
                <p>
                  Our Kids Foundation hosts Popcorn World Days to bring
                  all-ability fun to children and families.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center pt-5">
          <div>
            <button className="btn" onClick={() => navigate("/shop")}>
              Shop Popcorn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
