import React from "react";

function Shipping() {
  return (
    <div className="production">
      <div className="container px-0 pt-4">
        <div className="row justify-content-between">
          <div className="col-md-5  mb-3">
            <div>
              <h1>Shipping Facilities</h1>
            </div>
            <div>
              <p>
                At Popcorn World, our shipping game is strong! Our facilities run
                like well-oiled machines, zipping bags of popcorn straight to
                door steps around the country. Our team of superhero delivery
                drivers do more than just ship popcorn. They deliver happiness
                to every doorstep with speed and spot-on precision.
              </p>
            </div>
          </div>
          <div className="col-md-6  mb-3">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="d-flex gap-2">
                  <div className="pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>5K+Zip</h1>
                    </div>
                    <div>
                      <span>shipped to</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="d-flex gap-2">
                  <div className=" pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>2 Million</h1>
                    </div>
                    <div>
                      <span>
                        bags shipped to <br /> Essential workers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="d-flex gap-2">
                  <div className="pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>8+ States</h1>
                    </div>
                    <div>
                      <span>
                        the average number <br /> of states shipped <br /> to
                        per event.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/s1.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/s2.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/s3.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shipping;
