import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Home from "./Pages/Home/Home";
import Shop from "./Pages/Shop/Shop";
import ShopDetail from "./Pages/ShopDetail/ShopDetail";
import Careers from "./Pages/Careers/Careers";
import Culture from "./Pages/Culture/Culture";
import People from "./Pages/People/People";
import Base from "./Pages/Base/Base";
import Place from "./Pages/Place/Place";
import Checkout from "./Pages/Checkout/Checkout";
import ShopPayment from "./Pages/Checkout/ShopPayment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { globalSettings } from "./Services";
// import "bootstrap/dist/css/bootstrap.min.css"

import Toolkit from "./Pages/Toolkit/Toolkit";
import Virtualfundraising from "./Pages/VirtualFundraisin/Virtualfundraising";
import Brochurefundraising from "./Pages/BrochureFundraising/Brochurefundraising";
import Get from "./Pages/Started/Get";
import Demo from "./Pages/Store-Demo/Demo";
import Aboutus from "./Pages/About/Aboutus";
import TermsCondition from "./Pages/TermsCondition/TermsCondition";
import Privacy from "./Pages/Privacy/Privacy";
// import "bootstrap/dist/css/bootstrap.min.css"
import Kidsfoundation from "./Pages/KidsFoundation/Kidsfoundation";
import Donate from "./Pages/DonateJoy/Donate";
import Blogs from "./Pages/Stories/Blogs";
import Openblog from "./Pages/Blogsopened.jsx/Openblog";
import Getstarted from "./Components/Elements/Get-Started/Getstarted";
import OrderConfirmed from "./Pages/Checkout/OrderConfirmed";
import SignIn from "./Pages/SignIn/SignIn";
import Profile from "./Pages/user/Profile";
import TrackOrder from "./Pages/TrackOrder/TrackOrder";
import Orders from "./Pages/orders/Orders";
import Demo1 from "./Pages/Store-Demo/Demo1";
import FundraiserRegistered from "./Pages/Checkout/FundraiserRegistered";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalSettings());
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ textTransform: "uppercase" }}
      />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store-demo" element={<Demo1 />} />
          <Route path="/get-started" element={<Get />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/checkout" element={<Checkout />} />
          <Route path="/shop/checkout/payment" element={<ShopPayment />} />
          <Route
            path="/shop/checkout/order-confirmed"
            element={<OrderConfirmed />}
          />
          <Route
            path="/fundraiser-registered"
            element={<FundraiserRegistered />}
          />
          <Route path="/shop/:slug" element={<ShopDetail />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/our-culture" element={<Culture />} />
          <Route path="/our-people" element={<People />} />
          <Route path="/our-places" element={<Place />} />
          <Route path="/toolkit" element={<Toolkit />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/terms-and-conditions" element={<TermsCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/donate-joy" element={<Donate />} />
          <Route path="/blog/:slug" element={<Openblog />} />
          <Route path="/kids-foundation" element={<Kidsfoundation />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route
            path="/what-is-virtual-fundraising"
            element={<Virtualfundraising />}
          />
          <Route path="/s/:slug" element={<Demo />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/track-an-order" element={<TrackOrder />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/orders" element={<Orders />} />
          <Route
            path="/brochure-fundraising"
            element={<Brochurefundraising />}
          />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
