import React from "react";
import { useNavigate } from "react-router-dom";

function Ground() {
  const navigate = useNavigate();
  return (
    <div className="ground">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <div>
              <h1>
                ABOUT A STORY POPPED WITH HEART: POPCORN WORLD'S COMMUNITY
                CRUNCH
              </h1>
            </div>
            <div>
              <p>
                Popcorn World isn't just about delicious flavors (although,
                let's be honest, that's a big part of it!). It's a story about
                community, opportunity, and giving back, all wrapped up in a
                warm, buttery kernel.
              </p>
            </div>
            <div>
              <p>
                In 2018, LeBarron Burton took the reins of Popcorn World, a
                company started by two of his childhood friends. LeBarron knew
                he wanted to honor their legacy, not just by continuing the
                incredible variety of over 250 gourmet popcorn flavors, but by
                making Popcorn World a force for good.
              </p>
            </div>
            <div>
              <p>
                Here's where the real magic happens. Popcorn World isn't
                satisfied with just offering delicious, hand-coated popcorn.
                They've built a strong fundraising program designed to empower
                communities across the US. LeBarron, a proud resident of Gary,
                Indiana, sees the potential for revitalization. That's why 50%
                of the profits raised through our website will directly benefit
                Gary's schools, helping future generations pop with potential.
              </p>
            </div>
            <div>
              <p>
                But that's not all! Popcorn World believes in building bridges,
                not just bowls of popcorn. They actively partner with
                sororities, fraternities, Little League teams, churches, and
                nonprofits nationwide. They want to be a resource, a way for
                organizations to raise funds for their own projects and dreams.
              </p>
            </div>
            <div>
              <p>
                LeBarron believes his purpose goes beyond popcorn. He has a
                vision for Popcorn World to become the next biggest brand in the
                popcorn industry, driven by a powerful message. He sees it as
                his God-given mission to showcase a black-owned business
                thriving with integrity and deep community awareness. LeBarron
                wants to use Popcorn World as a tool to create a ripple effect,
                evening the playing field in low-income communities.
              </p>
            </div>
            <div>
              <p>
                <span>This is more than popcorn, it's a movement.</span> Join
                Popcorn World in their mission to build a brighter future, one
                delicious kernel at a time. Start your fundraiser today and see
                how your sweet tooth can make a difference!
              </p>
            </div>
           
            <div>
              <button className="btn" onClick={() => navigate("/our-culture")}>
                Get To Know Our Culture
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ground;
