import React from "react";

function Finished() {
  return (
    <div className="finished">
      <div className="container ">
        <div className="row justify-content-between">
          <div className="col-md-5 our-culture">
            <div>
              <h1>Finished fundraising? Submit your order!</h1>
            </div>
            <div>
              <p>
                Now that you’ve collected all orders, tally up seller brochures
                and order our premium, small batch popcorn. We'll handle the
                rest and ship the delicious goodies straight to you so your
                sellers can deliver some sweet treats!
              </p>
            </div>
            {/* <div>
              <button className="btn rounded-5">
                Submit Your Orders
              </button>
            </div> */}
          </div>
          <div className="col-md-6">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/finish.png"}
              className="img-fluid"
              alt="walking"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finished;
