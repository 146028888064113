import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import TabTitle from "../../Services/TabTitle";

const FundraiserRegistered = ({}) => {
  TabTitle("Fundraiser Registered");
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("user_token");

  return (
    <>
      {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous" /> */}
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        <div className="row">
          <div className="jumbotron" style={{ boxShadow: "0.5px #000000" }}>
            <h3 className="text-center">
              <strong>Congratulations!</strong>
            </h3>
            <h4 className="text-center">
              Your Fundraiser registration is completed successfully.
            </h4>
            <h6 className="text-center">
              Kindly check your email for furthur instructions.{" "}
            </h6>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FundraiserRegistered;
