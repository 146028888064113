export const get_shop_products = "/api/get-products";
export const get_single_product = "/api/get-product";
export const get_shop_collections = "/api/get-collections";
export const verify_otp = "/api/send-otp";
export const verify_otp_code = "/api/verify-otp";
export const create_order = "/api/create-order";
export const get_global_settings = "/api/get-global-settings";
export const calculate_shipping_charges = "/api/get-rates";
export const get_campaigns = "/api/get-campaigns";
export const get_single_campaign = "/api/get-campaign";
export const get_all_blogs = "/api/get-blogs";
export const track_order = "/api/track-order";
export const check_email_exists = "/api/check-email-exists";
export const get_customer_profile = "/api/customer-profile";
export const update_customer_profile = "/api/update-profile";
