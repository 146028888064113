import React from 'react'
import './BreadCrumb.css'
const BreadCrumb = ({list}) => {
  return (
    <div className='d-flex gap-1'>
      {list.map((li,index) => (
        <>
          <div className={` ${li.active ? "breadcrumb-item-active" : "breadcrumb-item"}`}>{li.label}</div>
          <span style={{display:index == list.length-1 ? 'none' : ''}}>
            <i aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="8"
                viewBox="0 0 5 8"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <g stroke="#000" stroke-width="2">
                    <g>
                      <path
                        d="M118 18L121 15 118 12"
                        transform="translate(-160 -418) translate(43 407)"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </i>
          </span>
        </>
      ))}
    </div>
  );
}

export default BreadCrumb