import React from "react";

function Location() {
  return (
    <div className="location">
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h1>Our Location</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/popcorn.png"
              }
              className="img-fluid"
              alt="people"
            />
            <div className="text-center">
              <h4>Burr Ridge, IL</h4>
              <p>
                <u>16W030 83rd St</u>
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/shipping.png"
              }
              className="img-fluid"
              alt="place"
            />
            <div className="text-center">
              <h4>Elmhurst, IL</h4>
              <p>
                <u>837 S Riverside Dr</u>
              </p>
            </div>
          </div>
          <div className="col-md-4 ">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/brand.png"}
              className="img-fluid"
              alt="place"
            />
            <div className="text-center">
              <h4>Chicago, IL</h4>
              <p>
                <u>233 S Wacker Dr, Suite 4400</u>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Location;
