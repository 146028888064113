import React from "react";

function Department() {
  return (
    <div className="department">
      <div className="container py-5">
        <div className="row py-3 justify-content-center">
          <div className="col-md-8 text-center">
            <h1>Department spotlights</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/popcorn.png"
              }
              className="img-fluid"
              alt="people"
            />
            <div>
              <h5>Popcorn Manufacturing</h5>
            </div>
            <div>
              <p>
                This is the heart and soul of Popcorn World, tirelessly popping
                and packaging delicious popcorn on demand every single day, and
                they do so knowing that each kernel has the potential to create
                joy in people’s lives. Our Joy Makers thrive in a friendly,
                communal, and safe work environment.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <img
              src="/assets/images/shipping.png"
              className="img-fluid"
              alt="place"
            />
            <div>
              <h5>Shipping</h5>
            </div>
            <div>
              <p>
                Whether it’s in support of a fundraiser or direct to consumers,
                our delicious artisanal popcorn isn’t much use unless it reaches
                the doorsteps of the millions of people who trust and enjoy
                Popcorn World every year. Our amazing team of sorters and packers
                work tirelessly and diligently every day to make sure our
                shipments don’t miss a beat.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/brand.png"}
              className="img-fluid"
              alt="place"
            />
            <div>
              <h5>Brand Experience</h5>
            </div>
            <div>
              <p>
                Join a diverse team of professionals driven by excellence and
                with varying levels of top-tier experience in everything from
                digital marketing, brand strategy, creative development, partner
                relationships and customer support – with just the right amount
                of goofiness to make every day a joyful experience.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL +
                "/assets/images/peopleplaces.png"
              }
              className="img-fluid"
              alt="people"
            />
            <div>
              <h5>People and Places</h5>
            </div>
            <div>
              <p>
                It takes a strong foundation to preserve Popcorn World’s very
                important mission of creating joy in an ever-changing world,
                which is why our People team leads the charge with fairness,
                healthy business practices, and a strong work ethic, all while
                maintaining a joyful work environment. After all, people are our
                most precious resource.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/product.png"
              }
              className="img-fluid"
              alt="place"
            />
            <div>
              <h5>Product and Engineering</h5>
            </div>
            <div>
              <p>
                We believe technology exists to make people’s lives better.
                That’s the guiding principle behind our Product and Engineering
                team, which is responsible for developing cutting-edge tech with
                our community of users in mind and a constant drive to continue
                improving our virtual experience.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/finance.png"
              }
              className="img-fluid"
              alt="place"
            />
            <div>
              <h5>Finance, Legal, and IT</h5>
            </div>
            <div>
              <p>
                Finance, Legal, and IT Whether your thing is numbers, legal
                matters, or hardware/software, if what drives you is excellence,
                assisting others, and a passion for technology and data in the
                service of people, then we want to hear from you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Department;
