import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import { FaRegHeart } from "react-icons/fa";
import { GoPeople } from "react-icons/go";
import { FaRegStar } from "react-icons/fa6";
import { HiOutlinePlus } from "react-icons/hi2";
import { HiOutlineMinus } from "react-icons/hi2";

function Greate() {
  const [isOpen, setIsOpen] = useState({
    collapseOne: true,
    collapseTwo: false,
    collapseThree: false,
    collapseFour: false,
  });

  const toggleAccordion = (collapseId) => {
    setIsOpen((prevOpenState) => ({
      ...Object.fromEntries(
        Object.entries(prevOpenState).map(([key]) => [key, false])
      ),
      [collapseId]: !prevOpenState[collapseId],
    }));
  };

  return (
    <div className="great">
      <div className="container ">
        <div className="row justify-content-between">
          <div className="col-md-5">
            <div>
              <h1>Great benefits for good people</h1>
            </div>
            <div>
              <p>
                At Popcorn World, our people are the heart of everything we do.
                We've got you covered with a benefits program that includes
                medical, dental, and vision insurance, a 401(k) plan, and plenty
                of time off. Plus, we offer additional perks to keep you feeling
                your best, both inside and out.
              </p>
            </div>
          </div>
          <div className="col-md-5">
            {Object.entries(isOpen).map(([collapseId, isOpenValue]) => (
              <div key={collapseId}>
                <div className="accordion" id="myAccordion">
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id={`heading${collapseId}`}
                    >
                      <button
                        type="button"
                        className={`accordion-button d-flex justify-content-between `}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${collapseId}`}
                        aria-expanded={isOpenValue}
                        onClick={() => toggleAccordion(collapseId)}
                      >
                        <div className="d-flex gap-3">
                          {collapseId === "collapseOne" && (
                            <div
                              style={{
                                backgroundColor: "#CCE5FF",
                                borderRadius: "50%",
                                padding: "15px",
                              }}
                            >
                              <FaRegHeart size={30} />
                            </div>
                          )}
                          {collapseId === "collapseTwo" && (
                            <div
                              style={{
                                backgroundColor: "#CCE5FF",
                                borderRadius: "50%",
                                padding: "15px",
                              }}
                            >
                              <GoPeople size={30} />
                            </div>
                          )}
                          {collapseId === "collapseThree" && (
                            <div
                              style={{
                                backgroundColor: "#CCE5FF",
                                borderRadius: "50%",
                                padding: "15px",
                              }}
                            >
                              <GoPeople size={30} />
                            </div>
                          )}
                          {collapseId === "collapseFour" && (
                            <div
                              style={{
                                backgroundColor: "#CCE5FF",
                                borderRadius: "50%",
                                padding: "15px",
                              }}
                            >
                              <FaRegStar size={30} />
                            </div>
                          )}
                          <h1 className="d-flex justify-content-center align-items-center health">
                            {collapseId === "collapseOne" && "Health"}
                            {collapseId === "collapseTwo" && "Retirement"}
                            {collapseId === "collapseThree" &&
                              "Life Assistance"}
                            {collapseId === "collapseFour" &&
                              "Additional Perks"}
                          </h1>
                        </div>
                        <span>
                          {isOpenValue ? (
                            <HiOutlineMinus size={25} />
                          ) : (
                            <HiOutlinePlus size={25} />
                          )}
                        </span>
                      </button>
                    </h2>
                    <div
                      id={`collapse${collapseId}`}
                      className={`accordion-collapse collapse ${
                        isOpenValue ? "show" : ""
                      }`}
                      aria-labelledby={`heading${collapseId}`}
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body">
                        <ul>
                          {collapseId === "collapseOne" && (
                            <>
                              <li>Medical</li>
                              <li>Dental</li>
                              <li>Vision</li>
                              <li>Flexible Spending Accounts (FSA)</li>
                              <li>Disability Income</li>
                            </>
                          )}
                          {collapseId === "collapseTwo" && <li>401K</li>}
                          {collapseId === "collapseThree" && (
                            <>
                              <li>Counselling</li>
                              <li>Coaching</li>
                              <li>Online Programs</li>
                              <li>Work-Life Services</li>
                            </>
                          )}
                          {collapseId === "collapseFour" && (
                            <>
                              <li>Paid Time Off</li>
                              <li>Massages</li>
                              <li>Free Company Gear</li>
                              <li>Monthly Popcorn Allowance</li>
                              <li>Company Sponsored Events</li>
                              <li>Volunteer Opportunities</li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Greate;
