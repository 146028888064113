import React, { useEffect, useState } from "react";

import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import ShopCart from "../../Pages/Cart/ShopCart";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import UserAvatar from "../..//assets/images/user_ava.png";
import { baseURL } from "../../Services/axios";

const Navbar = ({ bgColor, hide, topBgColor }) => {
  const [openCart, setOpenCart] = useState(false);
  const redux = useSelector((state) => state.app);
  // const isLoggedIn = useSelector((state) => state.user.user_token);
  const isLoggedIn = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const showCartSideBar = () => {
    setOpenCart(true);
  };
  return (
    <>
      <NavAlert />
      <div
        className="navPrimary d-flex justify-content-center desktopNav"
        style={{
          backgroundColor: "#33FF33",
        }}
      >
        <div className="container px-2">
          <div className="col-md-12 d-flex justify-content-end align-items-center">
            {/* <NavDropdown
              title="Careers"
              list={[
                {
                  label: "Careers",
                  href: "/careers",
                },
                {
                  label: "Our Culture",
                  href: "/our-culture",
                },
                {
                  label: "Our People",
                  href: "/our-people",
                },
                {
                  label: "Our Places",
                  href: "/our-places",
                },
              ]}
            /> */}
            <Link
              className="btn"
              to="/track-an-order"
              style={{ marginRight: "20px" }}
            >
              Track an Order
            </Link>
            {isLoggedIn ? (
              // <img src={UserAvatar} width={"35px"} alt="user-avatar" />
              <NavDropdown
                title="Avatar"
                icon={UserAvatar}
                list={[
                  {
                    label: "Profile",
                    href: "/profile",
                  },
                  {
                    label: "Orders",
                    href: "/orders",
                  },
                  {
                    label: "Logout",
                    href: "logout",
                  },
                ]}
              />
            ) : (
              <button
                className="btn signinbtn mr-2"
                style={{ marginLeft: "20px" }}
                onClick={() => navigate("/signin")}
              >
                Sign In
              </button>
            )}
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginLeft: "22px",
                position: "relative",
              }}
            >
              <div class="cartQuantity">
                {redux.shopCartItems.length > 0 && redux.shopCartItems.length}
              </div>
              <Svgs type="cartIcon" onClick={showCartSideBar} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center p-3 desktopNav"
        style={{ backgroundColor: "#3333CB" }}
      >
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-3">
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL +
                  "/assets/images/fundraiserLogo.png"
                }
                className="logo"
                style={{ width: "220px" }}
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <div className="col-md-9 d-flex justify-content-end align-items-center">
              <NavItem
                className="btn nav-color"
                to="/what-is-virtual-fundraising"
              >
                Fundraising
              </NavItem>

              {/* <NavDropdown
                title="Fundraising"
                list={[
                  {
                    label: "Virtual FundRaising",
                    href: "/what-is-virtual-fundraising",
                  },
                  {
                    label: "Explore Popcorn Flavors",
                    href: "/shop",
                  },
                  // {
                  //   label: "Toolkits",
                  //   href: "/toolkit",
                  // },
                  // {
                  //   label: "Brochure Fundraising",
                  //   href: "/brochure-fundraising",
                  // },
                ]}
              /> */}

              <NavItem className="btn nav-color">Shop Popcorn</NavItem>

              <NavItem className="btn nav-color" to="/about-us">
                About
              </NavItem>
              {/* <NavDropdown
                title="Stories"
                list={[
                  {
                    label: "Blogs",
                    href: "/blogs",
                  },
                ]}
              /> */}
              {/* <NavDropdown
                title="About"
                list={[
                  {
                    label: "About Us",
                    href: "/about-us",
                  },
                  {
                    label: "What is Virutal Fundraising",
                    href: "/what-is-virtual-fundraising",
                  },
                  // {
                  //   label: "Kids Foundation",
                  //   href: "/kids-foundation",
                  // },
                  // {
                  //   label: "Donate Joy",
                  //   href: "/donate-joy",
                  // },
                ]}
              /> */}
              <Link to={"/store-demo"}>
                <div className="getStartedBtn">
                  <Svgs type="started" width="24px" height="24px" />
                  Get Started
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="navPrimary d-flex justify-content-center  mobileNav"
        style={{ backgroundColor: "rgb(51, 255, 51)" }}
      >
        <div className="container-fluid d-flex justify-content-between px-4 mt-2">
          <div
            className="scrollbar"
            onClick={() => {
              document.getElementById("side-panel").style.width = "100%";
            }}
          >
            <div className="scrollbaricon">
              <svg
                width="1.4rem"
                height="1.4rem"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <path
                  d="M113.792 216.107c-15.531 4.779-28.459 22.912-28.459 39.893 0 17.152 13.312 35.285 29.44 40.064 7.68 2.304 54.4 2.603 397.227 2.603s389.547-0.299 397.227-2.603c16.128-4.779 29.44-22.912 29.44-40.064s-13.312-35.285-29.44-40.064c-7.723-2.304-54.229-2.603-397.952-2.475-339.029 0.085-390.315 0.427-397.483 2.645zM113.664 472.021c-9.756 3.576-17.648 10.295-22.631 18.908l-0.11 0.206c-4.053 6.869-4.736 9.941-4.736 20.864s0.683 13.995 4.736 20.864c5.504 9.387 13.056 15.573 23.339 19.2 6.485 2.304 52.821 2.603 397.653 2.603 438.059 0 400.085 1.152 413.995-12.757 7.731-7.619 12.521-18.205 12.521-29.909s-4.79-22.29-12.516-29.904l-0.005-0.005c-13.909-13.952 24.149-12.757-414.635-12.629-334.848 0.085-391.893 0.469-397.611 2.56zM113.792 728.107c-15.531 4.779-28.459 22.912-28.459 39.893 0 17.152 13.312 35.285 29.44 40.064 7.68 2.304 54.4 2.603 397.227 2.603s389.547-0.299 397.227-2.603c16.128-4.779 29.44-22.912 29.44-40.064s-13.312-35.285-29.44-40.064c-7.723-2.304-54.229-2.603-397.952-2.475-339.029 0.085-390.315 0.427-397.483 2.645z"
                  style={{ fill: "currentColor" }}
                ></path>
              </svg>
            </div>
          </div>

          <img
            src={
              process.env.REACT_APP_PUBLIC_URL +
              "/static/media/fundraiserLogo.png"
            }
            className="mobile_logo"
            width="80"
            height="55"
          />
          <div></div>
          <div className="d-flex align-items-center gap-3">
            <div className="signinIcon">
              <svg
                className=""
                width="1.4rem"
                height="1.4rem"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <path
                  d="M490.667 86.4c-50.799 5.585-95.484 28.19-129.015 61.9l-0.009 0.009c-72.747 72.747-83.2 184.491-25.387 270.848 11.392 17.024 37.888 43.648 54.571 54.784 25.045 16.768 50.773 27.648 80.299 33.92 19.968 4.267 61.781 4.267 81.749 0 43.691-9.301 78.763-28.16 109.483-58.837 30.677-30.72 49.536-65.792 58.837-109.483 4.267-19.968 4.267-61.781 0-81.749-9.301-43.691-28.16-78.763-58.837-109.483-28.013-28.531-64.126-49.018-104.614-57.737l-1.37-0.247c-14.293-3.2-51.797-5.419-65.707-3.925zM545.28 174.933c35.513 10.468 64.188 34.442 80.634 65.832l0.347 0.728c10.155 20.651 12.757 32.299 12.757 57.173s-2.603 36.523-12.757 57.173c-16.922 32.385-45.937 56.501-80.901 66.585l-0.934 0.231c-15.317 4.181-49.536 4.181-64.853 0-34.816-9.472-66.219-35.115-81.835-66.816-7.774-14.621-12.582-31.879-13.179-50.201l-0.005-0.188c-3.883-62.251 38.955-118.272 100.992-132.053 14.677-3.285 44.757-2.517 59.733 1.536zM313.173 599.296c-42.069 5.376-86.613 26.667-118.016 56.533-30.976 29.44-50.432 62.848-61.44 105.685-4.437 17.067-4.565 19.371-5.291 78.293-0.768 66.56-0.384 70.272 8.576 82.048 18.688 24.448 57.984 20.779 71.467-6.656 3.883-7.893 4.053-9.728 5.035-67.84 0.811-45.653 1.664-61.739 3.584-68.267 12.075-40.235 39.04-71.125 75.605-86.485 22.443-9.429 13.995-9.088 219.307-9.088 211.157 0 197.632-0.725 224.427 11.861 33.877 15.872 56.875 42.581 69.205 80.299 3.669 11.264 3.968 15.488 4.907 72.533l0.981 60.587 4.736 8.064c7.409 12.97 21.156 21.571 36.912 21.571 11.778 0 22.434-4.806 30.113-12.565l0.004-0.004c12.672-12.672 13.099-15.701 12.288-85.803-0.725-59.221-0.853-61.483-5.291-78.549-11.008-42.837-30.464-76.245-61.44-105.685-32.054-30.099-73.475-50.566-119.442-56.587l-1.092-0.117c-17.92-2.219-377.771-2.048-395.136 0.171z"
                  style={{ fill: "currentColor" }}
                ></path>
              </svg>
            </div>
            <div style={{ position: "relative" }}>
              <div class="cartQuantity">
                {redux.shopCartItems.length > 0 && redux.shopCartItems.length}
              </div>
              <Svgs type="cartIcon" onClick={showCartSideBar} />
            </div>
          </div>
        </div>
      </div>
      <>
        <div id="side-panel" className="sidepanel">
          <div
            className="d-flex align-items-center mt-2 w-100"
            style={{ position: "absolute", top: "20px" }}
          >
            <>
              <div
                style={{ marginLeft: "40px", cursor: "pointer" }}
                onClick={() => {
                  document.getElementById("side-panel").style.width = "0";
                }}
              >
                <svg
                  className=""
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                  width="1.4rem"
                  height="1.4rem"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ fill: "currentColor" }}
                    d="M240.768 216.276c-15.020 5.29-27.436 23.252-27.436 39.724 0 3.414 1.236 9.984 2.772 14.634 2.516 7.638 13.312 18.988 118.998 124.884l116.266 116.48-116.266 116.48c-105.686 105.9-116.48 117.248-118.998 124.884-5.504 16.64-1.878 32.682 9.984 44.544s27.904 15.488 44.544 9.984c7.638-2.518 18.988-13.312 124.884-118.996l116.48-116.266 116.48 116.268c105.9 105.684 117.248 116.48 124.884 118.996 16.64 5.504 32.682 1.876 44.544-9.984s15.488-27.904 9.984-44.544c-2.518-7.636-13.312-18.986-118.996-124.884l-116.266-116.48 116.268-116.48c105.684-105.9 116.48-117.248 118.996-124.884 5.504-16.64 1.876-32.682-9.984-44.544s-27.904-15.488-44.544-9.984c-7.636 2.516-18.986 13.312-124.884 118.996l-116.48 116.266-116.48-116.266c-105.9-105.686-117.248-116.48-124.884-118.998-4.376-1.6-9.428-2.526-14.698-2.526-5.444 0-10.66 0.988-15.474 2.796l0.304-0.1z"
                  ></path>
                </svg>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "40px",
                  gap: "20px",
                }}
              >
                <div className="signinIcon">
                  <svg
                    className=""
                    width="1.4rem"
                    height="1.4rem"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    <path
                      d="M490.667 86.4c-50.799 5.585-95.484 28.19-129.015 61.9l-0.009 0.009c-72.747 72.747-83.2 184.491-25.387 270.848 11.392 17.024 37.888 43.648 54.571 54.784 25.045 16.768 50.773 27.648 80.299 33.92 19.968 4.267 61.781 4.267 81.749 0 43.691-9.301 78.763-28.16 109.483-58.837 30.677-30.72 49.536-65.792 58.837-109.483 4.267-19.968 4.267-61.781 0-81.749-9.301-43.691-28.16-78.763-58.837-109.483-28.013-28.531-64.126-49.018-104.614-57.737l-1.37-0.247c-14.293-3.2-51.797-5.419-65.707-3.925zM545.28 174.933c35.513 10.468 64.188 34.442 80.634 65.832l0.347 0.728c10.155 20.651 12.757 32.299 12.757 57.173s-2.603 36.523-12.757 57.173c-16.922 32.385-45.937 56.501-80.901 66.585l-0.934 0.231c-15.317 4.181-49.536 4.181-64.853 0-34.816-9.472-66.219-35.115-81.835-66.816-7.774-14.621-12.582-31.879-13.179-50.201l-0.005-0.188c-3.883-62.251 38.955-118.272 100.992-132.053 14.677-3.285 44.757-2.517 59.733 1.536zM313.173 599.296c-42.069 5.376-86.613 26.667-118.016 56.533-30.976 29.44-50.432 62.848-61.44 105.685-4.437 17.067-4.565 19.371-5.291 78.293-0.768 66.56-0.384 70.272 8.576 82.048 18.688 24.448 57.984 20.779 71.467-6.656 3.883-7.893 4.053-9.728 5.035-67.84 0.811-45.653 1.664-61.739 3.584-68.267 12.075-40.235 39.04-71.125 75.605-86.485 22.443-9.429 13.995-9.088 219.307-9.088 211.157 0 197.632-0.725 224.427 11.861 33.877 15.872 56.875 42.581 69.205 80.299 3.669 11.264 3.968 15.488 4.907 72.533l0.981 60.587 4.736 8.064c7.409 12.97 21.156 21.571 36.912 21.571 11.778 0 22.434-4.806 30.113-12.565l0.004-0.004c12.672-12.672 13.099-15.701 12.288-85.803-0.725-59.221-0.853-61.483-5.291-78.549-11.008-42.837-30.464-76.245-61.44-105.685-32.054-30.099-73.475-50.566-119.442-56.587l-1.092-0.117c-17.92-2.219-377.771-2.048-395.136 0.171z"
                      style={{ fill: "currentColor" }}
                    ></path>
                  </svg>
                </div>
                <div>
                  <Svgs type="cartIcon" />
                </div>
              </div>
            </>
          </div>
          <div className="justify-content-center d-flex">
            <div
              className="getStartedBtn text-center mt-5"
              style={{ width: "95%", alignItems: "" }}
            >
              <Svgs type="started" width="24px" height="24px" />
              Get Started
            </div>
          </div>
          <div>
            <div
              className="accordion accordion-flush px-3 mt-4 "
              id="accordionFlushExample"
            >
              <AccordionItem
                title="FUNDRAISING"
                list={[
                  {
                    label: "Virtual Fundraising",
                    value: "/what-is-virtual-fundraising",
                  },
                  { label: "Explore Popcorn Flavor", value: "" },
                  { label: "Toolkits", value: "/toolkit" },
                  {
                    label: "Brochure Fundraising",
                    value: "/brochure-fundraising",
                  },
                ]}
              />

              <AccordionItem title="SHOP POPCORN" />
              {/* <AccordionItem
                title="STORIES"
                list={[{ label: "Blog", value: "/blogs" }]}
              /> */}
              <AccordionItem
                title="ABOUT"
                list={[
                  { label: "About Us", value: "/aboutus" },
                  {
                    label: "What is Virtual Fundraising",
                    value: "/what-is-virtual-fundraising",
                  },
                  { label: "Kids Foundation", value: "/kids-foundation" },
                  { label: "Donate Joy", value: "/donate-joy" },
                ]}
              />
              <AccordionItem
                title="CAREERS"
                list={[
                  { label: "Careers", value: "/careers" },
                  { label: "Our Culture", value: "/our-culture" },
                  { label: "Our People", value: "/our-people" },
                  { label: "Our Places", value: "/-our-places" },
                ]}
              />
            </div>

            <div className="btn p-3 gap-3" style={{ cursor: "pointer" }}>
              Track an Order
            </div>
          </div>
          <div
            className="btn poppin-bold w-100 mb-5"
            style={{ position: "relative", fontWeight: "bold" }}
          >
            <hr />
            <p>Sign In</p>
          </div>
        </div>
      </>
      <ShopCart
        openCart={openCart}
        onHideCart={() => {
          setOpenCart(false);
        }}
      />
    </>
  );
};

export default Navbar;

export const AccordionItem = ({ title, list, onClick }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="accordion-item mt-3" style={{ borderRadius: "10px" }}>
        <h2 className="accordion-header" id={`flush-headingOne${title}`}>
          {!list?.length ? (
            <>
              <button
                className="mb-2 btn mt-2 mb-2"
                type="button"
                style={{
                  fontWeight: "100",
                  width: "100%",
                  textAlign: "left",
                  marginLeft: "5px",
                }}
                onClick={onClick}
              >
                {title}
              </button>
            </>
          ) : (
            <>
              <button
                className="accordion-button collapsed mb-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapseOne${title}`}
                aria-expanded="false"
                aria-controls={`flush-collapseOne${title}`}
                style={{ fontWeight: "100" }}
                onClick={onClick}
              >
                {title}
              </button>
            </>
          )}
        </h2>
        {list?.length > 0 && (
          <div
            id={`flush-collapseOne${title}`}
            className="accordion-collapse collapse"
            aria-labelledby={`flush-headingOne${title}`}
            data-bs-parent="#accordionFlushExample"
          >
            <div
              className="accordion-body"
              style={{
                paddingTop: "0.5rem",
                paddingLeft: "0px",
                borderTop: "2px solid lightgrey",
              }}
            >
              <ul style={{ paddingLeft: "1.5rem" }}>
                {list?.map((option) => (
                  <li
                    onClick={() => navigate(option.value)}
                    style={{ listStyleType: "none", marginTop: "5px" }}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export const NavAlert = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsVisible(currentScrollPos <= 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`navAlert scroll-div  ${isVisible ? "visible" : "hidden"}`}
      style={{ zIndex: "1050" }}
    >
      <a className="navAlertLink secondary_font">
        Same popcorn. Same people. New look.
      </a>
    </div>
  );
};

export const NavDropdown = ({ title, list, icon, style }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const profileData = localStorage.getItem("profileData");
  return (
    <>
      <div
        style={style}
        className="align-items-center mt-1"
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => setOpen(false)} // Set isHovered to false on mouse leave
      >
        <a
          className="btn nav-color"
          id="defaultDropdown"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
        >
          {icon ? (
            <img
              src={profileData == "null" ? icon : `${baseURL}/${profileData}`}
              width={"35px"}
              alt="user-avatar"
              style={{ borderRadius: 100 }}
            />
          ) : (
            title
          )}

          {!icon ? (
            !open ? (
              <svg
                width="2.4rem"
                height="1.7rem"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <path
                  d="M240.768 344.277c-15.019 5.291-27.435 23.253-27.435 39.723 0 3.413 1.237 9.984 2.773 14.635 2.517 7.68 14.507 20.181 136.107 141.995 96.171 96.341 135.424 134.613 140.843 137.216 10.411 5.077 21.717 5.845 32.853 2.261 8.661-2.816 16-9.813 142.72-136.32 96.341-96.128 134.613-135.424 137.216-140.843 2.85-5.522 4.521-12.051 4.521-18.971 0-11.663-4.746-22.217-12.412-29.837l-0.002-0.002c-11.904-11.904-27.947-15.531-44.587-10.027-7.637 2.517-18.987 13.312-124.885 118.997l-116.48 116.267-116.48-116.267c-105.899-105.685-117.248-116.48-124.885-118.997-4.376-1.6-9.428-2.525-14.697-2.525-5.445 0-10.66 0.988-15.474 2.796l0.304-0.1z"
                  style={{ fill: "currentcolor" }}
                ></path>
              </svg>
            ) : (
              <svg
                width="2.4rem"
                height="1.7rem"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  transform: "rotate(180deg)",
                }}
              >
                <path
                  d="M240.768 344.277c-15.019 5.291-27.435 23.253-27.435 39.723 0 3.413 1.237 9.984 2.773 14.635 2.517 7.68 14.507 20.181 136.107 141.995 96.171 96.341 135.424 134.613 140.843 137.216 10.411 5.077 21.717 5.845 32.853 2.261 8.661-2.816 16-9.813 142.72-136.32 96.341-96.128 134.613-135.424 137.216-140.843 2.85-5.522 4.521-12.051 4.521-18.971 0-11.663-4.746-22.217-12.412-29.837l-0.002-0.002c-11.904-11.904-27.947-15.531-44.587-10.027-7.637 2.517-18.987 13.312-124.885 118.997l-116.48 116.267-116.48-116.267c-105.899-105.685-117.248-116.48-124.885-118.997-4.376-1.6-9.428-2.525-14.697-2.525-5.445 0-10.66 0.988-15.474 2.796l0.304-0.1z"
                  style={{ fill: "currentcolor" }}
                ></path>
              </svg>
            )
          ) : null}
          <div
            style={{
              width: "62%",
              height: "2.5px",
              backgroundColor: open ? "#CFCFF3" : "",
              marginBottom: "2px",
            }}
          ></div>
        </a>
        {
          <ul
            className={`dropdown-menu ${open ? "show" : ""}`}
            style={{ marginLeft: "10px", borderRadius: "8px" }}
            aria-labelledby="defaultDropdown"
          >
            {list.map((obj) => (
              <li key={obj.label}>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    if (obj.href == "logout") {
                      console.log("Logging out");
                      localStorage.removeItem("user_token");
                      localStorage.removeItem("phone_number");
                      localStorage.removeItem("profileData");
                      navigate("/");
                    }
                    // else if (obj.href == '/profile' || obj.href == '/orders') {
                    //   const isLoggedIn = localStorage.removeItem('user_token');
                    //   if (isLoggedIn) {
                    //     navigate(obj.href)
                    //   } else {
                    //     navigate('/')
                    //   }

                    // }
                    else {
                      navigate(obj.href);
                    }
                  }}
                >
                  {obj.label}
                </a>
              </li>
            ))}
          </ul>
        }
      </div>
    </>
  );
};

export const Svgs = ({ type, onClick, className, width, height }) => {
  if (type == "cartIcon") {
    return (
      <div onClick={onClick} className="svgContainer">
        <svg
          width={width ? width : "1.7rem"}
          height={height ? height : "1.5rem"}
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: "inline-block", verticalAlign: "middle" }}
        >
          <path d="M243.157 45.013c-4.523 1.507-8.431 3.341-12.075 5.564l0.256-0.145c-7.339 4.821-141.952 185.045-144.171 193.067-1.237 4.48-1.835 102.315-1.835 314.24 0 337.579-0.555 318.933 10.155 344.917 6.571 15.872 14.677 27.861 27.648 40.875 18.56 18.517 39.637 29.867 65.493 35.2 18.731 3.883 628.48 3.883 646.784 0.043 43.494-8.867 78.432-38.824 94.119-78.493l0.302-0.867c9.131-22.955 8.789-9.045 8.789-341.333 0-212.821-0.555-310.059-1.792-314.581-1.195-4.309-25.6-38.187-71.253-98.987-64.171-85.419-70.059-92.715-77.909-96.683l-8.491-4.309-264.576-0.341c-209.451-0.256-266.027 0.128-271.445 1.835zM778.667 170.667l32 42.667h-597.333l63.957-85.333h469.419l31.957 42.667zM853.333 579.115c0 314.923 1.024 290.347-12.757 304.128-13.824 13.824 13.739 12.757-328.576 12.757s-314.752 1.067-328.576-12.757c-13.781-13.781-12.757 10.795-12.757-304.128v-280.448h682.667v280.448zM327.125 386.773c-12.555 4.45-22.149 14.451-25.95 26.972l-0.076 0.292c-2.261 7.765-2.603 13.269-1.664 26.155 3.393 53.804 26.535 101.628 62.185 136.809l0.023 0.023c30.635 30.635 65.92 49.579 109.781 58.923 19.499 4.181 61.653 4.181 81.152 0 96.853-20.693 164.907-98.091 171.989-195.755 1.579-21.931-1.365-32.427-12.16-43.221-7.665-7.634-18.092-12.499-29.651-12.968l-0.088-0.003c-10.45 0.785-19.904 4.492-27.703 10.298l0.141-0.1c-9.429 7.765-13.44 17.749-15.189 37.461-1.877 22.016-5.419 35.413-13.653 52.181-12.754 24.701-32.387 44.333-56.361 56.746l-0.727 0.342c-20.651 10.155-32.299 12.757-57.173 12.757s-36.523-2.603-57.173-12.757c-24.701-12.754-44.333-32.387-56.746-56.361l-0.342-0.727c-8.192-16.683-11.776-30.165-13.653-51.797-1.707-19.925-4.352-26.795-13.781-35.968-11.477-11.136-26.624-14.379-43.179-9.301z"></path>
        </svg>
      </div>
    );
  } else if (type == "started") {
    return (
      <svg
        className=""
        width={width}
        height={height}
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginRight: "0.8rem",
        }}
      >
        <path
          d="M281.18 769.047c-21.486 0-42.967-2.85-64.014-8.332-42.092-11.182-80.677-33.542-111.805-64.673-97.117-97.117-97.117-255.399 0-352.521 62.7-62.7 154.994-87.473 240.712-64.453 16.882 4.604 26.964 21.704 22.36 38.586s-21.704 26.964-38.586 22.36c-64.014-17.1-133.072 1.314-179.768 48.231-72.563 72.563-72.563 190.73 0 263.293 23.238 23.238 51.957 39.9 83.307 48.231 64.014 17.1 133.073-1.314 179.767-48.231 12.278-12.278 32.446-12.278 44.723 0s12.278 32.446 0 44.722c-47.136 47.136-111.148 72.786-176.698 72.786z"
          style={{ fill: "currentcolor" }}
        />
        <path
          d="M871.122 614.271c-8.11 0-16.222-3.067-22.36-9.207-12.278-12.278-12.278-32.446 0-44.722 51.517-51.517 79.799-119.918 79.799-192.702s-28.282-141.185-79.799-192.702c-51.518-51.517-119.918-79.799-192.702-79.799s-141.185 28.282-192.702 79.799l-0.218 0.218c-68.399 68.837-95.363 169.463-70.373 263.075 4.604 16.882-5.482 34.2-22.36 38.586-16.882 4.604-34.2-5.482-38.586-22.36-30.692-115.313 2.41-239.18 86.377-323.581l0.218-0.218c63.577-63.577 147.981-98.653 237.644-98.653s173.848 34.857 237.204 98.213c63.357 63.357 98.213 147.759 98.213 237.204s-34.857 173.848-98.213 237.204c-5.918 6.577-14.032 9.647-22.142 9.647z"
          style={{ fill: "currentcolor" }}
        />
        <path
          d="M628.875 992.001c-7.236 0-14.25-0.218-21.486-0.878-77.168-5.701-147.54-40.996-197.962-99.531-33.322-38.586-55.903-85.059-65.332-134.827-3.29-17.1 7.892-33.76 25.209-37.050 17.1-3.29 33.76 7.892 37.050 25.209 7.454 38.803 24.992 75.414 51.082 105.449 39.46 45.818 94.487 73.442 154.776 77.827s118.602-14.908 164.423-54.367c60.945-52.614 89.446-134.604 74.317-213.748-10.086-52.835-10.086-52.835 0 0-7.454-38.803-24.992-75.414-51.082-105.449s-59.409-52.835-96.899-65.768c-16.443-5.701-25.209-23.678-19.51-40.117s23.678-25.209 40.118-19.51c47.792 16.66 90.76 45.818 123.862 84.185 32.007 37.050 53.932 81.552 64.014 128.905 1.314 3.508 2.193 7.454 2.193 11.182 17.1 99.748-19.51 201.908-96.023 268.117-52.835 45.818-119.258 70.373-188.755 70.373v0z"
          style={{ fill: "currentcolor" }}
        />
      </svg>
    );
  }
};

const NavItem = ({ title, style, children, to }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(to ? to : "/shop");
      }}
      className="btn nav-color"
      style={{ marginTop: "0.5px" }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {title || children}
      <div
        style={{
          width: "100%",
          height: "2.5px",
          backgroundColor: open ? "#CFCFF3" : "",
        }}
      ></div>
    </div>
  );
};
