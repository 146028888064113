import React, { useEffect, useState } from "react";
import "./SignIn.css";
import Input from "../../Components/GenericComponents/Input/Input";
import { verifyDetail, verifyOtpCode } from "../../Services";
import { Dropdown, Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import useValidations from "../../hooks/useValidation";
import { DIAL_CODES } from "../../Constant";
import { toast } from "react-toastify";
import { CartButton } from "../../Components/ShopCard";
import { Spinner } from "../Checkout/Checkout";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/fundraiserLogo.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { setTokenPhoneNo } from "../../Redux/Actions/user";
import TabTitle from "../../Services/TabTitle";

const SignIn = () => {
  TabTitle('Signin');
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [state, setState] = useState({
    showSignInModel: false,
    showOtpModel: false,
    otp: "",
    otp_code: "",
    country_code: "+1",
    phone_number: "",
    verifyOtpLoader: false,
    signInLoader: false,
    errors: null,
  });

  useEffect(() => {
    if (user?.user_token) {
      navigate('/profile')
    }

  }, [user])

  const navigate = useNavigate();

  const {
    onBlurHandler,
    errors,
    setErrors,
    validateRequiredFields,
    emailValidation,
  } = useValidations();

  let phone_code_list = DIAL_CODES.map((obj) => {
    return {
      label: obj.value + `  (${obj.label})`,
      value: obj.value,
    };
  });

  const validatePhoneNumber = (number) => {
    const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/;

    return phoneNumberPattern.test(number);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "phone_number") {


      let input = value.replace(/\D/g, '');
      let formattedNumber = '';

      if (input.length > 10) {
        input = input.slice(0, 10);
      }

      if (input.length > 3) {
        formattedNumber += input.slice(0, 3) + '-';
        input = input.slice(3);
      }
      if (input.length > 3) {
        formattedNumber += input.slice(0, 3) + '-';
        input = input.slice(3);
      }
      formattedNumber += input;
      setState((prev) => ({
        ...prev,
        [name]: formattedNumber,
        ["errors"]: {
          ['phone']: false
        },
      }));


      if (!validatePhoneNumber(formattedNumber) && formattedNumber?.length <= 12) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Invalid phone number format",
        }));
      }
      if (validatePhoneNumber(formattedNumber) && formattedNumber?.length <= 12) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }

    else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  // const verify = async () => {
  //   console.log("Clicked verify");
  //   // if (validatePhoneNumber(state.phone_number)) {
  //   //   let { phone_number } = state;
  //   //   let keys = ["phone_number"];
  //   //   let error = validateRequiredFields(keys, state);
  //   //   if (!error) {
  //   //     setState((prev) => ({
  //   //       ...prev,
  //   //       ["signInLoader"]: true,
  //   //     }));

  //   // let payload = {
  //   //   otp_type: 'signin',
  //   //   phone_number: (country_code + phone_number).split('-').filter(a => a.trim().length).join(''),
  //   // };
  //   const payload = new FormData();
  //   payload.append("otp_type", 'signin');
  //   payload.append("phone_number", 3490575931);
  //   let success = () => {
  //     setState((prev) => ({
  //       ...prev,
  //       ["signInLoader"]: false,
  //       ["showSignInModel"]: false,
  //       ["showOtpModel"]: true,
  //     }));
  //   };
  //   let fail = () => {
  //     setState((prev) => ({
  //       ...prev,
  //       //   phone_number: "",
  //       ["signInLoader"]: false,
  //     }));
  //   };

  //   const response = await verifyDetail(payload, success, fail);
  //   console.log("response : ", response);
  //   if (response?.data?.status == 200) {

  //     toast.success(response.data.message);
  //     setState((prev) => ({
  //       ...prev,
  //       ["signInLoader"]: false,
  //       ["showSignInModel"]: false,
  //       ["showOtpModel"]: true,
  //     }));
  //   }
  //   //   }
  //   // }
  // };


  const verify = async () => {
    console.log("Clicked verify");
    if (validatePhoneNumber(state.phone_number)) {
      let { phone_number } = state;
      let keys = ["phone_number"];
      let error = validateRequiredFields(keys, state);
      if (!error) {
        setState((prev) => ({
          ...prev,
          ["signInLoader"]: true,
        }));

        // let payload = {
        //   otp_type: 'signin',
        //   phone_number: (country_code + phone_number).split('-').filter(a => a.trim().length).join(''),
        // };
        const payload = new FormData();
        payload.append("otp_type", 'signin');
        payload.append("phone_number", `${state.country_code}${phone_number.split('-').filter(a => a.trim().length).join('')}`);
        let success = () => {
          setState((prev) => ({
            ...prev,
            ["signInLoader"]: false,
            ["showSignInModel"]: false,
            ["showOtpModel"]: true,
          }));
        };
        let fail = () => {
          setState((prev) => ({
            ...prev,
            //   phone_number: "",
            ["signInLoader"]: false,
          }));
        };

        const response = await verifyDetail(payload, success, fail);
        // console.log("response : ", response);
        if (response?.data?.status == 200) {
          setState((prev) => ({
            ...prev,
            ["signInLoader"]: false,
            ["showSignInModel"]: false,
            ["showOtpModel"]: true,
          }));
        }
        else {
          setState((prev) => ({
            ...prev,
            ["errors"]: {
              ['phone']: response?.data?.message
            },

          }));
        }
      }
    }
  };


  // const verifyOtp = async () => {
  //   try {
  //     let { otp_code, phone_number, country_code } = state;
  //     let payload = {
  //       otp_code,
  //       otp_type: 'signin',
  //       phone_number: country_code + phone_number,
  //     };
  //     const payload = new FormData();
  //     payload.append("otp_code", otp_code);
  //     payload.append("otp_type", 'signin');
  //     payload.append("phone_number", (phone_number).split('-').filter(a => a.trim().length).join(''));
  //     console.log("country_code + phone_number : ", country_code + phone_number);
  //     setState((prev) => ({
  //       ...prev,
  //       verifyOtpLoader: true,
  //     }));

  //     let success = () => {
  //       setState((prev) => ({
  //         ...prev,
  //         verifyOtpLoader: false,
  //         //   ["showOtpModel"]: false,
  //         otp_code: "",
  //         //   otpVerified: true,
  //       }));
  //     };
  //     let fail = () => {
  //       setState((prev) => ({
  //         ...prev,
  //         // ['showModal']:false,
  //         otp_code: "",
  //         verifyOtpLoader: false,
  //       }));
  //     };

  //     const response = await verifyOtpCode(payload, success, fail);
  //     console.log("response otp verification : ", response)
  //     if (response?.data?.status == 200) {
  //       localStorage.setItem("user_token", response?.data?.data?.access_token);
  //       navigate('/profile');
  //     }
  //   } catch (error) {
  //     console.log("Error : ", error);
  //   }
  // };
  const verifyOtp = async () => {
    try {
      let { otp_code, phone_number, country_code } = state;
      // let payload = {
      //   otp_code,
      //   otp_type: 'signin',
      //   phone_number: country_code + phone_number,
      // }; 3490575931
      const payload = new FormData();
      payload.append("otp_code", otp_code);
      payload.append("otp_type", 'signin');
      payload.append("phone_number", `${state.country_code}${(phone_number).split('-').filter(a => a.trim().length).join('')}`);
      console.log("country_code + phone_number : ", country_code + phone_number);
      setState((prev) => ({
        ...prev,
        verifyOtpLoader: true,
      }));

      let success = () => {
        setState((prev) => ({
          ...prev,
          verifyOtpLoader: false,
          //   ["showOtpModel"]: false,
          otp_code: "",
          //   otpVerified: true,
        }));
      };
      let fail = () => {
        setState((prev) => ({
          ...prev,
          // ['showModal']:false,
          otp_code: "",
          verifyOtpLoader: false,
        }));
      };

      const response = await verifyOtpCode(payload, success, fail);
      console.log("response otp verification : ", response)
      if (response?.data?.status == 200) {
        // dispatch(setTokenPhoneNo({
        //   user_token: response?.data?.data?.access_token,
        //   phone_number: (phone_number).split('-').filter(a => a.trim().length).join('')
        // }))
        localStorage.setItem("user_token", response?.data?.data?.access_token);
        localStorage.setItem("phone_number", `+1${(phone_number).split('-').filter(a => a.trim().length).join('')}`);
        navigate('/profile');
      } else {
        setState((prev) => ({
          ...prev,
          ["errors"]: {
            ['otp']: response?.data?.message
          },

        }));
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  return (
    <div className="signin">
      <div className="container px-5x pt-3">
        <div >
          <img src={Logo} style={{ width: 150, height: 120 }} className="logo" onClick={() => { navigate('/') }} />
        </div>
        <div className="row">
          <div className="col-4 offset-4">
            <h1 className="heading secondary_font">Enter your mobile phone number</h1>
            <p className="detail">We’ll text you to confirm your number. Standard message and data rates apply.</p>
            <div>
              <Input
                label="Phone Number"
                placeholder="Phone Number"
                name="phone_number"
                value={state.phone_number}
                onChange={handleChange}
                // onPaste={onPaste}
                // onBlur={() => onBlurHandler({ name: state.name })}
                error={errors.phone_number || state.errors?.phone}
              />
            </div>
            {state.signInLoader ? (
              <Spinner className="px-3 mt-4 d-flex justify-content-around" />
            ) : (
              <>
                {/* <button 
                                className="send-code-btn mt-4"
                                onClick={verify}
                                disabled={state.signInLoader || !state.phone_number || !validatePhoneNumber(state.phone_number)}
                            >
                                Send Confirmation Code
                            </button> */}
                <CartButton
                  title="Send Confirmation Code"
                  className="w-100"
                  onClick={verify}
                  disabled={state.signInLoader || !state.phone_number}
                // disabled={state.signInLoader || !state.phone_number || !validatePhoneNumber(state.phone_number)}

                />
              </>
            )}
          </div>
        </div>
      </div>


      <VerifyOtpModal
        show={state.showOtpModel}
        handleClose={() => {
          setState((prev) => ({
            ...prev,
            ["showOtpModel"]: false,
            otp_code: "",
          }));
        }}
        state={state}
        setState={setState}
        verify={verifyOtp}
      />
    </div>
  )
}

export default SignIn;





// export const SignInModel = ({ show, handleClose, state, setState }) => {


//     return (
//       <Modal backdrop="static" centered show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Sign In</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="">
//             <div className="row">
//             <div className="mt-2 d-flex justify-content-between">
//                 <div className="col-md-4" style={{ paddingRight: "10px" }}>
//                   <Dropdown
//                     name={"country_code"}
//                     label="Country Code*"
//                     options={phone_code_list}
//                     value={state.country_code}
//                     onChange={handleChange}
//                     onBlur={() =>
//                       onBlurHandler({
//                         country_code: state.country_code,
//                       })
//                     }
//                     // error={errors.shipping_state}
//                     // disabled={state.otpVerified}
//                   />
//                 </div>
//                 <div className="col-md-8">
//                   <Input
//                     type="text"
//                     label="Phone Number*"
//                     placeholder="Phone Number*"
//                     name="phone_number"
//                     value={state.phone_number}
//                     // disabled={state.otpVerified}
//                     onChange={handleChange}
//                     onBlur={() =>
//                       onBlurHandler({ phone_number: state.phone_number })
//                     }
//                     error={errors.phone_number}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="col-md-12 justify-content-end d-flex gap-2">
//             <div className="col-md-3">
//               {
//                 <CartButton
//                   border="0px"
//                   bgColor="grey"
//                   title="Cancel"
//                   className="w-100"
//                   onClick={() => {
//                     setState((prev) => ({
//                         ...prev,
//                         phone_number: ""
//                     }))
//                     handleClose();
//                 }}
//                   disabled={state.signInLoader}
//                 />
//               }
//             </div>
//             <div className="col-md-3 d-flex align-items-center justify-content-center">
//               {state.signInLoader ? (
//                 <Spinner className="px-3 mt-2 d-flex justify-content-end" />
//               ) : (
//                 <CartButton
//                   title="Sign In"
//                   className="w-100"
//                   onClick={verify}
//                   disabled={state.signInLoader || !state.phone_number || !validatePhoneNumber(state.phone_number)}
//                 />
//               )}
//             </div>
//           </div>
//         </Modal.Footer>
//       </Modal>
//     );
//   };

export const OTP = ({ otp, setOtp }) => {
  return (
    <OTPInput
      containerStyle={{ justifyContent: "space-between", display: "flex" }}
      inputStyle={{
        width: "4rem",
        height: "4rem",
        borderRadius: "10px",
        border: "1px solid rgb(117, 117, 117)",
      }}
      value={otp}
      onChange={setOtp}
      numInputs={5}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
  );
};

export const VerifyOtpModal = ({
  show,
  handleClose,
  state,
  setState,
  verify,
}) => {
  return (
    <Modal backdrop="static" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Verify OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mt-5 mb-2">
          <OTP
            otp={state.otp_code}
            setOtp={(e) => {
              setState((prev) => ({
                ...prev,
                ["otp_code"]: e,
                ["errors"]: {
                  ['otp']: false
                },
              }));
            }}
          />
        </div>
        {state.errors?.otp &&
          <text style={{ color: 'red', marginLeft: '12%', }}>{state.errors?.otp}</text>
        }
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 justify-content-end d-flex gap-2">
          <div className="col-md-3">
            {
              <CartButton
                border="0px"
                bgColor="grey"
                title="Cancel"
                className="w-100"
                onClick={handleClose}
                disabled={state.verifyLoader}
              />
            }
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            {state.verifyOtpLoader ? (
              <Spinner className="px-3 mt-2 d-flex justify-content-end" />
            ) : (
              <CartButton
                title="Verify Otp"
                className="w-100"
                onClick={verify}
                disabled={state.otp_code.length < 5 || state.verifyOtpLoader}
              />
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};