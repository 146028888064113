import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import DOMPurify from "dompurify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiFacebook } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FiLinkedin } from "react-icons/fi";
import { LuLink } from "react-icons/lu";
import Loader from "../../Loader/Loader";
import TabTitle from "../../../Services/TabTitle";

function Openhero({ loader, setLoader }) {
  const navigate = useNavigate;
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [category, setCategory] = useState([]);
  const state = useLocation();
  const [newState, setNewState] = useState(slug);

  TabTitle(`${data.title ? data.title : "Blog"}`);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://alphadevelopers.co.uk/api/get-blog?slug=${newState}`
      );
      if (!response.ok) {
        console.log("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.data);
      if (response) {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [newState]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://alphadevelopers.co.uk/api/get-blogs?limit=${7}`
        );
        if (!response.ok) {
          console.log("Network response was not ok");
        }
        const jsonData = await response.json();
        // console.log('blog slug data', jsonData.data.blogs)
        setBlogs(jsonData.data.blogs);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://alphadevelopers.co.uk/api/get-blogs"
      );
      if (!response.ok) {
        console.log("Network response was not ok");
      }
      const jsonData = await response.json();
      setCategory(jsonData.data.blog_categories);
      if (response) {
        console.log("response : ", response);
        console.log("json converted : ", jsonData);
      }
      console.log("json converted : ", jsonData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  function convertDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const handleItemClick = (blogItem) => {
    setNewState(blogItem.slug);
    setLoader(true);
  };
  return loader ? (
    <Loader />
  ) : (
    <div className="main-openhero">
      <div className="openhero">
        <div className="container ">
          <div className="row justify-content-between">
            <div className="col-md-6 our-culture order-md-1 order-2">
              <span className="blog">BLOG</span>
              <div>
                <h1 className="secondary_font">{data.title}</h1>
              </div>
              <div className="">
                <span className="d-good">Popcorn World</span> <br />
                <span className="date">
                  {convertDate(data.created_at)} • Read 2 Min
                </span>
              </div>
            </div>
            <div className="col-md-4  order-md-2 order-1">
              <img
                src={`https://alphadevelopers.co.uk/${data.image}`}
                className="img-fluid h-100 w-100"
                alt="blog-image"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row px-0">
          <div className="col-md-8">
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.description),
              }}
            />
            <div className="mt-5">
              {category?.map((item) => (
                <span className="category">{item?.name}</span>
              ))}
            </div>
          </div>
          <div className="col-md-4 recent-blog-list">
            <div className="recent-blog-list">
              <ul className="text-start recent">
                <h5 className="secondary_font">Recent Blog Posts</h5>
                {blogs.map((blog, index) => (
                  <li key={index} className="d-flex justify-content-between">
                    <div className="w-75" onClick={() => handleItemClick(blog)}>
                      {blog.title}
                    </div>
                    <GoArrowRight size={25} width={25} />
                  </li>
                ))}
              </ul>
              <div className="social pt-5 ">
                <h5 className="pb-3 secondary_font ">Share This Story</h5>
                <div className="d-flex gap-4">
                  <div>
                    <FiFacebook size={35} />
                  </div>
                  <div>
                    <FaXTwitter size={35} />
                  </div>
                  <div>
                    <FaInstagram size={35} />
                  </div>
                  <div>
                    <FiLinkedin size={35} />
                  </div>
                  <div>
                    <LuLink size={35} />
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Openhero;
