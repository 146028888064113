import { APP } from "../ActionTypes/index";

const initialState = {
  userData: {},
  user_token: null,
  phone_number: null,
}

const UserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case APP.SET_TOKEN_PHONENO:
      return {
        ...state,
        user_token: payload.user_token,
        phone_number: payload.phone_number
      };
      
      case APP.GET_USER:
      console.log("user payload inner8", payload)
      return {
        ...state,
        userData: payload
      };
    default:
      return state;
  }

};

export default UserReducer;