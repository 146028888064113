import { APP } from "../ActionTypes/index";

const initialState = {
  campaigns:[]
}

const DataReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case APP.GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: payload
      };
    default:
      return state;
  }

};

export default DataReducer;