import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import TabTitle from "../../Services/TabTitle";

const OrderConfirmed = ({ }) => {
  TabTitle('Order Confirmed')
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('user_token');
  const order = location?.state;

  return (
    <>
      {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous" /> */}
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        <div className="row">
          <div
            className="jumbotron"
            style={{ boxShadow: "0.5px #000000" }}
          >
            <h2 className="text-center">Thank You for Your Order!</h2>
            <h3 className="text-center">
              Thank you for your payment, it’s processing
            </h3>

            <p className="text-center">
              Order Number: <strong>#{order?.order?.data?.order_id}</strong>
            </p>
            <p className="text-center">
              An order confirmation email with details and a tracking link will
              be sent to you shortly.
            </p>
            < div className="text-center">
              <button
                onClick={() => {
                  isLoggedIn ?
                    navigate("/") :
                    navigate("/signin")
                }}
                className="btn"
                style={{
                  height: "50px",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "1rem",
                  fontWeight: 500,
                  borderRadius: "35px",
                  backgroundColor: "#323232",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  width: "100px",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >{isLoggedIn ? "Home" : "Sign In"}
              </button>
            </div>
          </div>
        </div >
      </div >

      <Footer />
    </>
  );
};

export default OrderConfirmed;
