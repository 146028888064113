import React from "react";

function TermsSection() {
  return (
    <main>
      <section className="MuiBox-root css-14kbwcq" id="">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-19m07ui">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-56d6a674-7732-4564-93b3-bd3e5327c827"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h2 className="secondary_font">Terms and conditions</h2>
                  <p>
                    <br />
                    Date of Last Revision: December 15, 2023
                    <br />
                    <br />
                    Welcome to Popcorn World! Popcorn World Technologies LP operates{" "}
                    <a href="http://www.DoubleGood.com" linktype="url">
                      www.DoubleGood.com
                    </a>
                    (the &quot;Site&quot;). Popcorn World Technologies LP
                    (sometimes called &quot;Popcorn World&quot;, &quot;we&quot;,
                    &quot;us&quot; or &quot;our&quot; below) offers our mobile
                    applications (collectively, called the &quot;Mobile
                    Application&quot; below). These Terms &amp; Conditions apply
                    to your interaction with us. Your access to and use of the
                    Site, including any content, information, materials, text,
                    images, functionality, and services and products offered
                    there, as well as your access to and use of the Mobile
                    Application, including any of the foregoing offered on or
                    through the Mobile Application, and your access to and use
                    of any services offered by Popcorn World (collectively, called
                    the &quot;Services&quot; below) is subject to these terms
                    and conditions, together with any documents they expressly
                    incorporate by reference (collectively, the &quot;Terms and
                    Conditions&quot;). We may revise the Terms and Conditions
                    from time to time without notice. Please review the Terms
                    and Conditions carefully on a regular basis, as they may
                    have changed since your last visit.
                    <br />
                    <br />
                    <b>
                      PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. THESE
                      TERMS AND CONDITIONS CONTAINS DISPUTE RESOLUTION
                      PROVISIONS THAT REQUIRE YOU TO SUBMIT TO ARBITRATION,
                      WHICH MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS,
                      INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. BY
                      ACCESSING ANY PORTION OF THE SITE OR THE MOBILE
                      APPLICATION, OR BY USING THE SERVICES, YOU AFFIRM THAT YOU
                      ARE A RESIDENT OR CITIZEN OF THE UNITED STATES, YOU ARE AT
                      LEAST 18 YEARS OF AGE, AND YOU AGREE TO THESE TERMS AND
                      CONDITIONS. IF YOU ARE THE PARENT OR LEGAL GUARDIAN OF A
                      MINOR WHO YOU WILL PERMIT TO ACCESS ANY PORTION OF THE
                      SITE OR THE MOBILE APPLICATION, OR MAKE USE OF THE
                      SERVICE, YOU AGREE THAT YOU TAKE FULL RESPONSIBILITY FOR
                      THE MINOR’S USE OF THE SITE, MOBILE APPLICATION, AND/OR
                      SERVICE, AND FOR THE MINOR’S COMPLIANCE WITH THESE TERMS
                      AND CONDITIONS.
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-1y8xt0p" id="">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-19m07ui">
            <section className="MuiBox-root css-p5vm0a" id="">
              <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                <div className="MuiBox-root css-19m07ui">
                  <span className="MuiTypography-root MuiTypography-eyebrow css-i2reuk">
                    General Terms and Conditions
                  </span>

                  <section className="MuiBox-root css-14eye43" id="">
                    <div className="MuiContainer-root MuiContainer-disableGutters css-1b9oz2m">
                      <div className="MuiBox-root css-x7x2r2">
                        <div className="MuiBox-root css-k008qs">
                          <div
                            className="MuiGrid-root MuiGrid-container css-1lagv4a"
                            id=""
                          >
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                              <div className="MuiStack-root css-1ihcc4j">
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#our-policies">
                                    1. Our policies
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#use-of-content">
                                    2. Use of content
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#user-accounts">
                                    3. User accounts
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#trademarks">
                                    4. Trademarks
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#digital-millennium-copyright-agent">
                                    5. Digital Millennium Copyright Agent
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#minors">
                                    6. Minors
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#ftc-disclosure-policy">
                                    7. FTC disclosure policy
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#fraud-protection">
                                    8. Fraud protection
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                              <div className="MuiStack-root css-1ihcc4j">
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#links-to-third-party-websites-and-email-addresses">
                                    9. Links to third-party websites and email
                                    addresses
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#links-to-the-site-and-or-mobile-application">
                                    10. Links to the site and/or mobile
                                    application
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#non-malicious-use">
                                    11. Non-malicious use
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#user-submissions">
                                    12. User submissions
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#limited-access">
                                    13. Limited access
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#warranty-disclaimers">
                                    14. WARRANTY DISCLAIMERS
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#limitation-of-liability">
                                    15. LIMITATION OF LIABILITY
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#indemnity">
                                    16. Indemnity
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                              <div className="MuiStack-root css-1ihcc4j">
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#reservation-of-rights">
                                    17. Reservation of rights
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#contact-information">
                                    18. Contact information
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#termination">
                                    19. Termination
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#severance-and-waiver">
                                    20. Severance and waiver
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#venue-and-choice-of-law">
                                    21. Venue and choice of law
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#timing-of-a-claim">
                                    22. Timing of a claim
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#prevailing-parties">
                                    23. Prevailing parties
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#entire-agreement">
                                    24. Entire agreement
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-19m07ui">
            <section className="MuiBox-root css-1ceee55" id="">
              <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                <div className="MuiBox-root css-iwrefp">
                  <span className="MuiTypography-root MuiTypography-eyebrow css-i2reuk">
                    Fundraising Policy
                  </span>
                  <section className="MuiBox-root css-1ceee55" id="">
                    <div className="MuiContainer-root MuiContainer-disableGutters css-1b9oz2m">
                      <div className="MuiBox-root css-19m07ui">
                        <div className="MuiBox-root css-k008qs">
                          <div
                            className="MuiGrid-root MuiGrid-container css-1lagv4a"
                            id=""
                          >
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                              <div className="MuiStack-root css-1ihcc4j">
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#our-policies">
                                    1. Our policies
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#fundraisers">
                                    2. Fundraisers
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#procedures">
                                    3. Procedures
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                              <div className="MuiStack-root css-1ihcc4j">
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#payments">
                                    4. Payments
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#government-fund-raising-and-charity-policy">
                                    5. Government fund raising and charity
                                    policy
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#communications">
                                    9. Communications
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                              <div className="MuiStack-root css-1ihcc4j">
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#amti-bullying">
                                    7. Anti-bullying
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#site-restrictions">
                                    8. Site restrictions
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#commnications">
                                    9. Communications
                                  </a>
                                </div>
                                <div className="MuiStack-root css-j7qwjs">
                                  <a href="/terms-and-conditions#amendment">
                                    10. Amendment or Changes to Terms and
                                    Conditions.
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-19m07ui">
            <div
              className="text-section MuiBox-root css-vxbqpx"
              id="text-section-21b6f0c2-3809-4a6a-a4f2-2fca6e844e45"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-10xet1i">
                  <h3>
                    General terms and conditions
                    <br />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="our-policies">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-a9531ccb-cd60-4e18-a7d8-ae89b5d6c7d8"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>1. Our policies</h3>
                  <p>
                    <br />
                    Our Privacy Policy (the &quot;Privacy Policy&quot;) and our
                    Fundraising Policy (the &quot;Fundraising Policy &quot;) are
                    hereby incorporated into these Terms and Conditions and made
                    a part hereof. The latest copies of each are found on the
                    Site and in the Mobile Application. To the extent of any
                    conflict among the body of these Terms and Conditions, the
                    Privacy Policy and the Fundraising Policy, they shall govern
                    and control in the following order: (a) the Privacy Policy,
                    (b) the Fundraising Policy, and (c) the body of these Terms
                    and Conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="use-of-content">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-812e0c46-4800-4d6b-a882-22a221ae704e"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>2. Use of content</h3>
                  <p>
                    <br />
                    The text, images, data, illustrations, files, audio and
                    video clips, designs, documents, and other materials and
                    content (collectively, the &quot;Content&quot;) on the Site
                    and the Mobile Application are the property of Popcorn World
                    and its affiliates and licensors, and may be protected by
                    copyright and other proprietary rights. You may not copy,
                    modify, distribute or otherwise use any of the Content,
                    except for your personal, non-commercial use. Please note
                    that we may withdraw any Content from the Site or Mobile
                    Application at any time in our sole discretion. By
                    downloading, printing, or otherwise using any of the
                    Content, you agree that you will (a) restrict your use of
                    such Content to personal and non-commercial use, (b) comply
                    with these Terms and Conditions, and (c) not violate the
                    rights of Popcorn World or any other person or entity.
                    Unauthorized commercial publication or exploitation of
                    Content is prohibited without our express written consent.
                    If you wish to use any of the Content in any way other than
                    as authorized by these Terms and Conditions, you must first
                    obtain our written permission. We may, in our sole
                    discretion, grant permission for such use on a case-by-case
                    basis and we may charge a fee for such use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="user-accounts">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-b9749e9d-368d-4708-ab65-34fe9fb7cf7a"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>3. User accounts</h3>
                  <p>
                    <br />
                    In order to access certain portions of the Services or areas
                    of the Site and/or Mobile Application, you may be required
                    to create a user account (a &quot;User Account&quot;). At
                    this time, only residents of the United States may (a)
                    create User Accounts or (b) otherwise use the Site and/or
                    Mobile Application (except to learn more about Popcorn World).
                    You may never use another party’s User Account without such
                    party’s express written authorization. You represent,
                    warrant and agree that all information which you provide
                    through any portion of the Site and/or Mobile Application in
                    connection with creating your User Account, fundraising or
                    otherwise is and will be current, accurate, legally
                    compliant and complete. You are solely responsible for all
                    use connected with your User Account. You are solely
                    responsible for maintaining the security of your User
                    Account login credentials (username, Multi Factor
                    Authorization (MFA) access authentication codes, etc.). You
                    agree to notify us immediately of any security breach or
                    unauthorized use of your User Account. We will not be liable
                    for any costs, losses, claims or damages that you or any
                    third party incur which are directly or indirectly caused by
                    any unauthorized use of your User Account, except where such
                    unauthorized use is the direct result of our gross
                    negligence or willful misconduct.
                    <br />
                    <br />
                    You authorize your wireless carrier to use or disclose
                    information about your account and your wireless device, if
                    available, to Popcorn World or its service provider for the
                    duration of your business relationship, solely to help them
                    identify you or your wireless device and to prevent fraud.
                    See our Privacy Policy for how we treat your data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="trademarks">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-c94f0239-f6a5-4523-a5a6-2df580e70060"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>4. Trademarks</h3>
                  <p>
                    <br />
                    Names, titles, trademarks, service marks, and logos
                    (collectively, the &quot;Trademarks&quot;) displayed on the
                    Site and/or Mobile Application are our registered and/or
                    unregistered common law trademarks or those of our
                    affiliates and licensors. Nothing contained on the Site
                    and/or Mobile Application should be construed as granting,
                    by implication or otherwise, any license or right to use any
                    Trademark displayed on the Site and/or Mobile Application
                    without our express written permission or that of the third
                    party that owns the Trademark. Any unauthorized use of the
                    Trademarks is prohibited.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="MuiBox-root css-14kbwcq"
        id="digital-millennium-copyright-agent"
      >
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-a798d4a7-d925-441e-9195-f3e542310ec5"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>5. Digital Millennium Copyright Agent</h3>
                  <p>
                    For purposes of the Digital Millennium Copyright Act
                    (&quot;DMCA&quot;), we have designated an agent for notices
                    of claimed infringement. If you have any objections governed
                    by the DMCA, please contact us as set forth in the paragraph
                    below entitled &quot;Contact Information&quot; below.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="minors">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-7f019a81-da7b-4462-be67-f7a4dbe2bf07"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>6. Minors</h3>
                  <p>
                    The Site, the Mobile Application and the Services are only
                    available only to, and may only be used by, individuals who
                    are 18 years and older, except as provided herein.
                    Individuals under the age of 18 can use them only in
                    conjunction with and under the supervision of a parent or
                    legal guardian. In this case, the adult is the user and is
                    responsible for any and all use. Users of the Site, the
                    Mobile Application and the Services who are California
                    residents and are under 18 years of age may request and
                    obtain removal of any User Content (as defined below) which
                    they authored by emailing us at{" "}
                    <a href="mailto:help@doublegood.com" linktype="url">
                      help@doublegood.com
                    </a>
                    . Please see also the section of the Site and the Mobile
                    Application entitled “Do not sell my personal information”
                    for more on California privacy rights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="ftc-disclosure-policy">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-30feb7f6-ca46-42dd-bc24-77f8844d6bcb"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>7. FTC disclosure policy</h3>
                  <p>
                    The Federal Trade Commission (FTC) requires Popcorn World to
                    disclose any relationship we may have with any person or
                    entity that provides something of value in connection with
                    any endorsements made by Popcorn World. The views and opinions
                    expressed on the Site, the Mobile Application and the
                    Services are purely our views and opinions or those of the
                    users of Popcorn World who maintain User Accounts. If we claim
                    or appear to be an expert on a certain topic or product or
                    service area, we will only endorse such products or services
                    that we believe, based on our expertise, are worthy of such
                    endorsement, and we shall not be compensated for such
                    endorsement except as disclosed in writing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="fraud-protection">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-f1f186a8-925c-49d6-bc9b-b49d9873fe1a"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>8. Fraud protection</h3>
                  <p>
                    As part of our order processing procedures, we may screen
                    Site and/or Mobile Application activity for fraud or other
                    types of unauthorized or illegal activity. We reserve the
                    right to refuse to provide services due to suspected fraud
                    or unauthorized or illegal activity. If we suspect
                    fraudulent, unauthorized or illegal activity, we may contact
                    you at the phone number or email address you provided. We
                    also reserve the right to cancel any User Accounts or refuse
                    to provide services due to suspected fraud or unauthorized
                    or illegal activity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="MuiBox-root css-14kbwcq"
        id="links-to-third-party-websites-and-email-addresses"
      >
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-0a453a28-1402-42bc-94e5-7a689a33d26e"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>9. Links to third-party websites and email addresses</h3>
                  <p>
                    <br />
                    The Site, the Mobile Application and the Services may
                    contain links to third-party websites. The linked sites are
                    not under Popcorn World’s control and we are not responsible
                    for the contents of any linked website. These links are
                    provided as a convenience only and shall not be construed as
                    our endorsement of, sponsorship of, or affiliation with a
                    linked website. The Site, the Mobile Application and the
                    Services may also contain links to the email addresses of
                    various employees or other service providers for purposes of
                    feedback and communication regarding Popcorn World and our
                    products and services. By clicking on a link to an
                    employee’s or other service provider’s email address, you
                    may leave Popcorn World and enter into an area, such as one
                    controlled by your email service provider, which these Terms
                    and Conditions do not govern. We assume no responsibility or
                    liability with respect to your entering such area.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="MuiBox-root css-14kbwcq"
        id="links-to-the-site-and-or-mobile-application"
      >
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-a9291711-bd8b-4bc9-a6b9-e288f487b573"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>10. Links to the site and/or mobile application</h3>
                  <p>
                    The Site, the Mobile Application and the Services may
                    contain links to third-party websites. The linked sites are
                    not under Popcorn World’s control and we are not responsible
                    for the contents of any linked website. These links are
                    provided as a convenience only and shall not be construed as
                    our endorsement of, sponsorship of, or affiliation with a
                    linked website. The Site, the Mobile Application and the
                    Services may also contain links to the email addresses of
                    various employees or other service providers for purposes of
                    feedback and communication regarding Popcorn World and our
                    products and services. By clicking on a link to an
                    employee’s or other service provider’s email address, you
                    may If you arrive at the Site and/or the Mobile Application
                    from a third-party link, please note that such link to or
                    solicitation for the Site and/or the Mobile Application does
                    not imply a relationship with, or endorsement of, the
                    third-party website, application, etc. or its content,
                    purpose, policies or practices. Popcorn World and enter into
                    an area, such as one controlled by your email service
                    provider, which these Terms and Conditions do not govern. We
                    assume no responsibility or liability with respect to your
                    entering such area.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="non-malicious-use">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-9d901b96-e3e2-4c72-8fec-0743256ddf4a"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>11. Non-malicious use</h3>
                  <p>
                    <br />
                    You agree not to use or launch any automated system,
                    including without limitation, &quot;bots&quot;,
                    &quot;spiders&quot; or &quot;offline readers&quot;, that
                    accesses the Site and/or the Mobile Application in a manner
                    that sends more request messages to our servers in a given
                    period of time than a human can reasonably produce in the
                    same period by using a conventional online web browser.
                    Notwithstanding the foregoing, we grant the operators of
                    public search engines permission to use spiders to copy
                    materials from the Site for the sole purpose of and solely
                    to the extent necessary for creating publicly available
                    searchable indices of the materials, but not caches or
                    archives of such materials. We reserve the right to revoke
                    these exceptions either generally or in specific cases. You
                    agree not to collect or harvest any personally identifiable
                    information, including names or other information related to
                    User Accounts, from the Site and/or Mobile Application, nor
                    to use the communication systems provided by Popcorn World,
                    including, for the avoidance of doubt, the chat feature, for
                    any commercial solicitation purposes. You agree not to
                    circumvent, disable or otherwise interfere with
                    security-related features of the Site and/or Mobile
                    Application or features that prevent or restrict use or
                    copying of any Content or enforce limitations on use the
                    Site and/or Mobile Application and/or the Content. You agree
                    that your access and use of the Site, Mobile Application,
                    and/or Services must comply with all applicable federal,
                    state, local, and international laws and regulations. Your
                    use of the Site, Mobile Application, and/or Services must
                    not promote any illegal activity, or advocate, promote, or
                    assist any unlawful act.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="user-submissions">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-39d3c79f-7679-40c0-88e3-a7c533b9cb50"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>12. User submissions</h3>
                  <p>
                    You agree that any information you provide through the Site,
                    Mobile Application or Services (&quot;User Content&quot;)
                    will be truthful, accurate and complete. For illustrative
                    purposes, User Content may be provided in account
                    information, in Stores (as defined in the Fundraising
                    Policy) and in chats. We have no obligations regarding User
                    Content except as specifically agreed to in a separate
                    writing or required by law. You acknowledge and agree that
                    you will not provide any User Content to us that is
                    defamatory, threatening, obscene, harassing, or otherwise
                    unlawful, or that incorporates the proprietary material of
                    another person or entity. By uploading the User Content, you
                    hereby grant Popcorn World and its affiliated companies a
                    nonexclusive, worldwide, royalty free, fully paid up,
                    transferable, sublicensable, perpetual, irrevocable license
                    to copy, display, upload, perform, distribute, store, modify
                    and otherwise use the User Content in connection with the
                    operation of the Service or the promotion, advertising or
                    marketing thereof, in any form, medium or technology now
                    known or later developed. Without limiting the foregoing, if
                    any User Content contains your name, image or likeness, you
                    hereby release and hold harmless Popcorn World and its
                    contractors and employees, from (i) all liability and claims
                    for invasion of privacy, publicity or libel, (ii) any
                    liability and claims by virtue of any blurring, distortion,
                    alteration, optical illusion, or other use or exploitation
                    of your name, image or likeness, and (iii) any liability and
                    claims for claims made by you (or any successor to any claim
                    you might bring) in connection with your User Content, name,
                    image or likeness. You waive any right to inspect or approve
                    any intermediary version(s) or finished version(s) of the
                    results of the use of your User Content (including your
                    name, image or likeness). Further, if any person (other than
                    you) appears in your User Content, you represent and warrant
                    that you have secured all necessary licenses, waivers and
                    releases from such person(s) for the benefit of Popcorn World
                    in a manner fully consistent with the licenses, waivers and
                    releases set forth above. You further acknowledge that your
                    participation in the Services and submission of User Content
                    is voluntary and that you will not receive financial
                    compensation of any type associated with the licenses,
                    waivers, and releases set forth herein (or Popcorn World’s
                    exploitation thereof), and that the sole consideration for
                    subject matter of this agreement is the opportunity to use
                    the Services. You further acknowledge and agree that Double
                    Good may preserve User Content and disclose User Content if
                    required to do so by law or in the good faith belief that
                    such preservation or disclosure is reasonably necessary to
                    (a) comply with legal process, applicable laws or government
                    requests; (b) enforce these Terms of Service; (c) respond to
                    claims that any content violates the rights of third
                    parties; or (d) protect the rights, property, or personal
                    safety of Popcorn World, its users or the public. By using the
                    Site, the Mobile Application, and/or the Services, all Users
                    consent to Popcorn World’s inclusion of such User Content in
                    Popcorn World’s marketing and advertising materials. For
                    further information on how Popcorn World may use and share
                    User Content, refer to the Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="limited-access">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-58e2043e-1884-449c-986f-cb45ac8ebf7b"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>13. Limited access</h3>
                  <p>
                    From time to time, the Site, Mobile Application, and/or
                    Services may be inaccessible or inoperable for various
                    reasons, including equipment malfunctions, updates,
                    maintenance and repairs and causes beyond our control or
                    those that are not reasonably foreseeable. We are not
                    obligated to compensate you for these conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="warranty-disclaimers">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-9f4c6b88-816c-4cd8-8a6c-8752ab4b5405"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>14. WARRANTY DISCLAIMERS</h3>
                  <p>
                    THE INFORMATION AND DESCRIPTIONS CONTAINED ON THE SITE, THE
                    MOBILE APPLICATIONS AND THE SERVICES ARE NOT INTENDED TO BE
                    COMPLETE DESCRIPTIONS OF ALL TERMS, EXCLUSIONS AND
                    CONDITIONS APPLICABLE TO EVERY PRODUCT OR SERVICE WE OFFER
                    BUT ARE PROVIDED FOR GENERAL INFORMATIONAL PURPOSES. WE MAKE
                    NO REPRESENTATIONS OR WARRANTIES THAT SUCH CONTENT IS
                    SUITABLE FOR YOUR NEEDS, COMPLETE, TIMELY OR RELIABLE. ALL
                    TEXT, IMAGES AND OTHER CONTENT ON THE SITE ARE PROVIDED
                    &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR USE, AND/OR NON-INFRINGEMENT. WE DO NOT WARRANT
                    THAT THE SITE, THE MOBILE APPLICATIONS OR THE SERVICES WILL
                    BE UNINTERRUPTED OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL
                    CODE, OR THAT ANY ERRORS OR DEFECTS WILL BE CORRECTED, AND
                    YOU HEREBY ACKNOWLEDGE THAT SUCH DOWNTIME MAY AFFECT YOUR
                    STORE (AS DEFINED IN THE FUNDRAISING POLICY), SALES, AND
                    YOUR PARTICIPATION IN SALES CONTESTS. SOME JURISDICTIONS DO
                    NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES. ACCORDINGLY,
                    SOME OF THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO
                    YOU.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="limitation-of-liability">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-646780cc-81a8-44c5-ac1b-c0a0085b3527"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>15. LIMITATION OF LIABILITY</h3>
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER
                    Popcorn World, NOR ANY OF ITS AFFILIATES, NOR ANY OF THEIR
                    RESPECTIVE OWNERS, PARTNERS, MEMBERS, MANAGERS, OFFICERS,
                    EMPLOYEES, AGENTS OR REPRESENTATIVES (COLLECTIVELY, THE
                    &quot;Popcorn World PARTIES&quot;), SHALL HAVE ANY LIABILITY
                    FOR ANY LOSSES OR DAMAGES, INCLUDING, WITHOUT LIMITATION,
                    DIRECT, INDIRECT, CONSEQUENTIAL, COMPENSATORY, SPECIAL,
                    PUNITIVE, OR INCIDENTAL DAMAGES, OR DAMAGES ARISING OUT OF
                    LOSS OF REVENUE, LOST PROFITS, OR LOSS OF DATA (EVEN IF
                    Popcorn World HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES) ARISING OUT OF OR RELATING TO (A) THE USE OF,
                    RELIANCE ON OR INABILITY TO USE THE SITE, THE MOBILE
                    APPLICATION, AND/OR THE SERVICES; (B) ANY CONDUCT OR CONTENT
                    OF ANY THIRD PARTY ON THE SITE, THE MOBILE APPLICATION,
                    AND/OR THE SERVICES; (C) THE CONTENT OR SERVICES PROVIDED ON
                    OR RECEIVED FROM THE SITE, THE MOBILE APPLICATION, AND/OR
                    THE SERVICE; OR (D) UNAUTHORIZED ACCESS, USE OR ALTERATION
                    OF USER CONTENT. TO THE FULLEST EXTENT PERMITTED BY
                    APPLICABLE LAW, IN NO EVENT WILL THE AGGREGATE LIABILITY OF
                    ALL Popcorn World PARTIES TO YOU FOR ALL DAMAGES, LOSSES
                    (INCLUDING CONTRACT, NEGLIGENCE, STATUTORY LIABILITY OR
                    OTHERWISE) OR CAUSES OF ACTION EXCEED THE GREATEST OF (1)
                    THE AMOUNT YOU HAVE PAID ALL OF THE Popcorn World PARTIES IN
                    THE SIX (6) MONTHS PRIOR TO A CLAIM ACCRUING, (2) THE AMOUNT
                    YOU HAVE SOLD IN A STORE MADE AVAILABLE THROUGH THE MOBILE
                    APPLICATIONS FOR SUCH SIX-MONTH PERIOD (IF YOU OPERATE A
                    STORE), OR (3) ONE HUNDRED DOLLARS ($100). SOME
                    JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
                    LIABILITY FOR CERTAIN OF THE FOREGOING. ACCORDINGLY, SOME OF
                    THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="indemnity">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-000c86da-bba9-48b8-8b31-5fa13ce5418a"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>16. Indemnity</h3>
                  <p>
                    You agree to indemnify and hold the Popcorn World Parties
                    harmless from any loss, liability, claim or demand,
                    including reasonable attorneys’ fees and costs, due to or
                    arising out of (a) your breach of these Terms and
                    Conditions; (b) your violation of any third-party right; and
                    (c) your gross negligence, intentional misconduct and
                    illegal conduct.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="reservation-of-rights">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-451d5918-cace-470a-98a2-203c081c0f7d"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>17. Reservation of rights</h3>
                  <p>
                    We specifically reserve all rights that we do not expressly
                    grant in these Terms and Conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="contact-information">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-01344dba-42c7-4fbc-a162-b6507bc49075"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>18. Contact information</h3>
                  <p>
                    If you wish to contact us regarding (a) information on our
                    products and services, (b) permission to reproduce or use
                    any Content, (c) notices of claimed infringement under the
                    DMCA, or (d) any other reason, please contact (the
                    &quot;Copyright Agent&quot;):
                    <br />
                    Popcorn World TECHNOLOGIES LP 16W030 83rd Street Burr Ridge,
                    IL 60527 Phone: 630-568-5544 Email:{" "}
                    <a href="mailto:help@doublegood.com" linktype="url">
                      help@doublegood.com
                    </a>
                    <br />
                    For clarity, only DMCA notices should go to the Copyright
                    Agent; any other feedback, comments, requests for technical
                    support, and other communications should be directed to
                    Popcorn World’s Customer Service through. You acknowledge that
                    if you fail to comply with all of the requirements of this
                    Section 18, your DMCA notice may not be valid. We provide
                    this contact information for purposes of the DMCA only and
                    reserve the right to respond only to communication that is
                    relevant for this purpose.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="termination">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-15a4d8ed-e7e3-4b6d-829a-bf9bc97c597a"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>19. Termination</h3>
                  <p>
                    Popcorn World reserves the right to discontinue any aspect of
                    the Site, Mobile Application, and/or Services at any time;
                    provided, however that we will use reasonable efforts to
                    communicate such plans in advance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="severance-and-waiver">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-d282ceb8-f88b-4dbd-a400-7288af3279c3"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>20. Severance and waiver</h3>
                  <p>
                    In the event any provision of these Terms and Conditions is
                    held by a court to be invalid, unlawful, or unenforceable,
                    you and we agree that the validity, legality, and/or
                    enforceability of the remaining provisions contained in
                    these Terms and Conditions will not in any way be affected
                    or impaired. Our failure to exercise or enforce any right or
                    provision under these Terms and Conditions will not
                    constitute a waiver of such right or provision by us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="venue-and-choice-of-law">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-f88a1960-a46e-4e94-b1ad-797348d9d898"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>21. Venue and choice of law</h3>
                  <p>
                    IMPORTANT NOTICE: ARBITRATION, JURY TRIAL, AND className
                    ACTION WAIVER: EXCEPT AS OTHERWISE DESCRIBED IN THIS SECTION
                    21, BY USING THE SITE, THE MOBILE APPLICATIONS OR THE
                    SERVICES, YOU AGREE THAT DISPUTES BETWEEN US WILL BE
                    RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE
                    YOUR RIGHT TO A JURY TRIAL OR TO PARTICIPATE IN A className
                    ACTION. PLEASE REVIEW THIS SECTION 21 CAREFULLY.
                    <br />
                    You acknowledge and agree that: (a) the Site, Mobile
                    Application, and Services shall be deemed solely based in
                    Illinois; and (b) the Site, Mobile Application, and Services
                    shall be deemed not to give rise to personal jurisdiction
                    over us, either specific or general, in jurisdictions other
                    than Illinois. These Terms and Conditions are governed by
                    the laws of the State of Illinois, without regard to its
                    conflict of laws principles. Any case, controversy, suit,
                    action, or proceeding arising out of, in connection with, or
                    related to these Terms and Conditions, the Site, the Mobile
                    Application or the Services shall be settled by binding
                    individual arbitration in Chicago, Illinois. The arbitration
                    shall be conducted on a confidential basis pursuant to the
                    Commercial Arbitration Rules of the American Arbitration
                    Association. The party seeking to initiate arbitration must
                    notify the adverse party in writing of a Demand for
                    Arbitration. If the parties cannot mutually agree on an
                    acceptable arbitrator within twenty days after the adverse
                    party has received the Demand for Arbitration from the
                    initiating party, the parties shall submit a joint-request
                    for arbitration to the American Arbitration Association
                    (&quot;AAA&quot;), and shall allow the AAA to select the
                    arbitrator. Any decision or award as a result of any such
                    arbitration proceeding shall be in writing and shall provide
                    an explanation for all conclusions of law and fact and shall
                    include the assessment of costs, expenses, and reasonable
                    attorneys’ fees. Any such arbitration shall be conducted by
                    a panel of three arbitrators each experienced in commercial
                    contract disputes for at least seven (7) years and each
                    having presided over at least five (5) previous arbitration
                    matters, and shall include a written record of the
                    arbitration hearing. The parties reserve the right to object
                    to any potential arbitrator who is employed by or affiliated
                    with a competing organization or entity thereby resulting in
                    a conflict-of-interest. An award of arbitration may be
                    confirmed in a court of the State of Illinois, and in the
                    event of non-compliance with the arbitration ruling, the
                    prevailing party may seek to enforce the arbitration
                    decision in the Illinois courts.
                    <br />
                    YOU AGREE THAT ALL DISPUTES BETWEEN YOU, ON THE ONE HAND,
                    AND Popcorn World, ITS AFFILIATES, AND ANY OF THEIR RESPECTIVE
                    OWNERS, PARTNERS, MEMBERS, MANAGERS, OFFICERS, EMPLOYEES,
                    AGENTS OR REPRESENTATIVES, ACTING IN THEIR CAPACITY AS SUCH
                    (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY), WITH
                    REGARD TO YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT
                    LIMITATION DISPUTES RELATED TO THESE TERMS OF SERVICE, YOUR
                    USE OF THE SITE, THE MOBILE APPLICATION OR THE SERVICES,
                    AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED
                    BY BINDING, INDIVIDUAL ARBITRATION AND YOU AND WE HEREBY
                    EXPRESSLY WAIVE TRIAL BY JURY. DISCOVERY AND RIGHTS TO
                    APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A
                    LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD HAVE IN
                    COURT MAY NOT BE AVAILABLE IN ARBITRATION. YOU UNDERSTAND
                    AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND WE ARE
                    EACH WAIVING OUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE
                    IN A className ACTION.
                    <br />
                    Notwithstanding the foregoing provisions of this Section 21,
                    (a) we shall be entitled to obtain injunctive relief and
                    specific performance from any court of competent
                    jurisdiction, for example, to stop you from using the Site,
                    the Mobile Application or the Services in a manner that
                    violates these Terms and Conditions, (b) you shall be
                    entitled to bring an individual action in small claims
                    court, and (c) you shall be entitled to bring other claims
                    in a court of competent jurisdiction solely to the extent
                    that such right may not be waived under applicable law.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="timing-of-a-claim">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-418f8ae6-b898-437e-ac25-13fcf5dfb032"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>22. Timing of a claim</h3>
                  <p>
                    ARBITRATION MUST BE INITIATED BY THE AGGRIEVED PARTY WITHIN
                    ONE (1) YEAR OF THE ALLEGED CONDUCT THAT MAY GIVE RISE TO
                    ANY CAUSE OF ACTION OR DISPUTED MATTER TO BE ARBITRATED THAT
                    ARISES OUT OF OR IS RELATED TO THE SITE, MOBILE
                    APPLICATIONS, SERVICES AND/OR THESE TERMS AND CONDITIONS. IF
                    ARBITRATION IS NOT INITIATED WITHIN ONE (1) YEAR OF A
                    DISPUTE OR CAUSE OF ACTION COMING INTO EXISTENCE, SUCH CAUSE
                    OF ACTION CANNOT BE PURSUED AND IS PERMANENTLY BARRED FROM
                    ARBITRATION AND LITIGATION.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="prevailing-parties">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-fddf1d21-9246-4559-ab43-6c428f2e04ab"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>23. Prevailing parties</h3>
                  <p>
                    In the event of any action to enforce or interpret any
                    provision of these Terms and Conditions, including
                    arbitration proceedings, the prevailing party is entitled to
                    recover, in addition to other costs, reasonable attorneys’
                    fees in connection with the suit, action, or arbitration,
                    and in any appeals. The determination of who is the
                    prevailing party and the amount of reasonable attorneys’
                    fees to be paid to the prevailing party will be decided by
                    the tribunal in which the matter is heard, tried, or
                    decided, including any arbitration panel, court(s), or
                    appellate court(s).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="entire-agreement">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-437f5389-5ee0-46f9-b98f-e9805ac0afdb"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>24. Entire agreement</h3>
                  <p>
                    You agree that these Terms and Conditions (including,
                    without limitation, the Privacy Policy and the Fundraising
                    Policy) constitute the entire agreement between you and us
                    with respect to your use of the Site, Mobile Application,
                    and/or Services. You agree that these Terms and Conditions
                    supersede any other agreements between you and us with
                    respect to your use of the Site, Mobile Application, and/or
                    Services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="entire-agreement">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1rj7994">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-a93beab0-5ef6-4ad5-8415-261cf536d18d"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>Fundraising policy</h3>
                  <p>
                    <br />
                    Date of Last Revision: December 15, 2023
                    <br />
                    <br />
                    PLEASE READ TERMS AND CONDITIONS CAREFULLY. THESE TERMS AND
                    CONDITIONS CONTAINS DISPUTE RESOLUTION PROVISIONS THAT
                    REQUIRE YOU TO SUBMIT TO ARBITRATION, WHICH MAY
                    SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT
                    TO FILE A LAWSUIT IN COURT. BY ACCESSING ANY PORTION OF THE
                    SITE OR ANY MOBILE APPLICATIONS, OR BY USING THE SERVICES,
                    YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ, UNDERSTAND AND
                    AGREE TO BE BOUND BY THIS FUNDRAISING POLICY. WE WILL INFORM
                    YOU REGARDING ANY MODIFICATIONS BY EITHER NOTIFYING YOU VIA
                    YOUR DESIGNATED EMAIL ADDRESS OR BY POSTING THE CHANGES ON
                    THE SITE.
                    <br />
                    <br />
                    All capitalized terms not otherwise defined herein shall
                    have the meanings set forth in the Terms and Conditions. We
                    may revise this Fundraising Policy from time to time without
                    notice. Please review it carefully on a regular basis, as it
                    may have changed since your last visit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="our-policies">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-26a3db9d-096a-4dc0-9f38-8f2b43975c5e"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>1. Our policies</h3>
                  <p>
                    Our Terms and Conditions, the most recent version of which
                    may be viewed on the Site or through our Mobile Application
                    (the &quot;Terms and Conditions&quot;) and our Privacy
                    Policy, the most recent version of which may be viewed on
                    the Site or through the Mobile Application (the &quot;
                    <a
                      href="/privacy"
                      uuid="cc2d9ed9-1afc-4662-bf61-ff567b7501f4"
                      target="_self"
                      linktype="story"
                    >
                      Privacy Policy
                    </a>
                    &quot;), are hereby incorporated into this Fundraising
                    Policy and made a part hereof. Please read our Terms and
                    Conditions and Privacy Policy carefully, as the terms and
                    conditions are subject to change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="fundraisers">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-11f59a10-bb02-4f9f-ad54-b97946f083d2"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>2. Fundraiser Campaigns.</h3>
                  <p>
                    As a part of the Services, we have created a fundraising
                    service platform (&quot;Fundraising Service&quot;) that
                    allows users to create their own online store or shop pages
                    through the Mobile Application (each a &quot;Store&quot; or
                    &quot;Shop&quot;) and promote the Stores via social media
                    and other online channels. Notwithstanding the foregoing,
                    Popcorn World reserves the right to discontinue any
                    Fundraising Service at any time.
                  </p>
                  <p>
                    <br />
                    a. Organizers:
                    <br />
                    An organizer (“Organizer”) is an authorized individual who
                    accesses and manages campaign information and/or information
                    about the beneficiary of the campaign. A beneficiary is an
                    organization that has authorized the Organizer to act for
                    the benefit of the organization for which funds are being
                    collected (“Beneficiary”). In some cases, the Beneficiary
                    and Organizer may be the same, provided however, that
                    Organizers are not acting as service providers, independent
                    contractors, or agents to Popcorn World and payments received
                    by Beneficiaries are not intended to be wages, premiums,
                    annuities, compensations, remunerations, emoluments or other
                    incentives commissions to the Organizer. Payments made to
                    Beneficiaries in connection with the Fundraising Service are
                    the result of contributions from Buyers for the stated
                    fundraising purpose that make up a portion of each
                    transaction. Organizers may be required to provide
                    verification information about Beneficiaries to verify the
                    legitimacy of the organization and/or certify that payments
                    made by Popcorn World to Organizers will be remitted to the
                    Beneficiary organization. A failure by the Organizer to
                    provide such information may result in tax reporting
                    obligations. For illustrative purposes, an Organizer may be
                    a gym owner, program director, coach, team parent, or parent
                    who accesses and manages gym/program/team/studio
                    information. Organizers are able to create an organizer
                    account on the Site or on the Mobile Application that will
                    allow them to add a Seller to their Organizer Account and to
                    edit their Seller profiles, including names, and addresses
                    (“Organizer Account”). Organizers (or the Beneficiaries)
                    must be able to accept payments to accounts or addresses
                    based in the United States; at this time, Popcorn World cannot
                    and will not send payments outside of the United States. The
                    Organizer, as set forth in the Organizer’s Acknowledgement
                    below, represents that it is authorized by a Beneficiary to
                    raise funds for a Beneficiary, and acknowledges and agrees
                    that Popcorn World is not responsible for the payment of any
                    funds to any Beneficiary.{" "}
                  </p>
                  <p>
                    <br />
                    b. Sellers:
                    <br />A seller (&quot;Seller&quot;) is a user and campaign
                    participant who sells products through Popcorn World’s
                    platform by creating a Seller account (&quot;Seller
                    Account&quot;) and an associated Store. For illustrative
                    purposes, a Seller may be a team member, student, parent, or
                    guardian. Each Seller must be associated in our records with
                    an Organizer. An Organizer may also be a Seller. Each Seller
                    may create and maintain only one Seller Account. Creating
                    multiple Seller Accounts, using aliases or proxy Seller
                    Accounts, or engaging in similar conduct designed to conceal
                    a Seller’s true identity or to enable a Seller to transact
                    business with Popcorn World under more than one Seller Account
                    is strictly prohibited. A Seller may create a Seller Account
                    only on the Mobile Application and manage the associated
                    Store on the Mobile Application and Site. A Seller shall
                    have no claims against us with respect to an Organizer’s
                    handling of sale proceeds or Payout (as defined below).
                  </p>
                  <p>
                    <br />
                    c. Buyers:
                    <br />A buyer (&quot;Buyer&quot;) is any user who purchases
                    products from a Store, the Site or the Mobile Application in
                    connection with the Fundraising Service. As described
                    further below in section 4(b), a portion of each Buyer
                    purchase is treated as a purchase of products from Double
                    Good (for which sales tax may apply) with the remaining
                    portion treated as a contribution to the Beneficiary
                    designated by the Organizer or Seller for the stated
                    fundraising purpose. A Buyer may also be a Seller, an
                    Organizer or both. Buyers must be able to make payments from
                    an account in the United States and to accept shipments at
                    an address in the United States; at this time, Popcorn World
                    cannot and will not accept payments from outside of the
                    United States or ship goods outside of the United States.
                  </p>
                  <p>
                    <br />
                    d. Our Platform:
                    <br />
                    We provide a platform for selling goods, such as popcorn, as
                    a vehicle for Organizers to contribute a portion of the
                    sales proceeds to raise funds for Beneficiaries. Any Payouts
                    to Beneficiaries in connection with Fundraising Services are
                    treated as contributions to such Beneficiaries by Buyers for
                    the stated fundraising purpose and are not intended to be
                    wages, premiums, annuities, compensations, remunerations,
                    emoluments, or other incentives or commissions to the
                    Organizer. As described above, a portion of each transaction
                    is treated as a purchase of products from Popcorn World (for
                    which sales tax may apply) with the remaining portion
                    treated as a contribution to the Beneficiary designated by
                    the Organizer or Seller for the stated fundraising purpose.
                    We are not a broker, financial institution, creditor, or
                    charity. All information and content provided by through our
                    platform relating to fundraising is for informational
                    purposes only, and we do not guarantee the accuracy,
                    completeness, timeliness, or reliability of any such
                    information or content. No content is intended to provide
                    financial, legal, tax, or other professional advice. Before
                    making any decisions regarding any sales and fundraising
                    related to our products, you should consult your financial,
                    legal, tax, or other professional advisor as appropriate. We
                    have no control over the conduct of or any information
                    provided by, any Beneficiary, Organizer, or Seller. We do
                    not guarantee that a Beneficiary, Organizer, or Seller will
                    obtain a certain amount of sales or any sales volume at all.
                    We do not endorse any campaign or cause except as expressly
                    stated in writing, and we make no guarantee, express or
                    implied, that any information provided through the Services
                    is accurate, including, without limitation, information
                    provided by Beneficiaries, Organizers, or Sellers. We
                    expressly disclaim any liability or responsibility for the
                    outcome or success of any campaign. As a Buyer, you must
                    make the final determination as to the value and
                    appropriateness of contributing to any campaign.
                    Notwithstanding the foregoing, we take suspected illegal
                    activity and the misuse of funds raised very seriously. If a
                    Beneficiary, Seller, or Buyer has reason to believe that an
                    Organizer is not raising or using the funds for their stated
                    purpose, please alert our team of this potential issue and
                    we will investigate.
                  </p>
                  <p>
                    <br />
                    e. Acknowledgement by Schools and School Districts:
                    <br />
                    Organizers, Beneficiaries, and Sellers that use Double
                    Good’s platforms for school-based fundraising activities
                    acknowledges and agree, that for schools and school
                    districts that are required to comply with Family
                    Educational Rights and Privacy Act (FERPA), Popcorn World does
                    not collect or access “education records” or “directory
                    information” as those terms are defined under FERPA.
                    Further, Popcorn World is not considered a “community-based
                    organization” to which some FERPA consent and disclosure
                    obligations may apply. As such, Popcorn World does not enter
                    into stand-alone agreements with schools or schools
                    districts intended to create obligations on the part of
                    Popcorn World to protect education records, or that relate to
                    FERPA’s consent and disclosure requirements for education
                    records or directory information.
                  </p>
                  <p>
                    f. Organizer&#39;s Acknowledgement:
                    <br />
                    <b>Organizers</b>: You, as an Organizer, represent, warrant,
                    and covenant that: (i) all information you (whether through
                    yourself, an agent or using artificial intelligence) provide
                    in connection with an Organizer or Beneficiary is accurate,
                    complete, and not likely to deceive Buyers and that you will
                    post updates as needed so that Buyers understand the use of
                    funds and any other relevant information about your
                    campaign; (ii) all Payouts contributed to your campaign will
                    be used solely as described in the materials that you post
                    or otherwise provide as the basis for your fundraising
                    campaign; (iii) if you are individual serving as a
                    representative for a Beneficiary, you represent and warrant
                    that you are authorized by a Beneficiary to raise funds on
                    their behalf and to use a Beneficiary’s name and
                    trademark(s) in any fundraising campaign or event; (iv) you
                    will promptly remit any proceeds you receive in connection
                    with the Fundraising Services designated for a Beneficiary
                    to such Beneficiary and a failure to do so may result in tax
                    reporting obligations (v) you will not infringe the rights
                    of others; (vi) you are not a service provider, independent
                    contractor, or agent to Popcorn World and proceeds you receive
                    are intended for a Beneficiary for the stated fundraising
                    purpose and not as wages, premiums, annuities,
                    compensations, remunerations, emoluments or other incentives
                    or commissions to you; (vii) you will comply with all
                    relevant and applicable laws and financial reporting
                    obligations, including but not limited to, laws and
                    obligations relating to registration, tax reporting,
                    political contributions, and asset disclosures for your
                    campaign.
                  </p>
                  <p>
                    If you use the Services as an agent of a charity to raise
                    funds for such charity, you represent and warrant that: (a)
                    you are a representative of the charity, which
                    representative is authorized to raise funds for the charity
                    and bind the charity to these Terms and Conditions; (b) you
                    are raising funds for a charity, with a cause or activity
                    that is legal under all applicable federal, state,
                    territorial and local laws and regulations; (c) all Payouts
                    will be used solely for the purpose you have stated on and
                    in connection with your campaign, and under no circumstances
                    may you use the funds for any other purpose; (d) your
                    charity has and will maintain tax-exempt status under
                    applicable law (for example, the Internal Revenue Code in
                    the United States); and (e) if your charity is in the United
                    States, it is registered with the IRS tax-exempt
                    organization database
                  </p>
                  <p>
                    g. Seller&#39;s Acknowledgement
                    <br />
                    <b>Seller</b>: You, as a Seller, represent, warrant, and
                    covenant that: (i) all information you (whether through
                    yourself, an agent or using artificial intelligence) provide
                    in connection with a Beneficiary or Organizer is accurate,
                    complete, and not likely to deceive Buyers and that you will
                    post updates as needed so that Buyers understand the use of
                    funds and any other relevant information about your
                    campaign; (ii) all Payouts contributed to your campaign will
                    be used solely as described in the materials that you post
                    or otherwise provide as the basis for your fundraising
                    campaign; (iii) if you are individual serving as a
                    representative for a Beneficiary, you represent and warrant
                    that you have received official authorization to act on
                    behalf of the Organizer; (iv) you will not infringe the
                    rights of others; (v) you will comply with all relevant and
                    applicable laws and financial reporting obligations,
                    including but not limited to, laws and obligations relating
                    to registration, tax reporting, political contributions, and
                    asset disclosures for your campaign;
                  </p>
                  <p>
                    If you use the Services as an agent of a charity to raise
                    funds for such charity, you represent and warrant that: (a)
                    you are a representative of the charity, which
                    representative is authorized to raise funds for the charity
                    and bind the charity to these Terms and Conditions; (b) you
                    are raising funds for a charity, with a cause or activity
                    that is legal under all applicable federal, state,
                    territorial and local laws and regulations; (c) all Payouts
                    will be used solely for the purpose you have stated on and
                    in connection with your campaign, and under no circumstances
                    may you use the funds for any other purpose; (d) your
                    charity has and will maintain tax-exempt status under
                    applicable law (for example, the Internal Revenue Code in
                    the United States); and (e) if your charity is in the United
                    States, it is registered with the IRS tax-exempt
                    organization database.-{" "}
                  </p>
                  <p>
                    <br />
                    h. Prohibited Conduct:
                    <br />
                    The following are examples of User Content and/or use that
                    is prohibited and possibly illegal. This list is not
                    exhaustive, and we reserve the right to investigate anyone
                    who, in our sole discretion, violates any of the terms or
                    spirit of this Fundraising Policy and the Terms and
                    Conditions. We further reserve without limitation, the right
                    to remove the offending User Content, suspend or terminate
                    the account of such violators, stop payments to any such
                    Organizer, freeze or place a hold on Payouts, and report you
                    to law enforcement authorities or otherwise take appropriate
                    legal action including seeking restitution on behalf of
                    ourselves and/or our users. Our procedures for resolving
                    account freezes or hold are outlined{" "}
                    <a
                      href="https://help.doublegood.com/en_us/account-ban-and-appeals-policy-HJ8FmBW6n"
                      target="_self"
                      linktype="url"
                    >
                      here
                    </a>
                    . Without limiting the foregoing, you agree not to use the
                    Services to:
                  </p>
                  <p>
                    (i) raise funds or establish or contribute to any campaign
                    with the implicit or explicit purpose of or involving:
                  </p>
                  <ol>
                    <li>
                      <p>
                        violating any law, regulation, industry requirement, or
                        third-party guidelines or agreements by which you are
                        bound, including those of payment card providers and
                        transaction processors that you utilize in connection
                        with the Services;
                      </p>
                    </li>
                    <li>
                      <p>
                        any political campaigns including without limitation
                        seeking election to public office, referenda, ballot
                        initiatives or otherwise;
                      </p>
                    </li>
                    <li>
                      <p>
                        content or campaigns that are fraudulent, misleading,
                        inaccurate, dishonest, or impossible;
                      </p>
                    </li>
                    <li>
                      <p>
                        drugs, narcotics, steroids, controlled substances,
                        pharmaceuticals or similar products or therapies that
                        are either illegal, prohibited, or enjoined by an
                        applicable regulatory body; legal substances that
                        provide the same effect as an illegal drug; or other
                        products, medical practices, or any related equipment or
                        paraphernalia that have been found by an applicable
                        regulatory body to cause consumer harm;
                      </p>
                    </li>
                    <li>
                      <p>
                        knives, explosives, ammunition, firearms, or other
                        weaponry or accessories;
                      </p>
                    </li>
                    <li>
                      <p>
                        annuities, investments, equity or lottery contracts,
                        lay-away systems, off-shore banking or similar
                        transactions, money service businesses (including
                        currency exchanges, check cashing or the like), pyramid
                        schemes, &quot;get rich quick schemes&quot; (i.e.,
                        Investment opportunities or other services that promise
                        high rewards), network marketing and referral marketing
                        programs, debt collection or crypto-currencies;
                      </p>
                    </li>
                    <li>
                      <p>
                        gambling, gaming and/or any other activity with an entry
                        fee and a prize, including, but not limited to casino
                        games, sports betting, fantasy sports, horse or
                        greyhound racing, lottery tickets, raffle tickets,
                        auctions and other ventures that facilitate gambling,
                        games of skill or chance (whether or not it is legally
                        defined as a lottery), promotions involving monetary
                        rewards, including gift cards, or sweepstakes;
                      </p>
                    </li>
                    <li>
                      <p>
                        campaigns we deem, in our sole discretion, to be in
                        support of, or for the legal defense of alleged crimes
                        associated with hate, violence, harassment, bullying,
                        discrimination, terrorism, or intolerance of any kind
                        relating to race, ethnicity, national origin, religious
                        affiliation, sexual orientation, sex, gender or gender
                        identity, or serious disabilities or diseases;
                      </p>
                    </li>
                    <li>
                      <p>
                        activities with, in, or involving countries, regions,
                        governments, persons, or entities that are subject to
                        U.S. and other economic sanctions under applicable law,
                        unless such activities are expressly authorized by the
                        appropriate governmental authority;
                      </p>
                    </li>
                    <li>
                      <p>
                        funding a ransom, human trafficking or exploitation,
                        vigilantism, bribes or bounty;
                      </p>
                    </li>
                    <li>
                      <p>pornography or other sexual content;</p>
                    </li>
                    <li>
                      <p>offensive, graphic, perverse, or sensitive content;</p>
                    </li>
                    <li>
                      <p>
                        the sale of items before the Seller has control or
                        possession of the item;
                      </p>
                    </li>
                    <li>
                      <p>
                        collecting payments on behalf of merchants by payment
                        processors or otherwise; including but not limited to
                        self-payments on campaigns or an attempt to bypass or
                        otherwise circumvent the designated method of payment as
                        designated by Popcorn World;
                      </p>
                    </li>
                    <li>
                      <p>credit repair or debt settlement services;</p>
                    </li>
                    <li>
                      <p>
                        the receipt or grant of cash advances or lines of credit
                        to yourself or to another person for purposes other than
                        those purposes clearly stated in the campaign;
                      </p>
                    </li>
                    <li>
                      <p>
                        publication or removal of content (such as mug shots),
                        where the primary purpose of posting such content is to
                        cause or raise concerns of reputational harm;
                      </p>
                    </li>
                    <li>
                      <p>
                        sale or resale of a service without added benefit to the
                        buyer;
                      </p>
                    </li>
                    <li>
                      <p>
                        resale of government offerings without authorization or
                        added value;
                      </p>
                    </li>
                    <li>
                      <p>
                        aggregation of funds owed to third parties, factoring,
                        or other activities intended to obfuscate the origin of
                        funds;
                      </p>
                    </li>
                    <li>
                      <p>
                        counterfeit music, movies, software, or other licensed
                        materials without the appropriate authorization from the
                        rights holder;
                      </p>
                    </li>
                    <li>
                      <p>
                        products or services that directly infringe or
                        facilitate infringement upon the trademark, patent,
                        copyright, trade secrets, or proprietary or privacy
                        rights of any third party;
                      </p>
                    </li>
                    <li>
                      <p>
                        unauthorized sale or resale of brand name or designer
                        products or services;
                      </p>
                    </li>
                    <li>
                      <p>
                        sale of goods or services that are illegally imported or
                        exported;
                      </p>
                    </li>
                    <li>
                      <p>
                        processing where there is no bona fide payment accepted;
                        cash advance; card testing; evasion of card network
                        chargeback monitoring programs;
                      </p>
                    </li>
                    <li>
                      <p>
                        collecting or providing funds for any purpose other than
                        as described in a campaign description;
                      </p>
                    </li>
                    <li>
                      <p>
                        any other activity that we may deem in our sole
                        discretion to be in support of individuals and/or
                        entities associated with alleged financial crimes
                        including but not limited to corruption, bribery, tax
                        evasion, fraud, and activities of a similar nature; or
                      </p>
                    </li>
                    <li>
                      <p>
                        any other activity that we may deem in our sole
                        discretion to be unacceptable.
                      </p>
                    </li>
                  </ol>
                  <p>
                    (ii) transmit or otherwise upload any content that (i)
                    infringes any intellectual property or other proprietary
                    rights of any party; (ii) you do not have a right to upload
                    under any law or under contractual or fiduciary
                    relationships; (iii) contains software viruses or any other
                    computer code, files or programs designed to interrupt,
                    destroy or limit the functionality of any computer software
                    or hardware or telecommunications equipment; (iv) poses or
                    creates a privacy or security risk to any person; (v)
                    constitutes unsolicited or unauthorized advertising,
                    promotional materials, commercial activities and/or sales,
                    &quot;junk mail,&quot; &quot;spam,&quot; &quot;chain
                    letters,&quot; &quot;pyramid schemes,&quot;
                    &quot;contests,&quot; &quot;sweepstakes,&quot; or any other
                    form of solicitation; or (vi) in our sole judgment, is
                    objectionable or which restricts or inhibits any other
                    person from using or enjoying the Services, or which may
                    expose us or our users to any harm or liability of any type;
                    or
                  </p>
                  <p>
                    (iii) interfere with or disrupt servers or networks
                    connected to the Services, or disobey any requirements,
                    procedures, policies, or regulations of networks connected
                    to the Services; or
                  </p>
                  <p>
                    (iv) harvest or collect email addresses or other contact
                    information of other users from the Services by electronic
                    or other means.
                  </p>
                  <p>
                    (v) raise funds for a minor without the express permission
                    of the minor’s parent or legal guardian unless the funds are
                    transferred into a trust account for the sole benefit of the
                    minor.
                  </p>
                  <p>
                    Additionally, with respect to all payments you make or
                    accept through the Services, you agree:
                  </p>
                  <ol>
                    <li>
                      <p>
                        not to make or accept any payments that you know or
                        suspect to be erroneous, suspicious or fraudulent;
                      </p>
                    </li>
                    <li>
                      <p>
                        not to use the Services in or for the benefit of a
                        country, organization, entity, or person embargoed or
                        blocked by any government, including those on sanctions
                        lists identified by the United States Office of Foreign
                        Asset Control (OFAC);
                      </p>
                    </li>
                    <li>
                      <p>
                        to maintain reasonable and standard security measures to
                        protect any information transmitted and received through
                        the Services, including without limitation by adhering
                        to any security procedures and controls required by us
                        from time to time;
                      </p>
                    </li>
                    <li>
                      <p>
                        to maintain a copy of all electronic and other records
                        related to campaigns and Payouts as necessary for us to
                        verify compliance and make such records available to us
                        upon our request. For clarity, the foregoing does not
                        affect or limit your obligations to maintain
                        documentation as required by applicable laws, rules,
                        regulations, or governmental authority; and
                      </p>
                    </li>
                    <li>
                      <p>
                        at our request, including without limitation in case of
                        investigations by us, a payment processing partner, or a
                        regulatory or governmental authority, fully cooperate in
                        the auditing of such records, investigation of the
                        relevant circumstances and remedy of any uncovered
                        violation or wrongdoing.
                      </p>
                    </li>
                  </ol>
                  <p>
                    We reserve the right to refuse, condition, or suspend any
                    Payouts or other transactions that we believe in our sole
                    discretion may violate terms or harm the interests of our
                    users, business partners, the public, or us, or that expose
                    you, us, or others to risks unacceptable to us. We may share
                    any information related to your use of the Services with the
                    appropriate financial institution, regulatory authority, or
                    law enforcement agency consistent with our legal
                    obligations. This information may include information about
                    you, your account, your buyers, your Payouts, and
                    transactions made through or in connection with your use of
                    the Services.
                  </p>
                  <p>
                    If you receive notice from us regarding a freeze or hold
                    that has been placed on your account or suspect that your
                    account is subject to a freeze or hold, you can contact us{" "}
                    <a
                      href="https://help.doublegood.com"
                      target="_self"
                      linktype="url"
                    >
                      help.doublegood.com
                    </a>
                    . Our procedures for resolving account freezes or hold are
                    outlined{" "}
                    <a
                      href="https://help.doublegood.com/en_us/account-ban-and-appeals-policy-HJ8FmBW6n"
                      target="_self"
                      linktype="url"
                    >
                      here
                    </a>
                    . Please note that if you request a payment change or change
                    any payment or routing instructions related to your Payout,
                    our internal review process may extend the Payout period up
                    to an additional 72 hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="procedures">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-ab477c44-112a-43ca-ad37-1a74d1efd98b"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>3. Procedures</h3>
                  <p>
                    As a part of the Fundraising Service:
                    <br />
                    a. The Organizer will receive a unique Event code to share
                    with potential Sellers. Sellers will use this Event code to
                    create/open a Store. After the Store is opened, Sellers
                    share the link to the Store with potential Buyers for them
                    to buy products from the Store.
                  </p>
                  <p>
                    <br />
                    b. We will use the information you provide to: (i) manage
                    your account; (ii) provide you and your contacts with
                    customer support; (iii) process payment for any purchases
                    made; (iv) enforce our Terms and Conditions; and (v) manage
                    our business.
                  </p>
                  <p>
                    <br />
                    c. We also may disclose your information:
                    <br />
                    (i) In response to a subpoena or similar investigative
                    demand, a court order, or a request for cooperation from a
                    law enforcement or other government agency; to establish or
                    exercise our legal rights; to defend against legal claims;
                    or as otherwise required by law. In such cases, we may raise
                    or waive any legal objection or right available to us.
                    <br />
                    (ii) When we believe disclosure is appropriate in connection
                    with efforts to investigate, prevent, or take other action
                    regarding illegal activity, suspected fraud or other
                    wrongdoing; to protect and defend the rights, property or
                    safety of our company, our users, our employees, or others;
                    to comply with applicable law or cooperate with law
                    enforcement; or to enforce our terms and conditions or other
                    agreements or policies.
                  </p>
                  <p>
                    <br />
                    d. We will not share, sell, use or otherwise distribute the
                    contacts or communicate with the contacts you provide us
                    other than for providing the Fundraising Service or if we
                    give you an opportunity to opt out of having your
                    information shared.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="payments">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-53cdf77b-fcf7-4859-aabf-3f915b1d9381"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>4. Payments</h3>
                  <p>
                    a. Buyer Payments for Products
                    <br />
                    If you are a Buyer, you agree to pay in full the prices (and
                    all applicable taxes and shipping and handling fees) for any
                    purchases you make either by credit / debit card concurrent
                    with your online order or by other payment means acceptable
                    to us. If payment is not received by us from your credit or
                    debit card issuer or its agents, you agree to pay all
                    amounts due upon demand by us. Certain products that you
                    purchase through the Site or the Mobile Application may be
                    subject to additional terms and conditions presented to you
                    at the time of such purchase.  
                  </p>
                  <p>
                    b. Our Payments
                    <br />
                    i. <u>Process</u>. A Buyer purchases products online, either
                    through the Site or the Mobile Application, from a Seller’s
                    Store and remits payment to Popcorn World for the products’
                    item price, any applicable taxes, and any applicable
                    shipping and handling costs. Popcorn World will ship the
                    products to the Buyer. Popcorn World is responsible, where
                    required, for submitting any collected sales tax to the
                    applicable tax collection agencies. Popcorn World will pay
                    fifty percent (50%) of the purchased products’ item price
                    (which, for the avoidance of doubt, does not include any
                    taxes, shipping and handling costs, or other fees) (the
                    &quot;Payout&quot;) to the Organizer responsible for the
                    Seller’s Store where Buyer made the purchase. 
                  </p>
                  <p>
                    <br />
                    ii. <u>Payment Details</u>. We generally remit payment of
                    the Payout to the Organizers through Dwolla, Inc.
                    (&quot;Dwolla&quot;). At this time, there is no transaction
                    fee charged by Dwolla for such transfers, provided that we
                    or Dwolla may modify such payment schedule and the
                    possibility of a transaction fee from time to time. We
                    reserve the right to, and have full discretion to, assign a
                    subsequent or backup Organizer to the Organizer Account if
                    for any reason the original Organizer is no longer able to
                    serve the role of Organizer including the inability to
                    perform fundraising Payout obligations. In the case of
                    fundraising account re-assignment, all potential backup
                    Organizers shall be subject to identity authentication and
                    authorization verification, which may include at a minimum,
                    verification that the backup Organizer is an authorized
                    member, employee, representative or agent of the Beneficiary
                    with initial verification based on authorization and
                    authentication evidenced on organization letterhead, and
                    email address domain verification, and identification
                    verification based on personal identification contained in
                    government issued identification such as driver’s license or
                    passport number. You hereby authorize Dwolla or any
                    successor provider to originate credit transfers to your
                    financial institution account by ACH and deduct the
                    transaction fees. Beneficiary, Organizer and Seller
                    acknowledge and agree the Popcorn World has no further
                    responsibility to how the funds are used after a Payout is
                    made to the Organizer. Any transfers of payments among
                    Organizers and Sellers are entirely the responsibility of
                    the Organizers and Sellers and is subject to their separate
                    agreement(s) with each other, to which Popcorn World is not a
                    party. For example, Organizers that receive payments on
                    behalf of Sellers are solely responsible for crediting such
                    funds to the Sellers. Popcorn World is not responsible or
                    liable for any such transfers or credits or lack thereof.
                    Popcorn World’s responsibility with respect to any Payout ends
                    with the payment of the Payout to either the applicable
                    Organizer.
                  </p>
                  <p>
                    <br />
                     iii. <u>Disputes</u>. If an Organizer or a Seller disputes
                    any payment of a Payout, limited to an Organizer’s claims
                    that: (1) a payment was owed but not paid, or : (2) a Payout
                    was not paid in full, the Organizer or Seller must notify
                    Popcorn World of the dispute in writing within six (6) months
                    of when the payment was made or allegedly should have been
                    made. Such notice must include identifying information of
                    the Organizer, Seller, Beneficiary, payment amount, payment
                    due date, and an explanation of any alleged discrepancies.
                    All such notices and potential resolutions of disputes
                    and/or claims will be subject the time limitations set forth
                    in paragraph 22 of the Terms and Conditions.
                  </p>
                  <p>
                    Except as otherwise provided in these Fundraising Policy,
                    Popcorn World will not intervene in or resolve any dispute
                    between Beneficiaries, Organizers, or Sellers related to
                    payments of proceeds generated on our platform. If you find
                    yourself in a dispute with a Beneficiary, Organizer, Seller
                    or other third party, we encourage you to engage with such
                    party to resolve the dispute. Except as otherwise provided
                    in these Fundraising Policy, Popcorn World will not assess the
                    merits factual disputes or legal issues or claims between
                    Beneficiaries, Organizers, or Sellers. Further, you release
                    Popcorn World from any claims, demands, and damages arising
                    out of disputes between Beneficiaries, Organizers, Seller or
                    any other third party relating to your use of our platform,
                    including those with other users or parties.
                  </p>
                  <p>
                    <br />
                     c. <u>Claw-backs</u>. Should any charge-backs, returns,
                    Buyer-side payment cancellations, disputed credit card /
                    debit card charges, voided transactions, insufficient
                    funds/bank-refusals to pay, or similar situations arise,
                    where we are not fully compensated for our products or
                    services (&quot;<u>Buyer Non-Payment</u>&quot;), we reserve
                    the right to treat such transaction as void and deduct any
                    uncollected amounts from either or both of the Seller’s
                    Store and the Organizer’s sales. In addition, if we have
                    transmitted funds to the Organizer or Seller in anticipation
                    of Buyer payment and the payment is a Buyer Non- Payment,
                    the Seller Account will reflect a negative balance to be
                    deducted from the Seller’s future sales.
                  </p>
                  <p>
                    <br />
                     d. <u>Buyer Returns</u>. All order cancellations must be
                    made by Buyers prior to shipment of the order and within
                    twenty-four (24) hours of making a purchase. If your product
                    arrived damaged or contaminated in any way, please contact
                    us directly at{" "}
                    <a href="mailto:help@doublegood.com" linktype="url">
                      help@doublegood.com
                    </a>
                    . All damaged goods or product quality claims must be made
                    within seven (7) days of delivery, otherwise we shall have
                    no obligations to refund purchase price or replace product.
                    EXCEPT AS SPECIFICALLY PROVIDED HEREIN, WE DO NOT ISSUE
                    REFUNDS AND WE DO NOT ACCEPT RETURNS.
                  </p>
                  <p>
                    e. <u>Time of Payouts for a campaign</u>. While Popcorn World
                    strives to make Payouts available to you promptly, you
                    acknowledge and agree that: (i) Payouts may not be available
                    to you for use immediately; (ii) Popcorn World does not
                    guarantee that Payouts will be available to you within any
                    specific time frame; and (iii) We may remit payment of the
                    Payout directly to the applicable Sellers at our discretion
                    if either the original or subsequent Organizer fails to
                    withdraw funds after a period of sixty (60) days, or
                    otherwise cannot or will not accept the payment, and (iv)
                    Popcorn World expressly disclaims any and all responsibility
                    for any delay in Payouts or your inability to access and use
                    Payouts at any specified time, and any consequences arising
                    from such delay or inability.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="MuiBox-root css-14kbwcq"
        id="government-fund-raising-and-charity-policy"
      >
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-fc4d70bd-2867-45a3-8d4d-6644d3664e4f"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>5. Government fund raising and charity policy</h3>
                  <p>
                    Our fundraising platform is a turn-key, one-size-fits-all
                    platform. The service is designed to provide fast and
                    efficient fundraising services to a wide range of
                    fund-raising organizers, whether they be private clubs,
                    schools, non-profits, local municipalities, or state or
                    federal government agencies and organizations. As a
                    one-size-fits-all platform, Popcorn World does not enter into
                    custom agreements outside of, or in addition to, our terms
                    of service with organizers. We take this position to avoid
                    the appearance of conflicts of interest and other ethical
                    concerns. As such, private entities such as businesses,
                    non-profit organizations, teams and clubs, and public
                    entities such schools and school districts local
                    municipalities or state or federal government agencies and
                    organizations are responsible to assess whether their
                    intended fundraising and or charitable activities comply
                    with all local, state, and federal laws, regulations and
                    rules. IN NO EVENT, SHALL Popcorn World BE RESPONSIBLE FOR AN
                    ORGANIZER’S REGULATORY OR COMPLIANCE OBLIGATIONS AS REQUIRED
                    BY LOCAL, STATE AND FEDERAL LAWS, REGULATIONS AND RULES.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="transaction-history">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-dd8022a0-f3e3-435c-9c64-806486cb8d24"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>6. Transaction history</h3>
                  <p>
                    Transactions and login sessions may be logged and tracked by
                    us and information about their transactions may appear
                    publicly in the &quot;Recent Buyers&quot; section or other
                    sections of{" "}
                    <a href="http://dgpopup.com" linktype="url">
                      dgpopup.com
                    </a>
                    . By using the Site, the Mobile Application, and/or the
                    Services, you consent to such tracking and public disclosure
                    of your use of the Site, Mobile Application, Services,
                    transactions and purchases.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="anti-bullying">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-62ab1539-9490-4916-b875-6feabf66821d"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>7. Anti-bullying</h3>
                  <p>
                    You agree not to use our Site, Mobile Application, and/or
                    Services (including, without limitation, the chat function)
                    to bully others. If you become aware of bullying, please
                    report it immediately to:{" "}
                    <a href="mailto:help@doublegood.com" linktype="url">
                      help@doublegood.com
                    </a>
                    . The best protection against bullying is to learn how to
                    recognize it and how to stop it. Here are some tips:
                    <br />
                    If you feel you’re in immediate danger, contact your local
                    authorities.
                    <br />
                    Reach out to a family member, counselor or teacher, who can
                    give you the help and support you need.
                    <br />
                    Print or take a screenshot of it in case you need to share
                    it later.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="site-restrictions">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-16f4311e-95cf-4c12-93ec-c039cda5aba3"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>8. Site restrictions</h3>
                  <p>
                    We may, in our sole discretion, without notice or liability,
                    suspend, terminate, limit, or otherwise restrict Organizer
                    accounts, Seller accounts, and/or Buyer accounts, as well as
                    the use of the Site, Mobile Application, and the Services
                    for any conduct we reasonably determine warrants such
                    suspension, termination or restrictions, including but not
                    limited to a violation of terms, illegal activity, damaging
                    or harassing conduct, or any other such user, Buyer, Seller,
                    or Organizer actions that cause difficulty or burden to
                    Popcorn World in rendering efficient and effective services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="communications">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-ed749201-8ac0-42d0-af88-79d0b266ce93"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>
                    9. <b>Electronic Communications Disclosures and Consent.</b>
                  </h3>
                  <p>
                    By providing Popcorn World with your mobile phone number,
                    using the Site, the Mobile Application, and/or Services and
                    by providing personal contact information to us, you
                    (whether a User, Buyer, Seller, Organizer or otherwise)
                    expressly consent to and authorize us to contact you and to
                    communicate with you via email, physical mail, text
                    messaging, phone calls and other means, no purchase required
                    / no purchase necessary. Further, when you provide Double
                    Good with mobile phone numbers from your contact lists or
                    community of prospective buyers, you represent and warrant
                    that you have the consent of the mobile phone number owner
                    to provide Popcorn World with that information. It is your
                    responsibility to update your personal contact information,
                    including if you change or deactivate your mobile phone
                    number. You may opt out of communications by contacting us
                    by email with &quot;opt out&quot; in the subject line, by
                    replying “STOP” to any text messages sent by Popcorn World, or
                    via settings within the Mobile Application. For additional
                    support, reply “HELP” to any text message. And for
                    additional details regarding your privacy choices, refer to
                    the Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="MuiBox-root css-14kbwcq" id="amendment">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
          <div className="MuiBox-root css-1tmono9">
            <div
              className="text-section MuiBox-root css-g8pswf"
              id="text-section-0a5a7548-63c9-4bb1-81d1-94630721dbd3"
            >
              <div className="MuiBox-root css-eg6041">
                <div className="MuiBox-root css-t2au9b">
                  <h3>
                    10. <b>Amendment or Changes to Terms and Conditions.</b>
                  </h3>
                  <p>
                    We reserve the right to modify, change, or add terms, or
                    features of the Mobile Application or Site at any time, and
                    at our sole discretion. Any additional terms that we may
                    provide to you will be incorporated by reference into these
                    Terms and will become effective from the date they are
                    posted on the Mobile Application or Site. To the extent any
                    additional terms conflict with these Terms, the additional
                    terms will control.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default TermsSection;
