import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Production from "./Production";
import Shipping from "./Shipping";
import Downtown from "./Downtown";

function Placetabs() {
  return (
    <div className="placetabs">
      <div className="container py-5">
        <div className="row">
          <Tabs
            defaultActiveKey="Production Facilities"
            id="uncontrolled-tab-example"
            className="mb-3 d-flex"
          >
            <Tab eventKey="Production Facilities" title="Production Facilities">
              <Production />
            </Tab>
            <Tab eventKey="Shipping Facilities" title="Shipping Facilities">
              <Shipping />
            </Tab>
            <Tab eventKey="Downtown Office" title="Downtown Office">
              <Downtown />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Placetabs;
