import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../Services/axios";
import { get_all_blogs } from "../../../Services/serviceVariables";

function Allblogs({ loader, setLoader }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("category");
  const [search, setSearch] = useState(null);
  const [order, setOrder] = useState("1");

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}` + `${get_all_blogs}`);
      try {
        const jsonData = await response.json();
        setCategory(jsonData.data.blog_categories);
        setData(jsonData.data.blogs);
        setOldData(jsonData.data.blogs);
        if (response) {
          setLoader(false);
        }
      } catch (error) { }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function convertDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    let newData;
    if (search === null) {
      newData = oldData?.filter(
        (item) =>
          item?.blog_categories[0]?.category.slug === e.target.value ||
          e.target.value === "category"
      );
    } else {
      const searchData = oldData?.filter((item) =>
        item?.title?.toLowerCase()?.includes(search?.toLowerCase())
      );
      newData = searchData?.filter(
        (item) =>
          item.blog_categories[0].category.slug === e.target.value ||
          e.target.value === "category"
      );
    }

    if (order === "2") {
      const reversedData = [...newData].reverse();
      setData(reversedData);
    } else {
      setData(newData);
    }
  };

  const handleNameFilter = (e) => {
    setSearch(e.target.value);
    const searchQuery = e.target.value.toLowerCase(); // Convert search query to lowercase for case-insensitive comparison
    let newData;
    if (category === "category") {
      newData = oldData.filter((item) =>
        item.title.toLowerCase().includes(searchQuery)
      );
    } else {
      const categoryData = oldData?.filter(
        (item) =>
          item?.blog_categories[0]?.category.slug === selectedCategory ||
          selectedCategory === "category"
      );
      newData = categoryData?.filter((item) =>
        item?.title?.toLowerCase()?.includes(searchQuery)
      );
    }
    if (order === "2") {
      const reversedData = [...newData].reverse();
      setData(reversedData);
    } else {
      setData(newData);
    }
  };
  const handleOrderChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue !== "") {
      setOrder(selectedValue);
      if (selectedValue === "2") {
        const reversedData = [...data].reverse();
        setData(reversedData);
      } else if (selectedValue === "1") {
        const reversedData = [...data].reverse();
        setData(reversedData);
      }
    }
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const truncatedText = text.substr(0, text.lastIndexOf(" ", maxLength));
    return truncatedText + ".";
  };

  return loader ? null : (
    <div className="allblog">
      <div className="container">
        <div className="row justify-content-between pb-5">
          <div className="col-md-4 mb-3">
            <div className="card py-2 px-4">
              <div className="d-flex gap-1">
                <CiSearch size={30} />
                <input
                  type="text"
                  className="w-100"
                  placeholder="Search"
                  onKeyUp={handleNameFilter}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex gap-4">
              <div class="input-group">
                <select
                  className="form-select w-100"
                  id="inputGroupSelect01"
                  aria-label="Example select with button addon"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option value="category">Select Category</option>
                  {category.map((cat, index) => (
                    <option key={index} value={cat.slug}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="input-group">
                <select
                  className="form-select w-100"
                  id="inputGroupSelect02"
                  aria-label="Example select with button addon"
                  onChange={handleOrderChange}
                >
                  <option value="" selected>
                    {" "}
                    Sort By
                  </option>
                  <option value="1">Latest</option>
                  <option value="2">Oldest</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {data?.map((blog) => (
            <div className="col-md-4 mb-3 blogcard" key={blog.id}>
              <div
                className="card" style={{ cursor: 'pointer' }}
                // onClick={() => navigate("/blog-open", { state: blog.slug })}
                onClick={() => navigate(`/blog/${blog?.slug}`, { state: blog.slug })}
              >
                <div className="card-body px-0 pt-0">
                  <div>
                    <img
                      src={`${baseURL}` + `/${blog.image}`}
                      className="img-fluid finger-img"
                    />
                  </div>
                  <div className="bg-dark camera py-2">
                    <div className="d-flex gap-2">
                      <span>Blog</span>
                    </div>
                  </div>
                  <div className="px-3 pt-3 learn">
                    <h4>{blog.title}</h4>
                  </div>
                  <div className="px-3">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: truncateText(blog.description, 80),
                      }}
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="d-good">Fundraisers</span>
                  </div>
                  <div>
                    <span className="date">{convertDate(blog.created_at)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data?.length === 0 && (
            <div className="text-center">
              <h3 clasName="text-center mx-4">No blogs found.</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Allblogs;
