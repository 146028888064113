import React, { useEffect, useState } from "react";

function GoodBack1() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="goodback1"
    >
      <img
        style={{
          width: `100%`,
        }}
        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/yum.jpg"}
        className="goodblack-img"
        alt="goodblack"
      />
    </div>
  );
}

export default GoodBack1;
