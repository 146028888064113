import React from "react";
import "./Home.css";
import Line from "../..//assets/images/line.webp";
// import MobilePicture from "../..//assets/images/mobile-white.webp";
// import Packet from "../..//assets/images/packet.webp";
import { Link } from "react-router-dom";

const PopcornProfit = () => {
  return (
    <>
      <section className="bg-primaryColor">
        <div className="container firstSectionContainer">
          <div className="row">
            <div
              className="col-md-7 col-lg-7 col-sm-12 pictureCol"
              style={{ position: "relative" }}
            >
              <div
                className=""
                style={{ marginTop: "10%" }}
              >
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/popcorn_money.png"
                  }
                  className="packetPicture"
                />
              </div>
            </div>
            <div className="col-lg-5 col-sm-12 textCol">
              <h1 className="heading poppin-bold  secondary_font">
                <span className="secondary_color">FUNDRAISING</span> HAS NEVER
                BEEN <span className="secondary_color">EASIER</span>
              </h1>
              <div>
                <p className="paragraph  pargraph-mb">
                  <b> Fundraising fees got you feeling salty? </b>
                  <span>
                    Virtual fundraising helps your team raise funds from
                    anywhere. Sell small-batch, gourmet popcorn and keep 50% of
                    all sales. No fundraising minimums or fees.
                  </span>
                  {/* <Link to={"/store-demo"}>
                  <div
                    className="get-started-home"
                    style={{
                      color: "white",
                    }}
                  >
                    
                    <span className="text-white">Get Started</span>
                  </div>
                </Link> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_PUBLIC_URL + "/assets/images/popcorn.jpg"
          })`,
          height: "80px",
          width: "100%",
          backgroundSize: "cover",
        }}
      ></div> */}
    </>
  );
};

export default PopcornProfit;
