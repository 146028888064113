import React from "react";

function Bags() {
  return (
    <div className="bags">
      <div className="container text-center">
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <div>
              <h2>The Popcorn World community has spread A LOT of joy</h2>
            </div>
            <div>
              <p>
                Since April 2020, Popcorn World supporters have brightened the
                days of many hometown heroes in their communities.
              </p>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-12">
            <div>
              <h1>5,500,000+</h1>
            </div>
            <p className="bag-donate">Bags donated by the Popcorn World Community</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/bag1.png"}
                  className="img-fluid"
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>5.1K+</h3>
              </div>
              <div>
                <p>Organizations</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/bag2.png"}
                  className="img-fluid "
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>2.3K+</h3>
              </div>
              <div>
                <p>Cities Reached</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/bag3.png"}
                  className="img-fluid "
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>5MM+</h3>
              </div>
              <div>
                <p>Bags of popcorn</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bags;
