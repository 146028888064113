import React from "react";

function About() {
  return (
    <div className="about">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-9 text-center">
            <span>ABOUT US</span>
            <div>
              <h1>DOING THE WORLD A FLAVOR </h1>
            </div>
            <div>
              <p>
                Popcorn World exists to support and create joy for America’s
                youth. Every fundraiser we host empowers kids to pursue their
                dreams, and 50% of every purchase made on our site goes to the
                Popcorn World Kids Foundation, which has been able to raise
                millions for children with special needs.
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
