import React from "react";
import { useNavigate } from "react-router-dom";

function Meet() {
  const navigate = useNavigate();
  return (
    <div className="meet">
      <div className="container py-5">
        <div className="row py-3 justify-content-center">
          <div className="col-md-8 text-center">
            <h1>Meet the folks who make Popcorn World so dang good</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 ">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/people.png"
              }
              className="img-fluid"
              alt="people"
            />
            <div>
              <h2>Our People</h2>
            </div>
            <div>
              <p>
                We encourage our employees to let their creativity and
                personalities shine through. We have regular team building
                activities, like bowling nights and volunteering opportunities,
                that help us build strong relationships. Every day at Double
                Good is a celebration!
              </p>
            </div>
            <div>
              <button className="btn" onClick={() => navigate("/our-people")}>
                Meet the Team
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/place.png"}
              className="img-fluid"
              alt="place"
            />
            <div>
              <h2>Our Places</h2>
            </div>
            <div>
              <p>
                We operate multiple manufacturing spaces and locations to
                produce our artisanal popcorn. These facilities are equipped
                with state-of-the-art equipment and staffed by a team of expert
                popcorn makers to ensure that each batch of Popcorn World popcorn
                is of the highest quality.
              </p>
            </div>
            <div>
              <button className="btn" onClick={() => navigate("/our-places")}>
                Explore Our Places
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meet;
