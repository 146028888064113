import React from 'react'
import Kidshero from '../../Components/Elements/Kids-Foundation/Kidshero.jsx'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Kidsbg from '../../Components/Elements/Kids-Foundation/Kidsbg.jsx'
import Foundationstarted from '../../Components/Elements/Kids-Foundation/Foundationstarted.jsx'
import Doublegood from '../../Components/Elements/Kids-Foundation/Doublegood.jsx'
import Buy from '../../Components/Elements/Kids-Foundation/Buy.jsx'
import Supporting from '../../Components/Elements/Kids-Foundation/Supporting.jsx'
import Popit from '../../Components/Elements/Kids-Foundation/Popit.jsx'
import TabTitle from '../../Services/TabTitle.js'
function kids() {
  TabTitle('Kids Foundation')
  return (
    <div>
      <Navbar bgColor={"white"} />
      <Kidshero />
      <Buy />
      <Kidsbg />
      <Foundationstarted />
      <Popit />
      <Doublegood />
      <Supporting />
      <Footer />
    </div>
  )
}

export default kids