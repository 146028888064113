import "./Home.css";

const Mission = () => {
  return (
    <section className="mission-spread-sec">
      <div className="container">
        <div className="mission-spread-tittle">
          <h2 className="secondary_font">We’re on a mission to spread joy</h2>
        </div>
        <div className="mission-spread-content">
          <div className="row">
            <div className="col-md-4">
              <div className="mission-spread-content-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/HomePages/mission-spread-img1.webp"
                  }
                />
              </div>
              <div className="mission-spread-text">
                <div className="mission-spread-content-hd">
                  Virtual Fundraising
                </div>
                <div className="mission-spread-content-sbhd">
                  Unlocking dreams through virtual fundraising.
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mission-spread-content-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/HomePages/mission-spread-img2.webp"
                  }
                />
              </div>
              <div className="mission-spread-text">
                <div className="mission-spread-content-hd">
                  Popcorn World Kids Foundation
                </div>
                <div className="mission-spread-content-sbhd">
                  Supporting the adaptive and inclusive community through our
                  Foundation.
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mission-spread-content-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/HomePages/mission-spread-img3.webp"
                  }
                />
              </div>
              <div className="mission-spread-text">
                <div className="mission-spread-content-hd">Premium Popcorn</div>
                <div className="mission-spread-content-sbhd">
                  Crafting award-winning popcorn to fuel the good we do in the
                  world.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
