import React from "react";
import TermsCondition from "../../Components/Elements/TermsCondition/Terms";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import TabTitle from "../../Services/TabTitle";

function Terms() {
  TabTitle("Terms & Conditions");
  return (
    <div>
      <Navbar bgColor={"#CCE5FF"} />
      <TermsCondition />
      <Footer />
    </div>
  );
}

export default Terms;
