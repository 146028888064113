import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
function Storefootertop() {
  return (
    <div className="store-footer-top">
      <div className="container py-3">
        <div className="row justify-content-between">
          <div className="col-md-4">
            <div className="d-flex gap-2 pt-2">
              <p>Need Help?</p>
              <a href="/">Contact Us</a>
            </div>
          </div>
          <div className="col-lg-2 text-end">
            <div className="d-flex gap-3">
              <div className="rounded-circle iconbg bg-dark p-2">
                <FaFacebookF size={35} color="white"/>
              </div>
              <div className="rounded-circle iconbg bg-dark p-2">
                <FaInstagram size={35} color="white"/>
              </div>
              <div className="rounded-circle iconbg bg-dark p-2">
                <FaXTwitter size={35} color="white"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Storefootertop;
