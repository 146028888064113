import React from "react";
import { APP } from "../../../Redux/ActionTypes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function Make({ openCart, linkCode }) {
  const dispatch = useDispatch();

  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  return (
    <div className="make">
      <div className="container py-5">
        <div className="row ">
          <div className="col-md-8">
            <div className="card p-4">
              <div className="row">
                <div className="col-md-8 ">
                  <div>
                    <span>ESSENTIAL WORKER DONATION PROGRAM</span>
                  </div>
                  <div>
                    <h3 className="secondary_font">Make a Popcorn Donation</h3>
                  </div>
                  <div>
                    <ul>
                      <li>Send DG popcorn to essential workers</li>
                      <li>50% of the sale benefits this fundraiser</li>
                      <li>No shipping cost</li>
                    </ul>
                  </div>
                  <div>
                    <h6>{base_currency}80</h6>
                  </div>
                  <div>
                    <button className="btn"
                      onClick={() => {
                        dispatch({ type: APP.ADD_CART_ITEM, payload: { link_code: linkCode, id: 0, type: "donation", price: `${base_currency}80`, image: "https://res.cloudinary.com/dg-prod/image/upload/s--5LI3h8xj--/w_500,h_392,f_auto,q_auto/v1/products/F-VIRTUAL/donation_checkout.png", quantity: 1, title: "Make a Donation" } });
                        openCart();
                      }}
                    >Add to Cart</button>
                  </div>
                </div>
                <div className="col-md-4 make-img">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Make;
