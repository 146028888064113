import React from 'react'
import './Loader.css';

const Loader = () => {
  return (
  <div className="loader-container">
    <div class="loader"></div>
  </div>
  )
}

export default Loader