import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Allflavours from "./Allflavours";
import Allcollection from "./Allcollection";
import { useSelector } from "react-redux";

function Collection({ collections, products, data, openCart, onHideCart }) {
  const cardArray = new Array(5).fill(null);
  const [activeTab, setActiveTab] = useState("collections");
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      // weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  return (
    <div className="collection">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <div className="pb-3">
              <h1>
                <span className="shop">Shop.</span>The most delicious way to
                support.
              </h1>
            </div>
            <div className="pb-3 shopby">
              <div className="d-flex gap-4">
                <div>
                  <h4>Shop By</h4>
                </div>
                <div>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key)}
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills" className="gap-2">
                          <Nav.Item>
                            <Nav.Link eventKey="product" className="secondary_font">All Flavours</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="collections" className="secondary_font">
                              Collections
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
            <div className="py-5">
              <Tab.Container
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key)}
              >
                <Row>
                  <Col md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="product">
                        <h1 className="flavours pb-5 secondary_font">All Flavours</h1>
                        <Allflavours
                          id={data.id}
                          linkCode={data?.link?.link_code}
                          products={products}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="collections">
                        <h1 className="flavours pb-5 secondary_font">Collections</h1>
                        <Allcollection
                          openCart={openCart}
                          onHideCart={onHideCart}
                          id={data.id}
                          linkCode={data?.link?.link_code}
                          collections={collections}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
          <div className="col-md-4">
            {/* <div>
              <div className="pb-3">
                <h2>Event Leaderboard</h2>
              </div>
              {cardArray.map((_, index) => (
                <div className="py-2" key={index}>
                  <div className="card event-card p-3 ">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex gap-2 justify-content-center align-items-center ">
                        <div>
                          <img src="/assets/images/event.png" alt="event" />
                        </div>
                        <div>
                          <span className="name">1. Savanah Parker</span>
                        </div>
                      </div>
                      <div>
                        <span className="price">{base_currency}701</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div className="py-5">
              <div className="pb-3">
                <h2 className="secondary_font">Recent Supporters {data?.supporters?.length > 0 ? `(${data?.supporters?.length})` : ''}</h2>
              </div>
              {data?.supporters?.length > 0 ?
                data?.supporters?.map((item, index) => (
                  <div className="py-2" key={index}>
                    <div className="card recent-card p-3 ">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2 justify-content-center align-items-center ">
                          <div>
                            <span className="name">{item?.customer?.name}</span>
                          </div>
                        </div>

                        <div>
                          <span className="price">
                            {base_currency}
                            {item.amount} {"⭐️"}
                          </span>
                        </div>
                      </div>
                      <div>
                        <h6>Way to go Kim!</h6>
                      </div>
                      <div>
                        <p>{formatDate(item.created_at)}</p>
                      </div>
                    </div>
                  </div>
                )) : <text>No Recent Supporter Yet</text>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collection;
