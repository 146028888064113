

export const APP = {
    'SHOP_PRODUCTS': 'SHOP_PRODUCTS',
    'SHOP_COLLECTIONS': 'SHOP_COLLECTIONS',
    'SHOP_CART_ITEMS': 'SHOP_CART_ITEMS',
    'ADD_CART_ITEM': 'ADD_CART_ITEM',
    'REMOVE_CART_ITEM': 'REMOVE_CART_ITEM',
    'INCREMENT_CART_QUANTITY': 'INCREMENT_CART_QUANTITY',
    'DECREMENT_CART_QUANTITY': 'DECREMENT_CART_QUANTITY',
    'GET_SHOP_CARTITEMS_PRICE': 'GET_SHOP_CARTITEMS_PRICE',
    'SET_CHECKOUT_INFORMATION': 'SET_CHECKOUT_INFORMATION',
    'CLEAR_CART_ITEMS': 'CLEAR_CART_ITEMS',
    'GLOBAL_SETTINGS': 'GLOBAL_SETTINGS',
    'GET_CAMPAIGNS': 'GET_CAMPAIGNS',
    'GET_USER': 'GET_USER',
    'SET_TOKEN_PHONENO': 'SET_TOKEN_PHONENO',
}