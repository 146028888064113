import React from "react";

function Donatehero() {
  return (
    <div className="donatehero">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-md-6 our-culture order-md-1 order-2">
            <span>DONATE JOY</span>
            <div>
              <h1>Donate popcorn. Make a double impact.</h1>
            </div>
            <div>
              <p>
                Whether contributing to a fundraiser or shopping directly on our
                site, Donate Joy™ allows you to buy our delicious, small batch
                popcorn for educators and healthcare workers who support
                America's youth.
              </p>
            </div>
            <div>
                {/* <button className="btn">Refer A Recipient</button> */}
            </div>
          </div>
          <div className="col-md-6 virtual-img order-md-2 order-1"></div>
        </div>
      </div>
    </div>
  );
}

export default Donatehero;
