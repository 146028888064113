import React from 'react'
import Getstarted from '../../Components/Elements/Get-Started/Getstarted'
import TabTitle from '../../Services/TabTitle'

function Get() {
  TabTitle('Get Started')
  return (
    <div>
      <Getstarted />
    </div>
  )
}

export default Get