// redux-persist-config.js
import storage from 'redux-persist/lib/storage'; // or 'redux-persist/session/storage' for sessionStorage
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  // You can whitelist or blacklist specific reducers if needed
  // whitelist: ['reducerName'],
  whitelist: ['app'],
};

export default (reducers) => persistReducer(persistConfig, reducers);
