import React from "react";

function Spread() {
  return (
    <div className="spread">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div>
              <h1>Donate Joy™ spreads joy to those who care for our youth</h1>
            </div>
            <div>
              <p>
                During the COVID-19 pandemic in 2020, educators and healthcare
                professionals worked unselfishly to care for America’s youth in
                extremely trying conditions.
              </p>
            </div>
            <div>
              <p>
                We didn’t want their sacrifice to go unrecognized and
                unrewarded. We figured that if Popcorn World exists to create joy
                for our kids, then why not extend the love to those who care for
                them?
              </p>
            </div>
            <div>
              <p>And so Donate Joy was born.</p>
            </div>
            <div>
              <p>
                Now, anyone can donate delicious Popcorn World popcorn to
                educators and healthcare professionals who support America’s
                youth.
              </p>
            </div>
            <div>
              <p>
                Buyers can share our delectable, small batch popcorn with these
                dedicated essential workers by adding a donation item to any
                purchase, whether supporting a live fundraiser or our Double
                Good Kids Foundation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spread;
