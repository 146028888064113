import React from "react";

function Kidshero() {
  return (
    <div className="kidshero">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-md-6 our-culture order-md-1 order-2">
            <span>Popcorn World KIDS FOUNDATION</span>
            <div>
              <h1>Get ready to feel warm and fuzzy inside</h1>
            </div>
            <div>
              <p>
                With the Popcorn World Kids Foundation, we’ve doubled down on our
                mission to support and create joy for America’s youth. Half of
                every purchase made directly on our site goes to the Foundation,
                which so far has been able to raise millions for kids with
                special needs.
              </p>
            </div>
          </div>
          <div className="col-md-6 virtual-img order-md-2 order-1"></div>
        </div>
      </div>
    </div>
  );
}

export default Kidshero;
