import React from 'react'

function Get() {
  return (
    <div className='get'>
          <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 text-center">
            <span>TOOLKITS</span>
            <div>
              <h1>Get the most out of your fundraiser</h1>
            </div>
            <div>
              <p>
              Run a great fundraiser with the help of our finger tips, downloadable tools,
and success stories
              </p>
            </div>
          </div>
        </div>
      </div>
        
    </div>
  )
}

export default Get