import React from "react";

function Doublegood() {
  return (
    <div className="doublegood">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-5 our-culture mb-4">
            <div>
              <h1>Popcorn World Days bring joy to life</h1>
            </div>
            <div>
              <p>
                Popcorn World Days is an important milestone in the steady growth
                of the Foundation. It’s an annual event where children of all
                abilities, along with their families and caregivers, can come
                together to experience joy with a purpose.
              </p>
            </div>
            <div>
              {/* <button className="btn rounded-5">Learn More</button> */}
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "assets/images/doublegood2.png"}
              className="img-fluid w-100 h-100"
              alt="walking"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Doublegood;
