import React from "react";
import "./Input.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Input = ({
  value,
  label,
  type,
  placeholder,
  disabled,
  className,
  style,
  fieldType,
  onBlur,
  error,
  name,
  onChange,
  onFocus,
  datatestid,
  onPaste
}) => {
  return (
    <div
      className={`form-floating mb-2 mt-2 ${className}`}
      style={{ position: "relative" }}
    >
      {fieldType == "textArea" ? (
        <>
          <textarea
            name={name}
            value={value}
            style={style}
            type={type}
            className="form-control"
            id="floatingInput"
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
          />
        </>
      ) : fieldType == "phone_number" ? (
        <>
          <PhoneInput
            country={"us"}
            name={name}
            style={style}
            type={type}
            className={`form-control ${className}`}
            id={"floatingInput"}
            placeholder={placeholder}
            value={value} // Use provided value or local state
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            data-testid={datatestid}
          />
        </>
      ) : (
        <>
          <input
            name={name}
            style={style}
            type={type}
            className="form-control"
            id="floatingInput"
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            data-testid={datatestid}
            onPaste={onPaste}
          />
        </>
      )}
      <label for="floatingInput">{label}</label>
      {error && <p className="text-danger px-1 mt-1">{error}</p>}
    </div>
  );
};

export default Input;
