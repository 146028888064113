import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import { baseURL } from "../../../Services/axios";
import { get_all_blogs } from "../../../Services/serviceVariables";
import { useNavigate } from "react-router-dom";

function Bloghero({ loader }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${baseURL}` + `${get_all_blogs}`);
      const jsonData = await response.json();
      setData(jsonData.data.blogs[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const truncatedText = text.substr(0, text.lastIndexOf(" ", maxLength));
    return truncatedText + ".";
  };

  function convertDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  return loader ? (
    <Loader />
  ) : (
    <div className="bloghero">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-10 text-center">
            <span>Blog</span>
            <div>
              <h1>
                <span className="secondary_color">Good</span> Stories.{" "}
                <span className="secondary_color">Big</span> Impact.
              </h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center blog1">
          <div className="col-md-12">
            <div
              className="card "
              style={{
                minHeight: "600px",
              }}
            >
              <div className="d-flex">
                <div className="card-body w-50">
                  <img
                    style={{
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                    src={`${baseURL}` + "/" + `${data.image}`}
                    className="img-fluid h-100 "
                  />
                </div>
                <div className="card-body px-0 pt-0 w-50">
                  <div className="bg-dark camera px-3 py-2">
                    <span>Blog</span>
                  </div>
                  <div className="feature">
                    <div className="px-3 pt-3 learn">
                      <span className="featured-blog">FEATURED BLOG POST</span>
                      <h2 className="w-75">{data.title}</h2>
                    </div>
                    <div className="px-3">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncateText(data.description, 80),
                        }}
                      />
                    </div>
                    <div className="footer">
                      <div>
                        <span className="d-good">Fundraisers</span>
                      </div>
                      <div>
                        <span className="date">
                          {convertDate(data.created_at)}
                        </span>
                      </div>
                    </div>
                    <div className="px-3">
                      <button
                        className="btn"
                        onClick={() =>
                          navigate(`/blog/${data?.slug}`, { state: data.slug })
                        }
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bloghero;
