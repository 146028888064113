import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Supporting() {
  const navigate = useNavigate();

  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  return (
    <div className="supporting">
      <div className="container py-5">
        <div className="row justify-content-center text-center">
          <div className="col-md-7">
            <div className="tips">
              <h1>Supporting a good cause never tasted so good</h1>
            </div>
            <div>
              <p className="sub-title">
                50% of your purchase through our site helps kids with special
                needs fulfill their athletic dreams.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card">
              <div className="card-body px-0 pt-0">
                <div>
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "/assets/images/support1.png"
                    }
                    className="img-fluid w-100 finger-img"
                    alt="finger1"
                  />
                </div>
                <div className="bg-dark camera py-2">
                  <div className="d-flex gap-2">
                    <span>SPICY + SAVORY</span>
                  </div>
                </div>
                <div className="card-content">
                  <div className="px-3 pt-3 learn d-flex justify-content-between">
                    <h4>In Queso Fire</h4>
                    <h6>{base_currency}12</h6>
                  </div>
                  <div className="px-3">
                    <p>
                      This cheddar and jalapeño recipe is built for the bold.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-footer pb-4">
                {/* <button className="btn">Add to Cart +</button> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <div className="card-body px-0 pt-0">
                <div>
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "/assets/images/support2.png"
                    }
                    className="img-fluid w-100 finger-img"
                    alt="finger1"
                  />
                </div>
                <div className="bg-dark camera py-2">
                  <div className="d-flex gap-2">
                    <span>SAVORY</span>
                  </div>
                </div>
                <div className="card-content">
                  <div className="px-3 pt-3 learn d-flex justify-content-between">
                    <h4>Easy Peasy Caramel Cheesy</h4>
                    <h6>{base_currency}14</h6>
                  </div>
                  <div className="px-3">
                    <p>A realm of cheddar-doused caramel glaze.</p>
                  </div>
                </div>
              </div>
              <div className="card-footer pb-4">
                {/* <button className="btn">Add to Cart +</button> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <div className="card-body px-0 pt-0">
                <div>
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "/assets/images/support3.png"
                    }
                    className="img-fluid w-100 finger-img"
                    alt="finger1"
                  />
                </div>
                <div className="bg-dark camera py-2">
                  <div className="d-flex gap-2">
                    <span>SAVORY</span>
                  </div>
                </div>
                <div className="card-content">
                  <div className="px-3 pt-3 learn d-flex justify-content-between">
                    <h4>My Main Cheese</h4>
                    <h6>{base_currency}12</h6>
                  </div>
                  <div className="px-3">
                    <p>
                      Drenched in a gooey, velvety blanket of cheesy goodness.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-footer pb-4">
                {/* <button className="btn">Add to Cart +</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center py-5">
          <div>
            <button className="btn2" onClick={() => navigate("/shop")}>
              Shop Popcorn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supporting;
