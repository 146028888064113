import "./Home.css";

const PopcornFlavors = () => {

    return (
        <section class="popcorn-flavors-cta bg-orange" style={{ backgroundColor: '#FFB600' }}>
            <div class="container">
                <div class="popcorn-flavors-cta-inn">
                    <div class="popcorn-flavors-cta-text">
                        <h2 class="popcorn-flavors-cta-heading secondary_font">
                            Popcorn flavors that sell themselves
                        </h2>
                        <div class="popcorn-flavors-cta-content">
                            Perfected over 25 years, all our flavors are popped fresh as
                            soon as the order is placed and shipped directly to taste buds
                            nationwide.
                        </div>
                        {/* <div class="popcorn-flavors-cta-btn">
                <a href="#">Explore Flavors</a>
              </div> */}
                    </div>
                </div>
            </div>
        </section>

    );
};

export default PopcornFlavors