import React from "react";
import { useNavigate } from "react-router-dom";

function Raisejoy() {
  const navigate = useNavigate();
  return (
    <div className="raisejoy">
      <div className="container py-5">
        <div className="row py-3 justify-content-center">
          <div className="col-md-8 text-center">
            <h1>HOW WE BE HAPPY</h1>
          </div>
        </div>
        <div className="row px-5">
          <div className="col-md-6 ">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/unlock.jpg"
              }
              className="img-fluid"
              alt="people"
            />
            <div>
              <h2>Unlocking dreams through fundraising</h2>
            </div>
            <div>
              <p>
                Our digital tools make it easy to organize a 4-day virtual
                fundraiser without ever handling paperwork, payments, or
                product.
              </p>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => navigate("/what-is-virtual-fundraising")}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/about_img_2.jpg"
              }
              className="img-fluid"
              alt="place"
            />
            <div>
              <h2>Supporting youth with disabilities through our Foundation</h2>
            </div>
            <div>
              <p>
                Half of every purchase made directly on our site goes to our
                Foundation which raises funds for kids with special needs.
              </p>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => navigate("/what-is-virtual-fundraising")}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/popping.jpg"
              }
              className="img-fluid"
              alt="place"
            />
            <div>
              <h2>Popping award-winning flavors with heart</h2>
            </div>
            <div>
              <p>
                Our award-winning popcorn fuels the good we do in the world.
              </p>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => navigate("/what-is-virtual-fundraising")}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Raisejoy;
