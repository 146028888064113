import React from "react";

function Askingquestion(props) {
  return (
    <div className="asking">
      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-md-7">
            <div>
              <h4>
                {props.heading}
              </h4>
            </div>
          </div>
        </div>
        <div className="row text-center justify-content-center">
          <div className="col-md-12">
            <div>
              <p>
                {props.paragraph}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Askingquestion;
