import React from "react";

function Production() {
  return (
    <div className="production">
      <div className="container-fluid px-0 pt-4">
        <div className="row justify-content-between">
          <div className="col-md-5  mb-3">
            <div>
              <h1>Production Facilities</h1>
            </div>
            <div>
              <p>
                Our popcorn recipe is made from a world of joy, a heart full of
                love, and a need for passion. We've got custom-built popcorn
                machines that would make Willy Wonka drool, and we're constantly
                keeping an eye on the process to make sure every kernel is
                popped to perfection.
              </p>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="d-flex gap-2">
                  <div className=" pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>3.8+ Billion</h1>
                    </div>
                    <div>
                      <span>
                        lbs of popcorn <br /> popped last year
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="d-flex gap-2">
                  <div className=" pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>15+ Billion</h1>
                    </div>
                    <div>
                      <span>bags were sold last year</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="d-flex gap-2">
                  <div className="pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>Top sellers</h1>
                    </div>
                    <div>
                      <span>
                        White Cheddar Go <br /> Getter, Butter Believe <br />{" "}
                        It, Easy Peasy <br /> Caramel Cheesy
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/p1.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/p2.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/p3.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Production;
