import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Askingquestion from "./Askingquestion";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    // slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    // slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
function Live() {
  const [selectedCard, setSelectedCard] = useState(0);
  const carouselRef = useRef(null);

  const handleCardClick = (cardIndex) => {
    setSelectedCard(cardIndex);
  };


  return (
    <div className="live">
      <div className="container ">
        <div className="row text-center">
          <div>
            <h1>We Live By Our Values</h1>
          </div>
        </div>
        <div className="row">
          <Carousel 
          // ref={carouselRef}
          responsive={responsive} 
          infinite={false}
           arrows={false}
          showDots={true}
          >
            <div
              className={` mb-3 card-deliver ${
                selectedCard === 0 ? "selected-card1" : ""
              }`}
              onClick={() => handleCardClick(0)}
            >
              <div className="card ">
                <div className="card-foot">
                  <h2>Deliver Excellence</h2>
                </div>
              </div>
            </div>
            <div
              className={` mb-3 green-card ${
                selectedCard === 1 ? "selected-card2" : ""
              }`}
              onClick={() => handleCardClick(1)}
            >
              <div className="card ">
                <div className="footer">
                  <h2>Own It</h2>
                </div>
              </div>
            </div>
            <div
              className={` mb-3 blue-card ${
                selectedCard === 2 ? "selected-card3" : ""
              }`}
              onClick={() => handleCardClick(2)}
            >
              <div className="card ">
                <div className="footer">
                  <h2>People Matter</h2>
                </div>
              </div>
            </div>
            <div
              className={` mb-3 pink-card ${
                selectedCard === 3 ? "selected-card4" : ""
              }`}
              onClick={() => handleCardClick(3)}
            >
              <div className="card ">
                <div className="footer">
                  <h2>Enjoy the Ride</h2>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="row text-center">
          {selectedCard === 0 && (
            <div className="h1">
              {" "}
              <Askingquestion
                heading="Asking questions. Raising the bar. Improving our communities."
                paragraph="We are driven by an unwavering commitment to create a better
                future. We question processes to find better ways and actively
                collaborate to raise the bar of what's possible. We believe in
                quality, consistency, and improvement make a lasting impact in
                the world."
              />{" "}
            </div>
          )}
          {selectedCard === 1 && (
            <div className="h2">
              <Askingquestion
                heading="Care courageously. Persist passionately.
Get it done."
                paragraph="We go above and beyond to achieve our goals while upholding ethical and moral standards. We fearlessly care, knowing that true impact requires vulnerability and commitment, uniting us in a shared purpose."
              />{" "}
            </div>
          )}
          {selectedCard === 2 && (
            <div className="h3">
              <Askingquestion
                heading="Build bridges. Encourage others.
Strive for excellence."
                paragraph="By caring for our team members, we empower them to care for one another. Through fostering relationships based on respect and trust, we actively encourage everyone to reach their full potential."
              />{" "}
            </div>
          )}
          {selectedCard === 3 && (
            <div className="h4">
              <Askingquestion
                heading="Elevating moments.
Uplifting stories."
                paragraph="We believe in relishing life's adventures and making every moment count. We find joy in taking your enjoyment to new heights, by consistently showing appreciation and giving recognition to those who make us smile."
              />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Live;
