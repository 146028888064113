import React from "react";

function Foundationstarted() {
  return (
    <div className="foundation">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div>
              <h1>How the Popcorn World Kids Foundation started</h1>
            </div>
            <div>
              <p>
                In 2017, Popcorn World Founder and CEO Tim Heitmann’s commitment
                to helping kids took on additional meaning when he and his wife,
                Natalie, founded the Popcorn World Kids Foundation, a non-profit
                organization dedicated to reaching children with special needs
                and bringing them joy through fun, interactive experiences.
              </p>
            </div>
            <div>
              <p>
                Throughout the years, the Foundation has expanded its reach by
                collaborating with like-minded organizations in order to make a
                positive impact on children with physical, neurological, and
                social barriers.
              </p>
            </div>
            <div>
              <p>
                This steady trajectory led to an important milestone in the
                growth of the Popcorn World Kids Foundation, the advent of Double
                Good Days, an annual event for children with special needs and
                their families and caregivers, which premiered on October 1st,
                2023 at the Kohl Children’s Museum and is set to expand into
                more annual events and locations in the upcoming years.
              </p>
            </div>
            <div>
                <h5>
                50% of every purchase from our website goes directly to our
                Popcorn World Kids Foundation.
                </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Foundationstarted;
