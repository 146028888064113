import { combineReducers } from "redux";
import AppReducer from "./Reducers/index";
import DataReducer from "./Reducers/data";
import UserReducer from "./Reducers/user";

const Reducers = combineReducers({
  app: AppReducer,
  data: DataReducer,
  user: UserReducer,
});

export default Reducers;
