import "./Home.css";
import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const DollarSupport = () => {
  const carouselRef = useRef(null);

  const goToPrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const goToNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <section
      class="dollar-supports-sec bg-yt"
      style={{ backgroundColor: "#FFEEB2" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="dollar-supports-number-text">
              <p>
                <span class="dollar-supports-number secondary_color secondary_font ">
                  50
                </span>
                <span className="secondary_color secondary_font">%</span>
              </p>
            </div>
            <div class="dollar-supports-tittle">
              <h2 className="">of every dollar supports your cause</h2>
            </div>
            <div class="dollar-supports-sbtittle " style={{ color: "black" }}>
              There are no set-up fees. No product handling fees. No extra fees
              ever. For every dollar you sell through the Popcorn World app, you
              keep 50%.
            </div>
            <div class="dollar-supports-buttons">
              <a href="/store-demo" class="btn btn-lg get-start">
                Get Started
              </a>
              <a
                href="/what-is-virtual-fundraising"
                class="btn btn-lg learn-more"
              >
                Learn More
              </a>
            </div>
          </div>
          <div class="col-md-7 dollar-supports-slider">
            <div class="swiper mySwiper px-3">
              <div class="swiper-wrapper" style={{ paddingBottom: 10 }}>
                <Carousel
                  responsive={responsive}
                  ref={carouselRef}
                  // autoPlay="false"
                  interval={2000}
                  infinite="true"
                  arrows={false}
                >
                  <div
                    class="swiper-slide slide_1"
                    style={{
                      borderWidth: 1,
                      maxHeight: 486,
                      borderRadius: 20,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/dollar-supports-swiper1.webp"
                      }
                      alt=""
                    />
                  </div>
                  <div class="swiper-slide slide_2">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/dollar-supports-swiper2.webp"
                      }
                      alt=""
                    />
                  </div>
                  <div class="swiper-slide slide_3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/dollar-supports-swiper3.webp"
                      }
                      alt=""
                    />
                  </div>
                  <div class="swiper-slide slide_4">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/dollar-supports-swiper4.webp"
                      }
                      alt=""
                    />
                  </div>
                  <div class="swiper-slide slide_5">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/dollar-supports-swiper5.webp"
                      }
                      alt=""
                    />
                  </div>
                  <div class="swiper-slide slide_6">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/dollar-supports-swiper6.webp"
                      }
                      alt=""
                    />
                  </div>
                </Carousel>
              </div>
              <p class="ch-text">Giana raised $2,685 for her team.</p>
              <div className="row justify-content-center align-items-center">
                <div className="btnArrow pb-1" onClick={goToPrevSlide}>
                  <svg
                    class=""
                    width="1.5rem"
                    height="1.5rem"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M496.64 173.568c-6.656 2.261-28.16 23.083-162.603 157.312-112.171 111.957-155.947 156.757-158.549 162.176-2.828 5.59-4.484 12.187-4.484 19.171 0 4.895 0.814 9.6 2.313 13.987l-0.090-0.304c2.816 8.661 10.539 16.725 157.653 164.053 111.957 112.171 156.757 155.947 162.176 158.549 5.522 2.85 12.051 4.521 18.971 4.521 11.663 0 22.217-4.746 29.837-12.412l0.002-0.002c11.904-11.904 15.531-27.947 10.027-44.587-2.517-7.637-13.312-18.987-118.997-124.885l-116.267-116.48h506.624l7.339-3.584c24.875-11.989 30.421-50.261 10.24-70.443-3.906-3.948-8.628-7.080-13.894-9.123l-0.271-0.093c-5.419-1.621-64.469-2.091-258.517-2.091h-251.52l116.267-116.48c105.685-105.899 116.48-117.248 118.997-124.885 5.504-16.64 1.877-32.683-9.984-44.544-7.765-7.75-18.484-12.543-30.322-12.543-5.368 0-10.506 0.985-15.242 2.785l0.295-0.098z"
                      style={{ fill: "white" }}
                    ></path>
                  </svg>
                </div>
                <div
                  className="btnArrow pb-1 "
                  style={{ marginLeft: 18 }}
                  onClick={goToNextSlide}
                >
                  <svg
                    width="1.5rem"
                    height="1.5rem"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M496.768 173.611c-15.019 5.291-27.435 23.253-27.435 39.723 0 3.413 1.237 9.984 2.773 14.635 2.517 7.637 13.312 18.987 118.997 124.885l116.267 116.48h-251.52c-194.048 0-253.099 0.469-258.517 2.091-14.72 4.395-26.667 22.613-26.667 40.576 0 16.469 9.429 32.64 22.741 39.083l7.339 3.584h506.624l-116.267 116.48c-105.685 105.899-116.48 117.248-118.997 124.885-5.504 16.64-1.877 32.683 10.027 44.587 7.622 7.668 18.176 12.414 29.839 12.414 6.92 0 13.45-1.671 19.208-4.631l-0.236 0.11c5.419-2.603 50.219-46.379 162.176-158.549 147.115-147.328 154.837-155.392 157.653-164.053 1.409-4.083 2.223-8.788 2.223-13.683 0-6.984-1.656-13.58-4.597-19.419l0.113 0.248c-2.603-5.419-46.379-50.176-158.549-162.176-141.696-141.483-155.648-154.923-163.328-157.44-4.377-1.59-9.429-2.509-14.695-2.509-5.445 0-10.66 0.982-15.478 2.78l0.306-0.1z"
                      style={{ fill: "white" }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DollarSupport;
