import "./Home.css";

const Whoa = () => {
  return (
    <section class="Whoa-look">
      <div class="Whoa-look-tittle">
        <h2 className="secondary_font">Whoa, look who's raving about us!</h2>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="Whoa-look-inn">
              <div class="Whoa-look-inn-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/Whoa-look-img1.svg"
                  }
                  alt=""
                />
              </div>
              <h3 class="Whoa-look-inn-heading">
                “Of all the popcorn we tried, Popcorn World's In Queso Fire was a
                near-unanimous favorite.”
              </h3>
              <div class="Whoa-look-inn-text">Food & Wine Magazine</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="Whoa-look-inn">
              <div class="Whoa-look-inn-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/Whoa-look-img2.svg"
                  }
                  alt=""
                />
              </div>
              <h3 class="Whoa-look-inn-heading">“Popcorn with a purpose.”</h3>
              <div class="Whoa-look-inn-text">Today Show</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="Whoa-look-inn">
              <div class="Whoa-look-inn-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/Whoa-look-img3.svg"
                  }
                  alt=""
                />
              </div>
              <h3 class="Whoa-look-inn-heading">
                “The most awesome snack we've ever tried.”
              </h3>
              <div class="Whoa-look-inn-text">Chicago Tribune</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whoa;
