import React from "react";

function Placehero() {
  return (
    <div className="place">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-md-5 our-culture order-md-1 order-2">
            <span>OUR PLACES</span>
            <div>
              <h1>We'd love to show you around</h1>
            </div>
            <div>
              <p>
                Where work and impact come together to build joy. Discover how
                the magic is made and how we craft joy in our state-of-the-art
                facilities.
              </p>
            </div>
          </div>
          <div className="col-md-6 love order-md-2 order-1">
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Placehero;
