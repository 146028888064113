import React, { useEffect, useState } from "react";
import "./user.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { getCustomerProfile, updateCustomerProfile } from "../../Services";
import { toast } from "react-toastify";
import avatar from "../../assets/images/user_ava.png";
import Loader from "../../Components/Loader/Loader";
import Input from "../../Components/GenericComponents/Input/Input";
import { CartButton } from "../../Components/ShopCard";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../Services/axios";
import { Spinner } from "../Checkout/Checkout";
import { MdEditSquare } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getUser } from "../../Redux/Actions/user";
import { APP } from "../../Redux/ActionTypes";
import TabTitle from "../../Services/TabTitle";

const Profile = () => {
  TabTitle("Profile");
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [state, setState] = useState({
    name: null,
    email: null,
    phone_number: null,
    profile_img: null,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem("user_token");
  const phoneNo = localStorage.getItem("phone_number");

  const getProfile = async () => {
    const payload = {
      params: {
        phone_number: `${phoneNo}`,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await getCustomerProfile(payload);
    setLoading(false);
    if (response.data.data) {
      const data = response.data.data;
      setProfileData(data);
      console.log("data.........", data);
      localStorage.setItem("profileData", data?.profile_img);
      // dispatch(getUser(data));
      // dispatch({ type: APP.GET_USER, payload: data });
      const formattedPhoneNumber = data?.phone_no
        .replace(/^\+1/, "")
        .replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
      setState({
        name: data?.name,
        email: data?.email,
        phone_number: formattedPhoneNumber,
        simple_number: data?.phone_no,
        profile_img: data?.profile_img,
      });
    } else {
      // toast.error("Profile not exists!");
    }
  };
  const updateProfile = async () => {
    const newNum = state.phone_number
      .split("-")
      .filter((a) => a.trim().length)
      .join("");

    if (!state.name.trim()) {
      setErrors((prev) => ({
        ...prev,
        ["name"]: "Name can't be empty",
      }));
      return;
    }
    if (!selectedImage) {
      if (
        state.name === profileData.name &&
        state.email === profileData.email &&
        `+1${newNum}` === state.simple_number
      ) {
        setErrors((prev) => ({
          ...prev,
          ["same"]: "At least one field must be different",
        }));
        return;
      }
    }

    setUpdateLoading(true);

    const payload = new FormData();
    payload.append("name", state.name);
    payload.append("old_phone_number", `${phoneNo}`);
    payload.append("new_phone_number", `+1${newNum}`);
    payload.append("email", state.email);
    if (selectedImage) {
      payload.append("user_profile", selectedImage);
    }

    const response = await updateCustomerProfile(payload);
    setUpdateLoading(false);
    if (response?.data?.data) {
      const data = response?.data?.data;
      localStorage.setItem("userData", data);
      localStorage.setItem("phone_number", data?.phone_no);
      setProfileData(data);
      const formattedPhoneNumber = data?.phone_no
        ?.replace(/^\+1/, "")
        .replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
      setState({
        name: data?.name,
        email: data?.email,
        phone_number: formattedPhoneNumber,
        profile_img: data?.profile_img,
      });
      setCanEdit(false);
      getProfile();
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/;
    return phoneNumberPattern.test(number);
  };

  const emailValidation = (name, value, returnError) => {
    if (value) {
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Invalid email address",
          ["same"]: "",
        }));
        if (returnError) {
          return true;
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
          ["same"]: "",
        }));
        if (returnError) {
          return false;
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "phone_number") {
      if (value?.length === 4 && value.slice(3, 4) !== "-") {
        setState((prev) => ({
          ...prev,
          [name]: value.slice(0, 3) + "-" + value.slice(3),
        }));
      } else if (value?.length === 8 && value.slice(7, 8) !== "-") {
        setState((prev) => ({
          ...prev,
          [name]: value.slice(0, 7) + "-" + value.slice(7),
        }));
      } else {
        if (value?.length <= 12) {
          setState((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      }

      if (!validatePhoneNumber(value) && value?.length <= 12) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Invalid phone number format",
          ["same"]: "",
        }));
      }
      if (validatePhoneNumber(value) && value?.length <= 12) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
          ["same"]: "",
        }));
      }
    } else if (name == "email") {
      emailValidation(name, value);
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
        ["same"]: "",
      }));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("user_token");
    if (token) {
      getProfile();
    } else {
      navigate("/signin");
    }
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const handleFileChange = (event) => {
    setSelectedImage(event.target.files[0]);
    setImageUrl(URL.createObjectURL(event.target.files[0]));
    setErrors((prev) => ({
      ...prev,
      ["same"]: "",
    }));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />

          <div className="profile-page">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 col-md-10 offset-lg-3 offset-md-1"
                  style={{ borderWidth: 2 }}
                >
                  <div className="email-div">
                    <div
                      className="edit"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <MdEditSquare
                        size={28}
                        onClick={() => setCanEdit(!canEdit)}
                      />
                    </div>

                    <div className="header">
                      <label
                        for="img"
                        className="custom-file-upload"
                        style={{ display: "inline-block" }}
                      >
                        <img
                          src={
                            selectedImage
                              ? imageUrl
                              : state?.profile_img
                              ? `${baseURL}/${state?.profile_img}`
                              : avatar
                          }
                          width={"150px"}
                          alt="user-avatar"
                        />
                        {/* <RiImageEditLine /> */}
                        {/* <div>{imagePath && <p>Image Path: {imagePath}</p>}</div> */}
                      </label>
                      <input
                        type="file"
                        id="img"
                        name="img"
                        disabled={canEdit ? false : true}
                        onChange={handleFileChange}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <h1>{state?.name}</h1>
                    </div>
                    <Input
                      label="Name*"
                      placeholder="Name*"
                      name="name"
                      value={state.name}
                      onChange={handleChange}
                      disabled={canEdit ? false : true}
                      error={errors.name}
                    />

                    <Input
                      label="Email Address*"
                      placeholder="Email Address*"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                      error={errors.email}
                      disabled={canEdit ? false : true}
                    />

                    <Input
                      type="text"
                      label="Phone Number*"
                      placeholder="Phone Number*"
                      name="phone_number"
                      value={state.phone_number}
                      onChange={handleChange}
                      disabled={canEdit ? false : true}
                      error={errors?.phone_number}
                    />
                    {errors?.same && (
                      <p style={{ color: "red" }}> {errors?.same}</p>
                    )}

                    {canEdit && (
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {updateLoading ? (
                          <Spinner className="px-3 mt-2 d-flex justify-content-end" />
                        ) : (
                          <CartButton
                            disabled={
                              // state.verifyLoader ||
                              !state.phone_number ||
                              errors.email ||
                              errors?.same ||
                              // !state.country_code ||
                              !validatePhoneNumber(state.phone_number)
                            }
                            title={"Update"}
                            onClick={updateProfile}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default Profile;
