import React from 'react'

function Need() {
  return (
    <div className='need'>
      <div className="container-fluid py-5">
        <div className="row justify-content-center text-center">
          <div className="col-md-8 ">
            <div>
              <h1>Need some more help? Visit our Help Center.</h1>
            </div>
            <div>
              {/* <button className='btn'>Take Me There</button> */}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Need