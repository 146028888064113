import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { MdOutlineArrowForward } from "react-icons/md";
import { MdOutlineArrowBack } from "react-icons/md";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Peoplefeedback() {
  const carouselRef = useRef(null);

  const goToPrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const goToNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
    <div className="peoplefeedback">
      <div className="container ">
        <div className="row justify-content-center text-center">
          <div className="col-md-7 mb-3">
            <h1>Meet our joy-makers and double do-gooders</h1>
          </div>
        </div>
        <div className="row  carousal-row pb-5">
          {/* <div className="col-md-10"> */}
          <div className=" carousal-container">
            <Carousel
              responsive={responsive}
              ref={carouselRef}
              autoPlay={false}
              interval={2000}
              infinite="true"
              arrows={false}
            >
              <div className="row justify-content-around client-row">
                <div className="col-md-4 order-md-1 order-2">
                  <div className="d-flex client ">
                    <div className="client-img ">
                      <div>
                        <BiSolidQuoteAltRight
                          className="arrowicon"
                          size={90}
                          color="#0941e5"
                        />
                      </div>
                      <div className="text-start ">
                        <div className="client-feedback">
                          <h3>
                            What is it about Popcorn World that’s just different?
                          </h3>
                          <h4>
                            “When you have this many smart and talented people
                            working together towards a common goal, you truly
                            feel like you can accomplish anything.”
                          </h4>
                        </div>
                      </div>
                      <div className="client-name">
                        <div>
                          <span>Alexa D.</span>
                        </div>
                        <div>
                          <p>Event Coordinator</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 order-md-2 order-1">
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "/assets/images/client.png"
                    }
                    className="img-fluid h-100  client-feedback-image"
                    alt="client"
                  />
                </div>
              </div>
              <div className="row justify-content-around client-row">
                <div className="col-md-4 order-md-1 order-2">
                  <div className="d-flex client ">
                    <div className="client-img ">
                      <div>
                        <BiSolidQuoteAltRight
                          className="arrowicon"
                          size={90}
                          color="#0941e5"
                        />
                      </div>
                      <div className="text-start ">
                        <div className="client-feedback">
                          <h3>
                            What is it about Popcorn World that’s just different?
                          </h3>
                          <h4>
                            “When you have this many smart and talented people
                            working together towards a common goal, you truly
                            feel like you can accomplish anything.”
                          </h4>
                        </div>
                      </div>
                      <div className="client-name">
                        <div>
                          <span>Alexa D.</span>
                        </div>
                        <div>
                          <p>Event Coordinator</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 order-md-2 order-1">
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "/assets/images/client.png"
                    }
                    className="img-fluid h-100  client-feedback-image"
                    alt="client"
                  />
                </div>
              </div>
              <div className="row justify-content-around client-row">
                <div className="col-md-4 order-md-1 order-2">
                  <div className="d-flex client ">
                    <div className="client-img ">
                      <div>
                        <BiSolidQuoteAltRight
                          className="arrowicon"
                          size={90}
                          color="#0941e5"
                        />
                      </div>
                      <div className="text-start ">
                        <div className="client-feedback">
                          <h3>
                            What is it about Popcorn World that’s just different?
                          </h3>
                          <h4>
                            “When you have this many smart and talented people
                            working together towards a common goal, you truly
                            feel like you can accomplish anything.”
                          </h4>
                        </div>
                      </div>
                      <div className="client-name">
                        <div>
                          <span>Alexa D.</span>
                        </div>
                        <div>
                          <p>Event Coordinator</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 order-md-2 order-1">
                  <img
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "/assets/images/client.png"
                    }
                    className="img-fluid h-100  client-feedback-image"
                    alt="client"
                  />
                </div>
              </div>
            </Carousel>
          </div>
          <div className="arrow-navigation gap-2">
            <div>
              <div className="arrow-left pb-1" onClick={goToPrevSlide}>
                <MdOutlineArrowBack size={"30px"} className="pt-1 arrow" />
              </div>
            </div>
            <div>
              <div className="arrow-right pb-1" onClick={goToNextSlide}>
                <MdOutlineArrowForward size={"30px"} className="pt-1 arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Peoplefeedback;
