import "./Home.css";

const VirtualFundraising = () => {
  return (
    <section
      class="virtual-fundraising-sec bg-yt"
      style={{ backgroundColor: "#FFEEB2" }}
    >
      <div class="container">
        <div class="virtual-fundraising-tittle">
          <h2 className="secondary_font">
            Fundraising made virtual is <br />
            fundraising made easy
          </h2>
        </div>
        <div class="virtual-fundraising-content">
          <div class="row">
            <div class="col-md-4">
              <div class="virtual-fundraising-content-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/virtual-fundraising-img1.webp"
                  }
                  alt=""
                />
              </div>
              <div class="virtual-fundraising-text">
                <div class="virtual-fundraising-content-hd">
                  100% virtual, 4-day fundraiser
                </div>
                <div class="virtual-fundraising-content-sbhd">
                  Our tested 4-day fundraisers maximize engagement and let you
                  fundraise from anywhere.
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="virtual-fundraising-content-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/virtual-fundraising-img2.webp"
                  }
                  alt=""
                />
              </div>
              <div class="virtual-fundraising-text">
                <div class="virtual-fundraising-content-hd">
                  Shipped directly to your supporters
                </div>
                <div class="virtual-fundraising-content-sbhd">
                  Never distribute product again.
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="virtual-fundraising-content-img">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "/assets/images/virtual-fundraising-img3.webp"
                  }
                  alt=""
                />
              </div>
              <div class="virtual-fundraising-text">
                <div class="virtual-fundraising-content-hd">
                  No fees or minimums, ever
                </div>
                <div class="virtual-fundraising-content-sbhd">
                  Keep 50% of what you sell, with payments sent directly to your
                  bank.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VirtualFundraising;
