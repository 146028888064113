import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { CiCalendarDate } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import logo from "../../..//assets/images/fundraiserLogo.jpeg";
import { Spinner, TickCheck } from "../../../Pages/Checkout/Checkout";
import { CartButton } from "../../ShopCard";
import Navbar from "../../Navbar/Navbar";
import {
  checkEmailAlreadyExists,
  verifyDetail,
  verifyOtpCode,
} from "../../../Services";
import { toast } from "react-toastify";
import { baseURL } from "../../../Services/axios";
import axios from "axios";
import { useSelector } from "react-redux";

function Getstarted() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [organId, setOrganId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [organizationsInput, setOrganizationsInput] = useState("");
  const [organizationsType, setOrganizationsType] = useState("");
  const [organizationsType2, setOrganizationsType2] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [selectedMembersCount, setSelectedMembersCount] = useState("");
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const isLoggedIn = localStorage.getItem("user_token");

  console.log("data", data);
  console.log("organizationsType", organizationsType);
  console.log("organId", organId);
  console.log("data[organId]", data[organId]);
  const [state, setState] = useState({
    estimatedEarnings: false,
    setUpAccount: false,
    first_name: "",
    last_name: "",
    mobile_number: "",
    accept: false,
    verifyLoader: false,
    optCodeSend: false,
    otp: "",
    verifyOtpLoader: false,
    success: false,
    email_error: null,
    mobile_error: null,
    country_code: "+1",
  });

  const [pickDate, setPickDate] = useState(true);
  const [show, setShow] = useState(false);
  const [secondDropdown, setShowSecondDropdown] = useState(false);
  const [organizationInput, setOrganizationInput] = useState(false);
  const [zipCode, setZipCode] = useState(false);
  const [started, setStarted] = useState(true);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [error, setError] = useState(false);
  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setSelectedMembersCount(buttonId);
    setShow(true);
  };
  const handleButtonClick2 = (buttonId) => {
    setActiveButton(buttonId);
    setSelectedStartTime(buttonId);
    setShow(true);
  };
  const handleSpecificData = () => {
    setPickDate(!pickDate);
  };
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    const { name, value } = e.target;
    const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegx.test(value)) {
      if (email && email.length > 3) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
    if (name && name.length > 3) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const handleOrganization = (e) => {
    const selectedID = e.target.value;
    // console.log(selectedID)
    setOrganId(selectedID);
    setOrganizationsType(selectedID);
    setShowSecondDropdown(true);
  };

  const handleOrganization2 = (e) => {
    setOrganizationsType2(e.target.value);
    setOrganizationInput(true);
  };
  const handleOrganizationInput = (e) => {
    setOrganizationsInput(e.target.value);
    setZipCode(true);
  };
  const handleZipCode = (e) => {
    const zipCodeValue = e.target.value;
    const numericInput = zipCodeValue.replace(/[^0-9]/g, "");
    setCode(numericInput);
    setShow(true);
  };
  const handleContinue = async () => {
    setState((prev) => ({
      ...prev,
      email_error: null,
    }));
    const payload = {
      email_address: email,
      user_type: "fundraiser",
    };
    const response = await checkEmailAlreadyExists(payload);
    if (response.data.status == 200) {
      setStarted(false);
      setStep1(true);
      setShow(false);
    }
    if (response.data.status == 400) {
      setState((prev) => ({
        ...prev,
        email_error: "The email address has already been registered.",
      }));
    }
    console.log("resposne : 400 : ", response);
  };
  const teamDetail = () => {
    setStep1(false);
    setStep2(true);
    setShow(false);
  };
  const fundraiserDetail = () => {
    setStep1(false);
    setStep2(false);
    setStep3(true);
    setShow(false);
  };
  const handleParticipants = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(true);
    setShow(false);
  };

  const handleNextStep4 = () => {
    setStep4(false);
    setState((prev) => ({
      ...prev,
      estimatedEarnings: true,
    }));
  };

  const validatePhoneNumber = (number) => {
    const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/;

    return phoneNumberPattern.test(number);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "otp") {
      setError(false);
      if (value?.length < 6) {
        setState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (name == "mobile_number") {
      let input = value.replace(/\D/g, "");
      let formattedNumber = "";

      if (input.length > 10) {
        input = input.slice(0, 10);
      }

      if (input.length > 3) {
        formattedNumber += input.slice(0, 3) + "-";
        input = input.slice(3);
      }
      if (input.length > 3) {
        formattedNumber += input.slice(0, 3) + "-";
        input = input.slice(3);
      }
      formattedNumber += input;
      setState((prev) => ({
        ...prev,
        [name]: formattedNumber,
      }));

      if (
        !validatePhoneNumber(formattedNumber) &&
        formattedNumber?.length <= 12
      ) {
        setState((prev) => ({
          ...prev,
          mobile_error: "Invalid phone number format",
        }));
      }
      if (
        validatePhoneNumber(formattedNumber) &&
        formattedNumber?.length <= 12
      ) {
        setState((prev) => ({
          ...prev,
          mobile_error: "",
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleNext = async () => {
    try {
      // const response = await fetch('https://alphadevelopers.co.uk/api/fundraiser-register', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     fundraiser_name: state.first_name + state.last_name ,
      //     email_address: email,
      //     team_name: name,
      //     organization_name: organizationInput,
      //     organization_type_id: organizationsType,
      //     organization_sub_type_id: organizationsType2,
      //     phone_no: state?.mobile_number,
      //     zip_code: code,
      //     fundraising_start_time: selectedStartTime,
      //     members_count: selectedMembersCount,

      //   }),
      // });

      const obj = {
        fundraiser_name: state.first_name + state.last_name,
        email_address: email,
        team_name: name,
        organization_name: organizationInput,
        organization_type_id: organizationsType,
        organization_sub_type_id: organizationsType2,
        phone_no: `${state.country_code}${state?.mobile_number
          ?.split("-")
          .filter((a) => a.trim().length)
          .join("")}`,
        zip_code: code,
        fundraising_start_time: selectedStartTime,
        members_count: selectedMembersCount,
        status: 1,
      };

      const response = await axios.post(
        `${baseURL}/api/fundraiser-register`,
        obj
      );

      console.log("response : ", response);
      if (response?.data?.status == 400) {
        // toast.error(response?.data?.message);
      }
      if (response?.data?.status == 200) {
        navigate("/fundraiser-registered");
        setState((prev) => ({
          ...prev,
          success: true,
        }));
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // If you need to navigate after successful submission
      // navigate('/careers');
    } catch (error) {
      console.error("Error posting form data:", error);
    }
  };

  const verifyMobileNumber = async () => {
    // if (validatePhoneNumber(state.phone_number)) {
    let { mobile_number } = state;
    // let keys = ["mobile_number"];
    // let error = validateRequiredFields(keys, state);
    // if (!error) {
    setState((prev) => ({
      ...prev,
      ["verifyLoader"]: true,
    }));

    const payload = new FormData();
    payload.append("otp_type", "fundraiser_signup");
    payload.append(
      "phone_number",
      mobile_number
        ?.split("-")
        .filter((a) => a.trim().length)
        .join("")
    );
    let success = () => {
      setState((prev) => ({
        ...prev,
        ["setUpAccount"]: false,
        ["optCodeSend"]: true,
        ["verifyLoader"]: false,
        ["otpV"]: false,
      }));
    };
    let fail = () => {
      setState((prev) => ({
        ...prev,
        //   phone_number: "",
        ["signInLoader"]: false,
      }));
    };

    const response = await verifyDetail(payload, success, fail);
    console.log("response : ", response);
    if (response?.data?.status == 200) {
      // toast.success(response.data.message);
      setState((prev) => ({
        ...prev,
        ["signInLoader"]: false,
        ["showSignInModel"]: false,
        ["showOtpModel"]: true,
      }));
    }
    // }
    // }
  };

  const verifyOtp = async () => {
    try {
      let { otp, mobile_number } = state;
      // let payload = {
      //   otp_code,
      //   otp_type: 'signin',
      //   phone_number: country_code + phone_number,
      // };
      const payload = new FormData();
      payload.append("otp_code", otp);
      payload.append("otp_type", "fundraiser_signup");
      payload.append(
        "phone_number",
        mobile_number
          ?.split("-")
          .filter((a) => a.trim().length)
          .join("")
      );
      // console.log("country_code + phone_number : ", country_code + phone_number);
      setState((prev) => ({
        ...prev,
        verifyOtpLoader: true,
      }));

      let success = () => {
        setState((prev) => ({
          ...prev,
          verifyOtpLoader: false,
          //   ["showOtpModel"]: false,
          otp: "",
          //   otpVerified: true,
        }));
      };
      let fail = () => {
        setState((prev) => ({
          ...prev,
          // ['showModal']:false,
          otp: "",
          verifyOtpLoader: false,
        }));
      };

      const response = await verifyOtpCode(payload, success, fail);
      console.log("response otp verification : ", response);
      if (response?.data?.status == 200) {
        handleNext();

        // localStorage.setItem("user_token", response?.data?.data?.access_token);
      } else {
        setError(response?.data?.message);
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://alphadevelopers.co.uk/api/get-organization-info"
      );
      if (!response.ok) {
        console.log("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log(jsonData.data);
      setData(jsonData.data);
      if (response) {
        console.log("json data : ", jsonData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="get-started">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 started">
              {started ? (
                <div>
                  <div>
                    <span>GET STARTED</span>
                  </div>
                  <div>
                    <h1 className="secondary_font">
                      Your{" "}
                      <span className="secondary_color secondary_font">
                        {" "}
                        fundraising{" "}
                      </span>
                      experience begins here
                    </h1>
                  </div>
                  <div>
                    <p>
                      Popcorn World is always free to use, there are no minimums
                      to meet, and you always keep 50% profit.
                    </p>
                  </div>
                  <div className="w-100">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email address"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={handleChangeEmail}
                      />
                    </FloatingLabel>
                  </div>
                  {state?.email_error && (
                    <div>
                      <span className="text-danger">{state?.email_error}</span>
                    </div>
                  )}
                  <div className="mt-5">
                    {show ? (
                      <button
                        className="btn btn-primary"
                        onClick={handleContinue}
                      >
                        Continue
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : step1 ? (
                <div>
                  <div>
                    <span>Step 1 of 4</span>
                  </div>
                  <div>
                    <h1 className="secondary_font">
                      Type in the name of your team
                    </h1>
                  </div>
                  <div>
                    <p>
                      The team name will be displayed on each participant’s
                      Pop-Up Store.
                    </p>
                  </div>
                  <div className="w-100">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Team Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="name"
                        value={name}
                        onChange={handleChangeName}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="mt-5">
                    {show ? (
                      <button className="btn btn-primary " onClick={teamDetail}>
                        Next: Team Details
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : step2 ? (
                <div>
                  <div>
                    <span>Step 2 of 4</span>
                  </div>
                  <div>
                    <h1 className="secondary_font">Tell us about your team</h1>
                  </div>
                  <div>
                    <p>
                      From sports teams to non-profit organizations, Popcorn
                      World has helped raise over {base_currency}100 million.
                    </p>
                  </div>
                  <div className="w-100 mb-4">
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Organization Type"
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        value={organizationsType}
                        onChange={handleOrganization}
                      >
                        <option></option>
                        {data.map((item) => (
                          <option key={item?.id} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </div>
                  <div className="mb-4">
                    {secondDropdown && data[organId] ? (
                      <div className="w-100">
                        <FloatingLabel
                          controlId="floatingSelect2"
                          label={data[organId]?.name}
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            value={organizationsType2}
                            onChange={handleOrganization2}
                          >
                            <option></option>
                            {data[organId]?.organization_sub_types?.map(
                              (item) => (
                                <option key={item?.id} value={item?.id}>
                                  {item?.name}
                                </option>
                              )
                            )}
                            {/* <option value="1">Arts & Culture</option>
                        <option value="2">
                          Assosiations, Clubs and Community
                        </option>
                        <option value="3">Education & Academics</option>
                        <option value="4">Health & Wellness</option>
                        <option value="5">Religious Organization</option>
                        <option value="6">Sororities & Fratarnities</option>
                        <option value="7">Sports & Athletics</option>
                        <option value="8">other</option> */}
                          </Form.Select>
                        </FloatingLabel>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    {organizationInput || (organId && !data[organId]) ? (
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Organization Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="name"
                          value={organizationsInput}
                          onChange={handleOrganizationInput}
                        />
                      </FloatingLabel>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="w-50">
                    {zipCode ? (
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Zip Code"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          value={code}
                          maxLength={5}
                          onChange={handleZipCode}
                        />
                      </FloatingLabel>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-5">
                    {show ? (
                      <button
                        className="btn btn-primary"
                        onClick={fundraiserDetail}
                      >
                        Next: Fundraiser Details{" "}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : step3 ? (
                <div>
                  <div>
                    <span>Step 3 of 4</span>
                  </div>
                  <div>
                    <h1 className="secondary_font">
                      When are you looking to start fundraising?
                    </h1>
                  </div>

                  {pickDate ? (
                    <>
                      <div className="mt-5">
                        <div className="d-flex gap-4 justify-content-between text-center mb-3">
                          <button
                            className={`btn ${
                              activeButton === "asap" ? "active" : "asap"
                            }`}
                            onClick={() => handleButtonClick2("asap")}
                          >
                            ASAP
                          </button>
                          <button
                            className={`btn ${
                              activeButton === "next4weeks" ? "active" : "asap"
                            }`}
                            onClick={() => handleButtonClick2("next4weeks")}
                          >
                            in the next 4 weeks
                          </button>
                        </div>
                        <div className="d-flex gap-4 mb-3 justify-content-between text-center">
                          <button
                            className={`btn ${
                              activeButton === "nextFewMonths"
                                ? "active"
                                : "asap"
                            }`}
                            onClick={() => handleButtonClick2("nextFewMonths")}
                          >
                            in the Next few months
                          </button>
                          <button
                            className={`btn ${
                              activeButton === "notSure" ? "active" : "asap"
                            }`}
                            onClick={() => handleButtonClick2("notSure")}
                          >
                            I am not sure
                          </button>
                        </div>
                      </div>

                      <div className="pickdate">
                        <CiCalendarDate size={25} /> &nbsp;
                        <u onClick={handleSpecificData}>Pick a Specific Date</u>
                      </div>
                    </>
                  ) : (
                    <div className="mt-5">
                      <div className=" dateinput ">
                        <div className="w-100">
                          <FloatingLabel
                            controlId="floatingstartdata"
                            label="Start Date/Time"
                            className="mb-3"
                          >
                            <Form.Control
                              type="datetime-local"
                              min="2015-01-01T00:00"
                              max="2025-12-31T23:59"
                              value={startDate}
                              onChange={handleStartDate}
                            />
                          </FloatingLabel>
                        </div>
                        <div className="w-100">
                          <FloatingLabel
                            controlId="floatingenddate"
                            label="End Date/Time"
                            className="mb-3"
                          >
                            <Form.Control
                              type="datetime-local"
                              min="2015-01-01T00:00"
                              max="2025-12-31T23:59"
                              value={endDate}
                              onChange={handleEndDate}
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div>
                        <h6
                          onClick={() => setPickDate(!pickDate)}
                          style={{ cursor: "pointer" }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  )}
                  <div className="mt-5">
                    {show ? (
                      <button
                        className="btn btn-primary"
                        onClick={handleParticipants}
                      >
                        Next: Participants
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : step4 ? (
                <>
                  <div>
                    <span>Step 4 of 4</span>
                  </div>
                  <div>
                    <h1 className="secondary_font">
                      How many members of your team will participate in the
                      fundraiser?
                    </h1>
                  </div>
                  <div className="mt-5">
                    <div className="d-flex gap-4 justify-content-between text-center mb-3">
                      <button
                        className={`btn ${
                          activeButton === "justme" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("justme")}
                      >
                        Just me
                      </button>
                      <button
                        className={`btn ${
                          activeButton === "2-10" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("2-10")}
                      >
                        2-10
                      </button>
                      <button
                        className={`btn ${
                          activeButton === "11-20" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("11-20")}
                      >
                        11-20
                      </button>
                      <button
                        className={`btn ${
                          activeButton === "21-30" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("21-30")}
                      >
                        21-30
                      </button>
                    </div>
                    <div className="d-flex gap-4 mb-3 justify-content-between text-center">
                      <button
                        className={`btn ${
                          activeButton === "31-40" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("31-40")}
                      >
                        31-40
                      </button>
                      <button
                        className={`btn ${
                          activeButton === "41-50" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("41-50")}
                      >
                        41-50
                      </button>
                      <button
                        className={`btn ${
                          activeButton === "51+" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("51+")}
                      >
                        51+
                      </button>
                      <button
                        className={`btn ${
                          activeButton === "notSure" ? "active" : "asap"
                        }`}
                        onClick={() => handleButtonClick("notSure")}
                      >
                        i'm not sure
                      </button>
                    </div>
                  </div>
                  <div className="mt-5">
                    {show ? (
                      <button
                        className="btn btn-primary"
                        onClick={handleNextStep4}
                      >
                        Next
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              {state?.estimatedEarnings ? (
                <div>
                  <span>ESTIMATED EARNINGS</span>
                  <h1 className="secondary_font">
                    Your team could earn between {base_currency}400-
                    {base_currency}2,200 in just 4 days!
                  </h1>
                  <p>
                    Our estimate is based on your team activity type and the
                    number of team members you expect to open a Pop-Up Store
                    during your fundraiser. The average participant sells{" "}
                    {base_currency}400!
                  </p>
                  {show ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          estimatedEarnings: false,
                          setUpAccount: true,
                        }));
                      }}
                    >
                      Next: Schedule Fundraiser
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : state?.setUpAccount ? (
                <div>
                  <span>ACCOUNT</span>
                  <div>
                    <h1 className="secondary_font">
                      Set up your Popcorn World account
                    </h1>
                    <div className="w-100 mt-3">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="First Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="first_name"
                          value={state.first_name}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="w-100 mt-3">
                      <FloatingLabel
                        controlId="floatingInput2"
                        label="Last Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="last_name"
                          value={state.last_name}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="w-100 mt-3">
                      <FloatingLabel
                        controlId="floatingInput2"
                        label="Mobile Phone"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="mobile_number"
                          value={state.mobile_number}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </div>
                    {state?.mobile_error && (
                      <div>
                        <span className="text-danger pb-4">
                          {state?.mobile_error}
                        </span>
                      </div>
                    )}
                    <div className="d-flex">
                      <div>
                        <TickCheck
                          check={state.accept}
                          setCheck={(e) => {
                            setState((prev) => ({
                              ...prev,
                              accept: !state?.accept,
                            }));
                          }}
                        />
                      </div>
                      <div className="ps-2">
                        <span className="text-style">
                          By creating an account, you agree to Popcorn World{" "}
                        </span>
                        <br></br>
                        <span className="text-style">
                          Terms and Conditions and Privacy Policy
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="col-md-6">
                        {state.verifyLoader ? (
                          <Spinner className="px-3 mt-2 d-flex justify-content-end" />
                        ) : (
                          <CartButton
                            title="Verify Mobile Number"
                            className="w-100"
                            onClick={verifyMobileNumber}
                            disabled={
                              !state.accept ||
                              !state.first_name ||
                              !state.last_name ||
                              !state.mobile_number ||
                              state.verifyLoader
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : state.optCodeSend ? (
                <div>
                  <div>
                    <h2>Enter the 5 digit code</h2>
                    <p className="mt-3">
                      We sent a code over SMS to {state?.mobile_number}.
                    </p>
                    <FloatingLabel
                      controlId="floatingInput2"
                      label="5 Digit OTP"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="otp"
                        value={state.otp}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                    {error && <text style={{ color: "red" }}>{error}</text>}
                  </div>
                  <div className="col-md-6">
                    {state.verifyOtpLoader ? (
                      <Spinner className="px-3 mt-2 d-flex justify-content-end" />
                    ) : (
                      <CartButton
                        title="Verify OTP"
                        className="w-100"
                        onClick={verifyOtp}
                        disabled={
                          !(state.otp.length === 5) || state.verifyOtpLoader
                        }
                      />
                    )}
                  </div>
                  {/* {state?.success && (
                  <div className="mt-4 p-0">
                    <p className="text-success mb-1">
                      Account created successfully. Check your gmail for login
                      details.
                    </p>
                    <p className="p-0">
                      Login url{" "}
                      <a target="_blank" href={`${baseURL}/fundraiser`}>
                        here
                      </a>
                    </p>
                  </div>
                )}
                {state?.error && (
                  <CartButton
                    title="Back"
                    className="w-100 btn-secondary"
                    onClick={() => navigate("/get-started")}
                  />
                )} */}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-4 text-center">
              <div className="getone">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "/assets/images/get1.png"
                  }
                  className="img-fluid get1"
                  alt="get1"
                />
              </div>
              <div className="gettwo">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "/assets/images/get2.png"
                  }
                  className="img-fluid get1"
                  alt="get1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Getstarted;
