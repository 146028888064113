import React from 'react'

function Peoplebackground() {
  return (
    <div className='peoplebackground'>
        
    </div>
  )
}

export default Peoplebackground