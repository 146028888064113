import React from "react";

function PrivacySection() {
  return (
    <main>
        
        <section class="MuiBox-root css-14kbwcq">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-1rj7994">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-4f1d1ecb-06fc-4c26-b4de-b70da4e82c3e">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h2 className="secondary_font">Privacy policy</h2>
                                <p><br />Welcome to Popcorn World Technologies, LP (&quot;Popcorn World&quot;,
                                    &quot; we&quot;, &quot; us&quot; or &quot; our&quot;). Our Privacy Policy
                                    explains how we and some of the companies we work with collect, use, permit
                                    third-party service providers access to, and protect information in relation
                                    to our mobile services, website, our mobile application, and any software
                                    provided on or in connection with Popcorn World services (collectively, the
                                    &quot; Service&quot;), and your choices about the collection and use of your
                                    information.<br /><br />By using our Service you understand and agree that
                                    we are providing a platform for you to post content, including photos,
                                    comments and other materials (&quot; User Content&quot;), to the Service and
                                    to share User Content publicly. This means that other Users may search for,
                                    see, use, or share any of your User Content that you make publicly available
                                    through the Service, consistent with the terms and conditions of this
                                    Privacy Policy and our Terms of Use (which can be found at <a
                                        href="http://www.doublegood.com/terms"
                                        linktype="url">www.doublegood.com/terms</a>).<a
                                        linktype="url"><br /></a><a linktype="url"><br /></a><a
                                        linktype="url">Our</a> Policy applies to all visitors, users, and others
                                    who access the Service (&quot;Users&quot;).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-9nej2e">
                    
                    <section class="MuiBox-root css-p5vm0a" id="">
                        <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                            
                            <div class="MuiBox-root css-19m07ui">
                                <span class="MuiTypography-root MuiTypography-eyebrow css-i2reuk">ON
                                    THIS PAGE</span>
                                <section class="MuiBox-root css-p5vm0a" id="">
                                    
                                    <div class="MuiContainer-root MuiContainer-disableGutters css-1b9oz2m">
                                        
                                        <div class="MuiBox-root css-x7x2r2">
                                            
                                            <div class="MuiBox-root css-k008qs">
                                                
                                                <div class="MuiGrid-root MuiGrid-container css-1lagv4a" id="">
                                                    
                                                    <div
                                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                                                        
                                                        
                                                        <div class="MuiStack-root css-1ihcc4j">
                                                            
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#Information-we-collect">1.
                                                                    Information we collect</a></div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#how-we-use-your-information">2.
                                                                    How we use your Information</a></div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#mobile-phone-numbers">3.
                                                                    Mobile phone numbers</a></div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#sharing-of-your-information">4.
                                                                    Sharing of your information</a></div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#how-we-store-your-information">5.
                                                                    How we store your information</a></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                                                        <div class="MuiStack-root css-1ihcc4j">
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#your-choices-about-your-information">6.
                                                                    Your choices about your information</a>
                                                            </div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#california-privacy-rights">7.
                                                                    State Data Protection Law Compliance</a>
                                                            </div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#childrens-privacy">8.
                                                                    Children&#x27;s privacy</a></div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#other-web-sites-and-services">9.
                                                                    Other web sites and services</a></div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#how-to-contact-us-about-a-deceased-user">10.
                                                                    How to contact us about a deceased user</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 css-11dho33">
                                                        <div class="MuiStack-root css-1ihcc4j">
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#how-to-contact-us">11. How to
                                                                    contact us</a></div>
                                                            <div class="MuiStack-root css-j7qwjs"><a
                                                                    
                                                                    href="/privacy#changes-to-our-privacy-policy">12.
                                                                    Changes to our privacy policy</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="information-we-collect">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-1rj7994">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-59891aba-4bb2-4e51-9585-39f4262a68b0">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>1. Information we collect</h3>
                                <p><br />We collect the following types of personal information.<br /><br />a.
                                    Personal Information you provide us directly:<br />When you register for a
                                    Popcorn World account, we collect your username, password and e-mail address
                                    and Profile information you provide for your user profile (e.g., first and
                                    last name, picture, phone number and other information about your activities
                                    and events). This information allows us to help you or others be
                                    &quot;found&quot; on the Service. When you organize or participate in a
                                    fundraiser, we may collect user Content (e.g., photos, comments, and other
                                    materials) you post to the Service. When you organize a fundraiser, we may
                                    collect information regarding the payee of any fundraising proceeds for
                                    validation purposes (which could include your social security number if you
                                    are an individual payee of such fundraising proceeds and we are required to
                                    collect for tax purposes). When ordering or registering on our Service you
                                    may be asked to enter your name, email address, mailing address, phone
                                    number, credit card information or other details to help process your order
                                    and assist you with your experience and allow us to communicate with you.
                                    For example, we may send you Service-related emails or text messages (e.g.,
                                    account verification, changes/updates to features of the Service, technical
                                    and security notices). Note that you may not opt out of Service-related
                                    e-mails.<br /><br />b. Analytics information:<br />We use third-party
                                    analytics tools to help us measure traffic and usage trends for the Service.
                                    These tools collect information sent by your device or our Service,
                                    including the web pages you visit, add-ons, and other information that
                                    assists us in improving the Service. We collect and use this analytics
                                    information to help improve our services.<br /><br />c. Cookies and similar
                                    technologies:<br />When you visit the Service, we may use cookies and
                                    similar technologies like pixels, web beacons, and local storage to collect
                                    information about how you use Popcorn World and provide features to you. We
                                    may ask affiliates and third party service providers to serve ads or
                                    services to your devices, which may use cookies or similar technologies
                                    placed by us or the third party. Please see our section on “Do Not Track”
                                    signals below.<br /><br />d. Log file information:<br />Log file information
                                    is automatically reported by your browser each time you make a request to
                                    access (i.e., visit) a web page or app. It can also be provided when the
                                    content of the webpage or app is downloaded to your browser or device. When
                                    you use our Service, our servers may automatically record certain log file
                                    information, including your web request, Internet Protocol (&quot;IP&quot;)
                                    address, browser type, referring / exit pages and URLs, number of clicks and
                                    how you interact with links on the Service, domain names, landing pages,
                                    pages viewed, and other such information. We may also collect similar
                                    information from emails sent to our Users which then help us track which
                                    emails are opened and which links are clicked by recipients. The information
                                    allows for more accurate reporting and improvement of the
                                    Service.<br /><br />e. Device identifiers:<br />When you use a mobile device
                                    like a tablet or phone to access our Service, we may access, collect,
                                    monitor, store on your device, and/or remotely store one or more
                                    &quot;device identifiers&quot;. Device identifiers are small data files or
                                    similar data structures stored on or associated with your mobile device,
                                    which uniquely identify your mobile device. A device identifier may be data
                                    stored in connection with the device hardware, data stored in connection
                                    with the device&#39;s operating system or other software, or data sent to
                                    the device by Popcorn World. A device identifier may deliver information to us
                                    or to a third party service provider about how you browse and use the
                                    Service and may help us or others provide reports or personalized content
                                    and ads. Some features of the Service may not function properly if use or
                                    availability of device identifiers is impaired or disabled.<br /><br />f.
                                    Metadata:<br />Metadata is usually technical data that is associated with
                                    User Content. For example, Metadata can describe how, when and by whom a
                                    piece of User Content was collected and how that content is formatted. Users
                                    can add or may have Metadata added to their User Content.</p>
                                <p><br /><b><i>How does our site handle Do Not Track signals?</i></b><br />We
                                    use services of <a href="http://Segment.io" linktype="url">Segment.io</a>,
                                    Inc. (“Segment”) to collect data. Segment does not respond to “Do Not Track”
                                    browser signals or other similar mechanisms. Third parties may collect
                                    personal information about your online activities over time and across sites
                                    when you visit or use the Service.</p>
                                <p>We may employ and/or our third-party tracking utility company may employ
                                    software technology called clear gifs (a.k.a. Web Beacons/Web Bugs), that
                                    help us better manage content on our Service by informing us what content is
                                    effective. These gifs (or other tracking technology) may be included in or
                                    associated with e-mails or other communications that you receive from us (or
                                    our third party service providers) in order to help us track your response
                                    and interests and to deliver you relevant content and services. We may tie
                                    information gathered by clear gifs or other tracking technology to your
                                    personal information.<br /><br />It’s also important to note that we allow
                                    third-party behavioral tracking.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="how-we-use-your-information">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-2fdde63e-f9c2-45f6-af07-76e4bc0b2c33">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>2. How we use your information</h3>
                                <p>In addition to some of the specific uses of information we describe in this
                                    Privacy Policy, we may use information that we receive to:</p>
                                <ul>
                                    <li>
                                        <p>help you efficiently access your information after you sign in;</p>
                                    </li>
                                    <li>
                                        <p>remember information so you will not have to re-enter it during your
                                            visit or the next time you visit the Service;</p>
                                    </li>
                                    <li>
                                        <p>provide personalized content and information to you and others, which
                                            could include online ads or other forms of marketing;</p>
                                    </li>
                                    <li>
                                        <p>provide, improve, test, and monitor the effectiveness of our Service;
                                        </p>
                                    </li>
                                    <li>
                                        <p>develop and test new products and features;</p>
                                    </li>
                                    <li>
                                        <p>monitor metrics such as total number of visitors, traffic, and
                                            demographic patterns;</p>
                                    </li>
                                    <li>
                                        <p>diagnose or fix technology problems;</p>
                                    </li>
                                    <li>
                                        <p>automatically update the Popcorn World application on your device; and
                                        </p>
                                    </li>
                                    <li>
                                        <p>run contests, special offers or other events or activities
                                            (&quot;Events&quot;) on the Service. If you do not want to
                                            participate in an Event, do not use the particular Metadata
                                            associated with that Event.</p>
                                    </li>
                                </ul>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="mobile-phone-numbers">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-c109340f-7fc5-4d38-9833-7f2a87473c0c">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>3. Mobile phone numbers</h3>
                                <p><br />When you provide us with your mobile phone number, you acknowledge,
                                    agree and consent to some of our services that include certain features and
                                    services that may be available via your mobile phone, including, without
                                    limitation: (a) the ability to receive information and reply to messages via
                                    in-app push notifications, and to send content and messages using text
                                    messaging (Mobile Texts), and (b) the ability to access our services from
                                    your mobile phone (Mobile Web) (collectively, the &quot;Mobile
                                    Services&quot;). We do not charge for Mobile Services. Your carrier’s normal
                                    messaging, data and other rates and fees will still apply. Certain Mobile
                                    Services may be incompatible with your carrier policies or mobile device. By
                                    using the Mobile Services you agree that we may communicate with you
                                    regarding the Website and our partners by in-app push notifications, SMS,
                                    MMS, text message or other electronic means to your mobile device and that
                                    certain information about your usage of the Mobile Services will be
                                    communicated to us. Such communications may include transactional
                                    information and marketing text messages. In connection with the Mobile
                                    Services we may send communications to your mobile device regarding us or
                                    other parties.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="sharing-of-your-information">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-ff976914-bbbf-430b-8449-19c533b06647">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>4. Sharing of your information</h3>
                                <p><br />a. Parties with whom we may share your information:<br />We only have
                                    access to collect information that you voluntarily give us via email or
                                    other direct contact from you. We collect your information in order to
                                    provide necessary services to fulfill your request. In order to do so, we
                                    may share your information we collect with a third party service provider as
                                    necessary to provide the Popcorn World services to you and the buyers that
                                    participate in your fundraising events. We will not sell or rent or exchange
                                    for any form of consideration this information to anyone. We will not share
                                    your information that we collect with any third party outside of our
                                    organization, other than as stated in the policy and what is necessary to
                                    fulfill your request or provide the Popcorn World services to you, e.g. to
                                    ship an order. Unless you ask us not to, we may contact you via email or
                                    text message in the future to tell you about specials, new products or
                                    services, or changes to this policy.<br /><br />Many of our services such as
                                    making a purchase and registering an account ask you to provide personal
                                    information such as your first and last name, billing and shipping address,
                                    email address, and telephone number. In order to process payment for
                                    fundraising orders and distribute profits, we may collect the following:
                                    Credit card information, bank name, your banking credentials, and your
                                    banking account and routing number, and other financial information. We may
                                    share the information you provide us with a third-party service provider as
                                    necessary to provide the Popcorn World services to you.<br /><br />Popcorn World
                                    may use Plaid Technologies, Inc. (“Plaid”) to verify your bank account and
                                    confirm your bank account balance prior to approving a transaction. Double
                                    Good only provides your information with Plaid in accordance with this
                                    Privacy Policy. Information provided to Plaid is treated by Plaid in
                                    accordance with its Privacy Policy, available at <a
                                        href="https://plaid.com/legal/.￼￼We"
                                        linktype="url">https://plaid.com/legal/.</a><a
                                        linktype="url"><br /></a><a linktype="url"><br /></a><a
                                        linktype="url">We</a> may remove parts of data that can identify you and
                                    share anonymized data with other parties. We may also combine your
                                    information with other information in a way that it is no longer associated
                                    with you and share that aggregated information.<br /><br />b. Parties with
                                    whom you may choose to share your User Content:<br /><br />Any information
                                    or content that you voluntarily disclose for posting to the Service, such as
                                    User Content, becomes available to the public, as controlled by any
                                    applicable privacy settings that you set. To change your privacy settings on
                                    the Service, please email us at <a href="mailto:help@doublegood.com"
                                        linktype="url">help@doublegood.com</a>. Once you have shared User
                                    Content or made it public, that User Content may be re-shared by
                                    others.<br /><br />If you remove information that you posted to the Service,
                                    copies may remain viewable in cached and archived pages of the Service, or
                                    if other Users or third parties using the Popcorn World webpage have copied or
                                    saved that information.<br /><br />c. What happens in the event of a change
                                    of control:<br /><br />If we sell or otherwise transfer part or the whole of
                                    Popcorn World or our assets to another organization (e.g., in the course of a
                                    transaction like a merger, acquisition, bankruptcy, dissolution,
                                    liquidation), your information such as name and email address, User Content
                                    and any other information collected through the Service may be among the
                                    assets acquired by the buyer or transferred as a result of such
                                    transactions. The buyer or transferee will have to honor the commitments we
                                    have made in this Privacy Policy.<br /><br />d. Responding to legal requests
                                    and preventing harm:<br /><br />We may access, preserve and share your
                                    information in response to a legal request (like a search warrant, court
                                    order or subpoena) if we have a good faith belief that the law requires us
                                    to do so. This may include responding to legal requests from jurisdictions
                                    outside of the United States where we have a good faith belief that the
                                    response is required by law in that jurisdiction, affects users in that
                                    jurisdiction, and is consistent with internationally recognized standards.
                                    We may also access, preserve and share information when we have a good faith
                                    belief it is necessary to: detect, prevent and address fraud and other
                                    illegal activity; to protect ourselves, you and others, including as part of
                                    investigations; and to prevent death or imminent bodily harm. Information we
                                    receive about you may be accessed, processed and retained for an extended
                                    period of time when it is the subject of a legal request or obligation,
                                    governmental investigation, or investigations concerning possible violations
                                    of our terms or policies, or otherwise to prevent harm.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="how-we-store-your-information">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-faeddafb-0620-48a8-86fe-ce2aa9222eb2">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>5. How we store your information</h3>
                                <p><br />a. Your information collected through the Service may be stored and
                                    processed in the United States or any other country in which Popcorn World or
                                    its service providers maintain facilities.<br /><br />b. Popcorn World and
                                    service providers may transfer information that we collect about you,
                                    including personal information across borders and from your country or
                                    jurisdiction to other countries or jurisdictions around the world. If you
                                    are located in the European Union or other regions with laws governing data
                                    collection and use that may differ from U.S. law, please note that we may
                                    transfer information, including personal information, to a country and
                                    jurisdiction that does not have the same data protection laws as your
                                    jurisdiction.<br /><br />c. By registering for and using the Service you
                                    consent to the transfer of information to the U.S. or to any other country
                                    in which Popcorn World, its Affiliates or service providers maintain
                                    facilities and the use and disclosure of information about you as described
                                    in this Privacy Policy.</p>
                                <p><br />d. We use commercially reasonable safeguards to help keep the
                                    information collected through the Service secure and take reasonable steps
                                    (such as requesting a unique password) to verify your identity before
                                    granting you access to your account. However, Popcorn World cannot ensure the
                                    security of any information you transmit to Popcorn World or guarantee that
                                    information on the Service may not be accessed, disclosed, altered, or
                                    destroyed.<br /><br />e. Please do your part to help us. You are responsible
                                    for maintaining the secrecy of your unique password and account information,
                                    and for controlling access to emails between you and Popcorn World, at all
                                    times. Your privacy settings may also be affected by changes the social
                                    media services you connect to Popcorn World make to their services. We are not
                                    responsible for the functionality, privacy, or security measures of any
                                    other organization.<br /><br />f. Your personal information is contained
                                    behind secured networks and is only accessible by a limited number of
                                    persons who have special access rights to such systems, and are required to
                                    keep the information confidential. In addition, all sensitive/credit
                                    information you supply is encrypted via Secure Socket Layer (SSL)
                                    technology.<br /><br />g. We implement a variety of security measures when a
                                    user places an order enters, submits, or accesses their information to
                                    maintain the safety of your personal information.<br /><br />h. All
                                    transactions are processed through a gateway provider and are not stored or
                                    processed on our servers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="your-choices-about-your-information">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-a078efd0-eb5f-40da-b530-1df14aaea1d4">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>6. Your choices about your information</h3>
                                <p><br />a. Your account information and profile/privacy
                                    settings:<br /><br />Update your account at any time by logging in and
                                    changing your profile settings. Unsubscribe from email communications from
                                    us by clicking on the &quot;unsubscribe link&quot; provided in such
                                    communications. As noted above, you may not opt out of Service-related
                                    communications (e.g., account verification, purchase and billing
                                    confirmations and reminders, changes/updates to features of the Service,
                                    technical and security notices).<br /><br />You have the right to access,
                                    update and correct factual inaccuracies in personally identifiable
                                    information that is collected by this Site. To help protect your privacy and
                                    the security of your personally identifiable information, we will take
                                    reasonable steps to verify your identity before granting
                                    access.<br /><br />Parents of children under the age of 13 may contact us to
                                    request that there be no further collection or use of their child’s
                                    personally identifiable information. We will take steps to verify the
                                    identity of anyone requesting personally identifiable information about a
                                    child to ensure that the person is in fact the child’s parent or legal
                                    guardian.<br /><br />Learn more about reviewing or modifying your account
                                    information.<br /><br />b. How long we keep your User Content:<br /><br />We
                                    will retain your information for as long as your account (if any) is active
                                    or as needed to provide you with the offerings and services you request. If
                                    you wish to cancel your account or request that we no longer use your
                                    information to provide you offerings or services, contact us at: <a
                                        href="mailto:privacy@doublegood.com"
                                        linktype="url">privacy@doublegood.com</a>. Following termination or
                                    deactivation of your account, Popcorn World, its Affiliates, or its Service
                                    Providers may retain information (including your profile information) and
                                    User Content for a commercially reasonable time for backup, archival, and/or
                                    audit purposes. We will also retain and use your information as necessary to
                                    comply with our legal obligations, resolve disputes, and enforce our
                                    rights.<br /><br />c. Third-Party Links:<br /><br />In addition to the
                                    non-personal information collected by Popcorn World, we may allow third
                                    parties to serve advertisements on our Service and/or collect non-personal
                                    information through cookies or web beacons (or other technologies). These
                                    third parties may use such information (i) to help tailor site content to
                                    users; (ii) to serve ads on our behalf; (iii) to measure advertising
                                    effectiveness (such as which web pages are visited or what products are
                                    purchased); and/or (iv) to provide advertisements about goods and services
                                    that may be of interest to you. Third-party cookies and web beacons are
                                    governed by each third party&#39;s own privacy policy, not Popcorn World’s
                                    Privacy Policy.<br /><br />Our Service may contain links to other websites
                                    which are outside our control and are not covered by this Privacy Policy. If
                                    you click the links provided, the operators of such sites may collect
                                    information from you which will be used by them in accordance with their own
                                    privacy policies, which may differ from ours. We encourage you to review the
                                    privacy policies of those websites so that you understand if/how they
                                    collect and/or use information from you or your computer.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="california-privacy-rights">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-ba89e169-0b40-43ce-a428-bbc886a5b72a">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>7. State Data Protection Law Compliance</h3>
                                <p><br />We do not sell or share personal information. Under several new state
                                    data protection laws, consumers have the following rights regarding personal
                                    data we have collected about you. For purposes of this section only,
                                    “personal data” means any information that is linked or reasonably linkable
                                    to an identified or identifiable natural person and excludes employment
                                    data, personal data that cannot be attributed to an individual without the
                                    use of additional information, and de-identified data or publicly available
                                    information. Under these laws consumers have the right to request any of the
                                    following information from us regarding personal information collected about
                                    you:</p>
                                <ul>
                                    <li>
                                        <p>The right to know (i.e., the right to confirm whether we are
                                            processing your personal data). This information includes:</p>
                                        <ul>
                                            <li>
                                                <p>The categories of personal information collected about you.
                                                </p>
                                            </li>
                                            <li>
                                                <p>The categories of sources from which the personal information
                                                    is collected.</p>
                                            </li>
                                            <li>
                                                <p>The business or commercial purpose for collecting or selling
                                                    personal information.</p>
                                            </li>
                                            <li>
                                                <p>The categories of third parties with whom we share personal
                                                    information, if any.</p>
                                            </li>
                                            <li>
                                                <p>The specific personal information collected about you.</p>
                                            </li>
                                            <li>
                                                <p>For personal information sold or disclosed to a third party
                                                    for a business purpose, you have a right to know the
                                                    categories of personal information about you that we sold
                                                    and the categories of third parties to whom the personal
                                                    information was sold; and the categories of personal
                                                    information that we disclosed about you for a business
                                                    purpose.</p>
                                                <p></p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>We will provide this information free of charge up to two (2) times in any
                                    twelve (12) month period within 45 days of receiving your verifiable request
                                    (including verification of your identity and your California residency),
                                    subject to delays and exclusions permitted by law. Specific personal
                                    information about you or your account that is categorized as sensitive or
                                    confidential may be redacted. <br /><br />You or your agent may exercise
                                    your data privacy rights here: <a
                                        href="https://doublegood.kustomer.help/contact/ccpa-contact-us-SJrdPODHK"
                                        linktype="url"><u>web form</u></a>.</p>
                                <p>We obtain the categories of personal information listed above from the
                                    following categories of sources:</p>
                                <ul>
                                    <li>
                                        <p>Directly from you. For example, from forms you complete or products
                                            and services you purchase.</p>
                                    </li>
                                    <li>
                                        <p>Indirectly from you. For example, from observing your actions on our
                                            Website.</p>
                                    </li>
                                </ul>
                                <p><b><u>Service Provider Access to Personal Information</u></b></p>
                                <p>We may disclose your personal information to a third party service provider
                                    for a business purpose. When we disclose personal information for a business
                                    purpose, we enter a contract that describes the purpose and requires the
                                    recipient to both keep that personal information confidential and not use it
                                    for any purpose except performing the contract.</p>
                                <p>We provide third-party access to your personal information to the following
                                    categories of third parties:</p>
                                <p>Service providers.</p>
                                <p><b><i>Disclosures of Personal Information for a Business Purpose</i></b></p>
                                <p>In the preceding twelve (12) months, Popcorn World has disclosed the following
                                    categories of personal information for a business purpose: </p>
                                <ul>
                                    <li>
                                        <p>Device Identifiers.</p>
                                    </li>
                                    <li>
                                        <p>Personal information including name, email address, payment
                                            information, phone, number and use content such as uploaded photos.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Commercial information, such as payment information and mailing
                                            addresses.</p>
                                    </li>
                                    <li>
                                        <p>Internet or other similar network activity, including, logs,
                                            metadata, and analytics information.</p>
                                    </li>
                                    <li>
                                        <p>Geo-location data.</p>
                                    </li>
                                </ul>
                                <p><b><i>Sales of Personal Information</i></b></p>
                                <p>In the preceding twelve (12) months, Popcorn World has not sold personal
                                    information.</p>
                                <p><b><u>Data Retention Periods</u></b></p>
                                <p>We will retain your information for as long as your account (if any) is
                                    active or as needed to provide you with the offerings and services you
                                    request. If you wish to cancel your account or request that we no longer use
                                    your information to provide you offerings or services, contact us at: <a
                                        href="mailto:privacy@doublegood.com"
                                        linktype="url"><u>privacy@doublegood.com</u></a>. Following termination
                                    or deactivation of your account, Popcorn World, its Affiliates, or its Service
                                    Providers may retain information (including your profile information) and
                                    User Content for a commercially reasonable time for backup, archival, and/or
                                    audit purposes. We will also retain and use your information as necessary to
                                    comply with our legal obligations, resolve disputes, and enforce our rights.
                                </p>
                                <p>To exercise the access, data portability, and deletion rights described
                                    above, please submit a verifiable consumer request to us by either:</p>
                                <ul>
                                    <li>
                                        <p>Calling us at 630-568-5544.</p>
                                    </li>
                                    <li>
                                        <p>Emailing us at <a href="mailto:privacy@doublegood.com"
                                                linktype="url"><u>privacy@doublegood.com</u></a></p>
                                    </li>
                                    <li>
                                        <p>Completing the <a
                                                href="https://doublegood.kustomer.help/contact/ccpa-contact-us-SJrdPODHK"
                                                linktype="url"><u>web form</u></a></p>
                                    </li>
                                </ul>
                                <p>Only you, or someone legally authorized to act on your behalf, may make a
                                    verifiable consumer request related to your personal information. You may
                                    also make a verifiable consumer request on behalf of your minor child. You
                                    may only make a verifiable consumer request for access or data portability
                                    twice within a 12-month period. The verifiable consumer request must:</p>
                                <ul>
                                    <li>
                                        <p>Provide sufficient information that allows us to reasonably verify
                                            you are the person about whom we collected personal information or
                                            an authorized representative, which may include:</p>
                                        <ul>
                                            <li>
                                                <p>Logging into your account to verify your identity</p>
                                            </li>
                                            <li>
                                                <p>Providing information that matches information we have on
                                                    file about you</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p>Describe your request with sufficient detail that allows us to
                                            properly understand, evaluate, and respond to it.</p>
                                    </li>
                                </ul>
                                <p>We cannot respond to your request or provide you with personal information if
                                    we cannot verify your identity or authority to make the request and confirm
                                    the personal information relates to you.</p>
                                <p>Making a verifiable consumer request does not require you to create an
                                    account with us. However, we do consider requests made through your password
                                    protected account sufficiently verified when the request relates to personal
                                    information associated with that specific account.</p>
                                <p>We will only use personal information provided in a verifiable consumer
                                    request to verify the requestor’s identity or authority to make the request.
                                </p>
                                <p>For instructions on exercising sale opt-out rights, see Personal Information
                                    Sales Opt-Out and Opt-In Rights.</p>
                                <p><b><i>Response Timing and Format</i></b></p>
                                <p>We endeavor to respond to a verifiable consumer request within forty-five
                                    (45) days of its receipt. If we require more time (up to 45 days), we will
                                    inform you of the reason and extension period in writing.</p>
                                <p>If you have an account with us, we will deliver our written response to that
                                    account. If you do not have an account with us, we will deliver our written
                                    response by mail or electronically, at your option.</p>
                                <p>Any disclosures we provide will only cover the 12-month period preceding the
                                    verifiable consumer request’s receipt. The response we provide will also
                                    explain the reasons we cannot comply with a request, if applicable. For data
                                    portability requests, we will select a format to provide your personal
                                    information that is readily useable and should allow you to transmit the
                                    information from one entity to another entity without hindrance.</p>
                                <p>We do not charge a fee to process or respond to your verifiable consumer
                                    request unless it is excessive, repetitive, or manifestly unfounded. If we
                                    determine that the request warrants a fee, we will tell you why we made that
                                    decision and provide you with a cost estimate before completing your
                                    request.</p>
                                <p><b><u>Non-Discrimination</u></b></p>
                                <p>We will not discriminate against you for exercising any of your CCPA rights.
                                    Unless permitted by the CCPA, we will not:</p>
                                <ul>
                                    <li>
                                        <p>Deny you goods or services.</p>
                                    </li>
                                    <li>
                                        <p>Charge you different prices or rates for goods or services, including
                                            through granting discounts or other benefits, or imposing penalties.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Provide you a different level or quality of goods or services.</p>
                                    </li>
                                    <li>
                                        <p>Suggest that you may receive a different price or rate for goods or
                                            services or a different level or quality of goods or services.</p>
                                    </li>
                                </ul>
                                <p>However, we may offer you certain financial incentives permitted by the CCPA
                                    that <b>can result </b>in different prices, rates, or quality levels. Any
                                    CCPA-permitted financial incentive we offer will reasonably relate to your
                                    personal information’s value and contain written terms that describe the
                                    program’s material aspects. Participation in a financial incentive program
                                    requires your prior opt in consent, which you may revoke at any time.</p>
                                <p><b><u>Other California Privacy Rights</u></b></p>
                                <p>California’s “Shine the Light” law (Civil Code Section § 1798.83) permits
                                    users of our Website that are California residents to request certain
                                    information regarding our disclosure of personal information to third
                                    parties for their direct marketing purposes. To make such a request, please
                                    send an email to <a href="mailto:privacy@doublegood.com"
                                        linktype="url">privacy@doublegood.com</a> or write us at: 16W030 83rd
                                    St, Burr Ridge, IL 60527.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="childrens-policy">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-f5cda1eb-a011-4cb7-bb6d-80bbd826cab4">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>8. Children&#39;s privacy</h3>
                                <p><br />Popcorn World complies with the Children&#39;s Online Privacy Protection
                                    Act (COPPA) and does not knowingly collect or solicit any information from
                                    anyone under the age of 13 or knowingly allow such persons to register for
                                    the Service. The Service and its content are not directed at children under
                                    the age of 13. In the event that we learn that we have collected personal
                                    information from a child under age 13 without parental consent, we will
                                    delete that information as quickly as possible. If you believe that we might
                                    have any information from or about a child under 13, please contact us at <a
                                        href="mailto:help@doublegood.com"
                                        linktype="url"><u>help@doublegood.com</u></a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="other-web-sites-and-services">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-966fd164-5dd4-42c2-9b1a-1c0b4a14a3ad">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>9. Other web sites and services</h3>
                                <p><br />We are not responsible for the practices employed by any websites or
                                    services linked to or from our Service, including the information or content
                                    contained within them. Please remember that when you use a link to go from
                                    our Service to another website or service, our Privacy Policy does not apply
                                    to those third-party websites or services. Your browsing and interaction on
                                    any third-party website or service, including those that have a link on our
                                    website, are subject to that third party&#39;s own rules and policies. In
                                    addition, you agree that we are not responsible and do not have control over
                                    any third-parties that you authorize to access your User Content. If you are
                                    using a third-party website or service and you allow them to access your
                                    User Content you do so at your own risk.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="how-to-contact-us-about-a-deceased-user">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-0be0cf4d-6d99-4003-972c-44e56cf7f3be">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>10. How to contact us about a deceased user</h3>
                                <p><br />In the event of the death of a Popcorn World User, please contact us at
                                    <a href="mailto:help@doublegood.com"
                                        linktype="url"><u>help@doublegood.com</u></a>. We will usually conduct
                                    our communication via email; should we require any other information, we
                                    will contact you at the email address you have provided in your request.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="how-to-contact-us">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-25e355ba-027d-4151-94f8-b5364c733468">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>11. How to contact us</h3>
                                <p><br />If you have any questions about this Privacy Policy or the Service,
                                    please contact <a href="mailto:help@doublegood.com"
                                        linktype="url"><u>help@doublegood.com</u></a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="MuiBox-root css-14kbwcq" id="changes-to-our-privacy-policy">
            
            <div class="MuiContainer-root MuiContainer-maxWidthLg css-14bwu7h">
                
                <div class="MuiBox-root css-iuolwn">
                    
                    <div class="text-section MuiBox-root css-g8pswf"
                        id="text-section-2977796e-69f8-4fc5-83f6-ce39b692f5ca">
                        
                        <div class="MuiBox-root css-eg6041">
                            
                            <div class="MuiBox-root css-10xet1i">
                                <h3>12. Changes to our privacy policy</h3>
                                <p><br />Popcorn World may modify or update this Privacy Policy from time to time,
                                    so please review it periodically. We may provide you additional forms of
                                    notice of modifications or updates as appropriate under the circumstances.
                                    Your continued use of Popcorn World or the Service after any modification to
                                    this Privacy Policy will constitute your acceptance of such
                                    modification.<br /><br /><br />This Privacy Policy was last updated on
                                    December 15, 2023.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  );
}

export default PrivacySection;
