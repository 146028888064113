import React from "react";

function Brochure() {
  return (
    <div className="brochure">
      <div className="brochure1">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <span>BROCHURE FUNDRAISING</span>
              <div>
                <h1>Our classic, in-person fundraising you know and love</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <div>
                <p>
                  Take your brochures to school, to work, or around the
                  neighborhood. Spread delicious joy wherever you go!
                </p>
              </div>
              {/* <div className="d-flex gap-3 justify-content-center submit-btn">
              <button className="btn btn1 rounded-5">Submit Order</button>
              <button className="btn btn2 rounded-5">Request Brochure</button>
            </div> */}
            </div>
          </div>
          <div className="row justify-content-center pt-5 ">
            <div className="col-md-12">
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/brouch.png"
                }
                className="img-fluid"
                alt="brochure"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brochure;
