import React, { useState } from "react";
import { baseURL } from "../../../Services/axios";
import ShopCard, { CartButton } from "../../ShopCard";
import { useNavigate } from "react-router-dom";

function Allflavours({ products, id, linkCode }) {
  const navigate = useNavigate()
  const [loadedItemsCount, setLoadedItemsCount] = useState(5);
  const loadMore = () => {
    setLoadedItemsCount(loadedItemsCount+5);
  };
  return (
    <div className="allflavours">
      <div className="container-fluid px-0">
        <div className="row justify-content-between">
          {products.length > 0 &&
            products.slice(0, loadedItemsCount).map((product) => (
              <>
                <ShopCard
                  productCategories={product?.product_categories}
                  price={product.price}
                  title={product.title}
                  image={`${baseURL}/${product.image}`}
                  htmlDesctiption={product.description}
                  className="col-12 col-md-6"
                  onClick={(event) => {
                    let searchParams = new URLSearchParams(window.location.search);
                    searchParams.set("id", id);
                    searchParams.set("linkCode", linkCode);
                    event.preventDefault();
                    // window.open(`${window.location.origin}/shop/${product.slug}?${searchParams}`, '_blank');
                    navigate(`/shop/${product.slug}?${searchParams}`);
                  }}
                />
              </>
            ))}
        {loadedItemsCount < products.length && (
            <div className="row">
                <CartButton title="Load More" className="btnHover justify-content-center d-flex" style={{width:"18%"}} onClick={loadMore} />
            </div>
        )}
        </div>
      </div>
    </div>
  );
}

export default Allflavours;
