import React from 'react'

function Aboutbg() {
  return (
    <div className='aboutbg'>
        
    </div>
  )
}

export default Aboutbg