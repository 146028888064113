import React from "react";

function Storefooter() {
  return (
    <div className="store-footer">
      <div className="container py-3">
        <div className="row justify-content-between">
          <div className="col-md-5 text-start">
            <div>
              <p>© Popcorn World 2024. All rights reserved.</p>
            </div>
          </div>
          <div className="col-md-3 text-end">
            <div className="d-flex gap-3">
              <a href="/terms-and-conditions">Terms of service</a>
              <a href="/privacy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Storefooter;
