import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import Coach from '../../Components/Elements/Store-Demo/Coach'
import Storefootertop from '../../Components/Elements/Store-Demo/Storefootertop'
import Storefooter from '../../Components/Elements/Store-Demo/Storefooter'
import Make from '../../Components/Elements/Store-Demo/Make'
import Looking from '../../Components/Elements/Store-Demo/Looking'
import Do from '../../Components/Elements/Store-Demo/Do'
import Collection from '../../Components/Elements/Store-Demo/Collection'
import { getShopCollections, getShopProduct, getSingleCampaign } from '../../Services';
import { useDispatch, useSelector } from "react-redux";
import ShopCart from '../Cart/ShopCart';
import Loader from '../../Components/Loader/Loader';
import TabTitle from '../../Services/TabTitle';
import { GoArrowRight } from "react-icons/go";

function Demo() {
  const [openCart, setOpenCart] = useState(false);
  const dispatch = useDispatch();
  const param = useParams();
  const [state, updateState] = useState({
    loader: true,
    campaign: {}
  });
  TabTitle(`${state?.campaign?.campaign_title ? state?.campaign?.campaign_title : 'Campaign'}`)
  const redux = useSelector((state) => state.app);

  const navigate = useNavigate();

  const setState = (object) => {
    updateState((prev) => ({
      ...prev,
      ...object,
    }));
  };

  const getCampaign = async (slug) => {
    try {
      setState({ loader: true });
      const response = await getSingleCampaign(slug);
      if (response.data.data) {
        setState({
          campaign: response.data.data,
          loader: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    let success = () => {
      // setState({loader:false});
    }
    dispatch(getShopProduct(success));
    dispatch(getShopCollections());
  }, []);

  useEffect(() => {
    if (param?.slug != undefined) {
      getCampaign(param.slug)
    } else {
      setState({
        ...state,
        loader: false
      })
    }
  }, [param]);

  return state.loader ?
    <Loader /> : (
      <div>
        <Coach data={state.campaign} />
        <Collection openCart={() => { setOpenCart(true) }} onHideCart={() => { setOpenCart(false) }} data={state.campaign} collections={redux.shopCollections} products={redux.shopProducts} />
        <Make openCart={() => { setOpenCart(true) }} linkCode={state?.campaign?.link?.link_code} />
        <Looking />
        <Do />
        <Storefootertop />
        <Storefooter />
        <ShopCart openCart={openCart} onHideCart={() => { setOpenCart(false) }} />
      </div>
    )
}

export default Demo