import React, { useEffect, useState } from 'react'
import Bloghero from '../../Components/Elements/Stories/Bloghero'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Allblogs from '../../Components/Elements/Stories/Allblogs'
import TabTitle from '../../Services/TabTitle'

function Blogs() {
  TabTitle('Blogs')
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar bgColor={"white"} />
      <Bloghero loader={loader} />
      <Allblogs setLoader={setLoader} loader={loader} />
      <Footer />
    </div>
  )
}

export default Blogs