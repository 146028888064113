import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

function Blogspage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://alphadevelopers.co.uk/api/get-blogs?limit=3"
      );
      if (!response.ok) {
        console.log("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.data.blogs);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function convertDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const truncatedText = text.substr(0, text.lastIndexOf(" ", maxLength));
    return truncatedText + ".";
  };

  return (
    <div className="blogs">
      <div className="container py-5">
        <div className="row justify-content-center text-center">
          <div className="col-md-12">
            <div className="tips">
              <h1>Discover what makes a Popcorn World Career so rewarding</h1>
            </div>
          </div>
        </div>
        <div className="row">
          {data?.map((blog) => (
            <div className="col-md-4 mb-3 blogcard pb-2"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/blog/${blog?.slug}`, { state: blog.slug })} key={blog.id}>
              <div
                className="card"
                onClick={() => navigate("/blog-open", { state: blog.slug })}
              >
                <div className="card-body px-0 pt-0">
                  <div>
                    <img
                      src={`https://alphadevelopers.co.uk/${blog.image}`}
                      className="img-fluid finger-img"
                      alt="finger1"
                    />
                  </div>
                  <div className="bg-dark camera py-2">
                    <div className="d-flex gap-2">
                      <span>Blog</span>
                    </div>
                  </div>
                  <div className="px-3 pt-1 learn">
                    <h4>{blog.title}</h4>
                  </div>
                  <div className="px-3">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: truncateText(blog.description, 100),
                      }}
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="d-good">Fundraiser</span>
                  </div>
                  <div>
                    <span className="date">{convertDate(blog.created_at)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row text-center py-5">
          <div>
            <button className="btn2" onClick={() => navigate("/blogs")}>
              Explore All Blog Posts
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogspage;
