import React from "react";

function Download() {
  return (
    <div className="download">
      <div className="container">
        <div className="row justify-content-center text-center pb-4">
          <div className="col-md-9 ">
            <h1>Download our tools. Supercharge your fundraiser.</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <div>
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/down1.png"
                }
                className="img-fluid"
                alt="download1"
              />
            </div>
            <div>
              <h2>Flyers and Handouts</h2>
            </div>
            <div>
              <p>
                Sometimes it’s hard to find the right thing to say, so we’ve
                given you some options that will be sure to catch some eyes!F
              </p>
            </div>
            <div>{/* <button className="btn">Download</button> */}</div>
          </div>
          <div className="col-md-6 mb-3">
            <div>
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/down2.png"
                }
                className="img-fluid"
                alt="download1"
              />
            </div>
            <div>
              <h2>Social Media Kit</h2>
            </div>
            <div>
              <p>
                Our social media kit contains resources for your team to promote
                your fundraiser. The kit includes fun, engaging Facebook and
                Instagram templates to customize for your events.
              </p>
            </div>
            <div>{/* <button className="btn">Download</button> */}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
