import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Openhero from '../../Components/Elements/OpenedBlogs.jsx/Openhero'
import Inspired from '../../Components/Elements/OpenedBlogs.jsx/Inspired'
import Recentblog from '../../Components/Elements/OpenedBlogs.jsx/Recentblog'
// import { useLocation, useParams } from 'react-router-dom'

function Openblog() {
  const [loader, setLoader] = useState(true);
 
  // const [data, setData] = useState([]);
  // const state = useLocation()
  // console.log(state.state)
  // const {slug} = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(()=>{
  //   const fetchData = async () => {
  //     try {
  //       // const response = await fetch(`https://alphadevelopers.co.uk/api/get-blog?slug=${state.state}`);
  //       const response = await fetch(`https://alphadevelopers.co.uk/api/get-blog?slug=${slug}`);
  //       if (!response.ok) {
  //         console.log('Network response was not ok');
  //       }
  //       const jsonData = await response.json();
  //       console.log(jsonData)
  //       setData(jsonData);
  //       if(response){
  //         console.log("response : ", response);
  //         console.log("json converted res : ", jsonData);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData()
  // },[])

  return (
    <div>
        <Navbar bgColor={"white"} />
        <Openhero loader={loader} setLoader={setLoader} />
        {loader === false && (
          <>
          <Inspired/>
          <Recentblog/>
          <Footer/>
          </>
        )}
    </div>
  );
}

export default Openblog;
