import React, { useState } from 'react';
import './Dropdown.css';

const Dropdown = ({ label, options , onChange,value ,name, error, onBlur,disabled}) => {
    const [selectedValue, setSelectedValue] = useState('');
    const [click, setClick] = useState(true);
  
    const handleSelectChange = (event) => {
      setSelectedValue(event.target.value);
    };
    
    return (
      <div className={`form-floating mt-2 mb-2 `} onBlur={onBlur}>
        <select
          style={{color:(!click || !value)?"#757575":"black",textAlign:'center'}}
          name={name}
          className="form-select"
          id={name}
          aria-label={name}
          value={value}
          // onClick={()=>{setClick(!click)}}
          // onBlur={()=>{setClick(true)}}
          onChange={onChange}
          disabled={disabled}
        >
          <option value="">{`Select ${label}`}</option>
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <label htmlFor={name}>{(!click || value)?label:''}</label>
        {
          (error) &&  (
            <p className="text-danger px-1 mt-1">{error}</p>
          )
        }
      </div>
    );
  };
  
  export default Dropdown;