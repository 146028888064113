import React from 'react'
import Toolkithero from '../../Components/Elements/Toolkit/Toolkithero'
import Get from '../../Components/Elements/Toolkit/Get'
import Finger from '../../Components/Elements/Toolkit/Finger'
import Download from '../../Components/Elements/Toolkit/Download'
import Popular from '../../Components/Elements/Toolkit/Popular'
import Need from '../../Components/Elements/Toolkit/Need'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Goodback1 from '../../Components/Elements/Toolkit/Goodback1'
import TabTitle from '../../Services/TabTitle'

function Toolkit() {
  TabTitle('Toolkits')
  return (
    <div>
      <Navbar bgColor={"white"} />
      <Toolkithero />
      <Get />
      <Finger />
      <Download />
      <Popular />
      {/* <Good/> */}
      <Goodback1 />
      <Need />
      <Footer />

    </div>
  )
}

export default Toolkit