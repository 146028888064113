import React from 'react'

function Good() {
  return (
    <div className='good'>
         <div className="container">
        <div className="row justify-content-center py-5">
          <div className="col-md-12 text-center">
            <div>
              <h1>Good teams have good leaders</h1>
            </div>
            <div>
              <p>
                Get to know and connect with our department leads a little
                deeper. Learn how their jobs fuel their passion to support their
                communities day in and day out.
              </p>
            </div>
            {/* <div>
              <button className="btn rounded-5">Meet Our Leadership</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Good