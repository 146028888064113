import React from "react";
import { useNavigate } from "react-router-dom";

function Joyful() {
  const navigate = useNavigate();
  return (
    <div className="joyful">
      <div className="container">
        <div className="row text-center pt-5">
          <h1>Joyful people. Committed to good.</h1>
        </div>
        <div className="row justify-content-between comm pt-5">
          <div className="col-md-5 mb-4">
            <div>
              <h1>A community of joy-makers</h1>
            </div>
            <div>
              <p>
                Popcorn World is a community of like-minded dreamers, do-gooders,
                believers, and thinkers committed to helping kids do what they
                love..F
              </p>
            </div>
            <div>
              <button
                className="btn rounded-5"
                onClick={() => navigate("/our-people")}
              >
                Meet Our People
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="comunity">
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + "/assets/images/custom.png"
                }
                className="img-fluid"
                alt="community"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-between comm pt-5">
          <div className="col-md-6 mb-4 ">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/tech.png"}
              className="img-fluid"
              alt="tech"
            />
          </div>
          <div className="col-md-5">
            <div>
              <h1>A community of joy-makers</h1>
            </div>
            <div>
              <p>
                Popcorn World is a community of like-minded dreamers, do-gooders,
                believers, and thinkers committed to helping kids do what they
                love..F
              </p>
            </div>
            <div>
              <button
                className="btn rounded-5"
                onClick={() => navigate("/our-culture")}
              >
                Explore Our Culture
              </button>
            </div>
          </div>
        </div>
        <div className="row justify-content-between comm pt-5">
          <div className="col-md-5 mb-4">
            <div>
              <h1>A community of joy-makers</h1>
            </div>
            <div>
              <p>
                Popcorn World is a community of like-minded dreamers, do-gooders,
                believers, and thinkers committed to helping kids do what they
                love..F
              </p>
            </div>
            <div>
              <button
                className="btn rounded-5"
                onClick={() => navigate("/our-places")}
              >
                Explore Our Places
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/custom.png"
              }
              className="img-fluid"
              alt="custom"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Joyful;
