import React from "react";

function Downtown() {
  return (
    <div className="production">
      <div className="container-fluid px-0 pt-4">
        <div className="row justify-content-between">
          <div className="col-md-5  mb-3">
            <div>
              <h1>Downtown Office</h1>
            </div>
            <div>
              <p>
                Located in downtown Chicago’s Willis Tower, our vibrant
                corporate offices feature stunning views of Lake Michigan and
                Chicago's skyline from the 44th floor, exclusive access to a
                whole host of sweet amenities, and a dynamic, inspirational work
                environment.
              </p>
            </div>
          </div>
          <div className="col-md-6  mb-3">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="d-flex gap-2">
                  <div className=" pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>700 feet</h1>
                    </div>
                    <div>
                      <span>over Chicago</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 mb-3">
                <div className="d-flex gap-2">
                  <div className=" pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>545 feet</h1>
                    </div>
                    <div>
                      <span>to the Brown Line</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="d-flex gap-2">
                  <div className="pro-bg p-3">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/download.svg"
                      }
                      className="img-fluid"
                      alt="popcorn"
                    />
                  </div>
                  <div>
                    <div>
                      <h1>2 Blocks</h1>
                    </div>
                    <div>
                      <span>to Union Station</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/d1.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/d2.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
          <div className="col-md-4 mb-3">
            <img
              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/d3.png"}
              className="img-fluid proimage"
              alt="p1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Downtown;
