import React from 'react'
import Placehero from '../../Components/Elements/Places/Placehero'
import Placefeedback from '../../Components/Elements/Places/Placefeedback'
import Comeplace from '../../Components/Elements/Places/Comeplace'
import Meetplace from '../../Components/Elements/Places/Meetplace'
import Location from '../../Components/Elements/Places/Location'
import Placetabs from '../../Components/Elements/Places/Placetabs'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import Joy from '../../Components/Elements/Places/Joy'
import TabTitle from '../../Services/TabTitle'

function Place() {
  TabTitle('Our Places')
  return (
    <div className="our-place" >
      <Navbar bgColor={"white"} />
      <Placehero />
      <Placetabs />
      <Joy />
      <Location />
      <Placefeedback />
      <Comeplace />
      <Meetplace />
      <Footer />
    </div>
  )
}

export default Place