import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    // slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    // slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
function Contribute() {
  return (
    <div className="contribute">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <div>
              <h1>How to contribute</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <Carousel 
          responsive={responsive} 
          infinite={false}
           arrows={false}
          showDots={true}
          >
            <div className="px-3 text-center">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/cont1.png"}
                  className="img-fluid"
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>Add a donation to your cart</h3>
              </div>
              <div>
                <p>
                  When contributing to a fundraiser through a Pop-Up Store or
                  when shopping through the site, add a donation to your cart.
                </p>
              </div>
            </div>
            </div>
            <div className="px-3 text-center">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/cont2.png"}
                  className="img-fluid "
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>We ship popcorn directly to recipients</h3>
              </div>
              <div>
                <p>
                  Our delicious, award-winning popcorn is sent on your behalf to
                  health and education workers who support America’s youth.
                </p>
              </div>
            </div>
            </div>
            <div className="px-3 text-center">
            <div className="card">
              <div>
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/cont3.png"}
                  className="img-fluid "
                  alt="buy1"
                />
              </div>
              <div className="text-center">
                <h3>Feel doubly good about raising joy</h3>
              </div>
              <div>
                <p>
                  Check your email for updates about who received your donation
                  and witness firsthand how your popcorn donation helped someone
                  feel joy and appreciated.
                </p>
              </div>
            </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Contribute;
