import React, { useState } from "react";
import { GoPlus } from "react-icons/go";
import { HiMiniMinus } from "react-icons/hi2";
import { useSelector } from "react-redux";

function Find() {
  const [showAllRows, setShowAllRows] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;

  const toggleRows = () => {
    setShowAllRows(!showAllRows);
    setShowButton(!showButton);
  };

  const renderRows = () => {
    const rowsData = [
      {
        label: "Average Amount Sold",
        virtual: `${base_currency}400 per participant`,
        brochure: `@${base_currency}160 per participant`,
      },
      {
        label: "Fundraiser timeline",
        virtual:
          "4 days fundraising window, scheduled up to 2 months in advance",
        brochure: "Organizers choose how long to run their fundraiser",
      },
      {
        label: "50% Profit",
        virtual: (
          <div>
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/download.svg"
              }
              width={50}
              className="p-2"
              style={{ backgroundColor: "#ffb600FF", borderRadius: "50%" }}
              alt="download"
            />
          </div>
        ),
        brochure: (
          <div>
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/download.svg"
              }
              width={50}
              className="p-2"
              style={{ backgroundColor: "#ffb600FF", borderRadius: "50%" }}
              alt="download"
            />
          </div>
        ),
      },
      {
        label: "No fees, minimums or upfront costs",
        virtual: (
          <div>
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/download.svg"
              }
              width={50}
              className="p-2"
              style={{ backgroundColor: "#ffb600FF", borderRadius: "50%" }}
              alt="download"
            />
          </div>
        ),
        brochure: (
          <div>
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/download.svg"
              }
              width={50}
              className="p-2"
              style={{ backgroundColor: "#ffb600FF", borderRadius: "50%" }}
              alt="download"
            />
          </div>
        ),
      },
      {
        label: "Real-time sales notifications",
        virtual: (
          <div>
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/download.svg"
              }
              width={50}
              className="p-2"
              style={{ backgroundColor: "#ffb600FF", borderRadius: "50%" }}
              alt="download"
            />
          </div>
        ),
        brochure: (
          <div>
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/download.svg"
              }
              width={50}
              className="p-2"
              style={{ backgroundColor: "#ffb600FF", borderRadius: "50%" }}
              alt="download"
            />
          </div>
        ),
      },
      {
        label: "Real-time participant leaderboard",
        virtual: (
          <div>
            <img
              src={
                process.env.REACT_APP_PUBLIC_URL + "/assets/images/download.svg"
              }
              width={50}
              className="p-2"
              style={{ backgroundColor: "#ffb600FF", borderRadius: "50%" }}
              alt="download"
            />
          </div>
        ),
        brochure: "",
      },
      {
        label: "Participation",
        virtual:
          "Participants share their Pop-Up Stop link via text, email, and social media",
        brochure: "Participants take home a printed brochure order form",
      },
      {
        label: "Order Placement",
        virtual: "Supporters place their orders online",
        brochure: "Organizers tally forms and place order",
      },
      {
        label: "Shipping",
        virtual: "Orders ship directly to the supporter, anywhere in the USA",
        brochure:
          "A bulk popcorn order ships to the organizer, sorted by participant",
      },
      {
        label: "Supporter location",
        virtual: (
          <div>Supporters can buy and receive orders anywhere in the USA </div>
        ),
        brochure: (
          <div>
            Supporters must live locally to organizer to buy and receive orders
          </div>
        ),
      },
      // { label: '', virtual: <button className="btn2">Learn More</button>, brochure: <button className="btn2">Request Brochure</button> }
    ];

    if (!showAllRows) {
      return rowsData.slice(0, 3).map((row, index) => (
        <tr key={index}>
          <td className="text-start row1">{row.label}</td>
          <td className="row2">{row.virtual}</td>
          <td className="row2 last-child">{row.brochure}</td>
        </tr>
      ));
    } else {
      return rowsData.map((row, index) => (
        <tr key={index}>
          <td className="text-start row1">{row.label}</td>
          <td className="row2">{row.virtual}</td>
          <td className="row2 last-child">{row.brochure}</td>
        </tr>
      ));
    }
  };

  return (
    <div className="find">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-9">
            <div>
              <h1>
                Find the Popcorn World fundraising experience that’s right for you
              </h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-10">
            <div>
              <p>
                Whether you’re an athletic team, school, not-for-profit, or just
                need to fundraise, you can easily and quickly organize a Double
                Good popcorn fundraiser that meets your needs.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" className="virtual">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/table1.png"
                      }
                      className="w-25 py-3"
                      alt="table1"
                    />
                    <h3>Virtual Fundraising</h3>
                  </th>
                  <th scope="col">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "/assets/images/table2.png"
                      }
                      className=" w-50 py-3"
                      alt="table1"
                    />
                    <h3>Brochure Fundraising</h3>
                  </th>
                </tr>
              </thead>
              <tbody>{renderRows()}</tbody>
            </table>
          </div>
        </div>
        {showButton ? (
          <div className="row">
            <div className="d-flex justify-content-around pb-3">
              <div className="col-md-4"></div>
              {/* <div className="col-md-4 text-center">
                <button className="btn2">Learn More</button>
              </div>
              <div className="col-md-4 text-center">
                <button className="btn2">Request Brochure</button>
              </div> */}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row text-center">
          <div>
            <button className="btn1" onClick={toggleRows}>
              {showAllRows ? (
                <>
                  See less <HiMiniMinus size={20} color="white" />
                </>
              ) : (
                <>
                  See more <GoPlus size={20} color="white" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Find;
