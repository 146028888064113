import { useSelector } from "react-redux";
import React from "react";
import "./index.css";

import CollectionImage from "../../assets/images/collectionImage.webp";

const ShopCard = ({
  className,
  title,
  price,
  htmlDesctiption,
  image,
  onClick,
  productCategories,
}) => {
  return (
    <div className={`${className} cardItem`} onClick={onClick}>
      <div className="cardItemS1">
        <div className="d-flex flex-column justify-content-end cardImage">
          <img src={image} style={{ "border-radius": "5px" }} />
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-between">
        <div className="cardTitle cardTitle-underline">{title}</div>
        <div className="cardTitle cardTitle-underline">{price}</div>
      </div>
      <div className="cardHeadingOne">
        {productCategories?.map((obj, index, array) => (
          <React.Fragment key={obj.category.id}>
            <span>{obj.category.name}</span>
            {index < array.length - 1 && <span> , </span>}
          </React.Fragment>
        ))}
      </div>

      <div
        className="cardDescription "
        dangerouslySetInnerHTML={{ __html: htmlDesctiption }}
      ></div>
    </div>
  );
};

export default ShopCard;

export const ShopCollection = ({ className, title, description, price, image, onClick, }) => {
  return (
    <div className={`${className} cardItem`}>
      <div className="shopCardItem">
        <img src={image ? image : CollectionImage} />
      </div>
      <div className="cardHeadingOne mt-3">{title}</div>
      <div className="mt-1 d-flex justify-content-between">
        <div className="cardTitle cardTitle-underline">{title}</div>

        <div className="cardTitle">{price}</div>
      </div>
      <div className="cardDescription"
        dangerouslySetInnerHTML={{ __html: description }}
      >
      </div>
      <CartButton title="Add to Cart" onClick={onClick} />
    </div>
  );
};

export const CartButton = ({ title, onClick, className, style, price, disabled, bgColor, border }) => {

  const redux = useSelector((state) => state?.app);
  let base_currency = redux?.globalSettings?.find(
    (obj) => obj.config_key == "campaign_currency"
  )?.config_value;
  return (
    <div
      className={`${disabled ? 'cardLastSection-disabled' : ''} cardLastSection ${className} `}
      onClick={onClick}
    >
      <button
        className={`cardButton ${className} `}
        style={{ ...style, backgroundColor: bgColor, border: border }}
        disabled={disabled}
      ><div>{title}</div><div className="ml-2" style={{ width: '20%', display: price ? '' : 'none' }}>{base_currency}{price}</div></button>
    </div>
  );
};
