import { toast } from "react-toastify";
import { APP } from "../Redux/ActionTypes";
import axios from "./axios";
import {
  create_order,
  get_global_settings,
  calculate_shipping_charges,
  get_shop_collections,
  get_shop_products,
  get_single_product,
  verify_otp,
  verify_otp_code,
  get_campaigns,
  get_single_campaign,
  track_order,
  check_email_exists,
  get_customer_profile,
  update_customer_profile,
} from "./serviceVariables";

export const getTableData = async (table) => {
  try {
    const response = await axios.get(table);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getShopProduct = (success, fail) => async (dispatch) => {
  try {
    const response = await axios.get(`${get_shop_products}`);
    if (response?.data?.status === 200) {
      let data = response?.data?.data;
      dispatch({ type: APP.SHOP_PRODUCTS, payload: data });
      success && success(response?.data);
      return response;
    }
  } catch (error) {
    fail && fail();
    return error;
  }
};

export const getShopCollections = (success, fail) => async (dispatch) => {
  try {
    const response = await axios.get(`${get_shop_collections}`);
    if (response?.data?.status === 200) {
      let data = response?.data?.data;
      dispatch({ type: APP.SHOP_COLLECTIONS, payload: data });
      success && success(response?.data);
      return response;
    }
  } catch (error) {
    fail && fail();
    return error;
  }
};

export const getSingleProduct = async (slug, success, fail) => {
  try {
    const response = await axios.get(`${get_single_product}?slug=${slug}`);
    if (response?.data?.status === 200) {
      let data = response?.data?.data;
      success && success(data);
      return response?.data?.data;
    }
  } catch (error) {
    fail && fail();
    return error;
  }
};

export const verifyDetail = async (payload, success, fail) => {
  // axios.post(`${verify_otp}`, payload).then((res) => console.log("Res : ", res)).catch((err) => console.log("err : ", err));
  try {
    const response = await axios.post(`${verify_otp}`, payload);
    console.log(response)
    if (response?.data?.status === 200) {
      let data = response?.data;
      success && success(data);
      return response;
    }
    if (response?.data?.status === 400) {
      let data = response?.data;
      fail && fail(data);
      // toast.error(response?.data?.message);
      return response;
    }
  } catch (error) {
    console.log("error : ", error);
    fail && fail();
    return error;
  }
};

export const verifyOtpCode = async (payload, success, fail) => {
  axios
    .post(`${verify_otp_code}`, payload)
    .then((res) => console.log("Res : ", res))
    .catch((err) => console.log("err : ", err));
  try {
    const response = await axios.post(`${verify_otp_code}`, payload);
    if (response?.status == 200) {
      if (response?.data?.status == 400) {
        fail();
        return response;
      }
      if (response?.data?.status == 200) {
        success();
        return response;
      }
    }
    if (response?.status == 400) {
      toast.error(response.data.message);
    }
  } catch (error) {
    console.log("Error : ", error);
    fail && fail();
    return error;
  }
};

export const calculateShippingCharges = async (payload, success, fail) => {
  try {
    const response = await axios.post(`${calculate_shipping_charges}`, payload);
    console.log("Response : ", response);
    if (response.status == 200) {
      if (response.data.status == 400) {
        // toast.success(response.data.message);
        fail();
        return response;
      }
      if (response.data.status == 200) {
        // toast.success(response.data.message);
        success();
        return response;
      }
    }
  } catch (error) {
    console.log("error", error);
    if (error.response?.request?.status == 500) {
      // toast.success("Shipping method not available to provided address");
      fail();
      return error;

    } else {
      // toast.error(error?.response.data.message);
      fail();
      return error;

    }
  }
};

export const createOrder = async (payload, success, fail) => {
  try {
    // console.log("payload : ", payload);
    const response = await axios.post(`${create_order}`, payload);
    if (response.status == 200) {
      if (response.data.status == 400) {
        fail && fail();
        return response;
      }
      if (response.data.status == 200) {
        // toast.success(response.data.data);
        success && success();
        return response;
      }
    }
  } catch (error) {
    fail && fail();
    console.log("error catch in api : ", error);
    return error;
  }
};

export const globalSettings = () => async (dispatch) => {
  try {
    const response = await axios.get(`${get_global_settings}`);
    if (response.status == 200) {
      dispatch({ type: APP.GLOBAL_SETTINGS, payload: response.data.data });
    }
  } catch (error) {
    return error;
  }
};

export const getCampaigns = () => async (dispatch) => {
  try {
    const response = await axios.get(`${get_campaigns}`);
    if (response.status == 200) {
      dispatch({ type: APP.GET_CAMPAIGNS, payload: response.data.data });
    }
  } catch (error) {
    return error;
  }
};

export const getSingleCampaign = async (slug) => {
  try {
    const response = await axios.get(`${get_single_campaign}?slug=${slug}`);
    if (response.status == 200) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const trackOrder = async (payload) => {
  try {
    const response = await axios.get(`${track_order}`, payload);
    if (response?.status == 200) {
      if (response?.data?.status == 400) {
        return response;
      }
      if (response?.data?.status == 200) {
        return response;
      }
    }
    if (response?.status == 400) {
      return response;
    }
  } catch (error) {
    console.log("Error : ", error);
    return error;
  }
};


export const getCustomerProfile = async (payload) => {
  try {
    const response = await axios.get(`${get_customer_profile}`, payload);
    if (response?.status == 200) {
      if (response?.data?.status == 200) {
        return response;
      }
    }
    if (response?.status == 400) {
      // toast.error(response.data.message);
    }
  } catch (error) {
    console.log("Error : ", error);
    return error;
  }
};

export const updateCustomerProfile = async (payload) => {
  try {
    const response = await axios.post(`${update_customer_profile}`, payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': '*/*'
        }
      });
    console.log("response out", response)
    if (response?.request?.status == 200) {
      if (response?.data?.message == 'User information updated successfully') {
        return response;
      }
    }
    if (response?.status == 400) {
      // toast.error(response.data.message);
    }
  } catch (error) {
    console.log("Error : ", error);
    // toast.error(error);
    // return error;
  }
};

export const checkEmailAlreadyExists = async (payload) => {
  try {
    const response = await axios.get(
      `${check_email_exists}?user_type=fundraiser&email_address=${payload?.email_address}`
    );
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
